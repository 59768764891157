import React from 'react';
import { StyleSheet, FlatList, Text, View } from 'react-native';

const CardView = ({ siteDetails }) => {
    return (
        <View style={styles.cardContainer}>
            {Object.entries(siteDetails).map(([key, value], index) => (
                <View key={key} style={[styles.card, index !== 0 && styles.cardWithTopMargin]}>
                    <Text style={styles.siteName}>Site Name: {value.siteName}</Text>
                    <FlatList
                        data={Object.entries(value.materialUsed)}
                        keyExtractor={(item) => item[0]}
                        numColumns={2}
                        renderItem={({ item }) => (
                            <View style={styles.item}>
                                <Text style={styles.itemText}>{item[0]}: {item[1]}</Text>
                            </View>
                        )}
                    />
                </View>
            ))}
        </View>
    );
}

const styles = StyleSheet.create({
    cardContainer: {
        marginTop: 20,
        paddingHorizontal: 10,
    },
    card: {
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 20,
        marginBottom: 20,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    cardWithTopMargin: {
        marginTop: 20,
    },
    siteName: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    item: {
        padding: 10,
        marginVertical: 5,
        backgroundColor: '#f9f9f9',
        borderRadius: 5,
        borderColor: '#ddd',
        borderWidth: 1,
        flex: 1,
        margin: 5,
        alignItems: 'center',
    },
    itemText: {
        fontSize: 14,
    },
});

export default CardView;
