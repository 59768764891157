import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Text, View, StyleSheet, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Colors } from '../constants/theme';
import { FontAwesome5 } from '@expo/vector-icons';
import Button from '../components/Button';
import useDataStore from '../stores/dataStore';
import { useNavigation } from '@react-navigation/native';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { EVENT_ACTIONS } from '../constants/Constant';
import { formatDate } from '../utils/dateUtil';
import { removeLeadingZero } from '../utils/commonUtils';
import { makeApiRequest } from '../utils/apiUtil';
import DateTimePicker from 'react-native-ui-datepicker';
import Popup from "../components/popup";

const AddProductionExpenseScreen = ({ route }) => {
    const [line, setLine] = useState('');
    const [price, setPrice] = useState('');
    const [comment, setComment] = useState('');
    const [isFocus, setIsFocus] = useState(null);
    const [error, setError] = useState('');
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
    const [API_CONFIG, userData, thekedarData, setToastMessage, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => ([state.API_CONFIG, state.userData, state.thekedarData, state.setToastMessage, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]));
    const { name, type, userID, transactionId } = route.params;
    const navigation = useNavigation();
    const [chosenDate, setChosenDate] = useState(new Date());
    const [loading, setLoading] = useState(false);

    let time = new Date();
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let url, payload;

    useLayoutEffect(() => {
        navigation.setOptions({
            title: `${name}`
        });
    }, [navigation]);

    useEffect(() => {
        if (transactionId) {
            fetchData();
        }
    }, [transactionId]);

    const fetchData = async () => {
        try {
            const response = await makeApiRequest('get', `${API_CONFIG.UNSETTLED_EXPENSES}/productionExpenses/${userID}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            let transactionData = getTransactionDetails(response, transactionId);
            if (transactionData) {
                setLine(transactionData.line);
                setPrice(transactionData.price);
                setComment(transactionData?.comment);
            }
        } catch (error) {
            handleAlertForError(error);
        }
    }

    function getTransactionDetails(data, transactionId) {
        if (data[transactionId]) {
            return data[transactionId];
        } else {
            console.log('Transaction ID not found in the data.');
            return null;
        }
    }

    const validateTransaction = () => {
        if (!line) {
            setError('Please enter line.');
            return false;
        }
        else if (!price) {
            setError('Please enter price.');
            return false;
        }
        setError('');
        return true;
    };
    const showDatePicker = () => {
        setDatePickerVisibility(true);
    };

    const hideDatePicker = () => {
        setDatePickerVisibility(false);
    };

    const handleConfirm = (date) => {
        setChosenDate(date);
        hideDatePicker();
    };

    const pushData = async () => {
        try {
            setLoading(true);
            if (transactionId) {
                url = `${API_CONFIG.UNSETTLED_EXPENSES}/productionExpenses/${userID}/${transactionId}`
                payload = {
                    line: line,
                    price: price,
                    amount: line * price,
                    date: formatDate(chosenDate),
                    userId: `${userID}`,
                    time: chosenDate.toDateString() !== today.toDateString() ? "11:59:59 PM" : time.toLocaleTimeString("en-US"),
                    pathToAdd: `productionExpenses/${userID}/${transactionId}`,
                    comment: comment
                };
                await makeApiRequest("put", url, authToken, refreshToken, setAuthToken, setRefreshToken, payload);
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_EDIT_WORK_CLICKED, userData);
                setLine("");
                setPrice("");
                setComment("");
                setLoading(false);
                setToastMessage("Production Expense Updated Successfully");
                navigation.goBack();
            }
            else {
                url = `${API_CONFIG.UNSETTLED_EXPENSES}/productionExpenses`;
                payload = {
                    amount: line * price,
                    date: formatDate(chosenDate),
                    time: chosenDate.toDateString() !== today.toDateString() ? "11:59:59 PM" : time.toLocaleTimeString("en-US"),
                    userId: `${userID}`,
                    actionBy: userData.username,
                    comment: comment,
                    line: line,
                    price: price
                };
                const data = await makeApiRequest("post", url, authToken, refreshToken, setAuthToken, setRefreshToken, payload);
                console.log("Pushing Production Expense", data);
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_ADD_WORK_CLICKED);
                setLine("");
                setPrice("");
                setComment("");
                setLoading(false);
                setToastMessage("Production Expense Added Successfully");
                navigation.goBack();
            }
        } catch (error) {
            handleAlertForError(error);
        }
    }

    const submitTransactionDetails = () => {
        if (validateTransaction()) {
            pushData();
        }
    }

    return (
        <View style={styles.container}>
            {loading && (
                <View style={styles.overlay}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )}
            <TextInput
                placeholder='Enter Line'
                value={line}
                keyboardType="numeric"
                onChangeText={(text) => setLine(removeLeadingZero(text))}
                style={[styles.input, isFocus === 'line' && { borderColor: Colors.primary }]}
                onFocus={() => setIsFocus('line')}
            />
            <TextInput
                placeholder='Enter price per line'
                value={price}
                keyboardType="numeric"
                onChangeText={(text) => setPrice(removeLeadingZero(text))}
                style={[styles.input, isFocus === 'price' && { borderColor: Colors.primary }]}
                onFocus={() => setIsFocus('price')}
            />
            <Text style={styles.totalAmountText}>{"Total Amount : " + line * price}</Text>
            <TouchableOpacity onPress={showDatePicker}>
                <View style={styles.datePickerView}>
                    <FontAwesome5
                        name="calendar"
                        size={24}
                        color={Colors.primary}
                    />
                    <Text style={styles.datePickerText}> {chosenDate.toDateString()}</Text>
                    <FontAwesome5
                        name="caret-down"
                        size={24}
                        color={Colors.primary}
                    />
                    <Popup visible={isDatePickerVisible} onClose={() => setDatePickerVisibility(false)}>
                        <DateTimePicker
                            mode="single"
                            date={chosenDate}
                            maxDate={today}
                            onChange={(param) => {
                                handleConfirm(new Date(param.date));
                                setDatePickerVisibility(false)
                            }}
                            headerButtonStyle={{ color: 'green' }}
                        />
                    </Popup>
                </View>
            </TouchableOpacity>
            <TextInput
                style={[styles.input, isFocus === 'comment' && { borderColor: Colors.primary }]}
                placeholder="Write your comment here... (Optional)"
                value={comment}
                onChangeText={(text) => setComment(text)}
                onFocus={() => setIsFocus('comment')}
            />
            {error ? <Text style={{ color: 'red', fontSize: 16, top: 10, alignSelf: "center" }}>{error}</Text> : null}
            <Button
                onPress={submitTransactionDetails}
                btnLabel={`ADD WORK ₹${line * price}`}
                buttonStyle={styles.BtnContainer}
                textStyle={styles.btnText} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    input: {
        borderWidth: 2,
        borderColor: '#636363',
        margin: 10,
        padding: 12,
        fontSize: 18,
        borderRadius: 6,
        width: '90%'
    },
    totalAmountText: {
        margin: 6,
        fontSize: 18,
        textAlign: 'center',
        fontWeight: 'bold',
        color: Colors.gray
    },
    datePickerView: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 55,
        borderColor: '#636363',
        borderRadius: 6,
        paddingHorizontal: 8,
        margin: 12,
        padding: 12,
        width: '55%',
        backgroundColor: "white"
    },
    datePickerText: {
        marginLeft: 10,
        marginRight: 10
    },
    BtnContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        width: '90%',
        position: 'absolute',
        bottom: 0,
        margin: 20,
        backgroundColor: Colors.primary
    },
    btnText: {
        color: 'white',
    },
    overlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
    },
});

export default AddProductionExpenseScreen