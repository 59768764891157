import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, Modal, StyleSheet, FlatList, TextInput } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';

const MultiSelect = ({ options, selectedValues, onSelect, label, singleSelect = false, isDisabled = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        setSelectedItems(selectedValues || []);
    }, [selectedValues]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelection = (value) => {
        let newSelectedItems;
        if (singleSelect) {
            if (selectedValues[0] === value) {
                newSelectedItems = [];
            } else {
                newSelectedItems = [value];
            }
        } else {
            newSelectedItems = [...selectedItems];
            const index = selectedItems.indexOf(value);
            if (index !== -1) {
                newSelectedItems.splice(index, 1);
            } else {
                newSelectedItems.push(value);
            }
        }
        setSelectedItems(newSelectedItems);
        onSelect(newSelectedItems);
        if (singleSelect) {
            closeModal();
        }
    };

    const closeModal = () => {
        setIsOpen(false);
        setSearchQuery(''); // Reset search query when modal is closed
    };

    const renderItem = ({ item }) => (
        <TouchableOpacity
            onPress={() => handleSelection(item.value)}
            style={[styles.option, selectedItems.includes(item.value) && styles.selectedOption]}
        >
            <Text>{item.label}</Text>
        </TouchableOpacity>
    );

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={toggleDropdown} style={styles.toggleButton} disabled={isDisabled}>
                <View style={styles.toggleButtonContent}>
                    <Text style={styles.toggleButtonText} numberOfLines={1} ellipsizeMode="tail">
                        {label}
                    </Text>
                    <Text>{isOpen ? '▲' : '▼'}</Text>
                </View>
            </TouchableOpacity>
            <Modal
                visible={isOpen}
                transparent={true}
                onRequestClose={closeModal}
                animationType="slide"
            >
                <View style={styles.modalContent}>
                    <TouchableOpacity style={styles.closeIconContainer} onPress={closeModal}>
                        <MaterialIcons name="close" size={30} color="red" />
                    </TouchableOpacity>
                    {options.length > 0 ? (
                        <View style={styles.dropdown}>
                            {options.length > 5 && (
                                <TextInput
                                    style={styles.searchInput}
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChangeText={setSearchQuery}
                                />
                            )}
                            <FlatList
                                data={filteredOptions}
                                renderItem={renderItem}
                                style={{ maxHeight: '220px', overflow: 'auto' }}
                                keyExtractor={(item) => item.value}
                                ListEmptyComponent={() => (
                                    <Text style={styles.noData}>No Data Found</Text>
                                )}
                            />
                        </View>
                    ) : (
                        <Text style={styles.noData}>No Data Found</Text>
                    )}
                </View>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'relative',
        zIndex: 1,
        top: 5,
        marginRight: 10,
    },
    modalContent: {
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    toggleButton: {
        padding: 8,
        backgroundColor: 'lightgray',
        alignItems: 'center',
        width: 160,
    },
    toggleButtonContent: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    toggleButtonText: {
        paddingHorizontal: 8,
        width: 140
    },
    dropdown: {
        backgroundColor: 'white',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: 'lightgray',
        padding: 10,
        maxHeight: 300,
        width: '60%'
    },
    option: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'lightgray',
    },
    selectedOption: {
        backgroundColor: 'lightblue',
    },
    closeIconContainer: {
        position: 'absolute',
        top: 18,
        right: 18,
        zIndex: 100,
    },
    noData: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 5,
    },
    searchInput: {
        padding: 10,
        borderWidth: 1,
        borderColor: 'lightgray',
        borderRadius: 5,
        marginBottom: 10,
    },
});

export default MultiSelect;
