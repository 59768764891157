import React from 'react';
import { TouchableOpacity, Text, StyleSheet, Keyboard } from 'react-native';
import { Colors } from '../constants/theme';
import useDataStore from '../stores/dataStore';

const Button = ({ onPress, btnLabel, buttonStyle, textStyle, allowedRole }) => {
    const [userData] = useDataStore((state) => [state.userData]);

    return (
        (allowedRole ? allowedRole.includes(userData.role) : true) && <TouchableOpacity onPress={() => {
            onPress();
            Keyboard.dismiss();
        }} style={[styles.button, buttonStyle]}>
            <Text style={[styles.text, textStyle]}>{btnLabel}</Text>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    button: {
        padding: 10,
        borderRadius: 5,
        width: '100%',
        backgroundColor: Colors.accent,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff',
    },
});

export default Button;