import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text, FlatList } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { USERS_API } from "../env-config/api-config";
import useDataStore from '../stores/dataStore';
import { Colors } from '../constants/theme';
import Loader from '../components/Loader';
import { APP_ROLE, EVENT_ACTIONS, USER_TYPE } from '../constants/Constant';
import TransactionList from '../components/TransactionList';
import { useIsFocused } from '@react-navigation/native';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { parseDate } from '../utils/dateUtil';
import { makeApiRequest } from '../utils/apiUtil';

const TransactionHistory = () => {
    const [API_CONFIG, userData, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => [state.API_CONFIG, state.userData, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]);
    const [isFocus, setIsFocus] = useState(null);
    const [allUserList, setAllUserList] = useState([]);
    const [dropdownItems, setDropdownItems] = useState([]);
    const [usersData, setUsersData] = useState();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const isFocused = useIsFocused();
    const [balance, setBalance] = useState('0');
    const [filteredTransactionData, setFilteredTransactionData] = useState([]);
    const [transactionData, setTransactionData] = useState([]);
    let prevDate = null;

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.TRANSACTION_HISTORY_CLICKED, userData);
            getUsersData();
            fetchTransactionData();
            setValue(null);
            setOpen(false);
            setIsFocus(null);
        }
    }, [isFocused]);

    useEffect(() => {
        let modifiedUserList;
        if (userData.role !== APP_ROLE.SUPERVISOR) {
            modifiedUserList = [{ label: 'All', value: 'all' }, ...allUserList];
            setValue("all");
            setIsFocus("picker");
        }
        else {
            modifiedUserList = allUserList;
        }
        setDropdownItems(modifiedUserList);
    }, [allUserList]);

    useEffect(() => {
        if (transactionData) {
            const filteredData = filterTransactionData(transactionData, value);
            setFilteredTransactionData(filteredData);
        }
    }, [value, transactionData]);

    useEffect(() => {
        getBalance();
    }, [value, usersData]);

    const sortDateLatest = (dataArray) => {
        dataArray.sort(function (a, b) {
            var dateA = parseDate(a.date);
            var dateB = parseDate(b.date);
            return dateB - dateA;
        });
        return reverseTransactionsByDate(dataArray);
    };

    function reverseTransactionsByDate(data) {
        const groupedData = {};
        // Step 1: Group the data by date
        for (const key in data) {
            const date = data[key].date;
            if (!groupedData[date]) {
                groupedData[date] = [];
            }
            groupedData[date].push(data[key]);
        }
        // Step 2 and 3: Sort and reverse the data within each group based on time
        for (const date in groupedData) {
            groupedData[date].sort((a, b) => {
                const timeA = new Date(`2000-01-01T${a.time}`).getTime();
                const timeB = new Date(`2000-01-01T${b.time}`).getTime();
                return timeA - timeB;
            });
            groupedData[date].reverse();
        }
        // Step 4: Concatenate the reversed groups
        const reversedData = [];
        for (const date in groupedData) {
            reversedData.push(...groupedData[date]);
        }
        return reversedData;
    }

    const getUsersData = async () => {
        try {
            const usersAllData = await makeApiRequest('get', `${USERS_API}/${userData.factory}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            if (usersAllData) {
                const allUser = Object.values(usersAllData);
                const noViewerData = allUser.filter(user => user.role !== APP_ROLE.VIEWER);
                setUsersData(noViewerData);
                if (userData.role === APP_ROLE.SUPERVISOR) {
                    const onlySupervisor = allUser.filter(user => {
                        return user.role === userData.role && user.username === userData.username;
                    });
                    const usernames = onlySupervisor.map(item => item.username);
                    const allUsernames = usernames.map(username => ({
                        label: username,
                        value: username
                    }));
                    setAllUserList(allUsernames);
                } else {
                    const usernames = noViewerData.map(item => item.username);
                    const allUsernames = usernames.map(username => ({
                        label: username,
                        value: username
                    }));
                    setAllUserList(allUsernames);
                }
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            handleAlertForError(error);
        }
    };

    const getBalance = () => {
        if (value !== 'all') {
            const user = usersData?.find(user => user.username === value);
            if (user) {
                setBalance(user.amount);
            } else {
                setBalance(0);
            }
        }
        else if (value === 'all') {
            let totalAmount = 0;
            for (const key in usersData) {
                if (usersData[key].amount && (usersData[key].role === APP_ROLE.OWNER || usersData[key].role === APP_ROLE.SUPERVISOR)) {
                    totalAmount += parseInt(usersData[key].amount);
                }
            }
            setBalance(totalAmount);
        }
        else {
            setBalance('');
        }
    };

    const fetchTransactionData = async () => {
        try {
            setLoading(true);
            const transactionData = await makeApiRequest('get', API_CONFIG.TRANSACTIONS, authToken, refreshToken, setAuthToken, setRefreshToken);
            const empResponse = await makeApiRequest('get', API_CONFIG.EMPLOYEES, authToken, refreshToken, setAuthToken, setRefreshToken);
            const suppResponse = await makeApiRequest('get', API_CONFIG.SUPPLIERS, authToken, refreshToken, setAuthToken, setRefreshToken);
            const clientResponse = await makeApiRequest('get', API_CONFIG.CLIENTS, authToken, refreshToken, setAuthToken, setRefreshToken);
            const accResponse = await makeApiRequest('get', API_CONFIG.ACCOUNTS, authToken, refreshToken, setAuthToken, setRefreshToken);
            const otherIncomeResponse = await makeApiRequest('get', API_CONFIG.OTHER_INCOME, authToken, refreshToken, setAuthToken, setRefreshToken);
            const siteDetailsData = await makeApiRequest('get', API_CONFIG.SITE_DETAILS, authToken, refreshToken, setAuthToken, setRefreshToken);

            const data = {
                empTransactionData: transactionData[USER_TYPE.EMPLOYEES],
                suppTransactionData: transactionData[USER_TYPE.SUPPLIERS],
                clientTransactionData: transactionData[USER_TYPE.CLIENTS],
                accTransactionData: transactionData[USER_TYPE.ACCOUNTS],
                thekedarTransactionData: transactionData[USER_TYPE.THEKEDARS],
                otherIncomeTransactionData: transactionData[USER_TYPE.INCOME],
                empData: empResponse,
                suppData: suppResponse,
                clientData: clientResponse,
                accData: accResponse,
                otherIncomeData: otherIncomeResponse,
                siteDetailsData: siteDetailsData,
            };

            setTransactionData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching transaction data:', error);
            handleAlertForError(error);
        }
    };

    const filterTransactionData = (data, value) => {
        if (!data || !value) return [];

        const {
            empTransactionData,
            suppTransactionData,
            clientTransactionData,
            accTransactionData,
            thekedarTransactionData,
            otherIncomeTransactionData,
            empData,
            suppData,
            clientData,
            accData,
            otherIncomeData,
            siteDetailsData,
        } = data;

        const empResult = [];
        const suppResult = [];
        const cliResult = [];
        const accResult = [];
        const thekResult = [];
        const otherIncomeResult = [];

        Object.values(empTransactionData || {}).forEach((innerObject) => {
            Object.values(innerObject).forEach((item) => {
                if (value === 'all' || item.actionBy === value) {
                    item['lowerType'] = 'Employee';
                    item['name'] = empData[item.userId]?.name;
                    empResult.push(item);
                }
            });
        });

        Object.values(suppTransactionData || {}).forEach((innerObject) => {
            Object.values(innerObject).forEach((item) => {
                if (value === 'all' || item.actionBy === value) {
                    item['lowerType'] = 'Supplier';
                    item['name'] = suppData[item.userId]?.name;
                    suppResult.push(item);
                }
            });
        });

        Object.values(clientTransactionData || {}).forEach((innerObject) => {
            Object.entries(innerObject).forEach(([key, item]) => {
                Object.values(item).forEach((subItems) => {
                    if (value === 'all' || subItems.actionBy === value) {
                        subItems['lowerType'] = 'Client';
                        subItems['name'] = clientData[subItems.clientId]?.name + ' -> ' + siteDetailsData[subItems.clientId][key]?.siteName;
                        cliResult.push(subItems);
                    }
                });
            });
        });

        Object.values(accTransactionData || {}).forEach((innerObject) => {
            if (value === 'all' || innerObject.actionBy === value) {
                innerObject['lowerType'] = 'Account';
                innerObject['name'] = accData[innerObject.accountType];
                accResult.push(innerObject);
            }
        });

        Object.values(thekedarTransactionData || {}).forEach((innerObject) => {
            Object.values(innerObject).forEach((item) => {
                if (value === 'all' || item.actionBy === value) {
                    item['lowerType'] = 'Thekedar';
                    item['name'] = item.thekedarName;
                    thekResult.push(item);
                }
            });
        });

        Object.values(otherIncomeTransactionData || {}).forEach((innerObject) => {
            if (value === 'all' || innerObject.actionBy === value) {
                innerObject['lowerType'] = 'Other Income';
                innerObject['name'] = otherIncomeData[innerObject.incomeType];
                otherIncomeResult.push(innerObject);
            }
        });

        let allData = [...empResult, ...suppResult, ...cliResult, ...accResult, ...thekResult, ...otherIncomeResult];
        sortDateLatest(allData);
        return allData;
    };

    return (
        <View style={styles.container}>
            <View style={styles.inputContainer}>
                {!loading && dropdownItems && (
                    <DropDownPicker
                        placeholder='Select User'
                        style={[styles.dropdownPicker, isFocus === "picker" && { borderColor: Colors.primary, borderWidth: 2 }]}
                        dropDownContainerStyle={[styles.dropdownPicker, isFocus && { borderColor: Colors.primary, borderWidth: 2 }]}
                        open={open}
                        value={value}
                        items={dropdownItems}
                        setOpen={(val) => {
                            if (val) MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.TRANSACTION_HISTORY_DROPDOWN, userData);
                            setOpen(val);
                        }}
                        setValue={setValue}
                        containerStyle={{ height: 40 }}
                        onOpen={() => setIsFocus("picker")}
                        onClose={() => setIsFocus("picker")}
                    />
                )}
                {!loading && dropdownItems && (
                    <View style={styles.balanceTextView}>
                        <Text style={styles.balanceText}>{balance ? `Balance: ${balance}` : '0'}</Text>
                    </View>
                )}
            </View>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) : (value ? (
                filteredTransactionData?.length > 0 ? (
                    <FlatList
                        style={styles.flatListStyle}
                        data={filteredTransactionData}
                        onScrollBeginDrag={() => { if (open) setOpen(false) }}
                        renderItem={({ item: key }) => {
                            let jsx = <>
                                {prevDate !== key.date && <View style={styles.dateView}><Text style={styles.textDate}>{key.date}</Text></View>}
                                <TransactionList
                                    key={key.id}
                                    commentFactoryExpense={key.actionBy}
                                    comment={key?.name}
                                    date={key.date}
                                    time={key.time}
                                    amount={key.amount}
                                    user={key.lowerType}
                                    id={key.id} />
                            </>
                            prevDate = key.date;
                            return (jsx);
                        }}
                        keyExtractor={(item) => item.id}
                    />
                ) : (
                    <View style={styles.noTransaction}>
                        <Text>No Transactions Found</Text>
                    </View>
                )
            ) : (
                <View style={styles.noTransaction}>
                    <Text>Please select a user</Text>
                </View>
            ))}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    dateView: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textDate: {
        backgroundColor: 'white',
        textAlign: 'center',
        width: "35%",
        borderRadius: 5,
    },
    inputContainer: {
        height: '10%',
        width: '90%',
        paddingVertical: 10,
        flexDirection: 'row',
        zIndex: 100
    },
    dropdownPicker: {
        width: '50%',
        marginLeft: 10,
        position: 'absolute',
    },
    balanceTextView: {
        width: '50%',
        top: 10,
        position: 'absolute',
        left: '56%',
        borderWidth: 2,
        borderRadius: 6,
        borderColor: Colors.secondary,
    },
    balanceText: {
        fontSize: 16,
        padding: 12,
    },
    flatListStyle: {
        width: '100%',
        height: '100%',
    },
    noTransaction: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default TransactionHistory;