import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { handleAlertForError } from './handleError';
import { apiKey } from '@env';

export const refreshTokenAndRetry = async (setAuthToken, refreshToken, setRefreshToken, retryFunction, idTokenRequired = false) => {
    if (refreshToken === null) {
        refreshToken = await AsyncStorage.getItem('refreshToken')
    }

    try {
        const response = await axios.post(`https://securetoken.googleapis.com/v1/token?key=${apiKey}`, {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
        });

        const newIdToken = response.data.id_token;
        const newRefreshToken = response.data.refresh_token;

        // Store the new tokens
        setAuthToken(newIdToken);
        setRefreshToken(newRefreshToken);
        await AsyncStorage.setItem('refreshToken', newRefreshToken);

        if (idTokenRequired) return newIdToken;
        else return await retryFunction(newIdToken, newRefreshToken);
    } catch (error) {
        console.error('Token refresh failed:', error);
        handleAlertForError(error);
    }
};
