import { View, Text, StyleSheet, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native'
import React, { useRef, useState, useLayoutEffect, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native';
import RadioForm from 'react-native-simple-radio-button';
import { Colors } from '../constants/theme';
import useDataStore from '../stores/dataStore';
import Button from '../components/Button';
import { AntDesign } from '@expo/vector-icons';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { EVENT_ACTIONS } from '../constants/Constant';
import { makeApiRequest } from '../utils/apiUtil';

export default function AddDetail({ route }) {
    const { existinguserID, existingName, existingContact, existingType, activeTabName, screen, plantName } = route.params || { existinguserID: null, existingName: null, existingContact: null, existingType: null, activeTabName: null, screen: null, plantName: null };
    const [name, setName] = useState(existingName || '');
    const [contact, setContact] = useState(existingContact || '');
    const [initialSelect, setInitialSelect] = useState(existingType || activeTabName);
    const [type, setType] = useState(activeTabName);
    const [isFocus, setIsFocus] = useState(null);
    const nameRef = useRef(null);
    const [error, setError] = useState('');
    const [userData, API_CONFIG, setToastMessage, selectedContact, setSelectedContact, authToken, setAuthToken, refreshToken, setRefreshToken] = useDataStore((state) => ([state.userData, state.API_CONFIG, state.setToastMessage, state.selectedContact, state.setSelectedContact, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken]));
    const navigation = useNavigation();
    const [loading, setLoading] = useState(false);

    const radio_props = [
        { label: 'Employee', value: 0 },
        { label: 'Supplier', value: 1 },
        { label: 'Client', value: 2 },
    ];

    useLayoutEffect(() => {
        if (existingName) {
            navigation.setOptions({
                title: `Edit ${existingName}`,
            });
        }
        else if (screen === "Production") {
            navigation.setOptions({
                title: "Add Thekedar",
            });
        }
        else {
            navigation.setOptions({
                title: "Add Details",
            });
        }
    }, [navigation]);

    useEffect(() => {
        if (selectedContact) {
            handleContactChange(selectedContact);
        }
    }, [selectedContact])

    const validateForm = () => {
        if (!name) {
            setError('Please add a name.');
            return false;
        }

        if (!contact) {
            setError('Please add contact number.');
            return false;
        }

        if (contact.length !== 10) {
            setError('Invalid contact number.');
            return false;
        }
        setError('');
        return true;
    };

    const handleContactChange = (text) => {
        // Remove any non-numeric characters
        let numericText = text.replace(/[^0-9]/g, '');
        // Remove leading zeros
        numericText = numericText.replace(/^0+/, '');
        const formattedText = numericText.slice(0, 10);
        setContact(formattedText);
        if (selectedContact) setSelectedContact(null);
    };

    const dataAddOn = () => {
        var url = null
        let updatedType = existingType === undefined ? type : existingType;
        switch (updatedType) {
            case 0:
                url = API_CONFIG.EMPLOYEES
                break;
            case 1:
                url = API_CONFIG.SUPPLIERS
                break;
            case 2:
                url = API_CONFIG.CLIENTS
                break;
        }
        if (validateForm()) {
            setLoading(true);
            const pushData = async () => {
                if (existingName && existingContact && existinguserID && screen !== "Production") {
                    try {
                        const data = await makeApiRequest("put",
                            `${url}/${existinguserID}`,
                            authToken, refreshToken, setAuthToken, setRefreshToken, {
                            name: name.trim(),
                            contact: contact,
                            actionBy: userData.username,
                        });
                        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_DETAIL_EDIT_CLICKED, userData);
                        setToastMessage("Data has been updated");
                        navigation.goBack();
                        console.log('Pushing updated data:', data);
                    } catch (error) {
                        setLoading(false);
                        console.error('Error editing data:', error);
                        handleAlertForError(error);
                    }
                }
                else if (screen === "Production") {
                    if (existingName && existingContact && existinguserID) {
                        const data = await makeApiRequest("put",
                            `${API_CONFIG.THEKEDARS}/${plantName}/${existinguserID}`,
                            authToken, refreshToken, setAuthToken, setRefreshToken, {
                            name: name.trim(),
                            contact: contact,
                            actionBy: userData.username,
                        });
                        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_DETAIL_EDIT_CLICKED, userData);
                        setToastMessage("Data has been updated");
                        navigation.goBack();
                        console.log('Pushing updated data:', data);
                    }
                    else {
                        const data = await makeApiRequest('post', `${API_CONFIG.THEKEDARS}/${plantName}`, authToken, refreshToken, setAuthToken, setRefreshToken, {
                            name: name.trim(),
                            contact: contact,
                            type: "thekedars",
                            absolute_amount: 0,
                            actionBy: userData.username,
                            isDeleted: false
                        });
                        setLoading(false);
                        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_DETAIL_CLICKED, userData);
                        setToastMessage("Data has been added");
                        navigation.goBack();
                        console.log('Pushing data:', data);
                    }
                }
                else {
                    try {
                        const data = await makeApiRequest('post', url, authToken, refreshToken, setAuthToken, setRefreshToken, {
                            name: name.trim(),
                            contact: contact,
                            type: radio_props[type].label.toLowerCase() + 's',
                            absolute_amount: 0,
                            actionBy: userData.username,
                            isDeleted: false
                        });
                        setLoading(false);
                        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_DETAIL_CLICKED, userData);
                        setToastMessage("Data has been added");
                        navigation.goBack();
                        console.log('Pushing data:', data);
                    } catch (error) {
                        setLoading(false);
                        console.error('Error adding data:', error);
                        handleAlertForError(error);
                    }
                }
            };
            pushData();
            setName('');
            setContact('');
            setIsFocus('name')
            nameRef.current.focus();
        }
    }

    const handleContactIconPress = () => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CONTACT_CLICKED, userData);
        navigation.navigate("ContactsScreen")
    }

    return (
        <View style={styles.container}>
            {/* <Text style={styles.header}>Add customer</Text> */}
            {loading && (
                <View style={styles.overlay}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )}
            <TextInput
                placeholder='Name'
                value={name}
                onChangeText={(text) => setName(text)}
                style={[styles.input, isFocus === 'name' && { borderColor: Colors.primary }]}
                onFocus={() => setIsFocus('name')}
                ref={nameRef}
            />
            <View style={styles.containerContact}>
                <View style={styles.containerCode}>
                    <Text style={styles.flag}>🇮🇳</Text>
                    <Text style={styles.countryCode}>+91</Text>
                </View>
                <TextInput
                    placeholder='Contact Number'
                    value={contact}
                    keyboardType="numeric"
                    onChangeText={handleContactChange}
                    style={[styles.inputContact, isFocus === 'contact' && { borderColor: Colors.primary }]}
                    onFocus={() => setIsFocus('contact')}
                />
                <TouchableOpacity onPress={handleContactIconPress}>
                    <AntDesign name="contacts" size={32} color={Colors.primary} padding={2} />
                </TouchableOpacity>
            </View>
            {/* <View style={styles.BtnContainer}> */}
            {/* <Button
                style={styles.BtnContainer}
                title='Add Data'
                onPress={dataAddOn}
            /> */}
            {/* </View> */}
            {screen !== "Production" && <View style={[styles.row, isFocus === 'type' && { borderColor: Colors.primary }]}>
                <Text style={styles.labelType}>Type:</Text>
                <RadioForm
                    disabled={existingName && existingContact ? true : false}
                    style={styles.radioRow}
                    radio_props={radio_props}
                    initial={initialSelect}
                    buttonColor={existingName && existingContact ? Colors.gray : Colors.blue}
                    labelStyle={{ color: existingName && existingContact ? Colors.gray : Colors.black }}
                    selectedButtonColor={existingName && existingContact ? Colors.gray : Colors.blue}
                    buttonStyle={styles.radioButton}
                    onPress={(value) => {
                        setIsFocus('type')
                        setType(value)
                    }}
                    formHorizontal={true}
                    buttonSize={15}
                />
            </View>}
            {error ? <Text style={{ color: 'red', fontSize: 16, top: 10 }}>{error}</Text> : null}
            <Button
                onPress={dataAddOn}
                btnLabel={existingName ? 'EDIT DETAIL' : 'ADD DETAIL'}
                buttonStyle={styles.BtnContainer} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        textAlign: 'center',
        alignItems: 'center'
    },
    header: {
        fontSize: 30,
        textAlign: 'center',
        marginTop: 100,
        fontWeight: 'bold'
    },
    input: {
        borderWidth: 2,
        borderColor: '#636363',
        margin: 10,
        padding: 12,
        fontSize: 18,
        borderRadius: 6,
        width: '90%'
    },
    row: {
        borderWidth: 2,
        flexDirection: 'row',
        alignItems: 'center',
        height: 90,
        borderColor: '#636363',
        borderRadius: 6,
        paddingHorizontal: 8,
        marginTop: 12,
        gap: 12,
        width: '90%'
    },
    radioRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start', // Ensures alignment to the start
        flexWrap: 'wrap', // Allows items to wrap to the next line
        height: 'auto', // Lets the container grow with content
        marginTop: 10,
        gap: 10,
        flexBasis: '80%'
    },
    labelType: {
        fontSize: 14
    },
    BtnContainer: {
        paddingHorizontal: 40,
        paddingVertical: 20,
        width: '90%',
        backgroundColor: Colors.primary,
        position: 'absolute',
        bottom: 10,
    },
    inputContact: {
        borderWidth: 2,
        borderColor: '#636363',
        marginLeft: '3%',
        padding: 12,
        fontSize: 18,
        borderRadius: 6,
        width: '62%'
    },
    containerCode: {
        borderWidth: 2,
        borderColor: '#636363',
        padding: 12,
        fontSize: 18,
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'row',
        width: '25%',
    },
    containerContact: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '90%',
    },
    countryCode: {
        marginLeft: '5%',
        fontSize: 18,
    },
    flag: {
        fontSize: 20,
    },
    overlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
    },
});
