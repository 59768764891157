import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import * as Contacts from 'expo-contacts';
import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import useDataStore from '../stores/dataStore';

const ContactsScreen = () => {
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [search, setSearch] = useState('');
    const [permission, setPermission] = useState(false);
    const navigation = useNavigation();
    const [setSelectedContact] = useDataStore((state) => [state.setSelectedContact]);

    useEffect(() => {
        (async () => {
            const { status } = await Contacts.requestPermissionsAsync();
            if (status === 'granted') {
                setPermission(true);
                const { data } = await Contacts.getContactsAsync({
                    fields: [Contacts.Fields.Emails, Contacts.Fields.PhoneNumbers],
                });
                if (data.length > 0) {
                    setContacts(data);
                    setFilteredContacts(data);
                }
            }
        })();
    }, []);

    const handleSearch = (text) => {
        setSearch(text);
        const filtered = contacts.filter(contact => {
            const contactLowercase = (contact.name || '').toLowerCase();
            const searchTextLowercase = text.toLowerCase();
            return contactLowercase.indexOf(searchTextLowercase) > -1;
        });
        setFilteredContacts(filtered);
    };

    const renderContactItem = ({ item }) => (
        <TouchableOpacity style={styles.contactItem} onPress={() => handleContactSelection(item)}>
            <View style={styles.initialsCircle}>
                <FontAwesome5 name="user-circle" size={32} color="black" style={styles.icon} />
            </View>
            <View style={styles.contactDetails}>
                <Text style={styles.name}>{item.name}</Text>
                {item.phoneNumbers && (
                    <Text style={styles.phoneNumber}>{item.phoneNumbers[0].number}</Text>
                )}
            </View>
        </TouchableOpacity>
    );

    const handleContactSelection = (selectedContact) => {
        if (selectedContact.phoneNumbers && selectedContact.phoneNumbers.length > 0) {
            let phoneNumber = selectedContact.phoneNumbers[0].number;
            phoneNumber = phoneNumber.replace(/^(\+91|91|0)/, ''); // Remove +91 or 91 or 0 from the beginning
            setSelectedContact(phoneNumber);
            navigation.goBack();
        } else {
            alert("No phone number available for the selected contact.");
        }
    };

    return (
        <View style={styles.container}>
            <TextInput
                style={styles.searchBar}
                placeholder="Search Contacts"
                value={search}
                onChangeText={handleSearch}
            />
            {permission ? (
                <FlatList
                    data={filteredContacts}
                    keyExtractor={(item) => item.id.toString()}
                    renderItem={renderContactItem}
                />
            ) : (
                <Text>No permission to access contacts</Text>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: '#fff'
    },
    searchBar: {
        fontSize: 18,
        marginBottom: 20,
        paddingHorizontal: 15,
        height: 45,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 22,
        backgroundColor: '#f0f0f0'
    },
    contactItem: {
        flexDirection: 'row',
        paddingVertical: 12,
        paddingHorizontal: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        alignItems: 'center'
    },
    initialsCircle: {
        width: 40,
        height: 40,
        borderRadius: 20,
        // backgroundColor: '#007bff',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 15
    },
    initialsText: {
        color: '#fff',
        fontSize: 18,
        fontWeight: 'bold'
    },
    contactDetails: {
        flex: 1
    },
    name: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    phoneNumber: {
        marginTop: 5,
        fontSize: 14,
        color: 'gray'
    }
});

export default ContactsScreen;
