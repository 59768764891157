import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Colors } from '../constants/theme';
import { FontAwesome } from '@expo/vector-icons';
import useDataStore from '../stores/dataStore';

const CustomToast = ({ message, isVisible, duration = 1500 }) => {
    const [visible, setVisible] = useState(isVisible);
    const [setToastMessage] = useDataStore((state) => ([state.setToastMessage]));

    useEffect(() => {
        if (isVisible) {
            setVisible(true);
            const timeout = setTimeout(() => {
                setVisible(false);
                setToastMessage("");
            }, duration);
            return () => clearTimeout(timeout);
        }
    }, [isVisible]);

    if (!visible) return null;

    return (
        <View style={styles.container}>
            <FontAwesome name="check-circle" size={90} color={Colors.secondary} />
            <Text style={styles.message}>{message}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        // position: 'absolute',
        // top: 0,
        left: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        justifyContent: 'center',
        alignItems: 'center',
    },
    message: {
        color: 'black',
        fontSize: 20,
        textAlign: 'center',
        marginTop: 20
    },
});

export default CustomToast;
