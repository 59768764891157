// SearchInput.js
import React from 'react';
import { TextInput, StyleSheet } from 'react-native';

const Search = ({ value, onChangeText, placeholder = "Search", searchStyle }) => {
    return (
        <TextInput
            style={[styles.input, searchStyle]}
            placeholder={placeholder}
            value={value}
            onChangeText={onChangeText}
        />
    );
}

const styles = StyleSheet.create({
    input: {
        padding: 10,
        height: 40,
        borderWidth: 1,
        borderColor: '#cccccc',
        borderRadius: 5,
        margin: 10,
        backgroundColor: '#FFFFFF',
    },
});

export default Search;
