import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, FlatList } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import SiteList from '../components/SiteList';
import { useIsFocused } from '@react-navigation/native';
import useDataStore from '../stores/dataStore';
import Loader from '../components/Loader';
import Button from '../components/Button';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import OverflowMenu from '../components/OverflowMenu';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { makeApiRequest } from '../utils/apiUtil';
import { isEmpty } from '../utils/commonUtils';
import ShowAlert from '../modules/AlertModule';

const ClientTransactionScreen = ({ route }) => {
    const navigation = useNavigation();
    const [siteDetailsData, setSiteDetailsData] = useState(null);
    const [dataKey, setDataKey] = useState(null);
    const isFocused = useIsFocused();
    let siteDetail = {};
    const [API_CONFIG, userData, authToken, refreshToken, setAuthToken, setRefreshToken, setToastMessage] = useDataStore((state) => [state.API_CONFIG, state.userData, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken, state.setToastMessage]);
    let { userID, type, name, contact, actionBy } = route.params;
    const [loading, setLoading] = useState(true);
    let response;
    const [menuOptions, setMenuOptions] = useState([
        { label: 'View Details', value: '1' },
        { label: 'Edit Details', value: '2' },
        { label: 'Download PDF', value: '3' },
        { label: 'Delete Client', value: '4' },
    ]);
    const handleSelect = (option) => {
        switch (option.value) {
            case '1':
                handleViewDetail();
                break;
            case '2':
                handleEditButton();
                break;
            case '3':
                handleDownloadPDF();
                break;
            case '4':
                handleDeleteClient();
                break;
        }
    };

    const goToOtherScreen = () => {
        navigation.navigate('AddSiteScreen', { userID });
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CLIENT_ADD_SITE_SCREEN, userData);
    };
    const handleViewDetail = () => {
        navigation.navigate('InfoScreen', { userID, contact, actionBy, name, type, siteDetailsData: response });
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CLIENT_VIEW_DETAILS, userData);
    };
    const handleEditButton = () => {
        navigation.navigate('AddDetail', { existinguserID: userID, existingName: name, existingContact: contact, existingType: 2 });
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CLIENT_EDIT_DETAILS, userData);
    };
    const handleDownloadPDF = () => {
        navigation.navigate('ReportScreen', { name, type, contact, userID, siteDetailsData: response });
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CLIENT_DOWNLOAD_PDF, userData);
    }
    const handleDeleteClient = () => {
        if (siteDetail && isEmpty(siteDetail)) {
            ShowAlert("Delete Client", "Are you sure you want to delete client?", "YesNo", () => {
                console.log('OK Pressed');
            }, async () => {
                console.log('Yes Pressed');
                try {
                    await makeApiRequest("put", `${API_CONFIG.CLIENTS}/${userID}`, authToken, refreshToken, setAuthToken, setRefreshToken, {
                        isDeleted: true
                    });
                    MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CLIENT_DELETE, userData);
                    setToastMessage("Client deleted successfully");
                    navigation.goBack();
                } catch (error) {
                    console.error('Failed to delete client:', error);
                    handleAlertForError(error);
                }
            }, () => {
                console.log('No Pressed');
            });
        }
        else {
            ShowAlert("Error", "Please close all sites", "Ok");
        }
    };

    const handleDeleteSite = async (siteKey) => {
        if (siteDetailsData && siteDetailsData[siteKey] && siteDetailsData[siteKey].totalAmount === 0 && siteDetailsData[siteKey].receivedAmount === 0) {
            ShowAlert("Delete Site", "Are you sure you want to delete this site?", "YesNo", () => {
                console.log('OK Pressed');
            }, async () => {
                console.log('Yes Pressed');
                try {
                    await makeApiRequest('delete', `${API_CONFIG.SITE_DETAILS}/${userID}/${siteKey}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                    MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SITE_DELETE, userData);
                    setToastMessage("Site deleted successfully");
                    const updatedSiteDetailsData = { ...siteDetailsData };
                    delete updatedSiteDetailsData[siteKey];
                    setSiteDetailsData(updatedSiteDetailsData);
                    siteDetail = updatedSiteDetailsData;
                    fetchData();
                } catch (error) {
                    console.error('Failed to delete site:', error);
                    handleAlertForError(error);
                }
            }, () => {
                console.log('No Pressed');
            });
        }
        else {
            ShowAlert("Try again", "Make total and received amount 0 in the site and then try again", "Ok");
        }
    };

    useEffect(() => {
        if (isFocused) {
            navigation.setOptions({
                title: `Client`,
            });
            fetchData();
            navigation.setOptions({
                title: `Client | ${name}`,
                headerRight: () => (
                    <View style={styles.headerRight}>
                        <OverflowMenu options={menuOptions} onSelect={handleSelect} allowedRole={[APP_ROLE.OWNER]} />
                    </View>
                ),
            });
        }
    }, [isFocused, menuOptions]);

    const fetchData = async () => {
        try {
            const responseType = await makeApiRequest('get', API_CONFIG.CLIENTS, authToken, refreshToken, setAuthToken, setRefreshToken);
            name = responseType[userID].name;
            contact = responseType[userID].contact;
            response = await makeApiRequest('get', `${API_CONFIG.SITE_DETAILS}/${userID}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            setSiteDetailsData(response);
            siteDetail = response;
            if (userData.role === APP_ROLE.SUPERVISOR) {
                const filterdata = [];
                Object.entries(response).forEach(([key, value]) => {
                    if (value.supervisorList && value.supervisorList.includes(userData.username)) {
                        filterdata.push({ key, value });
                    }
                })
                setDataKey(filterdata.map(item => item.key));
            }
            else {
                setDataKey(Object.keys(response));
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching Site Details:', error);
            handleAlertForError(error);
        }
    };

    return (
        <>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) :
                <View style={styles.container}>
                    {siteDetailsData && dataKey?.length > 0 ? (
                        // Check if all sites are closed
                        Object.values(siteDetailsData).every(site => site.isClosed) ? (
                            <Text style={styles.noSiteFoundText}>All sites are closed</Text>
                        ) : (
                            <FlatList
                                data={dataKey}
                                style={{ height: '100%', marginBottom: 85 }}
                                renderItem={({ item: key }) => (
                                    siteDetailsData[key] && !siteDetailsData[key].isClosed && <SiteList
                                        siteKey={key}
                                        siteName={siteDetailsData[key].siteName}
                                        clientKey={userID}
                                        type={type}
                                        isClosed={siteDetailsData[key].isClosed}
                                        callback={() => {
                                            fetchData();
                                        }}
                                        handleDelete={handleDeleteSite}
                                    />
                                )}
                                keyExtractor={(item) => item}
                            />
                        )
                    ) : (
                        <Text style={styles.noSiteFoundText}>No Sites Found</Text>
                    )}
                    <Button
                        onPress={goToOtherScreen}
                        btnLabel="ADD SITE"
                        buttonStyle={styles.BtnContainer}
                        textStyle={styles.btnText}
                        allowedRole={[APP_ROLE.OWNER]}
                    />
                </View>
            }
        </>
    );
}

const styles = StyleSheet.create({
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    noSiteFoundText: {
        textAlign: 'center',
    },
    BtnContainer: {
        paddingVertical: 20,
        paddingHorizontal: 40,
        position: 'absolute',
        width: '90%',
        bottom: 10,
        position: 'absolute'
    },
    btnText: {
        color: 'white',
    },
    headerRight: {
        paddingRight: 14,
        paddingTop: 8
    },
});

export default ClientTransactionScreen;