import React, { useEffect, useLayoutEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, FlatList } from 'react-native';
import { Feather, FontAwesome5 } from '@expo/vector-icons';
import { Colors } from '../constants/theme';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import useDataStore from '../stores/dataStore';
import { makeApiRequest } from '../utils/apiUtil';
import { isEmpty } from '../utils/commonUtils';
import TransactionList from '../components/TransactionList';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import { convertToDateTime, formatDate, parseDate } from '../utils/dateUtil';
import { handleAlertForError } from '../utils/handleError';
import Loader from '../components/Loader';
import ShowAlert from '../modules/AlertModule';
import DateTimePicker from 'react-native-ui-datepicker';
import Popup from "../components/popup";
import html2pdf from 'html2pdf.js';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import MixpanelTracker from '../utils/MixpanelTracker';

const StockTransactionScreen = ({ route }) => {
    const { plant, type } = route.params;
    const [userData, API_CONFIG, authToken, refreshToken, setAuthToken, setRefreshToken, thekedarData, employeeData, clientsData, siteData, setToastMessage] = useDataStore((state) => [state.userData, state.API_CONFIG, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken, state.thekedarData, state.employeeData, state.clientsData, state.siteData, state.setToastMessage]);
    const [isStartDatePickerVisible, setStartDatePickerVisibility] = useState(false);
    const [isEndDatePickerVisible, setEndDatePickerVisibility] = useState(false);
    const [startDate, setStartDate] = useState();
    const [loadTypeData, setLoadTypeData] = useState(null);
    const [filterTransaction, setFilterTransaction] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dataKey, setDataKey] = useState(null);
    const [endDate, setEndDate] = useState();
    const isFocused = useIsFocused();
    const navigation = useNavigation();
    let today = new Date();
    let prevDate = null;
    let filterTrans = null;

    useEffect(() => {
        if (isFocused) {
            if (type === 'loadingData') MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.LOADING_STOCK_TRANSACTION_SCREEN, userData);
            else if (type === 'unloadingData') MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.UNLOADING_STOCK_TRANSACTION_SCREEN, userData);
            fetchData();
        }
    }, [isFocused]);

    useLayoutEffect(() => {
        navigation.setOptions({
            title: `Stock Transaction | ${plant}`,
            headerRight: () => (
                <TouchableOpacity style={{ paddingRight: 20 }} onPress={handleDownloadPDF}>
                    <Feather name="download" size={24} color="black" />
                </TouchableOpacity>
            ),
        })
    }, [navigation]);

    const fetchData = async () => {
        try {
            const response = await makeApiRequest('get', `${API_CONFIG.PLANT}/${plant}/${type}/getData`, authToken, refreshToken, setAuthToken, setRefreshToken);
            setLoadTypeData(sortDateLatest(response)); //Parent
            filterTrans = sortDateLatest(response);
            setFilterTransaction(sortDateLatest(response)); //Child

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching reports:', error);
            handleAlertForError(error);
        }
    };

    const sortDateLatest = (data) => {
        let dataArray = Object.values(data);
        dataArray.sort(function (a, b) {
            // Convert dates to Date objects for comparison
            var dateA = parseDate(a.date);
            var dateB = parseDate(b.date);
            // Compare dates
            return dateB - dateA;
        });
        const sortedDataTData = {};
        dataArray.forEach(item => {
            sortedDataTData[item.id] = item;
        });
        setDataKey(Object.keys(reverseTransactionsByDate(sortedDataTData)));
        filterTrans = sortedDataTData;
        return sortedDataTData;
    };

    function reverseTransactionsByDate(data) {
        const groupedData = {};
        for (const key in data) {
            const date = data[key].date;
            if (!groupedData[date]) {
                groupedData[date] = [];
            }
            groupedData[date].push(key);
        }

        for (const date in groupedData) {
            groupedData[date].reverse();
        }

        const reversedData = {};
        for (const date in groupedData) {
            groupedData[date].forEach(key => {
                reversedData[key] = data[key];
            });
        }
        return reversedData;
    }

    const showStartDatePicker = () => {
        setStartDatePickerVisibility(true);
    };

    const showEndDatePicker = () => {
        // Only show the end date picker if the start date is already selected
        if (startDate) {
            setEndDatePickerVisibility(true);
        } else {
            // If start date is not selected, show an alert
            ShowAlert("Try again", "Please select the start date first.", "Ok");
        }
    };

    const hideStartDatePicker = () => {
        setStartDatePickerVisibility(false);
    };

    const hideEndDatePicker = () => {
        setEndDatePickerVisibility(false);
    };

    const handleStartDateConfirm = (date) => {
        setStartDate(date);
        hideStartDatePicker();
        updateFilteredTransactions(date, endDate);
    };

    const handleEndDateConfirm = (date) => {
        // Check if the selected end date is before the start date
        if (startDate && date < startDate) {
            setEndDatePickerVisibility(false);
            ShowAlert("Wrong Date", "End date cannot be before the start date.", "Ok");
        } else {
            // If the end date is valid, update the state and hide the date picker
            setEndDate(date);
            hideEndDatePicker();
            updateFilteredTransactions(startDate, date);
        }
    };

    const updateFilteredTransactions = (startDate, endDate) => {
        let sortedData = sortDataOnDate(loadTypeData, startDate, endDate);
        setFilterTransaction(sortDateLatest(sortedData));
        filterTrans = sortDateLatest(response);
    };

    const sortDataOnDate = (data, startDate, endDate) => {
        const sortedData = {};
        for (let [key, value] of Object.entries(data)) {
            const transactionDate = convertToDateTime(data[key]["date"]);

            if (transactionDate >= convertToDateTime(formatDate(startDate)) && transactionDate <= convertToDateTime(formatDate(endDate))) {
                sortedData[key] = data[key];
            }
        }
        return sortedData;
    }

    const handleDownloadPDF = async () => {
        let htmlContent;
        try {
            if (type === 'loadingData') {
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.LOADING_DATA_DOWNLOADPDF_CLICKED, userData);
                htmlContent = generateLoadingDataHTMLContent();
            } else if (type === 'unloadingData') {
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.UNLOADING_DATA_DOWNLOADPDF_CLICKED, userData);
                htmlContent = generateUnloadingDataHTMLContent();
            }
            let formatedDate = formatDate(today);
            const fileName = `${type}-${formatedDate}.pdf`;

            const element = document.createElement("div");
            element.innerHTML = htmlContent;

            html2pdf(element, {
                filename: fileName,
                jsPDF: { unit: 'px', format: [1484, 812], orientation: 'landscape' },
                html2canvas: { scale: 2 },
            });
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const handleDelete = async (key) => {
        ShowAlert("Delete", "Are you sure you want to delete this transaction?", "YesNo", () => {
            console.log('OK Pressed');
        }, async () => {
            console.log('Yes Pressed');
            if (type === 'loadingData') MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.LOADING_DATA_DELETE_CLICKED, userData);
            else if (type === 'unloadingData') MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.UNLOADING_DATA_DELETE_CLICKED, userData);
            try {
                await makeApiRequest('delete', `${API_CONFIG.PLANT}/${plant}/${type}/${key}/delete`, authToken, refreshToken, setAuthToken, setRefreshToken);
                setToastMessage("Data deleted successfully");
                fetchData();
            } catch (err) {
                console.log("Transaction delete err: ", err);
                handleAlertForError(err);
            }
        }, () => {
            console.log('No Pressed');
        });
    };

    const getCurrentDate = () => {
        // Get the date
        var date = today.getDate();

        // Get the month (January is 0)
        var month = today.getMonth() + 1; // Adding 1 because months start from 0

        // Get the year
        var year = today.getFullYear();

        // Formatting to ensure double-digit representation if necessary
        month = (month < 10) ? '0' + month : month;
        date = (date < 10) ? '0' + date : date;

        // Create a string with date + month + year
        var dateMonthYear = date + '-' + month + '-' + year;
        return dateMonthYear;
    }

    const getPoleTotalPrice = (poleData) => {
        return poleData?.reduce((acc, item) => {
            const { polePrice, poleQuantity } = item;
            if (polePrice !== "" && poleQuantity !== "") {
                const price = parseFloat(polePrice);
                const quantity = parseFloat(poleQuantity);
                return acc + (price * quantity);
            }
            return acc;
        }, 0);
    };

    const generateLoadingDataHTMLContent = () => {
        let currentDate = getCurrentDate();

        let totalPanelQty = 0;
        let totalPoleQty = 0;
        let totalCementQty = 0;
        let totalMsand = 0;
        let totalJalli = 0;
        let totalSteel = 0;

        let html = `
        <html>
            <head>
                <title>Loading Report</title>
                <style>
                    #details {
                        background-color: #fff;
                    }
                    #details_tr {
                        background-color: #fff;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th {
                        background-color: #dddddd;
                    }
                    h1 {
                        text-align: center;
                    }
                    h3 {
                        text-align: center;
                    }
                    #border {
                        border: 1px solid #000;
                    }
                    table {
                      font-family: arial, sans-serif;
                      border-collapse: collapse;
                      width: 100%;
                    }
                    
                    td, th {
                      border: 1px solid #dddddd;
                      text-align: left;
                      padding: 8px;
                    }
                    
                    tr:nth-child(even) {
                      background-color: #f2f2f2;
                    }
                    
                    #endctn p{
                        display: inline-block;
                        margin-right: 10px;
                    }
                </style>
            </head>
            <body>
                <h1>Transaction report</h1>
                <div id="border"></div> <br>
                <table id="details">
                    <tr id ="details_tr">
                        <td colspan="3" rowspan="2">${currentDate}</td>
                    </tr>
                </table>
                <br>
                <div style="border: 1px solid #000;"></div> <br>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Vehicle</th>
                            <th>Employee Details</th>
                            <th>Client Details</th>
                            <th>Panel Qty</th>
                            <th>Panel Amount</th>
                            <th>Pole Qty</th>
                            <th>Pole Amount</th>
                            <th>Cement Qty</th>
                            <th>Cement Amount</th>
                            <th>Msand</th>
                            <th>Jalli</th>
                            <th>MsandJalli Amount</th>
                            <th>Steel</th>
                            <th>Steel Amount</th>
                            <th>Total Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
    `;
        let grandTotal = 0;
        // Add filtered transactions to the table
        Object.values(filterTrans).forEach((transaction) => {

            totalPanelQty += parseFloat(transaction.panel || 0);
            totalPoleQty += parseFloat(transaction.pole || 0);
            totalCementQty += parseFloat(transaction.cement || 0);
            totalMsand += parseFloat(transaction.msand || 0);
            totalJalli += parseFloat(transaction.jalli || 0);
            totalSteel += parseFloat(transaction.steel || 0);
            grandTotal += parseFloat(transaction.totalAmount || 0);
            html += `
        <tr>
            <td>${transaction.date}</td>
            <td>${transaction.vehicle}</td>
            <td>${transaction.thekedar ? `Thekedar - ${thekedarData[transaction.thekedar].name}` : transaction.employee ? `Employee - ${employeeData[transaction.employee].name}` : "-"}</td>
            <td>${transaction.client ? `Client - ${clientsData[transaction.client].name} \nSite - ${siteData[transaction.client][transaction.site]?.siteName}` : "-"}</td>
            <td>${transaction.panel ? transaction.panel : 0}</td>
            <td>${transaction.panelPrice && (parseFloat(transaction.panelPrice) * parseFloat(transaction.panel) !== NaN) ? parseFloat(transaction.panelPrice) * parseFloat(transaction.panel) : 0}</td>
            <td>${transaction.pole ? transaction.pole : 0}</td>
            <td>${(transaction.poleData && !Number.isNaN(getPoleTotalPrice(transaction.poleData))) ? getPoleTotalPrice(transaction.poleData) : 0}</td>
            <td>${transaction.cement ? transaction.cement : 0}</td>
            <td>${transaction.cementBagPrice && !Number.isNaN(parseFloat(transaction.cementBagPrice) * parseFloat(transaction.cement)) ? parseFloat(transaction.cementBagPrice) * parseFloat(transaction.cement) : 0}</td>
            <td>${transaction.msand ? transaction.msand : 0}</td>
            <td>${transaction.jalli ? transaction.jalli : 0}</td>
            <td>${transaction.msandJalliPrice && !Number.isNaN(transaction.msandJalliPrice) ? transaction.msandJalliPrice : 0}</td>
            <td>${transaction.steel ? transaction.steel : 0}</td>
            <td>${transaction.steelPrice && !Number.isNaN(parseFloat(transaction.steel) * parseFloat(transaction.steelPrice)) ? parseFloat(transaction.steel) * parseFloat(transaction.steelPrice) : 0}</td>
            <td>${transaction.totalAmount ? transaction.totalAmount : 0}</td>
            <td>${transaction.actionBy}</td>
        </tr>
    `;
        });

        // Add totals row
        html += `
        <tr>
            <th colspan="4">Total</th>
            <th>${totalPanelQty}</th>
            <th>-</th>
            <th>${totalPoleQty}</th>
            <th>-</th>
            <th>${totalCementQty}</th>
            <th>-</th>
            <th>${totalMsand}</th>
            <th>${totalJalli}</th>
            <th>-</th>
            <th>${totalSteel}</th>
            <th>-</th>
            <th>${grandTotal}</th>
            <th>-</th>
        </tr>
    `;

        html += `
                    </tbody>
                </table>
            </body>
        </html>
    `;

        return html;
    };

    const generateUnloadingDataHTMLContent = () => {
        let currentDate = getCurrentDate();

        let totalPanel = 0;
        let totalPole = 0;
        let totalCement = 0;
        let totalMsand = 0;
        let totalJalli = 0;
        let totalSteel = 0;

        let html = `
        <html>
            <head>
                <title>Unloading Report</title>
                <style>
                    #details {
                        background-color: #fff;
                    }
                    #details_tr {
                        background-color: #fff;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th, td {
                        border: 1px solid #ddd;
                        padding: 8px;
                        text-align: left;
                    }
                    th {
                        background-color: #dddddd;
                    }
                    h1 {
                        text-align: center;
                    }
                    h3 {
                        text-align: center;
                    }
                    #border {
                        border: 1px solid #000;
                    }
                    table {
                      font-family: arial, sans-serif;
                      border-collapse: collapse;
                      width: 100%;
                    }
                    
                    td, th {
                      border: 1px solid #dddddd;
                      text-align: left;
                      padding: 8px;
                    }
                    
                    tr:nth-child(even) {
                      background-color: #f2f2f2;
                    }
                    
                    #endctn p{
                        display: inline-block;
                        margin-right: 10px;
                    }
                </style>
            </head>
            <body>
                <h1>Transaction report</h1>
                <div id="border"></div> <br>
                <table id="details">
                    <tr id ="details_tr">
                        <td colspan="3" rowspan="2">${currentDate}</td>
                    </tr>
                </table>
                <br>
                <div style="border: 1px solid #000;"></div> <br>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Panel</th>
                            <th>Pole</th>
                            <th>Cement</th>
                            <th>Msand</th>
                            <th>Jalli</th>
                            <th>Steel</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
    `;
        // Add filtered transactions to the table
        Object.values(filterTrans).forEach((transaction) => {

            const panel = parseInt(transaction.panel) || 0;
            const pole = parseInt(transaction.pole) || 0;
            const cement = parseInt(transaction.cement) || 0;
            const msand = parseInt(transaction.msand) || 0;
            const jalli = parseInt(transaction.jalli) || 0;
            const steel = parseInt(transaction.steel) || 0;

            totalPanel += panel;
            totalPole += pole;
            totalCement += cement;
            totalMsand += msand;
            totalJalli += jalli;
            totalSteel += steel;

            html += `
        <tr>
            <td>${transaction.date}</td>
            <td>${transaction.panel ? transaction.panel : 0}</td>
            <td>${transaction.pole ? transaction.pole : 0}</td>
            <td>${transaction.cement ? transaction.cement : 0}</td>
            <td>${transaction.msand ? transaction.msand : 0}</td>
            <td>${transaction.jalli ? transaction.jalli : 0}</td>
            <td>${transaction.steel ? transaction.steel : 0}</td>
            <td>${transaction.actionBy}</td>
        </tr>
    `;
        });

        // Add totals row
        html += `
        <tr>
            <th>Total</th>
            <th>${totalPanel}</th>
            <th>${totalPole}</th>
            <th>${totalCement} Bag</th>
            <th>${totalMsand} Ton</th>
            <th>${totalJalli} Ton</th>
            <th>${totalSteel} Ton</th>
            <th></th>
        </tr>
    `;

        html += `
                    </tbody>
                </table>
            </body>
        </html>
    `;
        return html;
    };

    const getSiteName = (siteId, siteData) => {
        for (const outerKey in siteData) {
            if (siteData.hasOwnProperty(outerKey)) {
                const innerData = siteData[outerKey];
                if (innerData.hasOwnProperty(siteId)) {
                    return innerData[siteId].siteName;
                }
            }
        }
        return null;
    };

    return (
        <View style={styles.container}>
            {
                loading ? (
                    <View style={styles.loaderContainer}>
                        <Loader size="large" />
                    </View>
                ) :
                    <>
                        <View style={styles.datePickerCOntainer}>
                            <View style={styles.startDateContainer}>
                                <TouchableOpacity onPress={showStartDatePicker}>
                                    <View style={styles.startDateContainerView}>
                                        <FontAwesome5
                                            name="calendar"
                                            size={16}
                                            color={Colors.primary}
                                        />
                                        <Text style={styles.startDateText}> {startDate ? startDate.toDateString() : "Select start date"}</Text>
                                        <FontAwesome5
                                            name="caret-down"
                                            size={16}
                                            color={Colors.primary}
                                        />
                                        <Popup visible={isStartDatePickerVisible} onClose={() => setStartDatePickerVisibility(false)}>
                                            <DateTimePicker
                                                mode="single"
                                                date={startDate || new Date()}
                                                maxDate={today}
                                                onChange={(param) => {
                                                    handleStartDateConfirm(new Date(param.date));
                                                    setStartDatePickerVisibility(false)
                                                }}
                                                headerButtonStyle={{ color: 'green' }}
                                            />
                                        </Popup>
                                    </View>
                                </TouchableOpacity>
                            </View>

                            <View style={styles.endDateContainer}>
                                <TouchableOpacity onPress={showEndDatePicker}>
                                    <View style={styles.endDateContainerView}>
                                        <FontAwesome5
                                            name="calendar"
                                            size={16}
                                            color={Colors.primary}
                                        />
                                        <Text style={styles.endDateText}> {endDate ? endDate.toDateString() : "Select end date"}</Text>
                                        <FontAwesome5
                                            name="caret-down"
                                            size={16}
                                            color={Colors.primary}
                                        />
                                        <Popup visible={isEndDatePickerVisible} onClose={() => setEndDatePickerVisibility(false)}>
                                            <DateTimePicker
                                                mode="single"
                                                date={endDate || new Date()}
                                                maxDate={today}
                                                onChange={(param) => {
                                                    handleEndDateConfirm(new Date(param.date));
                                                    setEndDatePickerVisibility(false)
                                                }}
                                                headerButtonStyle={{ color: 'green' }}
                                            />
                                        </Popup>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>

                        {dataKey?.length > 0 && filterTransaction && !isEmpty(filterTransaction) ? <FlatList
                            style={styles.flatListStyle}
                            data={dataKey}
                            renderItem={({ item: key }) => {
                                let jsx = <>
                                    {prevDate !== filterTransaction[key]?.date && <View style={styles.dateView}><Text style={styles.textDate}>{filterTransaction[key]?.date}</Text></View>}
                                    <View style={styles.rowContainer}>
                                        <View style={{
                                            width: '100%'
                                        }}>
                                            {(userData.role === APP_ROLE.SUPERVISOR ? userData.username === filterTransaction[key]?.actionBy : true) && <TransactionList
                                                key={key}
                                                comment={`Panel - ${filterTransaction[key]?.panel || 0}\nPole - ${filterTransaction[key]?.pole || 0}\nCement - ${filterTransaction[key]?.cement || 0}\nMsand - ${filterTransaction[key]?.msand || 0}\nJalli - ${filterTransaction[key]?.jalli || 0} \nSteel - ${filterTransaction[key]?.steel || 0}`}
                                                commentFactoryExpense={type === "unloadingData" ? "Unloading Data" : `Loading Data\nVehicle - ${filterTransaction[key]?.vehicle || "NA"}\nThekedar - ${filterTransaction[key]?.thekedar ? thekedarData[filterTransaction[key]?.thekedar].name : "NA"}\nEmployee - ${filterTransaction[key]?.employee ? employeeData[filterTransaction[key]?.employee].name : "NA"}\nClient - ${filterTransaction[key]?.client ? clientsData[filterTransaction[key]?.client].name : "NA"}\nSite - ${filterTransaction[key]?.site ? getSiteName(filterTransaction[key]?.site, siteData) : "NA"}`}
                                                date={filterTransaction[key]?.date}
                                                time={filterTransaction[key]?.time}
                                                amount={filterTransaction[key]?.totalAmount || 0}
                                                user={filterTransaction[key]?.actionBy}
                                                type={type}
                                                // userId={filterTransaction[key]?.id}
                                                transactionId={key}
                                                allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]}
                                                // name={name}
                                                plantName={plant}
                                                data={filterTransaction[key]}
                                            />}
                                        </View>
                                        {userData.username === filterTransaction[key]?.actionBy && <TouchableOpacity style={styles.trigger} onPress={() => handleDelete(key)}>
                                            <MaterialCommunityIcons name="delete" size={28} color="#000" />
                                        </TouchableOpacity>}
                                    </View>
                                </>
                                prevDate = filterTransaction[key]?.date;
                                return (jsx);
                            }}
                            keyExtractor={(item) => item}
                        /> : <View style={styles.centeredView}>
                            <Text style={styles.noTransaction}>No Production Work Found</Text>
                        </View>
                        }
                    </>
            }
        </View>
    )
}

const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        marginVertical: 5
    },
    container: {
        flex: 1,
        textAlign: 'center'
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    datePickerCOntainer: {
        flexDirection: 'row',
    },
    startDateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 55,
        borderColor: '#636363',
        borderRadius: 6,
        paddingHorizontal: 8,
        margin: 12,
        padding: 6,
        width: '45%',
        backgroundColor: "white"
    },
    startDateContainerView: {
        flexDirection: 'row',
    },
    startDateText: {
        marginLeft: 5,
        marginRight: 5
    },
    endDateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 55,
        borderColor: '#636363',
        borderRadius: 6,
        marginTop: 12,
        paddingLeft: 6,
        width: '45%',
        backgroundColor: "white"
    },
    endDateContainerView: {
        flexDirection: 'row',
        padding: 5,
    },
    endDateText: {
        marginLeft: 5,
        marginRight: 5
    },
    bottomContainer: {
        flexDirection: 'row',
        width: '90%',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 20,
        bottom: 0
    },
    flatListStyle: {
        width: '100%',
        height: '100%',
    },
    dateView: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textDate: {
        backgroundColor: 'white',
        textAlign: 'center',
        width: "35%",
        borderRadius: 5,
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noTransaction: {
        textAlign: 'center',
    },
});

export default StockTransactionScreen