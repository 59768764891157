import { API_ENDPOINTS } from '@env';

export const APIS = {
    EMPLOYEES: 'EMPLOYEES',
    SUPPLIERS: 'SUPPLIERS',
    TRANSACTIONS: 'TRANSACTIONS',
    CLIENTS: 'CLIENTS',
    SITE_DETAILS: 'SITE_DETAILS',
    SITE_TRANSACTIONS: 'SITE_TRANSACTIONS',
    USERS: 'USERS',
    ACCOUNTS: 'ACCOUNTS',
    THEKEDARS: 'THEKEDARS',
    QUOTATION: 'QUOTATION',
    OTHER_INCOME: 'OTHER_INCOME',
    UNSETTLED_EXPENSES: 'UNSETTLED_EXPENSES',
    PLANT: 'PLANT',
}

export const USERS_API = `${API_ENDPOINTS}/users`;
export const TOKEN_API = `${API_ENDPOINTS}/tokens`;
export const FACTORY_API = `${API_ENDPOINTS}/factories`;

export const getAPIConfigs = (factory) => {
    return {
        [APIS.EMPLOYEES]: `${API_ENDPOINTS}/employees/${factory}`,
        [APIS.SUPPLIERS]: `${API_ENDPOINTS}/suppliers/${factory}`,
        [APIS.TRANSACTIONS]: `${API_ENDPOINTS}/transactions/${factory}`,
        [APIS.CLIENTS]: `${API_ENDPOINTS}/clients/${factory}`,
        [APIS.SITE_DETAILS]: `${API_ENDPOINTS}/siteDetails/${factory}`,
        [APIS.SITE_TRANSACTIONS]: `${API_ENDPOINTS}/siteTransactions/${factory}`,
        [APIS.ACCOUNTS]: `${API_ENDPOINTS}/accounts/${factory}`,
        [APIS.THEKEDARS]: `${API_ENDPOINTS}/production/thekedars/${factory}`,
        [APIS.QUOTATION]: `${API_ENDPOINTS}/quotation/${factory}`,
        [APIS.OTHER_INCOME]: `${API_ENDPOINTS}/income/${factory}`,
        [APIS.UNSETTLED_EXPENSES]: `${API_ENDPOINTS}/unsettledExpenses/${factory}`,
        [APIS.PLANT]: `${API_ENDPOINTS}/production/${factory}`
    }
}