import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { TOKEN_API } from '../env-config/api-config';
import Constants from 'expo-constants';
import { handleAlertForError } from '../utils/handleError';
import { makeApiRequest } from '../utils/apiUtil';
import { Platform } from 'react-native';
import { API_ENDPOINTS } from '@env';

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
    }),
});

export async function sendNotification(factory, title, body, authToken, refreshToken, setAuthToken, setRefreshToken) {
    console.log("Sending push notification...");

    const tokens = await getPushTokens(factory, authToken, refreshToken, setAuthToken, setRefreshToken);

    const messages = tokens.map(token => ({
        to: token,
        sound: "default",
        title: title,
        body: body,
    }));

    await Promise.all(
        messages.map(async message => {
            await makeApiRequest('post', `${API_ENDPOINTS}/sendNotification`, authToken, refreshToken, setAuthToken, setRefreshToken, JSON.stringify(message));

        })
    );
}


export async function getExpoPushTokenAsync() {
    let token;

    if (Platform.OS === 'android') {
        await Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
        });
    }
    // Request permission to send push notifications (if not already granted)
    if (Device.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== 'granted') {
            console.log('Failed to get push token for push notification!');
            return;
        }

        // Get the Expo push notification token
        token = (await Notifications.getExpoPushTokenAsync({ projectId: Constants.expoConfig.extra.eas.projectId })).data;
    } else {
        alert('Must use physical device for Push Notifications');
    }
    return token;
}

async function getPushTokens(factory, authToken, refreshToken, setAuthToken, setRefreshToken) {

    try {
        // Implement logic to fetch all push tokens from your backend
        // This could be an API call to your server/database
        // For demonstration purposes, let's return a hard-coded array of push tokens
        let tokens = await makeApiRequest('get', `${TOKEN_API}/${factory}`, authToken, refreshToken, setAuthToken, setRefreshToken);
        return tokens;
    } catch (error) {
        console.error('Error fetching push tokens:', error);
        handleAlertForError(error)
        return [];
    }
}
