import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, FlatList } from 'react-native';
import Button from '../components/Button';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { FontAwesome5 } from '@expo/vector-icons';
import ShowAlert from '../modules/AlertModule';
import { handleAlertForError } from '../utils/handleError';
import useDataStore from '../stores/dataStore';
import { Colors } from '../constants/theme';
import Loader from '../components/Loader';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import TransactionList from '../components/TransactionList';
import { convertToDateTime, formatDate, parseDate } from '../utils/dateUtil';
import { isEmpty } from '../utils/commonUtils';
import { makeApiRequest } from '../utils/apiUtil';
import DateTimePicker from 'react-native-ui-datepicker';
import Popup from "../components/popup";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import MixpanelTracker from '../utils/MixpanelTracker';

const ProductionWorkScreen = ({ route }) => {

    const { name, userID, plantName } = route.params;
    const [API_CONFIG, userData, setToastMessage, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => [state.API_CONFIG, state.userData, state.setToastMessage, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isStartDatePickerVisible, setStartDatePickerVisibility] = useState(false);
    const [isEndDatePickerVisible, setEndDatePickerVisibility] = useState(false);
    const [loading, setLoading] = useState(true);
    const [grandTotal, setGrandTotal] = useState(null);
    const [totalPole, setTotalPole] = useState(null);
    const [totalProductionLine, setTotalProductionLine] = useState(null);
    const [productionExpenseData, setProductionExpenseData] = useState(null);
    const [dataKey, setDataKey] = useState(null);
    const [filteredTransactions, setFilterTransactions] = useState(null);
    const navigation = useNavigation();
    const isFocused = useIsFocused();

    let today = new Date();
    let prevDate = null;

    useLayoutEffect(() => {
        navigation.setOptions({
            title: `Production History | ${name}`
        })
    })

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_HISTORY_SCREEN, userData)
            fetchData();
            setStartDate(null);
            setEndDate(null);
        }
    }, [isFocused]);

    const fetchData = async () => {
        try {
            let responseData = await makeApiRequest('get', `${API_CONFIG.PLANT}/${plantName}/${userID}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            if (responseData) setLoading(false);
            setGrandTotal(calculateTotalAmount(responseData));
            setTotalProductionLine(calculateTotalLines(responseData));
            setTotalPole(calculateTotalPoles(responseData));

            setProductionExpenseData(sortDateLatest(responseData)); //Parent
            setFilterTransactions(sortDateLatest(responseData)); //Child

        } catch (err) {
            setLoading(false);
            console.error('Error fetching Production Expenses:', err);
            handleAlertForError(err);
        }
    };

    function calculateTotalAmount(dataObject) {
        let totalAmount = 0;
        for (const key in dataObject) {
            totalAmount += parseFloat(dataObject[key].amount);
        }
        return totalAmount;

    }

    function calculateTotalLines(dataObject) {
        let totalLine = 0;
        for (const key in dataObject) {
            totalLine += parseFloat(dataObject[key].line) || 0;
        }
        return totalLine;
    }

    function calculateTotalPoles(dataObject) {
        let totalPoles = 0;
        for (const key in dataObject) {
            totalPoles += parseFloat(dataObject[key].poleLine) || 0;
        }
        return totalPoles;
    }

    const handleButtonPress = () => {
        navigation.navigate("AddProductionScreen", { name, userID, plantName });
    };

    //Start Date Functions
    const showStartDatePicker = () => {
        setStartDatePickerVisibility(true);
    };

    const handleStartDateConfirm = (date) => {
        setStartDate(date);
        hideStartDatePicker();
        updateFilteredTransactions(date, endDate);
    };

    const hideStartDatePicker = () => {
        setStartDatePickerVisibility(false);
    };

    //End Date Funstions
    const showEndDatePicker = () => {
        // Only show the end date picker if the start date is already selected
        if (startDate) {
            setEndDatePickerVisibility(true);
        } else {
            // If start date is not selected, show an alert
            ShowAlert("Try again", "Please select the start date first.", "Ok");
        }
    };

    const handleEndDateConfirm = (date) => {
        // Check if the selected end date is before the start date
        if (startDate && date < startDate) {
            setEndDatePickerVisibility(false);
            ShowAlert("Wrong Date", "End date cannot be before the start date.", "Ok");
        } else {
            // If the end date is valid, update the state and hide the date picker
            setEndDate(date);
            hideEndDatePicker();
            updateFilteredTransactions(startDate, date);
        }
    };

    const hideEndDatePicker = () => {
        setEndDatePickerVisibility(false);
    };

    const handleDelete = async (key) => {
        ShowAlert("Delete", "Are you sure you want to delete this expense?", "YesNo", () => {
            console.log('OK Pressed');
        }, async () => {
            console.log('Yes Pressed');
            try {
                let deleteURL = await makeApiRequest('delete', `${API_CONFIG.PLANT}/${plantName}/${userID}/${key}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                setToastMessage("Expense deleted successfully");
                setDataKey(dataKey.filter(item => item !== key));
                fetchData();
            } catch (err) {
                console.log("Transaction delete err: ", err);
                handleAlertForError(err);
            }
        }, () => {
            console.log('No Pressed');
        });
    }

    const sortDateLatest = (data) => {
        let dataArray = Object.values(data);
        dataArray.sort(function (a, b) {
            // Convert dates to Date objects for comparison
            var dateA = parseDate(a.date);
            var dateB = parseDate(b.date);

            // Compare dates
            return dateB - dateA;
        });

        const sortedDataTData = {};
        dataArray.forEach(item => {
            sortedDataTData[item.id] = item;
        });
        setDataKey(Object.keys(reverseTransactionsByDate(sortedDataTData)));
        return sortedDataTData;
    };

    function reverseTransactionsByDate(data) {
        const groupedData = {};
        for (const key in data) {
            const date = data[key].date;
            if (!groupedData[date]) {
                groupedData[date] = [];
            }
            groupedData[date].push(key);
        }

        for (const date in groupedData) {
            groupedData[date].reverse();
        }

        const reversedData = {};
        for (const date in groupedData) {
            groupedData[date].forEach(key => {
                reversedData[key] = data[key];
            });
        }
        return reversedData;
    }

    const sortDataOnDate = (data, startDate, endDate) => {
        const sortedData = {};
        for (let [key, value] of Object.entries(data)) {
            const transactionDate = convertToDateTime(data[key]["date"]);

            if (transactionDate >= convertToDateTime(formatDate(startDate)) && transactionDate <= convertToDateTime(formatDate(endDate))) {
                sortedData[key] = data[key];
            }
        }
        return sortedData;
    }

    const updateFilteredTransactions = (startDate, endDate) => {
        if (!isEmpty(productionExpenseData) && startDate && endDate) {
            const sortedResponse = sortDataOnDate(productionExpenseData, startDate, endDate);
            // Calculate new totals
            const newTotalAmount = calculateTotalAmount(sortedResponse);
            const newTotalLines = calculateTotalLines(sortedResponse);
            const newTotalPoles = calculateTotalPoles(sortedResponse);

            setDataKey(Object.keys(reverseTransactionsByDate(sortedResponse)));
            setFilterTransactions(sortedResponse);

            setGrandTotal(newTotalAmount);
            setTotalProductionLine(newTotalLines);
            setTotalPole(newTotalPoles);
        }
    };

    return (
        <View style={styles.container}>

            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) : (
                <>
                    <View style={styles.datePickerContainer}>
                        <View style={styles.startDateContainer}>
                            <TouchableOpacity onPress={showStartDatePicker}>
                                <View style={styles.startDateContainerView}>
                                    <FontAwesome5
                                        name="calendar"
                                        size={16}
                                        color={Colors.primary}
                                    />
                                    <Text style={styles.startDateText}> {startDate ? startDate.toDateString() : "Select start date"}</Text>
                                    <FontAwesome5
                                        name="caret-down"
                                        size={16}
                                        color={Colors.primary}
                                    />
                                    <Popup visible={isStartDatePickerVisible} onClose={() => setStartDatePickerVisibility(false)}>
                                        <DateTimePicker
                                            mode="single"
                                            date={startDate || new Date()}
                                            maxDate={today}
                                            onChange={(param) => {
                                                handleStartDateConfirm(new Date(param.date))
                                                setStartDatePickerVisibility(false)
                                            }}
                                            headerButtonStyle={{ color: 'green' }}
                                        />
                                    </Popup>
                                </View>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.endDateContainer}>
                            <TouchableOpacity onPress={showEndDatePicker}>
                                <View style={styles.endDateContainerView}>
                                    <FontAwesome5
                                        name="calendar"
                                        size={16}
                                        color={Colors.primary}
                                    />
                                    <Text style={styles.endDateText}> {endDate ? endDate.toDateString() : "Select end date"}</Text>
                                    <FontAwesome5
                                        name="caret-down"
                                        size={16}
                                        color={Colors.primary}
                                    />
                                    <Popup visible={isEndDatePickerVisible} onClose={() => setEndDatePickerVisibility(false)}>
                                        <DateTimePicker
                                            mode="single"
                                            date={endDate || new Date()}
                                            maxDate={today}
                                            onChange={(param) => {
                                                handleEndDateConfirm(new Date(param.date));
                                                setEndDatePickerVisibility(false);
                                            }}
                                            headerButtonStyle={{ color: 'green' }}
                                        />
                                    </Popup>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={styles.amountSection}>
                        <View style={styles.leftSection}>
                            <Text style={styles.textStyle}>Total Line</Text>
                            <Text style={styles.textStyle}>{totalProductionLine}</Text>
                        </View>
                        <View style={styles.middleSection}>
                            <Text style={styles.textStyle}>Total Pole</Text>
                            <Text style={styles.textStyle}>{totalPole}</Text>
                        </View>
                        <View style={styles.rightSection}>
                            <Text style={styles.textStyle}>Total Amount</Text>
                            <Text style={styles.textStyle}>{grandTotal}</Text>
                        </View>
                    </View>

                    {dataKey?.length > 0 && filteredTransactions && !isEmpty(filteredTransactions) ? <FlatList
                        style={styles.flatListStyle}
                        data={dataKey}
                        renderItem={({ item: key }) => {
                            let jsx = <>
                                {prevDate !== filteredTransactions[key]?.date && <View style={styles.dateView}><Text style={styles.textDate}>{filteredTransactions[key]?.date}</Text></View>}
                                <View style={styles.rowContainer}>
                                    <View style={{
                                        width: '100%'
                                    }}>
                                        {(userData.role === APP_ROLE.SUPERVISOR ? userData.username === filteredTransactions[key]?.actionBy : true) && <TransactionList
                                            key={key}
                                            comment={`Line - ${filteredTransactions[key]?.line || 0}\nPole - ${filteredTransactions[key]?.poleLine || 0}`}
                                            commentFactoryExpense={filteredTransactions[key]?.comment ? filteredTransactions[key]?.comment : filteredTransactions[key]?.vehicle ? `Payment for loading ${filteredTransactions[key]?.vehicle}` : "-"}
                                            date={filteredTransactions[key]?.date}
                                            time={filteredTransactions[key]?.time}
                                            amount={filteredTransactions[key]?.amount}
                                            user={filteredTransactions[key]?.actionBy}
                                            type={"ProductionExpense"}
                                            userId={userID}
                                            transactionId={key}
                                            allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]}
                                            name={name}
                                            plantName={plantName}
                                            data={filteredTransactions[key]}
                                            isDisable={filteredTransactions[key]?.vehicle ? true : false}
                                        />}
                                    </View>
                                    {userData.username === filteredTransactions[key]?.actionBy && !filteredTransactions[key]?.vehicle && <TouchableOpacity style={styles.trigger} onPress={() => handleDelete(key)}>
                                        <MaterialCommunityIcons name="delete" size={28} color="#000" />
                                    </TouchableOpacity>}
                                </View>
                            </>
                            prevDate = filteredTransactions[key]?.date;
                            return (jsx);
                        }}
                        keyExtractor={(item) => item}
                    /> : <View style={styles.centeredView}>
                        <Text style={styles.noTransaction}>No Production Work Found</Text>
                    </View>
                    }
                </>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        marginVertical: 5
    },
    container: {
        flex: 1,
        alignItems: 'center'
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    datePickerContainer: {
        flexDirection: 'row',
    },
    startDateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 55,
        borderColor: '#636363',
        borderRadius: 6,
        paddingHorizontal: 8,
        margin: 12,
        padding: 6,
        width: '45%',
        backgroundColor: "white"
    },
    startDateContainerView: {
        flexDirection: 'row',
    },
    startDateText: {
        marginLeft: 5,
        marginRight: 5
    },
    endDateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 55,
        borderColor: '#636363',
        borderRadius: 6,
        marginTop: 12,
        paddingLeft: 6,
        width: '45%',
        backgroundColor: "white"
    },
    endDateContainerView: {
        flexDirection: 'row',
        padding: 5,
    },
    endDateText: {
        marginLeft: 5,
        marginRight: 5
    },
    amountSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 8,
        alignItems: 'left',
        width: '100%',
        marginTop: 0,
        marginBottom: 5,
        paddingVertical: 0,
    },
    leftSection: {
        width: "32%",
        borderRadius: 12,
        textAlign: "center",
        alignItems: 'center',
        backgroundColor: "#AFDBF5"
    },
    rightSection: {
        width: "32%",
        borderRadius: 12,
        textAlign: "center",
        alignItems: 'center',
        backgroundColor: "#FCACA5"
    },
    middleSection: {
        width: "32%",
        backgroundColor: "#93E2AE",
        borderRadius: 12,
        textAlign: "center",
        alignItems: 'center'
    },
    textStyle: {
        padding: 1,
        color: '#000',
        fontSize: 16,
        fontWeight: 'bold',
    },
    flatListStyle: {
        width: '100%',
        height: '100%',
    },
    dateView: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textDate: {
        backgroundColor: 'white',
        textAlign: 'center',
        width: "35%",
        borderRadius: 5,
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noTransaction: {
        textAlign: 'center',
    },
});

export default ProductionWorkScreen