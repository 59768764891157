import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, Modal, TextInput, FlatList, TouchableWithoutFeedback } from 'react-native';
import { useIsFocused, useRoute } from '@react-navigation/native';
import Button from '../components/Button';
import { Colors } from '../constants/theme';
import useDataStore from '../stores/dataStore';
import { makeApiRequest } from '../utils/apiUtil';
import Loader from '../components/Loader';
import { removeLeadingZero } from '../utils/commonUtils';
import List from '../components/List';
import MixpanelTracker from '../utils/MixpanelTracker';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import MultiSelect from '../components/MultiSelect';
import { USERS_API } from "../env-config/api-config";
import { handleAlertForError } from '../utils/handleError';

const PlantScreen = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
    const [plantName, setPlantName] = useState('');
    const [panelSize, setPanelSize] = useState('');
    const isFocused = useIsFocused();
    const [plantData, setPlantData] = useState([]);
    const [API_CONFIG, userData, authToken, setAuthToken, refreshToken, setRefreshToken] = useDataStore((state) => ([state.API_CONFIG, state.userData, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken]));
    const [loading, setLoading] = useState(true);
    const [supervisorItems, setSupervisorItems] = useState([]);
    const [supervisorValue, setSupervisorValue] = useState([]);
    let allPlants;
    let today = new Date();
    let time = new Date();
    const route = useRoute();
    const currentScreen = route.name;

    useEffect(() => {
        if (isFocused) {
            if (currentScreen === "Stock Management") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.STOCK_MANAGEMENT_CLICKED, userData);
            else if (currentScreen === "Production") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_CLICKED, userData);
            getPlantList();
        }
    }, [isFocused]);

    const addPlant = async () => {
        fetchtUserData();
        setIsOpen(true);
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_PLANT_CLICKED, userData);
    };

    const closeModal = () => {
        setIsOpen(false);
        setPlantName('');
        setPanelSize('');
    };

    const transformData = (data) => {
        const transformed = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
        }));


        // Filter the plants based on supervisor assignment
        if (userData.role === APP_ROLE.SUPERVISOR) {
            const filteredData = transformed.filter(plant => {
                return plant.supervisorList && plant.supervisorList.some(supervisor => supervisor.toLowerCase() === userData.username.toLowerCase());
            });
            setPlantData(filteredData)
        } else {
            setPlantData(transformed);
        }
    };

    const savePlant = async () => {
        if (plantName && panelSize) {
            await makeApiRequest("post", `${API_CONFIG.PLANT}/${plantName}`, authToken, refreshToken, setAuthToken, setRefreshToken, {
                panelSize: panelSize,
                actionBy: userData.username,
                supervisorList: supervisorValue,
                date: today.toDateString(),
                time: time.toLocaleTimeString("en-US"),
            });
            getPlantList();
        } else {
            setError('Please enter plant name');
        }
        closeModal();
    };

    const getPlantList = async () => {
        allPlants = await makeApiRequest('get', API_CONFIG.PLANT, authToken, refreshToken, setAuthToken, setRefreshToken);
        transformData(allPlants);
        setLoading(false);
    }

    const fetchtUserData = async () => {
        try {
            const userDataResponse = await makeApiRequest('get', `${USERS_API}/${userData.factory}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            const supervisors = Object.values(userDataResponse).filter(user => user.role === 'supervisor');
            const items = supervisors.map(supervisor => ({
                label: supervisor.username,
                value: supervisor.username,
            }));
            setSupervisorItems(items);
        } catch (error) {
            handleAlertForError(error);
        }
    }

    const getColor = (index) => {
        const colors = [
            '#FFCCCB', '#FFE4C4', '#FFD700', '#DAA520', '#ADFF2F', '#98FB98', '#00FFFF', '#AFEEEE', '#ADD8E6', '#87CEEB'
        ];
        return colors[index % colors.length];
    };


    return (
        <View style={styles.container}>
            <Modal
                visible={isOpen}
                transparent={true}
                onRequestClose={closeModal}
                animationType="fade">
                {/* <TouchableWithoutFeedback onPress={closeModal}> */}
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Add New Plant</Text>
                        <TextInput
                            style={styles.input}
                            placeholder="Plant Name"
                            value={plantName}
                            onChangeText={setPlantName}
                        />
                        <TextInput
                            style={styles.input}
                            placeholder="Panel Size"
                            keyboardType="numeric"
                            value={panelSize}
                            onChangeText={(text) => setPanelSize(removeLeadingZero(text))}
                        />

                        <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                            <Text style={styles.selectSupervisorText}>Select Supervisor : </Text>
                            <MultiSelect
                                isOpen={isMultiSelectOpen}
                                onClose={() => setIsMultiSelectOpen(false)}
                                options={supervisorItems}
                                selectedValues={supervisorValue}
                                onSelect={(selectedItems) => {
                                    setSupervisorValue(selectedItems)
                                }}
                                label="Select Supervisors..."
                            />
                        </View>
                        {supervisorValue?.length > 0 && <View style={styles.badgeContainer}>
                            {supervisorValue.map((tag, index) => (
                                <View key={index} style={[styles.tagContainer, { backgroundColor: getColor(index) }]}>
                                    <Text style={styles.tagText}>{tag}</Text>
                                </View>
                            ))}
                        </View>}
                        <View style={{ marginTop: 18 }}>
                            <Button onPress={savePlant} btnLabel="SAVE" buttonStyle={styles.saveButton} />
                        </View>
                    </View>
                </View>
                {/* </TouchableWithoutFeedback> */}
            </Modal>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) : plantData?.length > 0 ? (
                <FlatList
                    data={plantData}
                    renderItem={({ item: key }) => (
                        <List
                            key={key}
                            name={key.id}
                            amount={key.panelSize}
                            type={currentScreen === "Stock Management" ? "Stock" : "Production"}
                            supervisorList={key.supervisorList}
                        />
                    )}
                />
            ) : (
                <View style={styles.noPlantContainer}>
                    <Text>No Plants Found</Text>
                </View>
            )}
            <View style={styles.buttonContainer}>
                <Button
                    onPress={addPlant}
                    btnLabel="ADD PLANT"
                    buttonStyle={styles.btnAddPlant}
                    allowedRole={APP_ROLE.OWNER}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        zIndex: 1,
        top: 5
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 10,
        width: '100%',
        alignItems: 'center',
    },
    btnAddPlant: {
        paddingVertical: 20,
        width: '90%',
        backgroundColor: Colors.primary,
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: '80%',
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        elevation: 5,
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 10,
        paddingHorizontal: 10,
        borderRadius: 5,
    },
    rowContainer: {
        flexDirection: 'row',
        // justifyContent: 'space-between',
        // alignItems: 'center',
        width: '90%',
        // top: 15,
    },
    selectSupervisorText: {
        marginTop: 10,
        // fontSize: 18,
        // width: '52%',
        // textAlign: 'center',
        // fontWeight: 'bold',
    },
    badgeContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        borderWidth: 2,
        borderRadius: 5,
        borderColor: '#ccc',
        // padding: 5,
        width: '100%',
        // top: 10,
    },
    tagContainer: {
        backgroundColor: '#E0FFFF',
        borderRadius: 20,
        paddingHorizontal: 10,
        paddingVertical: 5,
        margin: 5,
    },
    tagText: {
        color: '#000',
    },
    saveButton: {
        backgroundColor: Colors.primary,
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    noPlantContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default PlantScreen;