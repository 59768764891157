export const USER_TYPE = {
    EMPLOYEES: 'employees',
    SUPPLIERS: 'suppliers',
    CLIENTS: 'clients',
    ACCOUNTS: 'accounts',
    THEKEDARS: 'thekedars',
    INCOME: 'income',
};

export const APP_ROLE = {
    OWNER: 'owner',
    SUPERVISOR: 'supervisor',
    VIEWER: 'viewer',
};

export const POLE_SIZES = [
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '12_5', value: '12_5' },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
];

export const POLE_SIZES_BOLT = [
    { label: '7b', value: '7b' },
    { label: '8b', value: '8b' },
    { label: '9b', value: '9b' },
    { label: '10b', value: '10b' },
    { label: '11b', value: '11b' },
    { label: '12b', value: '12b' },
    { label: '12_5b', value: '12_5b' },
    { label: '13b', value: '13b' },
    { label: '14b', value: '14b' },
];

export const EVENT_ACTIONS = {
    // User Init
    MIXPANEL_INIT: 'MIXPANEL_INIT',

    // Login
    LOGIN_CLICKED: 'LOGIN_CLICKED',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',
    NEW_LOGIN_CLICKED: 'NEW_LOGIN_CLICKED',
    VERIFY_CLICKED: 'VERIFY_CLICKED',
    INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',

    // Home screen
    EMPLOYEE_TAB: 'EMPLOYEE_TAB',
    SUPPLIER_TAB: 'SUPPLIER_TAB',
    CLIENT_TAB: 'CLIENT_TAB',
    ADD_DETAIL_SCREEN: 'ADD_DETAIL_SCREEN',

    // Add detail screen
    ADD_DETAIL_CLICKED: 'ADD_DETAIL_CLICKED',
    ADD_DETAIL_EDIT_CLICKED: 'ADD_DETAIL_EDIT_CLICKED',
    CONTACT_CLICKED: 'CONTACT_CLICKED',

    // Side navigation
    DAY_TRANSACTION_CLICKED: 'DAY_TRANSACTION_CLICKED',
    FACTORY_EXPENSE_CLICKED: 'FACTORY_EXPENSE_CLICKED',
    PRODUCTION_CLICKED: 'PRODUCTION_CLICKED',
    STOCK_MANAGEMENT_CLICKED: 'STOCK_MANAGEMENT_CLICKED',
    FACTORY_ACCOUNTS_CLICKED: 'FACTORY_ACCOUNTS_CLICKED',
    PRODUCTION_EXPENSE_CLICKED: 'PRODUCTION_EXPENSE_CLICKED',
    CLOSED_SITE_CLICKED: 'CLOSED_SITE_CLICKED',
    TRANSACTION_HISTORY_CLICKED: 'TRANSACTION_HISTORY_CLICKED',
    QUOTATION_CLICKED: 'QUOTATION_CLICKED',
    PROFILE_CLICKED: 'PROFILE_CLICKED',
    ADD_USERS_CLICKED: 'ADD_USERS_CLICKED',
    CHANGE_PIN_CLICKED: 'CHANGE_PIN_CLICKED',
    LOG_OUT_CLICKED: 'LOG_OUT_CLICKED',

    // Emp transaction Screen

    EMPLOYEE_CLICKED: 'EMPLOYEE_CLICKED',

    EMPLOYEE_YOU_GAVE_SCREEN: 'EMPLOYEE_YOU_GAVE_SCREEN',
    EMPLOYEE_TRANSACTION_ALL: 'EMPLOYEE_TRANSACTION_ALL',
    EMPLOYEE_DOWNLOAD_PDF: 'EMPLOYEE_DOWNLOAD_PDF',
    EMPLOYEE_VIEW_DETAILS: 'EMPLOYEE_VIEW_DETAILS',
    EMPLOYEE_EDIT_DETAILS: 'EMPLOYEE_EDIT_DETAILS',
    EMPLOYEE_DELETE: 'EMPLOYEE_DELETE',
    EMPLOYEE_EDIT_TRANSACTION: 'EMPLOYEE_EDIT_TRANSACTION',
    EMPLOYEE_DELETE_TRANSACTION: 'EMPLOYEE_DELETE_TRANSACTION',
    EMPLOYEE_ADD_EXTRA_EXPENSE: 'EMPLOYEE_ADD_EXTRA_EXPENSE',

    // Supplier transaction Screen

    SUPPLIER_CLICKED: 'SUPPLIER_CLICKED',

    SUPPLIER_YOU_GAVE_SCREEN: 'SUPPLIER_YOU_GAVE_SCREEN',
    SUPPLIER_TRANSACTION_ALL: 'SUPPLIER_TRANSACTION_ALL',
    SUPPLIER_DOWNLOAD_PDF: 'SUPPLIER_DOWNLOAD_PDF',
    SUPPLIER_VIEW_DETAILS: 'SUPPLIER_VIEW_DETAILS',
    SUPPLIER_EDIT_DETAILS: 'SUPPLIER_EDIT_DETAILS',
    SUPPLIER_DELETE: 'SUPPLIER_DELETE',
    SUPPLIER_EDIT_TRANSACTION: 'SUPPLIER_EDIT_TRANSACTION',
    SUPPLIER_DELETE_TRANSACTION: 'SUPPLIER_DELETE_TRANSACTION',

    // Client Screen

    CLIENT_CLICKED: 'CLIENT_CLICKED',

    CLIENT_ADD_SITE_SCREEN: 'CLIENT_ADD_SITE_SCREEN',
    CLIENT_VIEW_DETAILS: 'CLIENT_VIEW_DETAILS',
    CLIENT_EDIT_DETAILS: 'CLIENT_EDIT_DETAILS',
    CLIENT_DOWNLOAD_PDF: 'CLIENT_DOWNLOAD_PDF',
    CLIENT_CLOSED_SITE_CLICK: 'CLIENT_CLOSED_SITE_CLICK',
    CLIENT_DELETE: 'CLIENT_DELETE',

    // Add site data 
    CLIENT_ADD_SITE_CLICKED: 'CLIENT_ADD_SITE_CLICKED',

    // Site transaction screen

    SITE_CLICKED: 'SITE_CLICKED',
    SITE_DELETE: 'SITE_DELETE',

    EDIT_SITE_SCREEN: 'EDIT_SITE_SCREEN',
    CLIENT_EDIT_TRANSACTION: 'CLIENT_EDIT_TRANSACTION',
    CLIENT_DELETE_TRANSACTION: 'CLIENT_DELETE_TRANSACTION',

    // add transaction screen
    EMPLOYEE_YOU_GAVE_CLICKED: 'EMPLOYEE_YOU_GAVE_CLICKED',
    SUPPLIER_YOU_GAVE_CLICKED: 'SUPPLIER_YOU_GAVE_CLICKED',
    CLIENT_YOU_RECIEVED_CLICKED: 'CLIENT_YOU_RECIEVED_CLICKED',
    EMPLOYEE_YOU_GAVE_EDIT_CLICKED: 'EMPLOYEE_YOU_GAVE_EDIT_CLICKED',
    SUPPLIER_YOU_GAVE_EDIT_CLICKED: 'SUPPLIER_YOU_GAVE_EDIT_CLICKED',
    CLIENT_YOU_RECIEVED_EDIT_CLICKED: 'CLIENT_YOU_RECIEVED_EDIT_CLICKED',
    PRODUCTION_YOU_GAVE_EDIT_CLICKED: 'PRODUCTION_YOU_GAVE_EDIT_CLICKED',

    // Factory expense transaction Screen
    FACTORY_EXPENSE_YOU_GAVE_SCREEN: 'FACTORY_EXPENSE_YOU_GAVE_SCREEN',
    FACTORY_EXPENSE_TRANSACTION_ALL: 'FACTORY_EXPENSE_TRANSACTION_ALL',
    FACTORY_EXPENSE_EDIT_TRANSACTION: 'FACTORY_EXPENSE_EDIT_TRANSACTION',
    FACTORY_EXPENSE_DELETE_TRANSACTION: 'FACTORY_EXPENSE_DELETE_TRANSACTION',

    // Factory expense add transaction screen
    FACTORY_EXPENSE_CLICKED: 'FACTORY_EXPENSE_CLICKED',
    FACTORY_EXPENSE_YOU_GAVE_CLICKED: 'FACTORY_EXPENSE_YOU_GAVE_CLICKED',
    FACTORY_EXPENSE_ADD_ACCOUNT_CLICKED: 'FACTORY_EXPENSE_ADD_ACCOUNT_CLICKED',
    FACTORY_EXPENSE_ADD_ACCOUNT: 'FACTORY_EXPENSE_ADD_ACCOUNT',

    // Production expense

    THEKEDAR_CLICKED: 'THEKEDAR_CLICKED',
    ADD_THEKEDAR: 'ADD_THEKEDAR',
    PRODUCTION_EXPENSE_YOU_GAVE_SCREEN: 'PRODUCTION_EXPENSE_YOU_GAVE_SCREEN',
    PRODUCTION_EXPENSE_TRANSACTION_DROPDOWN: 'PRODUCTION_EXPENSE_TRANSACTION_DROPDOWN',
    PRODUCTION_EXPENSE_TRANSACTION_ALL: 'PRODUCTION_EXPENSE_TRANSACTION_ALL',
    PRODUCTION_EXPENSE_DOWNLOAD_PDF: 'PRODUCTION_EXPENSE_DOWNLOAD_PDF',
    PRODUCTION_EXPENSE_VIEW_DETAIL: 'PRODUCTION_EXPENSE_VIEW_DETAIL',
    PRODUCTION_EXPENSE_EDIT_DETAIL: 'PRODUCTION_EXPENSE_EDIT_DETAIL',
    PRODUCTION_EXPENSE_DELETE_THEKEDAR: 'PRODUCTION_EXPENSE_DELETE_THEKEDAR',
    PRODUCTION_EXPENSE_EDIT_TRANSACTION: 'PRODUCTION_EXPENSE_EDIT_TRANSACTION',
    PRODUCTION_EXPENSE_DELETE_TRANSACTION: 'PRODUCTION_EXPENSE_DELETE_TRANSACTION',
    PRODUCTION_EXPENSE_PRODUCTION_WORK: 'PRODUCTION_EXPENSE_PRODUCTION_WORK',
    PRODUCTION_EXPENSE_YOU_GAVE_CLICKED: 'PRODUCTION_EXPENSE_YOU_GAVE_CLICKED',
    PRODUCTION_EXPENSE_ADD_WORK_CLICKED: 'PRODUCTION_EXPENSE_ADD_WORK_CLICKED',
    PRODUCTION_EXPENSE_EDIT_WORK_CLICKED: 'PRODUCTION_EXPENSE_EDIT_WORK_CLICKED',
    ADD_PLANT_CLICKED: 'ADD_PLANT_CLICKED',
    PRODUCTION_PLANT_CLICKED: 'PRODUCTION_PLANT_CLICKED',
    PRODUCTION_EXPENSE_SCREEN: 'PRODUCTION_EXPENSE_SCREEN',

    // Closed site
    SITE_OPEN_CLICKED: 'SITE_OPEN_CLICKED',

    // Transaction history
    TRANSACTION_HISTORY_DROPDOWN: 'TRANSACTION_HISTORY_DROPDOWN',

    // Quotation screen
    QUOTATION_CREATE_SCREEN: 'QUOTATION_CREATE_SCREEN',
    QUOTATION_EDIT_SCREEN: 'QUOTATION_EDIT_SCREEN',

    // Create quotation screen
    QUOTATION_CREATE_CLICKED: 'QUOTATION_CREATE_CLICKED',
    QUOTATION_EDIT_CLICKED: 'QUOTATION_EDIT_CLICKED',
    QUOTATION_DELETE_CLICKED: 'QUOTATION_DELETE_CLICKED',

    // Add user screen
    ADD_USER_REGISTER_CLICKED: 'ADD_USER_REGISTER_CLICKED',

    // Change pin screen
    CHANGE_PIN_BUTTON_CLICKED: 'CHANGE_PIN_BUTTON_CLICKED',

    // Error
    ERROR_OCCURED: 'ERROR_OCCURED',

    //Report Screen
    DOWNLOAD_PDF_BUTTON_CLICKED: 'DOWNLOAD_PDF_BUTTON_CLICKED',

    // Factory expense transaction Screen
    OTHER_INCOME_YOU_GAVE_SCREEN: 'OTHER_INCOME_YOU_GAVE_SCREEN',
    OTHER_INCOME_TRANSACTION_ALL: 'OTHER_INCOME_TRANSACTION_ALL',
    OTHER_INCOME_EDIT_TRANSACTION: 'OTHER_INCOME_EDIT_TRANSACTION',
    OTHER_INCOME_DELETE_TRANSACTION: 'OTHER_INCOME_DELETE_TRANSACTION',

    // Factory expense add transaction screen
    OTHER_INCOME_YOU_GAVE_CLICKED: 'OTHER_INCOME_YOU_GAVE_CLICKED',
    OTHER_INCOME_ADD_ACCOUNT: 'OTHER_INCOME_ADD_ACCOUNT',
    OTHER_INCOME_CLICKED: 'OTHER_INCOME_CLICKED',
    OTHER_INCOME_HISTORY_CLICKED: 'OTHER_INCOME_HISTORY_CLICKED',

    //Add Extra Expense Screen
    ADD_EXTRA_EXPENSE_SCREEN: 'ADD_EXTRA_EXPENSE_SCREEN',
    EXTRA_EXPENSE_CLICKED: 'EXTRA_EXPENSE_CLICKED',

    //Stock Management Screen
    STOCK_PLANT_CLICKED: 'STOCK_PLANT_CLICKED',
    STOCK_MANAGEMENT_SCREEN: 'STOCK_MANAGEMENT_SCREEN',
    EDIT_PLANT_DETAILS_CLICKED: 'EDIT_PLANT_DETAILS_CLICKED',
    SAVE_PLANT_CLICKED: 'SAVE_PLANT_CLICKED',

    //Loading Screen
    LOADING_BUTTON_CLICKED: 'LOADING_BUTTON_CLICKED',
    LOADING_SCREEN: 'LOADING_SCREEN',
    LOADING_SCREEN_EDIT: 'LOADING_SCREEN_EDIT',
    PLUS_BUTTON_CLICKED: 'PLUS_BUTTON_CLICKED',
    ADD_VEHICLE_CLICKED: 'ADD_VEHICLE_CLICKED',
    ADD_LOADING_BUTTON_CLICKED: 'ADD_LOADING_BUTTON_CLICKED',
    LOADING_HISTORY_CLICKED: 'LOADING_HISTORY_CLICKED',

    //Unloading Screen
    UNLOADING_BUTTON_CLICKED: 'UNLOADING_BUTTON_CLICKED',
    UNLOADING_SCREEN_EDIT: 'UNLOADING_SCREEN_EDIT',
    UNLOADING_SCREEN: 'UNLOADING_SCREEN',
    ADD_UNLOADING_BUTTON_CLICKED: 'ADD_UNLOADING_BUTTON_CLICKED',
    UNLOADING_HISTORY_CLICKED: 'UNLOADING_HISTORY_CLICKED',

    //Stock Transaction Screen
    LOADING_STOCK_TRANSACTION_SCREEN: 'LOADING_STOCK_TRANSACTION_SCREEN',
    UNLOADING_STOCK_TRANSACTION_SCREEN: 'UNLOADING_STOCK_TRANSACTION_SCREEN',
    LOADING_TRANSACTION_DATA_CLICKED: 'LOADING_TRANSACTION_DATA_CLICKED',
    UNLOADING_TRANSACTION_DATA_CLICKED: 'UNLOADING_TRANSACTION_DATA_CLICKED',
    LOADING_DATA_DOWNLOADPDF_CLICKED: 'LOADING_DATA_DOWNLOADPDF_CLICKED',
    UNLOADING_DATA_DOWNLOADPDF_CLICKED: 'UNLOADING_DATA_DOWNLOADPDF_CLICKED',
    LOADING_DATA_EDIT_CLICKED: 'LOADING_DATA_EDIT_CLICKED',
    UNLOADING_DATA_EDIT_CLICKED: 'UNLOADING_DATA_EDIT_CLICKED',
    LOADING_DATA_DELETE_CLICKED: 'LOADING_DATA_DELETE_CLICKED',
    UNLOADING_DATA_DELETE_CLICKED: 'UNLOADING_DATA_DELETE_CLICKED',

    //Thekedar Transactions Screen
    ADD_PRODCUTION_SCREEN: 'ADD_PRODCUTION_SCREEN',
    ADD_PRODCUTION_CLICKED: 'ADD_PRODCUTION_CLICKED',

    //Production History Screen
    PRODUCTION_HISTORY_SCREEN: 'PRODUCTION_HISTORY_SCREEN',
    PRODUCTION_EDIT_CLICKED: 'PRODUCTION_EDIT_CLICKED',
}