import { Platform, StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import AddDetail from './screens/AddDetail';
import DrawerItems from './constants/DrawerItems';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { createStackNavigator } from '@react-navigation/stack';
import { Feather } from '@expo/vector-icons';
import { FontAwesome5, FontAwesome } from '@expo/vector-icons';
import HomeScreen from './screens/Home';
import TransactionScreen from './screens/TransactionScreen';
import AddTransactionScreen from './screens/AddTransactionScreen';
import ClientTransactionScreen from './screens/ClientTransactionScreen';
import AddSiteScreen from './screens/AddSiteScreen';
import SiteDetailsScreen from './screens/SiteDetailsScreen';
import LoginScreen from './screens/LoginScreen';
import useDataStore from './stores/dataStore';
import ChangePassScreen from './screens/ChangePassScreen';
import AddUserScreen from './screens/AddUserScreen';
import ReportScreen from './screens/ReportScreen';
import InfoScreen from './screens/InfoScreen';
import ProfileScreen from './screens/ProfileScreen';
import ShowAlert from './modules/AlertModule';
import CustomToast from './components/CustomToast';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DayTransactionScreen from './screens/DayTransactionScreen';
import ClosedSitesScreen from './screens/ClosedSitesScreen';
import * as Notifications from 'expo-notifications';
import { useEffect, useState } from 'react';
import { EVENT_ACTIONS } from './constants/Constant';
import FactoryExpense from './screens/FactoryExpense';
import AddFactoryExpense from './screens/AddFactoryExpense';
import TransactionHistory from './screens/TransactionHistory';
import ContactsScreen from './screens/ContactsScreen';
import AddProductionExpenseScreen from './screens/AddProductionExpenseScreen';
import InternetCheckWrapper from './components/InternetCheckWrapper';
import QuotationScreen from './screens/QuotationScreen';
import CreateQuotationScreen from './screens/CreateQuotationScreen';
import OtherIncome from './screens/OtherIncome';
import AddOtherIncome from './screens/AddOtherIncome';
import { handleAlertForError } from './utils/handleError';
import MixpanelTracker from './utils/MixpanelTracker';
import ExtraExpenseScreen from './screens/ExtraExpenseScreen';
import AddExtraExpenseScreen from './screens/AddExtraExpenseScreen';
import ProductionWorkScreen from './screens/ProductionWorkScreen';
import PlantScreen from './screens/PlantScreen';
import StockManagementScreen from './screens/StockManagementScreen';
import LoadingScreen from './screens/LoadingScreen';
import UnloadingScreen from './screens/UnloadingScreen';
import AddProductionScreen from './screens/AddProductionScreen';
import ProductionExpense from './screens/ProductionExpense';
import StockTransactionScreen from './screens/StockTransactionScreen';
import FactoryExpenseScreen from './screens/FactoryExpenseScreen';
import OtherIncomeScreen from './screens/OtherIncomeScreen';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const CustomDrawerContent = (props) => {
  const [userData, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => [state.userData, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]);
  const navigation = useNavigation();

  const handleLogout = async () => {
    ShowAlert("Log Out", "Are you sure you want to log out?", "YesNo", () => {
      console.log('OK Pressed');
    }, async () => {
      MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.LOG_OUT_CLICKED, userData);
      MixpanelTracker.resetUserIdentity();
      console.log('Yes Pressed');
      try {
        await AsyncStorage.removeItem('username');
        await AsyncStorage.removeItem('value');
        navigation.reset({
          index: 0,
          routes: [{ name: 'LoginScreen' }],
        });
      } catch (error) {
        handleAlertForError(error);
      }
    }, () => {
      console.log('No Pressed');
      navigation.goBack();
    });
  };

  return (
    <DrawerContentScrollView {...props}>
      <View style={styles.welcomeContainer}>
        <FontAwesome
          name="user-circle-o"
          size={24}
          color="black"
          style={{ paddingRight: 10 }}
        />
        <Text style={styles.welcomeText}>Welcome {userData.username}</Text>
      </View>
      <DrawerItemList {...props} />
      <View style={styles.logoutContainer}>
        <MaterialCommunityIcons
          name="logout"
          size={24}
          color="black"
        />
        <Text style={styles.logoutText} onPress={handleLogout}>Log Out</Text>
      </View>
    </DrawerContentScrollView>
  );
};

const DrawerNavigator = () => {
  const [userData] = useDataStore((state) => [state.userData]);

  return (<Drawer.Navigator
    drawerType="front"
    drawerContent={(props) => <CustomDrawerContent {...props} />}
    initialRouteName="Home"
    screenOptions={{
      activeTintColor: '#e91e63',
      itemStyle: { marginVertical: 10 },
    }}>
    {
      DrawerItems.map(drawer => drawer.allowedRole?.includes(userData.role) && <Drawer.Screen
        key={drawer.name}
        name={drawer.name}
        options={{
          drawerIcon: ({ focused }) =>
            drawer.iconType === 'Material' ?
              <MaterialCommunityIcons
                name={drawer.iconName}
                size={24}
                color={focused ? "#e91e63" : "black"}
              />
              :
              drawer.iconType === 'Feather' ?
                <Feather
                  name={drawer.iconName}
                  size={24}
                  color={focused ? "#e91e63" : "black"}
                />
                :
                <FontAwesome5
                  name={drawer.iconName}
                  size={22}
                  color={focused ? "#e91e63" : "black"}
                />
        }}
        component={
          drawer.name === 'Home' ? HomeScreen :
            drawer.name === 'Change PIN' ? ChangePassScreen :
              drawer.name === 'Add Users' ? AddUserScreen :
                drawer.name === 'Profile' ? ProfileScreen :
                  drawer.name === 'Day Transactions' ? DayTransactionScreen :
                    drawer.name === 'Factory Accounts' ? FactoryExpenseScreen :
                      drawer.name === 'Closed Sites' ? ClosedSitesScreen :
                        drawer.name === 'Transactions History' ? TransactionHistory :
                          drawer.name === 'Supervisor Transactions' ? SupervisorTransaction :
                            drawer.name === 'Production' ? PlantScreen :
                              drawer.name === 'Quotation' ? QuotationScreen :
                                drawer.name === 'Other Income' ? OtherIncomeScreen :
                                  drawer.name === 'Stock Management' ? PlantScreen :
                                    HomeScreen
        }
      />)
    }
  </Drawer.Navigator>)
}

export default function App() {
  const [toastMessage] = useDataStore((state) => [state.toastMessage]);
  const { width, height } = useWindowDimensions();
  const osData = Platform;
  const brand = osData?.__constants?.Brand;

  useEffect(() => {
    MixpanelTracker.initializeAnalytics(true);
    MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.MIXPANEL_INIT);
  }, [])

  useState(async () => {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
  }, [])

  return (
    <>
      <NavigationContainer>
        <InternetCheckWrapper>
          <Stack.Navigator>
            <Stack.Screen name="LoginScreen" component={LoginScreen} options={{ headerShown: false }} />
            <Stack.Screen name="HomeScreen" component={DrawerNavigator} options={{ headerShown: false }} />
            <Stack.Screen name="AddDetail" component={AddDetail} />
            <Stack.Screen name="TransactionScreen" component={TransactionScreen} />
            <Stack.Screen name="AddTransactionScreen" component={AddTransactionScreen} />
            <Stack.Screen name="ClientTransactionScreen" component={ClientTransactionScreen} />
            <Stack.Screen name="AddSiteScreen" component={AddSiteScreen} />
            <Stack.Screen name="SiteDetailsScreen" component={SiteDetailsScreen} />
            <Stack.Screen name="ReportScreen" component={ReportScreen} options={{ title: 'Report Screen' }} />
            <Stack.Screen name="InfoScreen" component={InfoScreen} />
            <Stack.Screen name="AddFactoryExpense" component={AddFactoryExpense} />
            <Stack.Screen name="FactoryExpenses" component={FactoryExpense} />
            <Stack.Screen name="OtherIncome" component={OtherIncome} />
            <Stack.Screen name="AddOtherIncome" component={AddOtherIncome} />
            <Stack.Screen name="ContactsScreen" component={ContactsScreen} options={{ title: 'Contact Screen' }} />
            <Stack.Screen name="AddProductionExpenseScreen" component={AddProductionExpenseScreen} />
            <Stack.Screen name="CreateQuotationScreen" component={CreateQuotationScreen} options={{ title: 'Create Quotation' }} />
            <Stack.Screen name="ExtraExpenseScreen" component={ExtraExpenseScreen} />
            <Stack.Screen name="AddExtraExpenseScreen" component={AddExtraExpenseScreen} />
            <Stack.Screen name="ProductionWorkScreen" component={ProductionWorkScreen} />
            <Stack.Screen name="AddProductionScreen" component={AddProductionScreen} />
            <Stack.Screen name="ProductionExpense" component={ProductionExpense} />
            <Stack.Screen name="StockManagementScreen" component={StockManagementScreen} />
            <Stack.Screen name="LoadingScreen" component={LoadingScreen} options={{ title: 'Add Loading Data' }} />
            <Stack.Screen name="UnloadingScreen" component={UnloadingScreen} />
            <Stack.Screen name="StockTransactionScreen" component={StockTransactionScreen} />
          </Stack.Navigator>
        </InternetCheckWrapper>
      </NavigationContainer>
      <CustomToast
        message={toastMessage}
        isVisible={toastMessage}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  welcomeContainer: {
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    flexDirection: 'row'
  },
  welcomeText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  logoutContainer: {
    flexDirection: "row",
    padding: 18,
    width: "100%"
  },
  logoutText: {
    fontSize: 14,
    paddingLeft: 32,
    fontWeight: "500",
    color: "#000000ad",
    width: "50%"
  }
});
