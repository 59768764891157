import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, StyleSheet } from 'react-native';
import Search from '../components/Search';
import List from '../components/List';
import useDataStore from '../stores/dataStore';
import Loader from '../components/Loader';
import { useIsFocused } from '@react-navigation/native';
import MixpanelTracker from '../utils/MixpanelTracker';
import { EVENT_ACTIONS } from '../constants/Constant';
import { makeApiRequest } from '../utils/apiUtil';

const EmployeesScreen = () => {
    const [employeesKey, setEmployeesKey] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [employeeData, setEmployeeData, API_CONFIG, userData, authToken, setAuthToken, refreshToken, setRefreshToken] = useDataStore((state) => [state.employeeData, state.setEmployeeData, state.API_CONFIG, state.userData, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken]);
    const [loading, setLoading] = useState(true);
    const isFocused = useIsFocused();

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_TAB, userData);
            fetchData();
        }
    }, [isFocused]);

    const fetchData = async () => {
        try {
            const data = await makeApiRequest('get', API_CONFIG.EMPLOYEES, authToken, refreshToken, setAuthToken, setRefreshToken);
            setEmployeeData(data);
            setEmployeesKey(Object.keys(data));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Failed to fetch employees:', error);
        }
    };

    const filteredEmployees = employeesKey?.filter(key => {
        return employeeData[key]['name'].toLowerCase().includes(searchQuery.toLowerCase()) && !employeeData[key]['isDeleted']
    });

    return (
        <View style={styles.container}>
            <Search value={searchQuery} onChangeText={setSearchQuery} placeholder="Search Employee" />
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) : filteredEmployees?.length > 0 ? (
                <FlatList
                    data={filteredEmployees}
                    renderItem={({ item: key }) => (
                        <List
                            key={key}
                            name={employeeData[key]['name']}
                            contact={employeeData[key]['contact']}
                            actionBy={employeeData[key]['actionBy']}
                            amount={employeeData[key]['absolute_amount']}
                            type={'Employees'}
                            userID={key}
                        />
                    )}
                    keyExtractor={(item) => item}
                />
            ) : (
                <View style={styles.noEmpContainer}>
                    {searchQuery ? <Text>No Employees Found</Text> : <Text style={styles.noEmpText}>No Employees Added</Text>}
                </View>
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    noEmpContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noEmpText: {
        textAlign: 'center',
        fontSize: 15,
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default EmployeesScreen;
