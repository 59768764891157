import React, { useEffect, useLayoutEffect, useState } from 'react'
import { FlatList, StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import Button from '../components/Button'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import MixpanelTracker from '../utils/MixpanelTracker'
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant'
import useDataStore from '../stores/dataStore'
import Loader from '../components/Loader'
import TransactionList from '../components/TransactionList'
import { handleAlertForError } from '../utils/handleError'
import ShowAlert from '../modules/AlertModule'
import { parseDate } from '../utils/dateUtil'
import { isEmpty } from '../utils/commonUtils';
import { makeApiRequest } from '../utils/apiUtil';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const ExtraExpenseScreen = ({ route }) => {

    let { name, userID } = route.params;
    const [extraExpenseData, setExtraExpenseData] = useState('');
    const [dataKey, setDataKey] = useState('');
    const [loading, setLoading] = useState(true);
    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const [API_CONFIG, userData, setToastMessage, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => ([state.API_CONFIG, state.userData, state.setToastMessage, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]));
    let prevDate = null;

    const handleButtonPress = () => {
        navigation.navigate("AddExtraExpenseScreen", { name, userID });
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_EXTRA_EXPENSE_SCREEN, userData);
    };

    const sortDateLatest = (data) => {
        let dataArray = Object.values(data);
        dataArray.sort(function (a, b) {
            // Convert dates to Date objects for comparison
            var dateA = parseDate(a.date);
            var dateB = parseDate(b.date);

            // Compare dates
            return dateB - dateA;
        });

        const sortedDataTData = {};
        dataArray.forEach(item => {
            sortedDataTData[item.id] = item;
        });
        return sortedDataTData;
    };

    function reverseTransactionsByDate(data) {
        const groupedData = {};
        for (const key in data) {
            const date = data[key].date;
            if (!groupedData[date]) {
                groupedData[date] = [];
            }
            groupedData[date].push(key);
        }

        for (const date in groupedData) {
            groupedData[date].reverse();
        }

        const reversedData = {};
        for (const date in groupedData) {
            groupedData[date].forEach(key => {
                reversedData[key] = data[key];
            });
        }
        return reversedData;
    }

    useLayoutEffect(() => {
        navigation.setOptions({
            title: `Extra Expense | ${name}`,
        })
    });

    useEffect(() => {
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);

    const fetchData = async () => {
        try {
            let responseData = await makeApiRequest('get', `${API_CONFIG.UNSETTLED_EXPENSES}/extraExpenses/${userID}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            if (!isEmpty(responseData) && responseData) {
                let sortedData = sortDateLatest(responseData)
                setExtraExpenseData(responseData);
                setDataKey(Object.keys(reverseTransactionsByDate(sortedData)));
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            console.error('Error fetching extra expenses:', err);
            handleAlertForError(err);
        }
    };

    const handleDelete = (key) => {
        ShowAlert("Delete", "Are you sure you want to delete this transaction?", "YesNo", () => {
            console.log('OK Pressed');
        }, async () => {
            console.log('Yes Pressed');
            try {
                await makeApiRequest('delete', `${API_CONFIG.UNSETTLED_EXPENSES}/extraExpenses/${userID}/${key}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                setDataKey(dataKey.filter(item => item !== key));
                fetchData();
                setToastMessage("Expense deleted successfully");
            } catch (err) {
                console.log("Transaction delete err: ", err);
                handleAlertForError(err);
            }
        }, () => {
            console.log('No Pressed');
        });
    }

    return (
        <View style={styles.container}>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) :
                dataKey?.length > 0 && extraExpenseData ? <FlatList
                    style={styles.flatListStyle}
                    data={dataKey}
                    // onScrollBeginDrag={handleOutsidePress}
                    renderItem={({ item: key }) => {
                        let jsx = <>
                            {prevDate !== extraExpenseData[key].date && <View style={styles.dateView}><Text style={styles.textDate}>{extraExpenseData[key].date}</Text></View>}
                            <View style={styles.rowContainer}>
                                <View style={{
                                    width: '100%'
                                }}>
                                    {(userData.role === APP_ROLE.SUPERVISOR ? userData.username === extraExpenseData[key].actionBy : true) && <TransactionList
                                        key={key}
                                        comment={extraExpenseData[key].comment ? extraExpenseData[key].comment : extraExpenseData[key].vehicle ? `Payment for Loading ${extraExpenseData[key].vehicle}` : "-"}
                                        date={extraExpenseData[key].date}
                                        time={extraExpenseData[key].time}
                                        amount={extraExpenseData[key].amount}
                                        user={extraExpenseData[key].actionBy}
                                        type={"extraExpense"}
                                        userId={userID}
                                        transactionId={key}
                                        allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]}
                                        isDisable={extraExpenseData[key]?.vehicle ? true : false}
                                    />}
                                </View>
                                {userData.username === extraExpenseData[key].actionBy && !extraExpenseData[key].vehicle && <TouchableOpacity style={styles.trigger} onPress={() => handleDelete(key)}>
                                    <MaterialCommunityIcons name="delete" size={28} color="#000" />
                                </TouchableOpacity>}
                            </View>
                        </>
                        prevDate = extraExpenseData[key].date;
                        return (jsx);
                    }}
                    keyExtractor={(item) => item}
                /> :
                    <View style={styles.centeredView}>
                        <Text style={styles.noTransaction}>No Expenses Added</Text>
                    </View>
            }
            <Button
                btnLabel="Add Extra Expense"
                buttonStyle={styles.btnContainer}
                onPress={handleButtonPress}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        marginVertical: 5
    },
    container: {
        flex: 1,
        alignItems: 'center'
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    flatListStyle: {
        width: '100%',
        height: '100%',
        marginBottom: 76
    },
    dateView: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',

    },
    textDate: {
        backgroundColor: 'white',
        textAlign: 'center',
        width: "35%",
        borderRadius: 5,
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noTransaction: {
        textAlign: 'center',
    },
    btnContainer: {
        paddingVertical: 20,
        width: '90%',
        position: 'absolute',
        bottom: 10,
    }
});

export default ExtraExpenseScreen