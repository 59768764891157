import React, { useEffect, useRef, useState } from 'react';
import { Text, View, StyleSheet, TextInput, TouchableOpacity, FlatList, ActivityIndicator } from 'react-native';
import { Colors } from '../constants/theme';
import useDataStore from '../stores/dataStore';
import Button from '../components/Button';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/FontAwesome';
import MultiSelect from '../components/MultiSelect';
import { USERS_API } from "../env-config/api-config";
import { handleAlertForError } from '../utils/handleError';
import { removeLeadingZero } from '../utils/commonUtils';
import { makeApiRequest } from '../utils/apiUtil';
import { formatDate } from '../utils/dateUtil';
import { Ionicons } from '@expo/vector-icons';

const AddSiteScreen = ({ route }) => {
    const [siteName, setSiteName] = useState('');
    const [isFocus, setIsFocus] = useState(null);
    const [totalSQFT, setTotalSQFT] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [runningFtData, setRunningFtData] = useState([{ runningFt: '', height: '', sqFeet: '', priceSqFeet: '' }]);
    const [pricePerSQFT, setPricePerSQFT] = useState('');
    const [gst, setGst] = useState(null);
    const [extraExpense, setExtraExpense] = useState("");
    const [subtractExpense, setSubtractExpense] = useState("");
    const [empList, setEmpList] = useState([]);
    const [employeeData, API_CONFIG, setToastMessage, userData, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => [state.employeeData, state.API_CONFIG, state.setToastMessage, state.userData, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]);
    const { userID, siteDetails, clientID, siteID } = route.params;
    const [error, setError] = useState('');
    const navigation = useNavigation();
    const [isOpen, setIsOpen] = useState(false);
    const flatListRef = useRef();
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);

    const [value, setValue] = useState(siteDetails?.employeeList?.map((e) => e.id) || []);
    const [supervisorValue, setSupervisorValue] = useState(siteDetails?.supervisorList || []);
    const [search, setSearch] = useState('');
    const [supervisorItems, setSupervisorItems] = useState([]);
    let today = new Date();
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        const newDataList = [...dataList];
        empList?.forEach((_, index) => {
            if (!newDataList[index]) {
                newDataList[index] = [{ sqFeet: '', price: '' }];
            }
        });
        setDataList(newDataList);

    }, [empList]);

    const updateData1 = (empIndex, rowIndex, field, value) => {
        const updatedList = dataList.map((empData, i) =>
            i === empIndex
                ? empData.map((row, j) =>
                    j === rowIndex ? { ...row, [field]: value } : row
                )
                : empData
        );
        setDataList(updatedList);
    };

    const addRow = (index) => {
        const updatedList = dataList.map((empData, i) =>
            i === index ? [...empData, { sqFeet: '', price: '' }] : empData
        );
        setDataList(updatedList);
    };

    const removeRow = (empIndex, rowIndex) => {
        const updatedList = dataList.map((empData, i) =>
            i === empIndex ? empData.filter((_, j) => j !== rowIndex) : empData
        );
        setDataList(updatedList);
    };

    const employeeDataArray = Object.keys(employeeData).map(key => ({
        label: employeeData[key].name,
        value: key,
        deleted: employeeData[key].isDeleted,
    }));

    // Filter items based on search value
    const filteredEmployeeData = employeeDataArray.filter(item => {
        return item.label?.toLowerCase().includes(search?.toLowerCase()) && !item.deleted
    });

    const validateForm = () => {
        if (!siteName) {
            setError('Please add a site name.');
            return false;
        }
        setError('');
        return true;
    };

    useEffect(() => {
        calculateTotalAmount();
    }, [runningFtData]);

    useEffect(() => {
        const arrayObjects = value.map(id => ({
            id: id,
            name: employeeData[id].name,
        }));
        setEmpList(arrayObjects)
    }, [value]);

    const calculateTotalAmount = () => {
        const total = runningFtData.reduce((accumulator, currentValue) => {
            if (currentValue.height !== '' && currentValue.runningFt !== '') {
                const product = parseFloat(currentValue.height) * parseFloat(currentValue.runningFt);
                return accumulator + product;
            }
            return accumulator
        }, 0);
        setTotalSQFT(total.toFixed(2));
    };

    const dataAddOn = () => {
        if (validateForm()) {
            setLoading(true);
            updateEmpData();
            const pushData = async () => {
                try {
                    const data = await makeApiRequest("post",
                        `${API_CONFIG.SITE_DETAILS}/${userID}`,
                        authToken, refreshToken, setAuthToken, setRefreshToken, {
                        siteName: siteName.trim(),
                        totalSQFT: totalSQFT,
                        pricePerSQFT: pricePerSQFT,
                        employeeList: empList,
                        runningFtData: runningFtData,
                        gst: gst,
                        extraExpense: extraExpense || 0,
                        subtractExpense: subtractExpense || 0,
                        totalAmount: totalAmount,
                        supervisorList: supervisorValue,
                        isClosed: false,
                        receivedAmount: 0,
                        startDate: formatDate(today)
                    });
                    setLoading(false);
                    setToastMessage("Site has been added");
                    navigation.goBack();
                    console.log('Pushing Clients', data);
                } catch (error) {
                    console.error('Error Pushing Clients:', error);
                    handleAlertForError(error);
                }
            };
            pushData();
            setSiteName('');
            setTotalSQFT('');
            setPricePerSQFT('');
            setValue([]);
            setSupervisorValue([]);
            setIsFocus('Site Name')
        }
    }

    const updateData = async () => {
        if (validateForm()) {
            updateEmpData();
            try {
                setLoading(true);
                const data = await makeApiRequest("put",
                    `${API_CONFIG.SITE_DETAILS}/${clientID}/${siteID}`,
                    authToken, refreshToken, setAuthToken, setRefreshToken, {
                    siteName: siteName.trim(),
                    totalSQFT: totalSQFT,
                    pricePerSQFT: pricePerSQFT,
                    employeeList: empList,
                    runningFtData: runningFtData,
                    gst: gst,
                    extraExpense: extraExpense,
                    subtractExpense: subtractExpense,
                    totalAmount: totalAmount,
                    supervisorList: supervisorValue,
                    isClosed: false
                });
                setToastMessage("Site has been updated");
                setLoading(false);
                navigation.goBack();
                console.log('Updating Site Details', data);
            } catch (error) {
                setLoading(false);
                console.error('Error Updating Site Details:', error);
                handleAlertForError(error);
            }
        }
    }

    useEffect(() => {
        navigation.setOptions({
            title: siteDetails ? 'Edit Site' : 'Add Site',
        });
        if (siteDetails) {
            setSiteName(siteDetails.siteName);
            setTotalSQFT(siteDetails.totalSQFT);
            setPricePerSQFT(siteDetails.pricePerSQFT ? siteDetails.pricePerSQFT : 0);
            setRunningFtData(siteDetails.runningFtData || [{ runningFt: '', height: '', sqFeet: '', priceSqFeet: '' }]);
            setGst(siteDetails?.gst || 0);
            setExtraExpense(siteDetails?.extraExpense || 0);
            setSubtractExpense(siteDetails?.subtractExpense || 0);
            setTotalAmount(siteDetails?.totalAmount || 0);
            setSupervisorValue(siteDetails.supervisorList || []);

            const updatedEmpList = siteDetails.employeeList?.map(({ work, ...rest }) => rest) || [];
            const upateddataList = siteDetails.employeeList?.map(item => item.work) || [];
            setAmount(parseFloat(siteDetails?.runningFtData?.reduce((sum, item) => {
                const sqFeet = item.sqFeet != null && item.sqFeet !== '' ? parseFloat(item.sqFeet).toFixed(2) : 0;
                const priceSqFeet = item.priceSqFeet != null && item.priceSqFeet !== '' ? parseFloat(item.priceSqFeet) : 0;
                return sum + (parseFloat(sqFeet) * parseFloat(priceSqFeet));
            }, 0))?.toFixed(2))
            setEmpList(updatedEmpList);
            setDataList(upateddataList);
        }
        fetchtUserData();
    }, [siteDetails, clientID, siteID]);

    const addRunningFtRow = () => {
        setRunningFtData(prevData => [...prevData, { runningFt: '', height: '', sqFeet: '', priceSqFeet: '' }]);
    }

    const removeRunningFtRow = (index) => {
        setRunningFtData(prevData => prevData.filter((_, i) => i !== index));
        let newData = runningFtData.filter((_, i) => i !== index)
        setAmount(parseFloat(newData.reduce((sum, item) => {
            const sqFeet = item.sqFeet != null && item.sqFeet !== '' ? parseFloat(item.sqFeet) : 0;
            const priceSqFeet = item.priceSqFeet != null && item.priceSqFeet !== '' ? parseFloat(item.priceSqFeet) : 0;
            return sum + (sqFeet * priceSqFeet);
        }, 0))?.toFixed(2));
    }

    const updateRunningFtData = (index, key, value) => {
        setRunningFtData(prevData => {
            const newData = [...prevData];
            newData[index][key] = value;
            if ((key === 'runningFt' || key === 'height') && (newData[index]['runningFt'] && newData[index]['height'])) {
                newData[index]['sqFeet'] = (newData[index]['runningFt'] * newData[index]['height']).toFixed(2);
            }
            setAmount(parseFloat(newData.reduce((sum, item) => {
                const sqFeet = item.sqFeet != null && item.sqFeet !== '' ? parseFloat(item.sqFeet).toFixed(2) : 0;
                const priceSqFeet = item.priceSqFeet != null && item.priceSqFeet !== '' ? parseFloat(item.priceSqFeet) : 0;
                return sum + (sqFeet * priceSqFeet);
            }, 0))?.toFixed(2));
            return newData;
        });
    }

    const updateEmpData = () => {
        let updatedEmpList = empList;
        updatedEmpList.map((data, index) => {
            updatedEmpList[index]['work'] = dataList[index];
        })
        setEmpList(updatedEmpList);
    }

    useEffect(() => {
        setTotalAmount((parseFloat(amount) + parseFloat((amount * gst) / 100)) + parseFloat(extraExpense === "" ? 0 : extraExpense) - parseFloat(subtractExpense === "" ? 0 : subtractExpense))
    }, [amount, gst, extraExpense, subtractExpense]);

    const fetchtUserData = async () => {
        try {
            const userDataResponse = await makeApiRequest('get', `${USERS_API}/${userData.factory}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            const supervisors = Object.values(userDataResponse).filter(user => user.role === 'supervisor');
            const items = supervisors.map(supervisor => ({
                label: supervisor.username,
                value: supervisor.username,
            }));
            setSupervisorItems(items);
        } catch (error) {
            handleAlertForError(error);
        }
    }

    const getColor = (index) => {
        const colors = [
            '#FFCCCB', '#FFE4C4', '#FFD700', '#DAA520', '#ADFF2F', '#98FB98', '#00FFFF', '#AFEEEE', '#ADD8E6', '#87CEEB'
        ];
        return colors[index % colors.length];
    };

    const scrollToBottom = () => {
        flatListRef?.current?.scrollToEnd({ animated: true });
    };

    return (
        <View style={{ backgroundColor: 'white', marginBottom: 70 }}>
            {loading && (
                <View style={styles.overlay}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )}
            <FlatList
                ref={flatListRef}
                style={{ marginBottom: 10, backgroundColor: 'white' }}
                data={[{ key: 'dummy' }]} // Provide a dummy item as FlatList requires data
                renderItem={({ item }) => ( // Render the inner content within FlatList
                    <View style={styles.container}>
                        <TextInput
                            placeholder='Site Name'
                            value={siteName}
                            onChangeText={(text) => setSiteName(text)}
                            style={[styles.inputSite, isFocus === 'Site Name' && { borderColor: Colors.primary }]}
                            onFocus={() => setIsFocus('Site Name')}
                        />
                        {runningFtData.map((item, index) => (
                            <View key={index} style={styles.siteDetailsContainer}>
                                <TextInput
                                    placeholder='Running Ft'
                                    value={item.runningFt?.toString()}
                                    keyboardType="numeric"
                                    onChangeText={(text) => updateRunningFtData(index, 'runningFt', removeLeadingZero(text))}
                                    style={[styles.inputSQFT, isFocus === `runningFT-${index}` && { borderColor: Colors.primary }]}
                                    onFocus={() => setIsFocus(`runningFT-${index}`)}
                                />
                                <Text style={styles.multiplyText}>*</Text>
                                <TextInput
                                    placeholder='Height'
                                    value={item.height?.toString()}
                                    keyboardType="numeric"
                                    onChangeText={(text) => updateRunningFtData(index, 'height', text)}
                                    style={[styles.inputSQFT, { width: '15%' }, isFocus === `Height-${index}` && { borderColor: Colors.primary }]}
                                    onFocus={() => setIsFocus(`Height-${index}`)}
                                />
                                <TextInput
                                    placeholder='SQ Feet'
                                    value={item.sqFeet.toString()}
                                    keyboardType="numeric"
                                    editable={false}
                                    onChangeText={(text) => updateRunningFtData(index, 'sqFeet', text)}
                                    style={[styles.inputSQFT, { width: '20%', color: '#000', marginLeft: 10 }, isFocus === `sqFeet-${index}` && { borderColor: Colors.primary }]}
                                    onFocus={() => setIsFocus(`sqFeet-${index}`)}
                                />
                                <TextInput
                                    placeholder='Price/Sq'
                                    value={item.priceSqFeet}
                                    keyboardType="numeric"
                                    onChangeText={(text) => updateRunningFtData(index, 'priceSqFeet', text)}
                                    style={[styles.inputSQFT, { width: '20%', marginLeft: 10 }, isFocus === `priceSqFeet-${index}` && { borderColor: Colors.primary }]}
                                    onFocus={() => setIsFocus(`priceSqFeet-${index}`)}
                                />
                                {index === 0 && ( // Render remove button for all rows except the first one
                                    <TouchableOpacity onPress={addRunningFtRow}>
                                        <Icon name="plus-circle" size={24} color="green" style={styles.removeButtonIcon} />
                                    </TouchableOpacity>
                                )}
                                {index !== 0 && ( // Render remove button for all rows except the first one
                                    <TouchableOpacity onPress={() => removeRunningFtRow(index)}>
                                        <Icon name="minus-circle" size={24} color="red" style={styles.removeButtonIcon} />
                                    </TouchableOpacity>
                                )}
                            </View>
                        ))}
                        <View style={styles.rowContainer}>
                            <Text style={styles.totalSQText}>Total SQ Feet:</Text>
                            <TextInput
                                value={totalSQFT?.toString() + ' SQ Feet'}
                                keyboardType="numeric"
                                style={[styles.inputExpense, { color: '#7d7d7a' }]}
                                editable={false}
                            />
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={styles.totalSQText}>GST %:</Text>
                            <TextInput
                                placeholder='GST %'
                                value={gst?.toString()}
                                keyboardType="numeric"
                                onChangeText={(text) => setGst(text)}
                                onFocus={() => setIsFocus(`GST`)}
                                style={[styles.inputExpense, isFocus === `GST` && { borderColor: Colors.primary }]}
                            />
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={styles.totalSQText}>Amount:</Text>
                            <TextInput
                                value={(parseFloat(amount) + parseFloat((amount * gst) / 100)).toFixed(2).toString() + ' Rs'}
                                keyboardType="numeric"
                                style={[styles.inputExpense, { color: '#7d7d7a' }]}
                                editable={false}
                            />
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={styles.totalSQText}>Extra Expense:</Text>
                            <TextInput
                                placeholder='Extra Expense'
                                value={extraExpense?.toString()}
                                keyboardType="numeric"
                                onChangeText={(text) => setExtraExpense(text)}
                                style={[styles.inputExpense, isFocus === 'Extra Expense' && { borderColor: Colors.primary }]}
                                onFocus={() => setIsFocus('Extra Expense')}
                            />
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={styles.totalSQText}>Subtract Expense:</Text>
                            <TextInput
                                placeholder='Subtract Expense'
                                value={subtractExpense?.toString()}
                                keyboardType="numeric"
                                onChangeText={(text) => setSubtractExpense(text)}
                                style={[styles.inputExpense, isFocus === 'Subtract Expense' && { borderColor: Colors.primary }]}
                                onFocus={() => setIsFocus('Subtract Expense')}
                            />
                        </View>

                        <Text style={styles.totalAmountText}>{"Total Amount : " + totalAmount}</Text>

                        <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                            <Text style={styles.selectEmployeeText}>Select Employee : </Text>
                            <MultiSelect
                                isOpen={isOpen}
                                onClose={() => setIsOpen(false)}
                                options={filteredEmployeeData}
                                selectedValues={value}
                                onSelect={(selectedItems) => {
                                    setValue(selectedItems)
                                    setTimeout(() => {
                                        scrollToBottom();
                                    }, 100)
                                }}
                                label="Select Employees..."
                            />
                        </View>
                        {empList?.map((item, empIndex) => (
                            <View key={empIndex} style={styles.empContainer}>
                                <Text style={styles.empName}>{item.name}</Text>
                                {dataList && dataList.length > 0 && dataList[empIndex]?.map((data, rowIndex) => (
                                    <View key={rowIndex} style={styles.siteDetailsContainer1}>
                                        <TextInput
                                            placeholder='SQ Feet'
                                            value={data.sqFeet}
                                            keyboardType="numeric"
                                            onChangeText={(text) => updateData1(empIndex, rowIndex, 'sqFeet', text)}
                                            style={[styles.inputPrice, isFocus === `sqFeet-${empIndex}-${rowIndex}` && { borderColor: Colors.primary }]}
                                            onFocus={() => setIsFocus(`sqFeet-${empIndex}-${rowIndex}`)}
                                        />
                                        <TextInput
                                            placeholder='Price/Sq Feet'
                                            value={data.price}
                                            keyboardType="numeric"
                                            onChangeText={(text) => updateData1(empIndex, rowIndex, 'price', text)}
                                            style={[styles.inputPrice, { marginLeft: 10, marginRight: 5 }, isFocus === `price-${empIndex}-${rowIndex}` && { borderColor: Colors.primary }]}
                                            onFocus={() => setIsFocus(`price-${empIndex}-${rowIndex}`)}
                                        />
                                        {rowIndex === 0 && <TouchableOpacity onPress={() => addRow(empIndex)}>
                                            <Ionicons name="add-circle-outline" size={24} color="green" />
                                        </TouchableOpacity>}
                                        {rowIndex > 0 && (
                                            <TouchableOpacity onPress={() => removeRow(empIndex, rowIndex)}>
                                                <Ionicons name="remove-circle-outline" size={24} color="red" />
                                            </TouchableOpacity>
                                        )}
                                    </View>
                                ))}
                            </View>
                        ))}
                        <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                            <Text style={styles.selectEmployeeText}>Select Supervisor : </Text>
                            <MultiSelect
                                isOpen={isOpen}
                                onClose={() => setIsOpen(false)}
                                options={supervisorItems}
                                selectedValues={supervisorValue}
                                onSelect={(selectedItems) => {
                                    setSupervisorValue(selectedItems)
                                    setTimeout(() => {
                                        scrollToBottom();
                                    }, 100)
                                }}
                                label="Select Supervisors..."
                            />
                        </View>

                        {supervisorValue?.length > 0 && <View style={styles.badgeContainer}>
                            {supervisorValue.map((tag, index) => (
                                <View key={index} style={[styles.tagContainer, { backgroundColor: getColor(index) }]}>
                                    <Text style={styles.tagText}>{tag}</Text>
                                </View>
                            ))}
                        </View>}
                        {error ? <Text style={{ color: 'red', fontSize: 16, top: 10 }}>{error}</Text> : null}

                    </View >
                )}
            />
            <View style={styles.container} >
                <Button
                    onPress={() => siteDetails ? updateData() : dataAddOn()}
                    btnLabel="ADD DATA"
                    buttonStyle={styles.BtnContainer} />
            </View >
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        textAlign: 'center',
        alignItems: 'center',
        marginBottom: 10,
    },
    inputSite: {
        borderWidth: 2,
        borderColor: '#636363',
        margin: 5,
        padding: 8,
        fontSize: 14,
        borderRadius: 6,
        width: '90%'
    },
    inputExpense: {
        borderWidth: 2,
        borderColor: '#636363',
        right: 45,
        padding: 6,
        fontSize: 14,
        borderRadius: 6,
        width: '48%',
        top: 5
    },
    siteDetailsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '90%',
        top: 5,
        paddingTop: 5
    },
    removeButtonIcon: {
        marginLeft: 25, // Adding some space between the input fields and the icon
    },
    inputSQFT: {
        borderWidth: 2,
        borderColor: '#636363',
        padding: 8,
        paddingLeft: 8,
        fontSize: 12,
        borderRadius: 6,
        width: '20%'
    },
    multiplyText: {
        padding: 4,
        fontSize: 18,
    },
    empName: {
        padding: 12,
        fontSize: 16,
        width: '35%'
    },
    inputPrice: {
        borderWidth: 2,
        borderColor: '#636363',
        padding: 2,
        paddingLeft: 8,
        fontSize: 12,
        borderRadius: 6,
        width: '30%'
    },
    totalAmountText: {
        marginTop: 20,
        padding: 12,
        fontSize: 18,
        width: '90%',
        fontWeight: 'bold'
    },
    totalSQText: {
        marginTop: 10,
        padding: 12,
        fontSize: 16,
        marginRight: 10,
        width: '60%',
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        top: 15
    },
    selectEmployeeText: {
        marginTop: 10,
        fontSize: 18,
        width: '52%',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    BtnContainer: {
        paddingVertical: 20,
        paddingHorizontal: 40,
        width: '90%',
        backgroundColor: Colors.primary,
        margin: 0,
    },
    badgeContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        borderWidth: 2,
        borderRadius: 5,
        borderColor: '#ccc',
        padding: 5,
        width: '90%',
        top: 10
    },
    tagContainer: {
        backgroundColor: '#E0FFFF',
        borderRadius: 20,
        paddingHorizontal: 10,
        paddingVertical: 5,
        margin: 5,
    },
    tagText: {
        color: '#000',
    },
    overlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
    },
    empContainer: {
        marginBottom: 5,
        width: '100%',
        alignItems: 'center',
        marginTop: 10
    },
    empName: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    inputPrice: {
        borderWidth: 1,
        borderRadius: 6,
        borderColor: 'gray',
        padding: 5,
        width: 120,
    },
    siteDetailsContainer1: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        alignSelf: 'center'
    },
});

export default AddSiteScreen