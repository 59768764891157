import React, { useEffect, useState } from 'react';
import { View, Text, FlatList, StyleSheet } from 'react-native';
import Search from '../components/Search';
import List from '../components/List';
import useDataStore from '../stores/dataStore';
import Loader from '../components/Loader';
import { useIsFocused } from '@react-navigation/native';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { isEmpty } from '../utils/commonUtils';
import { makeApiRequest } from '../utils/apiUtil';

const ClientScreen = () => {
  const [clientsKey, setClientssKey] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [clientsData, setClientsData, API_CONFIG, userData, authToken, setAuthToken, refreshToken, setRefreshToken] = useDataStore((state) => [state.clientsData, state.setClientsData, state.API_CONFIG, state.userData, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken]);
  const [loading, setLoading] = useState(true);
  const isFocused = useIsFocused();
  const [siteDetails, setSiteDetails] = useState(null);
  const [clientIds, setClientIds] = useState(null);
  let siteDetailsData;
  let grandAbsoluteAmount = 0;

  useEffect(() => {
    if (isFocused) {
      fetchData();
      MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CLIENT_TAB, userData)
    }
  }, [isFocused]);

  const fetchData = async () => {
    try {
      const data = await makeApiRequest('get', API_CONFIG.CLIENTS, authToken, refreshToken, setAuthToken, setRefreshToken);
      setClientsData(data);
      setClientssKey(Object.keys(data));

      siteDetailsData = await makeApiRequest('get', API_CONFIG.SITE_DETAILS, authToken, refreshToken, setAuthToken, setRefreshToken);
      if (!isEmpty(siteDetailsData)) {
        setSiteDetails(siteDetailsData);
        if (userData.role === APP_ROLE.SUPERVISOR) getSupervisor(siteDetailsData);
      } else {
        setSiteDetails({});
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching Clients:', error);
      handleAlertForError(error);
    }
  };
  const filteredClients = clientsKey?.filter(key => {
    return clientsData[key]['name'].toLowerCase().includes(searchQuery.toLowerCase()) && !clientsData[key]['isDeleted']
  });

  const calculateTotalAmount = (clientData) => {
    const totalAmountsByClient = {};
    for (const clientId in clientData) {
      let totalAmount = 0;
      let receivedAmount = 0;
      const sites = clientData[clientId];
      for (const siteId in sites) {
        const site = sites[siteId];
        if (!site.isClosed) {
          totalAmount += site.totalAmount ? parseFloat(site.totalAmount) : 0;
          receivedAmount += site.receivedAmount ? parseFloat(site.receivedAmount) : 0;
        }
      }
      totalAmountsByClient[clientId] = {
        totalAmount: totalAmount,
        receivedAmount: receivedAmount
      };
    }
    return totalAmountsByClient;
  };

  const totalAmountsByClient = calculateTotalAmount(siteDetails);

  const getSupervisor = (data) => {
    const filteredData = [];
    for (const clientId in data) {
      const clientSites = data[clientId];
      for (const siteId in clientSites) {
        const site = clientSites[siteId];
        if (site.supervisorList && site.supervisorList.length > 0 && site.supervisorList.includes(userData.username)) {
          filteredData.push({
            clientId,
            siteDetails: site
          });
        }
      }
    }
    const cID = filteredData.map(item => item.clientId);
    setClientIds(cID);
    return filteredData;
  }

  function getTotalAmount(data) {
    let totalAmount = 0;

    // Loop through each property in the data
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        let site = data[key];

        // Loop through each site's properties
        for (let siteKey in site) {
          if (site.hasOwnProperty(siteKey)) {
            // Add the totalAmount of each site to the totalAmount
            if (site[siteKey]?.isClosed === false) {
              totalAmount += parseInt(site[siteKey].totalAmount);
              grandAbsoluteAmount += parseInt(site[siteKey].receivedAmount);
            }
          }
        }
      }
    }
    return totalAmount;
  }

  const grandTotalAmount = getTotalAmount(siteDetails);

  return (
    <View style={styles.container}>
      <Search value={searchQuery} onChangeText={setSearchQuery} placeholder="Search Clients" />
      {loading ? (
        <View style={styles.loaderContainer}>
          <Loader size="large" />
        </View>
      ) : userData.role === APP_ROLE.SUPERVISOR && clientIds.length === 0 ? (
        <View style={styles.noEmpContainer}>
          <Text style={styles.noEmpText}>No Clients Added</Text>
        </View>
      ) :
        filteredClients?.length > 0 ? (
          <>
            <View style={styles.allSiteAmountContainer}>
              <View style={styles.itemLeft}>
                <Text style={styles.totalAmountText}> Total Amount </Text>
                <Text style={styles.totalAmountText}>₹ {grandTotalAmount} </Text>
              </View>
              <View style={styles.itemMiddle}>
                <Text style={styles.totalPendingText}> Total Pending </Text>
                <Text style={styles.totalPendingText}>₹ {grandTotalAmount - grandAbsoluteAmount} </Text>
              </View>
              <View style={styles.itemRight}>
                <Text style={styles.totalReceivedText}> Total Received </Text>
                <Text style={styles.totalReceivedText}>₹ {grandAbsoluteAmount} </Text>
              </View>
            </View>
            <FlatList
              data={userData.role === APP_ROLE.SUPERVISOR ? clientIds : filteredClients}
              renderItem={({ item: key }) => (
                !isEmpty(siteDetails) && siteDetails[key] && Object.values(siteDetails[key]).every(site => site.isClosed) ? null :
                  <List
                    key={key}
                    name={clientsData[key]['name']}
                    contact={clientsData[key]['contact']}
                    actionBy={clientsData[key]['actionBy']}
                    amount={totalAmountsByClient[key] ? totalAmountsByClient[key].receivedAmount : 0}
                    totalAmount={totalAmountsByClient[key] ? totalAmountsByClient[key].totalAmount : 0}
                    type={'Clients'}
                    userID={key}
                  />
              )}
              keyExtractor={(item) => item}
            />
          </>
        ) : (
          <View style={styles.noEmpContainer}>
            {searchQuery ? <Text>No Clients Found</Text> : <Text style={styles.noEmpText}>No Clients Added</Text>}
          </View>
        )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  noEmpContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noEmpText: {
    textAlign: 'center',
    fontSize: 15,
  },
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  allSiteAmountContainer: {
    width: '100%',
    backgroundColor: 'white',
    flexDirection: 'row',
    height: 50,
    marginBottom: 10
  },
  itemLeft: {
    width: '33.34%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E5E4E2'
  },
  itemMiddle: {
    width: '33.34%',
    backgroundColor: "#fdf6f8",
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemRight: {
    width: '33.34%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#e4f7e7",
  },
  totalAmountText: {
    fontWeight: 'bold'
  },
  totalPendingText: {
    color: 'red',
    fontWeight: 'bold'
  },
  totalReceivedText: {
    color: 'green',
    fontWeight: 'bold'
  }

});

export default ClientScreen;
