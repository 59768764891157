import { Linking } from 'react-native';

const isEmpty = (obj) => {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }
    return true;
}

const removeLeadingZero = (text) => {
    let numericText = text.replace(/[\s~`!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
    numericText = numericText.replace(/^0+/, '');
    return numericText;
}

const sendWAMessage = (message, contact) => {
    let url = "";
    url = "whatsapp://send?text=" + message + "&phone=+91" + contact;
    Linking.openURL(url);
}

export { isEmpty, removeLeadingZero, sendWAMessage };