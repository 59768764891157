import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import useDataStore from '../stores/dataStore';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import MixpanelTracker from '../utils/MixpanelTracker';
import { Colors } from '../constants/theme';

const List = (props) => {
    const navigation = useNavigation();
    const initLetter = props.name.charAt(0).toUpperCase();
    const [userData] = useDataStore((state) => [state.userData]);

    const goToOtherScreen = () => {
        if (props.type === "Clients") {
            navigation.navigate('ClientTransactionScreen', { name: props.name, amount: props.amount, type: props.type, userID: props.userID, contact: props.contact, actionBy: props.actionBy });
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CLIENT_CLICKED, userData);
        }
        else if (props.type === "Quotation") {
            navigation.navigate('CreateQuotationScreen', { data: props.data });
            if (props.data) {
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.QUOTATION_EDIT_SCREEN, userData);
            } else {
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.QUOTATION_CREATE_SCREEN, userData);
            }
        }
        else if (props.type === "Production") {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_PLANT_CLICKED, userData);
            navigation.navigate('ProductionExpense', { plantName: props.name });
        }
        else if (props.type === "Stock") {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.STOCK_PLANT_CLICKED, userData);
            navigation.navigate('StockManagementScreen', { plant: props.name, panelSize: props.amount, supervisorList: props.supervisorList });
        }
        else if (props.type === "Accounts") {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.FACTORY_EXPENSE_YOU_GAVE_SCREEN, userData);
            navigation.navigate('AddFactoryExpense', { accountType: props.accountId, accountName: props.name });
        }
        else if (props.type === "IncomeAccount") {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.OTHER_INCOME_YOU_GAVE_SCREEN, userData);
            navigation.navigate('AddOtherIncome', { accountType: props.accountId, accountName: props.name });
        }
        else {
            navigation.navigate('TransactionScreen', { name: props.name, amount: props.amount, type: props.type, userID: props.userID, contact: props.contact, actionBy: props.actionBy, plantName: props.plantName });
            if (props.type === "Employees") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_CLICKED, userData);
            else if (props.type === "Suppliers") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SUPPLIER_CLICKED, userData);
            else if (props.type === "Thekedars") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.THEKEDAR_CLICKED, userData);
        }
    };

    return (
        <TouchableOpacity style={styles.button} onPress={goToOtherScreen}>
            <View style={styles.item}>
                <View style={styles.itemLeft}>
                    <View style={styles.tile}>
                        <Text style={styles.initialLetter}>{initLetter}</Text>
                    </View>
                    <Text style={styles.itemText}>{props.name}</Text>
                </View>
                {userData.role !== APP_ROLE.SUPERVISOR && props.type !== 'Quotation' && (
                    <View style={styles.amount}>
                        {props.type === 'Clients' ? (
                            props.totalAmount ?
                                <>
                                    <View style={styles.middleSection}>
                                        <Text style={styles.pendingText}>₹ {(parseFloat(props.totalAmount) - parseFloat(props.amount)).toFixed(2)}</Text>
                                    </View>
                                    <View style={styles.endSection}>
                                        <Text style={styles.amountText}>₹ {parseFloat(props.amount).toFixed(2)}</Text>
                                    </View>
                                </> : null
                        ) : (
                            <View style={styles.finalAmountSection}>
                                {
                                    props.type === "Production" || props.type === "Stock" ? <Text style={styles.panelSizeText}> Panel Size: {props.amount}</Text> :
                                        props.amount !== undefined && <Text style={styles.finalAmountText}> ₹{props.amount}</Text>
                                }
                            </View>
                        )}
                    </View>
                )}
            </View>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    item: {
        backgroundColor: '#FFF',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 5,
        marginTop: 5,
    },
    itemLeft: {
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: 15,
        flex: 1,
    },
    tile: {
        width: 40,
        height: 40,
        backgroundColor: '#55BCF6',
        opacity: 0.4,
        borderRadius: 20,
        marginRight: 20,
        justifyContent: 'center',
    },
    initialLetter: {
        textAlign: 'center',
        fontSize: 20,
    },
    itemText: {
        flexShrink: 1,
        flexGrow: 1,
        padding: 15,
        paddingLeft: 0,
        paddingRight: 15,
    },
    amount: {
        justifyContent: 'flex-end',
        borderRadius: 10,
        marginRight: 0,
        flexDirection: 'row',
        height: '100%',
        flex: 1,
    },
    middleSection: {
        backgroundColor: "#fdf6f8",
        width: "50%",
        justifyContent: 'center',
        alignItems: 'center',
    },
    endSection: {
        backgroundColor: "#e4f7e7",
        width: "50%",
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
        justifyContent: 'center',
    },
    amountText: {
        color: 'green',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    pendingText: {
        color: 'red',
        fontWeight: 'bold',
    },
    finalAmountSection: {
        justifyContent: 'center',
        padding: 10,
        borderRadius: 10,
        marginRight: 0,
        width: "100%",
        textAlign: "right",
        alignItems: "flex-end",
    },
    finalAmountText: {
        fontSize: 18,
        color: '#333',
        padding: 15,
        textAlign: "right",
        color: 'green',
        fontWeight: 'bold'
    },
    panelSizeText: {
        fontSize: 18,
        padding: 15,
        textAlign: "right",
        color: Colors.gray,
        fontWeight: "bold"
    }
});

export default List;
