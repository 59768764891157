import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import { StyleSheet, Text, View, TextInput, TouchableOpacity, FlatList } from 'react-native'
import { Colors } from '../constants/theme';
import Button from '../components/Button';
import MultiSelect from '../components/MultiSelect';
import CustomCheckbox from '../components/CustomCheckbox';
import Icon from 'react-native-vector-icons/FontAwesome';
import { EVENT_ACTIONS, POLE_SIZES, POLE_SIZES_BOLT } from '../constants/Constant';
import { makeApiRequest } from '../utils/apiUtil';
import { handleAlertForError } from '../utils/handleError';
import useDataStore from '../stores/dataStore';
import { formatDate } from '../utils/dateUtil';
import { useNavigation } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';
import Loader from '../components/Loader';
import MixpanelTracker from '../utils/MixpanelTracker';

const UnloadingScreen = ({ route }) => {
    const { plant, data, transactionId } = route.params;
    const [loading, setLoading] = useState(true);
    const [isFocus, setIsFocus] = useState(null);
    const [panel, setPanel] = useState(null);
    const [cementBag, setCementBag] = useState(null);
    const [mSand, setMsand] = useState(null);
    const [jalli, setJalli] = useState(null);
    const [steel, setSteel] = useState(null);
    const [error, setError] = useState(null);
    const [poleData, setPoleData] = useState([{ poleQuantity: '', poleSize: '', isBolt: false }]);
    const [open, setOpen] = useState(false);
    const flatListRef = useRef();
    const [userData, API_CONFIG, authToken, setAuthToken, refreshToken, setRefreshToken, setToastMessage] = useDataStore((state) => [state.userData, state.API_CONFIG, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken, state.setToastMessage]);
    const navigation = useNavigation();

    useEffect(() => {
        if (data) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.UNLOADING_SCREEN_EDIT, userData);
            data.panel ? setPanel(data?.panel) : null;
            data.cement ? setCementBag(data?.cement) : null;
            data.mSand ? setMsand(data?.msand) : null;
            data.jaali ? setJalli(data?.jaali) : null;
            data.steel ? setSteel(data?.steel) : null;
            data.poleData ? setPoleData(data?.poleData) : null;
        }
        setLoading(false);
    }, [data]);

    useLayoutEffect(() => {
        if (!data) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.UNLOADING_SCREEN, userData);
            navigation.setOptions({
                title: `Add Unloading Data | ${plant}`,
                headerRight: () => (
                    <TouchableOpacity style={{ paddingRight: 20 }} onPress={goToOtherScreen}>
                        <MaterialIcons name="history" size={24} color="black" />
                    </TouchableOpacity>
                ),
            });
        } else {
            navigation.setOptions({
                title: `Edit Unloading Data | ${plant}`,
                headerRight: () => null,
            });
        }
    }, [navigation, data, plant]);

    const goToOtherScreen = () => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.UNLOADING_HISTORY_CLICKED, userData);
        navigation.navigate('StockTransactionScreen', { plant, type: 'unloadingData' });
    };

    const updatePoleData = (index, key, value) => {
        setPoleData(prevData => {
            const newData = [...prevData];
            newData[index][key] = value;
            return newData;
        });
    }

    const addPoleRow = () => {
        setPoleData(prevData => [...prevData, { poleQuantity: '', poleSize: '', isBolt: false }]);
    }

    const removePoleRow = (index) => {
        setPoleData(prevData => prevData.filter((_, i) => i !== index));
    }

    const getPoleCount = () => {
        return poleData?.reduce((acc, item) => {
            const { poleQuantity } = item;
            if (poleQuantity !== "") {
                const quantity = parseFloat(poleQuantity);
                return acc + quantity;
            }
            return acc;
        }, 0)
    }

    const convertToPolesObject = (polesArray) => {
        return polesArray.reduce((acc, pole) => {
            const size = pole.poleSize;
            const quantity = parseInt(pole.poleQuantity, 10);

            if (acc[size]) {
                acc[size] += quantity;
            } else {
                acc[size] = quantity;
            }

            return acc;
        }, {});
    };

    const validateForm = () => {
        if (!panel && !cementBag && !jalli && !mSand && !steel && !poleData[0].poleQuantity) {
            setError('Please enter unloading Data');
            return false;
        }

        let isErr = false;
        poleData.map(key => {
            if (key['poleQuantity'] || key['poleSize']) {
                if (key['poleQuantity'] === '') {
                    setError('Please add pole quantity.');
                    isErr = true;
                }
                if (key['poleSize'] === '') {
                    setError('Please add pole size.');
                    isErr = true;
                }
            }
        });

        if (isErr) {
            return false;
        }

        setError('');
        return true;
    };

    const handleAddData = async () => {
        let today = new Date();
        try {
            if (validateForm()) {
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_UNLOADING_BUTTON_CLICKED, userData);
                if (data) {
                    setLoading(true);
                    await makeApiRequest('put', `${API_CONFIG.PLANT}/${plant}/unloadingData/${transactionId}`, authToken, refreshToken, setAuthToken, setRefreshToken, {
                        date: formatDate(today),
                        time: today.toLocaleTimeString("en-US"),
                        panel: panel ? panel : 0,
                        pole: getPoleCount(),
                        poles: getPoleCount() > 0 ? convertToPolesObject(poleData) : 0,
                        poleData: poleData,
                        cement: cementBag ? cementBag : 0,
                        msand: mSand ? mSand : 0,
                        jalli: jalli ? jalli : 0,
                        steel: steel ? steel : 0,
                        actionBy: userData.username,
                    });
                    setLoading(false);
                    setToastMessage("Data updated successfully");
                    navigation.goBack();
                } else {
                    setLoading(true);
                    await makeApiRequest('post', `${API_CONFIG.PLANT}/${plant}/unloadingData`, authToken, refreshToken, setAuthToken, setRefreshToken, {
                        date: formatDate(today),
                        time: today.toLocaleTimeString("en-US"),
                        panel: panel ? panel : 0,
                        pole: getPoleCount(),
                        poles: getPoleCount() > 0 ? convertToPolesObject(poleData) : 0,
                        poleData: poleData,
                        cement: cementBag ? cementBag : 0,
                        msand: mSand ? mSand : 0,
                        jalli: jalli ? jalli : 0,
                        steel: steel ? steel : 0,
                        actionBy: userData.username,
                    })
                    setLoading(false);
                    setToastMessage("Data added successfully");
                    navigation.goBack();
                }
            }

        } catch (e) {
            setLoading(false);
            handleAlertForError(e);
            console.error("Error while adding unloading data", e);
        }
    };

    return (
        <>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) : (
                <>
                    <FlatList
                        ref={flatListRef}
                        style={{ marginBottom: 80 }}
                        data={[{ key: 'dummy' }]} // Provide a dummy item as FlatList requires data
                        renderItem={({ item }) => ( // Render the inner content within FlatList
                            <View style={styles.container}>
                                <View style={styles.rowContainer}>
                                    <Text style={styles.selectText}>Enter Panel:</Text>
                                    <TextInput
                                        placeholder='Piece'
                                        value={panel}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setPanel(text)}
                                        style={[styles.inputPanel, isFocus === 'Panel' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Panel')}
                                    />
                                </View>

                                <View>
                                    <Text style={styles.poleDetailsTitle}>Pole Details:</Text>
                                    {poleData?.map((item, index) => (
                                        <View key={index} style={styles.productContainer}>
                                            <View style={styles.poleContainer}>
                                                <Text style={styles.poleInputText}>Pole Quantity:</Text>
                                                <TextInput
                                                    placeholder="Pole Qty"
                                                    value={item.poleQuantity}
                                                    keyboardType="numeric"
                                                    onChangeText={(text) => updatePoleData(index, 'poleQuantity', text)}
                                                    style={styles.inputRow}
                                                />
                                            </View>
                                            <View style={styles.poleContainer}>
                                                <Text style={[styles.poleInputText, { marginTop: 10 }]}>Pole Size:</Text>
                                                <MultiSelect
                                                    isOpen={open}
                                                    onClose={() => setOpen(false)}
                                                    options={item.isBolt ? POLE_SIZES_BOLT : POLE_SIZES}
                                                    selectedValues={[]}
                                                    onSelect={(selectedItems) => updatePoleData(index, 'poleSize', selectedItems[0])}
                                                    label={item.poleSize || "Select size"}
                                                    singleSelect={true}
                                                />
                                                <CustomCheckbox
                                                    label="With bolt"
                                                    isChecked={item.isBolt}
                                                    onChange={() => {
                                                        updatePoleData(index, 'isBolt', !item.isBolt);
                                                        updatePoleData(index, 'poleSize', '');
                                                    }}
                                                    style={styles.checkbox}
                                                />
                                            </View>
                                            <View style={styles.buttonContainer}>
                                                {index === 0 && (
                                                    <TouchableOpacity onPress={addPoleRow}>
                                                        <Icon name="plus-circle" size={24} color="green" style={styles.addButtonIcon} />
                                                    </TouchableOpacity>
                                                )}
                                                {index !== 0 && (
                                                    <TouchableOpacity onPress={() => removePoleRow(index)}>
                                                        <Icon name="minus-circle" size={24} color="red" style={styles.removeButtonIcon} />
                                                    </TouchableOpacity>
                                                )}
                                            </View>
                                        </View>
                                    ))}
                                </View>

                                <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Enter Cement:</Text>
                                    <TextInput
                                        placeholder='Bag'
                                        value={cementBag?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setCementBag(text)}
                                        style={[styles.inputPanel, isFocus === 'Cement' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Cement')}
                                    />
                                </View>

                                <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Enter Msand:</Text>
                                    <TextInput
                                        placeholder='Ton'
                                        value={mSand?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setMsand(text)}
                                        style={[styles.inputPanel, isFocus === 'Msand' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Msand')}
                                    />
                                </View>

                                <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Enter Jalli:</Text>
                                    <TextInput
                                        placeholder='Ton'
                                        value={jalli?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setJalli(text)}
                                        style={[styles.inputPanel, isFocus === 'Jalli' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Jalli')}
                                    />
                                </View>

                                <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Enter Steel:</Text>
                                    <TextInput
                                        placeholder='Ton'
                                        value={steel?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setSteel(text)}
                                        style={[styles.inputPanel, isFocus === 'Steel' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Steel')}
                                    />
                                </View>
                            </View>
                        )}
                    />
                    <View style={styles.bottomContainer} >
                        {error ? <Text style={{ color: 'red', fontSize: 16, marginBottom: 80, textAlign: 'center' }}>{error}</Text> : null}
                        <Button
                            onPress={handleAddData}
                            btnLabel="ADD UNLOADING DATA"
                            buttonStyle={styles.BtnContainer}
                            textStyle={styles.btnText}
                        />
                    </View>
                </>
            )}
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    BtnContainer: {
        paddingVertical: 20,
        paddingHorizontal: 40,
        width: '90%',
        bottom: 10,
        position: 'absolute',
        backgroundColor: Colors.secondary,
        alignSelf: 'center',
    },
    btnText: {
        color: 'white',
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        top: 15,
    },
    selectText: {
        marginTop: 10,
        fontSize: 18,
        width: '48%',
        textAlign: 'left',
        fontWeight: 'bold',
        paddingHorizontal: 20,
    },
    inputPanel: {
        borderWidth: 2,
        borderColor: '#636363',
        right: 10,
        padding: 6,
        fontSize: 14,
        borderRadius: 6,
        width: '42%',
        top: 5,
        marginRight: 10
    },
    poleDetailsTitle: {
        paddingTop: 30,
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'center',
        textDecorationLine: 'underline',
    },
    productContainer: {
        width: '90%',
        marginVertical: 10,
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 2,
        alignSelf: 'center',
    },
    poleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 5,
    },
    poleInputText: {
        fontSize: 14,
        fontWeight: '500',
        width: '30%',
    },
    inputRow: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 6,
        padding: 4,
        fontSize: 14,
        width: '40%',
    },
    checkbox: {
        marginTop: 10,
        marginLeft: 2,
    },
    buttonContainer: {
        position: 'absolute',
        right: 20,
        marginTop: 20,
    },
    addButtonIcon: {
        marginLeft: 10,
    },
    removeButtonIcon: {
        marginLeft: 10,
    },
    bottomContainer: {
        width: '100%',
        position: 'absolute',
        bottom: 0,
        backgroundColor: 'white'
    }
});

export default UnloadingScreen