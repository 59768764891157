import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Modal, TouchableWithoutFeedback } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import useDataStore from '../stores/dataStore';

const OverflowMenu = ({ options, onSelect, allowedRole }) => {
    const [visible, setVisible] = useState(false);
    const [userData] = useDataStore((state) => [state.userData]);

    const toggleMenu = () => setVisible(!visible);

    const closeMenu = () => setVisible(false);

    return (
        (allowedRole ? allowedRole.includes(userData.role) : true) &&
        <View style={styles.container}>
            <TouchableOpacity onPress={toggleMenu} style={styles.trigger}>
                <MaterialCommunityIcons name="dots-vertical" size={28} color="#000" />
            </TouchableOpacity>
            <Modal
                visible={visible}
                transparent
                onRequestClose={closeMenu}
                animationType="fade">
                <TouchableWithoutFeedback onPress={closeMenu}>
                    <View style={styles.overlay} />
                </TouchableWithoutFeedback>
                <View style={styles.menuContainer}>
                    {options.map((option, index) => (
                        <TouchableOpacity
                            key={index}
                            onPress={() => {
                                onSelect(option);
                                closeMenu();
                            }}
                            style={styles.menuOption}>
                            <Text style={styles.menuOptionText}>{option.label}</Text>
                        </TouchableOpacity>
                    ))}
                </View>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'relative',
    },
    trigger: {
        padding: 10,
    },
    overlay: {
        flex: 1,
    },
    menuContainer: {
        position: 'absolute',
        top: 50,
        right: 5,
        backgroundColor: 'white',
        borderRadius: 5,
        minWidth: 160,
        maxWidth: 300,
        padding: 10,
    },
    menuOption: {
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#FFF',
    },
    menuOptionText: {
        fontSize: 16,
    },
});

export default OverflowMenu;
