import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Keyboard, Modal, StyleSheet, Text, TouchableWithoutFeedback, View, TextInput, TouchableOpacity, FlatList } from 'react-native'
import { Colors } from '../constants/theme';
import DropDownPicker from 'react-native-dropdown-picker';
import { AntDesign } from '@expo/vector-icons';
import Button from '../components/Button';
import useDataStore from '../stores/dataStore';
import { makeApiRequest } from '../utils/apiUtil';
import { handleAlertForError } from '../utils/handleError';
import MultiSelect from '../components/MultiSelect';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import Loader from '../components/Loader';
import CustomCheckbox from '../components/CustomCheckbox';
import Icon from 'react-native-vector-icons/FontAwesome';
import { EVENT_ACTIONS, POLE_SIZES, POLE_SIZES_BOLT } from '../constants/Constant';
import { formatDate } from '../utils/dateUtil';
import { MaterialIcons } from '@expo/vector-icons';
import { isEmpty } from '../utils/commonUtils';
import MixpanelTracker from '../utils/MixpanelTracker';

const LoadingScreen = ({ route }) => {
    const { plant, data, transactionId } = route.params;
    const [loading, setLoading] = useState(true);
    const isFocused = useIsFocused();
    const [isFocus, setIsFocus] = useState(null);
    const [vehicleOpen, setVehicleOpen] = useState(false);
    const [vehicle, setVehicle] = useState(null);
    const [vehicleList, setVehicleList] = useState([{ label: 'No Vehicle', value: 'No Vehicle', key: '0_no_vehicle' }, { label: 'Breakage', value: 'Breakage', key: '1_Breakage' }, { label: 'Production', value: 'Production', key: '2_Production' }]);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [vehicleName, setVehicleName] = useState('');
    const [userData, API_CONFIG, authToken, setAuthToken, refreshToken, setRefreshToken, thekedarData, setThekedarData, employeeData, setEmployeeData, clientsData, setClientsData, siteData, setSiteData, setToastMessage, stockData] = useDataStore((state) => [state.userData, state.API_CONFIG, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken, state.thekedarData, state.setThekedarData, state.employeeData, state.setEmployeeData, state.clientsData, state.setClientsData, state.siteData, state.setSiteData, state.setToastMessage, state.stockData]);
    const [isOpen, setIsOpen] = useState(null);
    const [thekedarList, setThekedarList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [clientList, setClinetList] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [thekedarValue, setThekedarValue] = useState([]);
    const [employeeValue, setEmployeeValue] = useState([]);
    const [clientValue, setClientValue] = useState([]);
    const [siteValue, setSiteValue] = useState([]);
    const [panel, setPanel] = useState(null);
    const [panelPrice, setPanelPrice] = useState(null);
    const [cementBag, setCementBag] = useState(null);
    const [cementPrice, setCementPrice] = useState(null);
    const [mSand, setMsand] = useState(null);
    const [jalli, setJalli] = useState(null);
    const [msandJalliPrice, setMsandJalliPrice] = useState(null);
    const [steel, setSteel] = useState(null);
    const [steelPrice, setSteelPrice] = useState(null);
    const [poleData, setPoleData] = useState([{ poleQuantity: '', polePrice: '', poleSize: '', isBolt: false }]);
    const [poleAmount, setPoleAmount] = useState(null);
    const [open, setOpen] = useState(false);
    const [totalAmount, setTotalAmount] = useState(null);
    const [error, setError] = useState(null);
    const flatListRef = useRef();
    const navigation = useNavigation();

    useLayoutEffect(() => {
        if (!loading && !data) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.LOADING_SCREEN, userData);
            navigation.setOptions({
                title: `Add Loading Data | ${plant}`,
                headerRight: () => (
                    <TouchableOpacity style={{ paddingRight: 20 }} onPress={goToOtherScreen}>
                        <MaterialIcons name="history" size={24} color="black" />
                    </TouchableOpacity>
                ),
            });
        } else if (data) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.LOADING_SCREEN_EDIT, userData);
            navigation.setOptions({
                title: `Edit Loading Data | ${plant}`,
                headerRight: () => null,
            });
        }
    }, [navigation, loading, data, plant]);

    useEffect(() => {
        if (data) {
            setVehicle(data?.vehicle);
            data.thekedar ? setThekedarValue([data?.thekedar]) : null;
            data.employee ? setEmployeeValue([data?.employee]) : null;
            data.client ? setClientValue([data?.client]) : null;
            data.site ? setSiteValue([data?.site]) : null;
            data.panel ? setPanel(data?.panel) : null;
            data.panelPrice ? setPanelPrice(data?.panelPrice) : null;
            data.cement ? setCementBag(data?.cement) : null;
            data.cementBagPrice ? setCementPrice(data?.cementBagPrice) : null;
            data.msand ? setMsand(data?.msand) : null;
            data.jalli ? setJalli(data?.jalli) : null;
            data.msandJalliPrice ? setMsandJalliPrice(data?.msandJalliPrice) : null;
            data.steel ? setSteel(data?.steel) : null;
            data.steelPrice ? setSteelPrice(data?.steelPrice) : null;
            setPoleData(data?.poleData);
            setPoleAmount(data?.poleData.reduce((acc, item) => {
                const { polePrice, poleQuantity } = item;
                if (polePrice !== "" && poleQuantity !== "") {
                    const price = parseFloat(polePrice);
                    const quantity = parseFloat(poleQuantity);
                    return acc + (price * quantity);
                }
                return acc;
            }, 0));
            setTotalAmount(data?.totalAmount);
        }
    }, [data]);

    const goToOtherScreen = () => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.LOADING_HISTORY_CLICKED, userData);
        navigation.navigate('StockTransactionScreen', { plant, type: 'loadingData' });
    };

    useEffect(() => {
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);

    const fetchData = async () => {
        try {
            //Fetching Vehicle List 
            if (vehicleList.length === 3) {
                const vehicleResponse = await makeApiRequest('get', `${API_CONFIG.PLANT}/getVehicles/${plant}/vehicles`, authToken, refreshToken, setAuthToken, setRefreshToken);
                if (isEmpty(vehicleResponse)) {
                    setVehicleList(prevList => [...prevList]);
                }
                else {
                    const formattedVehicleList = vehicleResponse.map((vehicle, index) => ({
                        label: vehicle,
                        value: vehicle,
                        key: `${index}_${vehicle}` // Ensure each item has a unique key
                    }));
                    setVehicleList(prevList => [...prevList, ...formattedVehicleList]);
                }
            }

            //Fetching Thekedar List
            const thekedarResponse = await makeApiRequest('get', `${API_CONFIG.THEKEDARS}/${plant}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            setThekedarData(thekedarResponse);
            const formattedThekedarList = Object.keys(thekedarResponse)
                .filter(key => !thekedarResponse[key].isDeleted)
                .map((key) => ({
                    label: thekedarResponse[key].name,
                    value: key,
                    deleted: thekedarResponse[key].isDeleted,
                }));
            setThekedarList(formattedThekedarList);

            //Fetching Employee List
            const employeeResponse = await makeApiRequest('get', API_CONFIG.EMPLOYEES, authToken, refreshToken, setAuthToken, setRefreshToken);
            setEmployeeData(employeeResponse);
            const formattedEmployeeList = Object.keys(employeeResponse)
                .filter(key => !employeeResponse[key].isDeleted)
                .map((key) => ({
                    label: employeeResponse[key].name,
                    value: key,
                    deleted: employeeResponse[key].isDeleted,
                }));
            setEmployeeList(formattedEmployeeList);

            //Fetching Client List
            const clientResponse = await makeApiRequest('get', API_CONFIG.CLIENTS, authToken, refreshToken, setAuthToken, setRefreshToken);
            setClientsData(clientResponse);

            //Fetching Site List
            const siteDetails = await makeApiRequest('get', API_CONFIG.SITE_DETAILS, authToken, refreshToken, setAuthToken, setRefreshToken);
            if (!isEmpty(siteDetails)) {
                setSiteData(siteDetails);
                const formattedClientList = Object.keys(clientResponse)
                    .filter(key => !clientResponse[key].isDeleted && siteDetails[key] && Object.values(siteDetails[key]).some(site => !site.isClosed))
                    .map((key) => ({
                        label: clientResponse[key].name,
                        value: key,
                        deleted: clientResponse[key].isDeleted,
                    }));
                setClinetList(formattedClientList);
            }

            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleAlertForError(err);
            console.error('Error while fetching data: ', err);
        }
    };

    useEffect(() => {
        if (clientValue) {
            fetchSiteDetails(clientValue);
        }
    }, [clientValue]);

    const fetchSiteDetails = async (client) => {
        try {
            if (client.length > 0) {
                const siteResponse = await makeApiRequest('get', `${API_CONFIG.SITE_DETAILS}/${client}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                // setSiteData(siteResponse);
                const formattedSiteList = Object.keys(siteResponse)
                    .filter(key => !siteResponse[key].isClosed) // Example condition to filter closed sites
                    .map((key) => ({
                        label: siteResponse[key].siteName,
                        value: key,
                        key: key
                    }));
                setSiteList(formattedSiteList);
            } else {
                setSiteList([]);
                setSiteValue('');
            }
        } catch (err) {
            handleAlertForError(err);
            console.error('Error while fetching site details: ', err);
        }
    };

    const calculateTotalAmount = () => {
        let total = 0;

        if (panelPrice && panel) {
            total += parseFloat(panelPrice) * parseFloat(panel);
        }
        if (poleAmount) {
            total += parseFloat(poleAmount);
        }
        if (cementPrice && cementBag) {
            total += parseFloat(cementPrice) * parseFloat(cementBag);
        }
        if (msandJalliPrice) {
            total += parseFloat(msandJalliPrice);
        }
        if (steelPrice && steel) {
            total += parseFloat(steelPrice) * parseFloat(steel);
        }
        setTotalAmount(total);
    };

    useEffect(() => {
        calculateTotalAmount();
    }, [panelPrice, panel, cementPrice, cementBag, msandJalliPrice, steelPrice, steel, poleAmount]);

    const handlePlusButtonPress = () => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PLUS_BUTTON_CLICKED, userData);
        setPopupVisible(true);
    };

    const validateForm = () => {
        let isErr = false;
        if (!vehicle) {
            setError('Please select a vehicle.');
            return false;
        }

        if (!panel && !cementBag && !jalli && !mSand && !steel && !poleData[0].poleQuantity) {
            setError('Please enter Loading Data');
            return false;
        }

        if (vehicle !== "Breakage" && vehicle !== "No Vehicle" && vehicle !== "Production") {
            if (thekedarValue.length === 1 && employeeValue.length === 1) {
                setError('Please select only one from Thekedar or Employee.');
                return false;
            }

            if (clientValue.length === 1 && siteValue.length === 0) {
                setError('Please select Site');
                return false;
            }

            if (panel && !panelPrice) {
                setError('Please enter Panel price');
                return false;
            }

            if (!panel && panelPrice) {
                setError('Please enter Panel');
                return false;
            }

            poleData.map(key => {
                if (key['poleQuantity'] || key['poleSize'] || key['polePrice']) {
                    if (key['poleQuantity'] === '') {
                        setError('Please add pole quantity.');
                        isErr = true;
                    }
                    if (key['poleSize'] === '') {
                        setError('Please add pole size.');
                        isErr = true;
                    }
                    if (key['polePrice'] === '') {
                        setError('Please add pole price.');
                        isErr = true;
                    }
                    // Validate pole quantity against stock
                    if (key['poleQuantity'] && key['poleSize']) {
                        const poleSize = key['poleSize'];
                        const poleQuantity = parseFloat(key['poleQuantity']);
                        if (stockData.poles[poleSize] === undefined) {
                            setError(`Pole size ${poleSize} is not available in stock.`);
                            isErr = true;
                        } else if (poleQuantity > stockData.poles[poleSize]) {
                            setError(`You do not have sufficient quantity for pole size ${poleSize}.`);
                            isErr = true;
                        }
                    }
                }
            });

            if (isErr) {
                return false;
            }

            if (cementBag && !cementPrice) {
                setError('Please enter Cement Price');
                return false;
            }

            if (!cementBag && cementPrice) {
                setError('Please enter Cement Bag');
                return false;
            }

            if (((mSand && !jalli) || (!mSand && jalli)) && !msandJalliPrice) {
                setError('Please enter MsandJalli Price');
                return false;
            }

            if ((msandJalliPrice && !jalli) && (msandJalliPrice && !mSand) && (msandJalliPrice && !mSand && !jalli)) {
                setError('Please enter Msand or Jalli Quantity');
                return false;
            }

            if (steel && !steelPrice) {
                setError('Please enter Steel Price');
                return false;
            }

            if (!steel && steelPrice) {
                setError('Please enter Steel Quantity');
                return false;
            }

            if (mSand && jalli && !msandJalliPrice) {
                setError('Please enter MsandJalli Price');
                return false;
            }
        }

        if (vehicle !== "No Vehicle" && vehicle !== "Breakage" && vehicle !== "Production") {
            if (thekedarValue.length === 0 && employeeValue.length === 0) {
                setError('Please select Thekedar or Employee.');
                return false;
            }

            if (thekedarValue.length === 1 && employeeValue.length === 1) {
                setError('Please select only one from Thekedar or Employee.');
                return false;
            }

            if (clientValue.length === 1 && siteValue.length === 0) {
                setError('Please select Site');
                return false;
            }
        }

        if (panel && panel > stockData.panel) {
            setError('You do not have sufficient quantity for panel');
            return false;
        }

        if (cementBag && cementBag > stockData.cement) {
            setError('You do not have sufficient bags for cement');
            return false;
        }

        if (mSand && mSand > stockData.msand) {
            setError('You do not have sufficient msand');
            return false;
        }

        if (jalli && jalli > stockData.jalli) {
            setError('You do not have sufficient jalli');
            return false;
        }

        if (steel && steel > stockData.steel) {
            setError('You do not have sufficient steel');
            return false;
        }

        if (vehicle === "Breakage") {
            poleData.map(key => {
                if (key['poleQuantity'] && !key['poleSize']) {
                    setError('Please add pole size for Breakage.');
                    isErr = true;
                }

                if (!key['poleQuantity'] && key['poleSize']) {
                    setError('Please add pole qunatity for Breakage.');
                    isErr = true;
                }

                if (key['poleQuantity'] && key['poleSize']) {
                    const poleSize = key['poleSize'];
                    const poleQuantity = parseFloat(key['poleQuantity']);
                    if (stockData.poles[poleSize] === undefined) {
                        setError(`Pole size ${poleSize} is not available in stock.`);
                        isErr = true;
                    } else if (poleQuantity > stockData.poles[poleSize]) {
                        setError(`You do not have sufficient quantity for pole size ${poleSize}.`);
                        isErr = true;
                    }
                }
            });
        }

        if (isErr) {
            return false;
        }

        if (vehicle === "No Vehicle" && thekedarValue.length === 0 && employeeValue.length === 0) {
            poleData.map(key => {
                if (key['polePrice']) {
                    setError('Please select Thekedar or Employee');
                    isErr = true;
                }
            });
            if (panelPrice || cementPrice || msandJalliPrice || steelPrice) {
                setError('Please select Thekedar or Employee');
                return false;
            }
        }

        if (vehicle === "No Vehicle" && (thekedarValue.length === 1 || employeeValue.length === 1)) {
            if (thekedarValue.length === 1 && employeeValue.length === 1) {
                setError('Please select only one from Thekedar or Employee.');
                return false;
            }
            if (panel && !panelPrice) {
                setError('Please enter Panel price');
                return false;
            }

            if (!panel && panelPrice) {
                setError('Please enter Panel');
                return false;
            }

            poleData.map(key => {
                if (key['poleQuantity'] || key['poleSize'] || key['polePrice']) {
                    if (key['poleQuantity'] === '') {
                        setError('Please add pole quantity.');
                        isErr = true;
                    }
                    if (key['poleSize'] === '') {
                        setError('Please add pole size.');
                        isErr = true;
                    }
                    if (key['polePrice'] === '') {
                        setError('Please add pole price.');
                        isErr = true;
                    }
                    // Validate pole quantity against stock
                    if (key['poleQuantity'] && key['poleSize']) {
                        const poleSize = key['poleSize'];
                        const poleQuantity = parseFloat(key['poleQuantity']);
                        if (stockData.poles[poleSize] === undefined) {
                            setError(`Pole size ${poleSize} is not available in stock.`);
                            isErr = true;
                        } else if (poleQuantity > stockData.poles[poleSize]) {
                            setError(`You do not have sufficient quantity for pole size ${poleSize}.`);
                            isErr = true;
                        }
                    }
                }
            });

            if (isErr) {
                return false;
            }

            if (cementBag && !cementPrice) {
                setError('Please enter Cement Price');
                return false;
            }

            if (!cementBag && cementPrice) {
                setError('Please enter Cement Bag');
                return false;
            }

            if (((mSand && !jalli) || (!mSand && jalli)) && !msandJalliPrice) {
                setError('Please enter MsandJalli Price');
                return false;
            }

            if ((msandJalliPrice && !jalli) && (msandJalliPrice && !mSand) && (msandJalliPrice && !mSand && !jalli)) {
                setError('Please enter Msand or Jalli Quantity');
                return false;
            }

            if (steel && !steelPrice) {
                setError('Please enter Steel Price');
                return false;
            }

            if (!steel && steelPrice) {
                setError('Please enter Steel Quantity');
                return false;
            }

            if (mSand && jalli && !msandJalliPrice) {
                setError('Please enter MsandJalli Price');
                return false;
            }
        }

        setError('');
        return true;
    };

    const handleAddVehicle = async () => {
        try {
            setLoading(true);
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_VEHICLE_CLICKED, userData);
            await makeApiRequest("put",
                `${API_CONFIG.PLANT}/${plant}`,
                authToken, refreshToken, setAuthToken, setRefreshToken, {
                vehicle: vehicleName
            });
            setVehicleList([...vehicleList, { label: vehicleName, value: vehicleName }]);
            setVehicleName('');
            setPopupVisible(false);
            setToastMessage('Vechicle added successfully');
            setLoading(false);
        } catch (err) {
            setLoading(false);
            handleAlertForError(err);
            console.error('Error while adding vehicle: ', err);
        }
    };

    const getPoleCount = () => {
        return poleData?.reduce((acc, item) => {
            const { poleQuantity } = item;
            if (poleQuantity !== "") {
                const quantity = parseFloat(poleQuantity);
                return acc + quantity;
            }
            return acc;
        }, 0)
    }

    const convertToPolesObject = (polesArray) => {
        return polesArray.reduce((acc, pole) => {
            const size = pole.poleSize;
            const quantity = parseInt(pole.poleQuantity, 10);

            if (acc[size]) {
                acc[size] += quantity;
            } else {
                acc[size] = quantity;
            }
            return acc;
        }, {});
    };

    const handleAddData = async () => {
        let today = new Date();
        try {
            if (validateForm()) {
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_LOADING_BUTTON_CLICKED, userData);
                if (data && transactionId) {
                    setLoading(true);
                    await makeApiRequest('put', `${API_CONFIG.PLANT}/${plant}/loadingData/${transactionId}`,
                        authToken, refreshToken, setAuthToken, setRefreshToken, {
                        date: formatDate(today),
                        time: today.toLocaleTimeString("en-US"),
                        vehicle: vehicle,
                        thekedar: thekedarValue ? thekedarValue[0] : null,
                        employee: employeeValue ? employeeValue[0] : null,
                        client: clientValue ? clientValue[0] : null,
                        site: siteValue ? siteValue[0] : null,
                        panel: panel ? panel : 0,
                        panelPrice: panelPrice ? panelPrice : 0,
                        pole: getPoleCount(),
                        poles: getPoleCount() > 0 ? convertToPolesObject(poleData) : 0,
                        poleData: poleData,
                        cement: cementBag ? cementBag : 0,
                        cementBagPrice: cementPrice ? cementPrice : 0,
                        msand: mSand ? mSand : 0,
                        jalli: jalli ? jalli : 0,
                        msandJalliPrice: msandJalliPrice ? msandJalliPrice : 0,
                        steel: steel ? steel : 0,
                        steelPrice: steelPrice ? steelPrice : 0,
                        totalAmount: totalAmount ? totalAmount : 0,
                        actionBy: userData.username,
                    });

                    if (clientValue.length > 0 && siteValue.length > 0) {
                        await makeApiRequest('put', `${API_CONFIG.SITE_DETAILS}/${clientValue[0]}/${siteValue[0]}`, authToken, refreshToken, setAuthToken, setRefreshToken, {
                            plant: plant,
                            transactionId: transactionId,
                            panel: panel ? panel : 0,
                            pole: getPoleCount(),
                            msand: mSand ? mSand : 0,
                            jalli: jalli ? jalli : 0,
                            cement: cementBag ? cementBag : 0,
                            steel: steel ? steel : 0,
                            data: data
                        });
                    }

                    setLoading(false);
                    setToastMessage("Data Updated successfully");
                    navigation.goBack();
                } else {
                    setLoading(true);
                    await makeApiRequest('post', `${API_CONFIG.PLANT}/${plant}/loadingData`, authToken, refreshToken, setAuthToken, setRefreshToken, {
                        date: formatDate(today),
                        time: today.toLocaleTimeString("en-US"),
                        vehicle: vehicle,
                        thekedar: thekedarValue ? thekedarValue[0] : null,
                        employee: employeeValue ? employeeValue[0] : null,
                        client: clientValue ? clientValue[0] : null,
                        site: siteValue ? siteValue[0] : null,
                        panel: panel ? panel : 0,
                        panelPrice: panelPrice ? panelPrice : 0,
                        pole: getPoleCount(),
                        poles: getPoleCount() > 0 ? convertToPolesObject(poleData) : 0,
                        poleData: poleData,
                        cement: cementBag ? cementBag : 0,
                        cementBagPrice: cementPrice ? cementPrice : 0,
                        msand: mSand ? mSand : 0,
                        jalli: jalli ? jalli : 0,
                        msandJalliPrice: msandJalliPrice ? msandJalliPrice : 0,
                        steel: steel ? steel : 0,
                        steelPrice: steelPrice ? steelPrice : 0,
                        totalAmount: totalAmount ? totalAmount : 0,
                        actionBy: userData.username,
                    });

                    if (clientValue.length > 0 && siteValue.length > 0) {
                        await makeApiRequest('put', `${API_CONFIG.SITE_DETAILS}/${clientValue[0]}/${siteValue[0]}`, authToken, refreshToken, setAuthToken, setRefreshToken, {
                            material: {
                                panel: panel ? panel : 0,
                                pole: getPoleCount(),
                                msand: mSand ? mSand : 0,
                                jalli: jalli ? jalli : 0,
                                cement: cementBag ? cementBag : 0,
                                steel: steel ? steel : 0
                            }
                        });
                    }

                    setLoading(false);
                    setToastMessage("Data added successfully");
                    navigation.goBack();
                }
            }
        } catch (e) {
            setLoading(false);
            handleAlertForError(e);
            console.error("Error while adding loading data", e);
        }
    };

    const updatePoleData = (index, key, value) => {
        setPoleData(prevData => {
            const newData = [...prevData];
            newData[index][key] = value;
            if (key === 'polePrice' || key === 'poleQuantity') {
                setPoleAmount(poleData.reduce((acc, item) => {
                    const { polePrice, poleQuantity } = item;
                    if (polePrice !== "" && poleQuantity !== "") {
                        const price = parseFloat(polePrice);
                        const quantity = parseFloat(poleQuantity);
                        return acc + (price * quantity);
                    }
                    return acc;
                }, 0));
            }
            return newData;
        });
    }

    const addPoleRow = () => {
        setPoleData(prevData => [...prevData, { poleQuantity: '', polePrice: '', poleSize: '', isBolt: false }]);
    }

    const removePoleRow = (index) => {
        setPoleData(prevData => prevData.filter((_, i) => i !== index));
    }

    const getSiteName = (siteId, siteData) => {
        for (const outerKey in siteData) {
            if (siteData.hasOwnProperty(outerKey)) {
                const innerData = siteData[outerKey];
                if (innerData.hasOwnProperty(siteId)) {
                    return innerData[siteId].siteName;
                }
            }
        }
        return null;
    };

    return (
        <View style={styles.container}>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) : (
                <>
                    <FlatList
                        ref={flatListRef}
                        style={{ marginBottom: 90 }}
                        data={[{ key: 'dummy' }]} // Provide a dummy item as FlatList requires data
                        renderItem={({ item }) => ( // Render the inner content within FlatList
                            <View style={{ marginBottom: 20 }}>
                                <View style={styles.vehicleContainer}>
                                    <DropDownPicker
                                        placeholder='Select Vehicle'
                                        style={[styles.dropdownPicker, isFocus === "picker" && { borderColor: Colors.primary, borderWidth: 2 }]}
                                        dropDownContainerStyle={[styles.dropdownPicker, isFocus && { borderColor: Colors.primary, borderWidth: 2 }]}
                                        open={vehicleOpen}
                                        value={vehicle}
                                        items={vehicleList}
                                        setOpen={(val) => {
                                            Keyboard.dismiss();
                                            setVehicleOpen(val)
                                        }}
                                        setValue={setVehicle}
                                        setItems={vehicleList}
                                        onOpen={() => setIsFocus("picker")}
                                        onClose={() => setIsFocus("picker")}
                                        containerStyle={{ height: 40 }}
                                        listMode="SCROLLVIEW" />
                                    <Button
                                        onPress={handlePlusButtonPress}
                                        btnLabel={<AntDesign name="pluscircle" size={24} color={Colors.primary} />}
                                        buttonStyle={styles.plusIcon}
                                    />
                                </View>

                                <Modal
                                    animationType="slide"
                                    transparent={true}
                                    visible={isPopupVisible}
                                    onRequestClose={() => setPopupVisible(false)}>
                                    {/* <TouchableWithoutFeedback onPress={() => setPopupVisible(false)}> */}
                                    <View style={styles.centeredView}>
                                        <View style={styles.modalView}>
                                            <TextInput
                                                style={styles.textInput}
                                                onChangeText={(text) => setVehicleName(text)}
                                                placeholder="Vehicle Name"
                                                value={vehicleName} />
                                            <Button
                                                onPress={handleAddVehicle}
                                                buttonStyle={styles.addButton}
                                                btnLabel={"Add Vehicle"} />
                                        </View>
                                    </View>
                                    {/* </TouchableWithoutFeedback> */}
                                </Modal>

                                {vehicle !== "Breakage" && vehicle !== "Production" && <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Select Thekedar : </Text>
                                    <View style={styles.multiSelectView}>
                                        <MultiSelect
                                            singleSelect={true}
                                            isOpen={isOpen}
                                            onClose={() => setIsOpen(false)}
                                            options={thekedarList}
                                            selectedValues={thekedarValue}
                                            onSelect={(selectedItems) => {
                                                setThekedarValue(selectedItems)
                                            }}
                                            label={!isEmpty(thekedarValue) ? thekedarData[thekedarValue].name : "Select Thekedar..."}
                                            isDisabled={data ? true : false}
                                        />
                                    </View>
                                </View>}

                                {vehicle !== "Breakage" && vehicle !== "Production" && <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Select Employee : </Text>
                                    <View style={styles.multiSelectView}>
                                        <MultiSelect
                                            singleSelect={true}
                                            isOpen={isOpen}
                                            onClose={() => setIsOpen(false)}
                                            options={employeeList}
                                            selectedValues={employeeValue}
                                            onSelect={(selectedItems) => {
                                                setEmployeeValue(selectedItems)
                                            }}
                                            label={!isEmpty(employeeValue) ? employeeData[employeeValue].name : "Select Employee..."}
                                            isDisabled={data ? true : false}
                                        />
                                    </View>
                                </View>}

                                {vehicle !== "Breakage" && vehicle !== "Production" && <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Select Client : </Text>
                                    <View style={styles.multiSelectView}>
                                        <MultiSelect
                                            singleSelect={true}
                                            isOpen={isOpen}
                                            onClose={() => setIsOpen(false)}
                                            options={clientList}
                                            selectedValues={clientValue}
                                            onSelect={(selectedItems) => {
                                                setClientValue(selectedItems)
                                            }}
                                            label={!isEmpty(clientValue) ? clientsData[clientValue].name : "Select Client..."}
                                        />
                                    </View>
                                </View>}

                                {clientValue.length > 0 && vehicle !== "Breakage" && vehicle !== "Production" && <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Select Site : </Text>
                                    <View style={styles.multiSelectView}>
                                        <MultiSelect
                                            singleSelect={true}
                                            isOpen={isOpen}
                                            onClose={() => setIsOpen(false)}
                                            options={siteList}
                                            selectedValues={siteValue}
                                            onSelect={(selectedItems) => {
                                                setSiteValue(selectedItems)
                                            }}
                                            label={!isEmpty(siteValue) ? getSiteName(siteValue[0], siteData) : "Select Site..."}
                                        />
                                    </View>
                                </View>
                                }

                                <View style={styles.rowContainer}>
                                    <Text style={styles.selectText}>Enter Panel:</Text>
                                    <TextInput
                                        placeholder='Piece'
                                        value={panel?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setPanel(text)}
                                        style={[styles.inputPanel, isFocus === 'Panel' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Panel')}
                                    />
                                    {vehicle !== "Breakage" && vehicle !== "Production" && <TextInput
                                        placeholder='Amount'
                                        value={panelPrice?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setPanelPrice(text)}
                                        style={[styles.inputPanel, isFocus === 'Panel Price' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Panel Price')}
                                    />}
                                </View>

                                <View>
                                    <Text style={styles.poleDetailsTitle}>Pole Details:</Text>
                                    {poleData.map((item, index) => (
                                        <View key={index} style={styles.productContainer}>
                                            <View style={styles.poleContainer}>
                                                <Text style={styles.poleInputText}>Pole Quantity:</Text>
                                                <TextInput
                                                    placeholder="Pole Qty"
                                                    value={item.poleQuantity}
                                                    keyboardType="numeric"
                                                    onChangeText={(text) => updatePoleData(index, 'poleQuantity', text)}
                                                    style={[styles.inputRow, isFocus === 'Pole Qty' && { borderColor: Colors.primary }]}
                                                    onFocus={() => setIsFocus('Pole Qty')}
                                                />
                                                {vehicle !== "Breakage" && vehicle !== "Production" && <TextInput
                                                    placeholder="Pole Amt"
                                                    value={item.polePrice}
                                                    keyboardType="numeric"
                                                    onChangeText={(text) => updatePoleData(index, 'polePrice', text)}
                                                    style={[styles.inputRow, isFocus === 'Pole Amt' && { borderColor: Colors.primary }]}
                                                    onFocus={() => setIsFocus('Pole Amt')}
                                                />}
                                            </View>
                                            <View style={styles.poleContainer}>
                                                <Text style={[styles.poleInputText, { marginTop: 10 }]}>Pole Size:</Text>
                                                <MultiSelect
                                                    isOpen={open}
                                                    onClose={() => setOpen(false)}
                                                    options={item.isBolt ? POLE_SIZES_BOLT : POLE_SIZES}
                                                    selectedValues={[item.poleSize]}
                                                    onSelect={(selectedItems) => updatePoleData(index, 'poleSize', selectedItems[0])}
                                                    label={item.poleSize || "Select size"}
                                                    singleSelect={true}
                                                />
                                                <CustomCheckbox
                                                    label="With bolt"
                                                    isChecked={item.isBolt}
                                                    onChange={() => {
                                                        updatePoleData(index, 'isBolt', !item.isBolt);
                                                        updatePoleData(index, 'poleSize', '');
                                                    }}
                                                    style={styles.checkbox}
                                                />
                                            </View>
                                            <View style={styles.buttonContainer}>
                                                {index === 0 && (
                                                    <TouchableOpacity onPress={addPoleRow}>
                                                        <Icon name="plus-circle" size={24} color="green" style={styles.addButtonIcon} />
                                                    </TouchableOpacity>
                                                )}
                                                {index !== 0 && (
                                                    <TouchableOpacity onPress={() => removePoleRow(index)}>
                                                        <Icon name="minus-circle" size={24} color="red" style={styles.removeButtonIcon} />
                                                    </TouchableOpacity>
                                                )}
                                            </View>
                                        </View>
                                    ))}
                                </View>

                                <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Enter Cement:</Text>
                                    <TextInput
                                        placeholder='Bag'
                                        value={cementBag?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setCementBag(text)}
                                        style={[styles.inputPanel, isFocus === 'Cement' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Cement')}
                                    />
                                    {vehicle !== "Breakage" && vehicle !== "Production" && <TextInput
                                        placeholder='Amount'
                                        value={cementPrice?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setCementPrice(text)}
                                        style={[styles.inputPanel, isFocus === 'Cement Price' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Cement Price')}
                                    />}
                                </View>

                                <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Enter Msand:</Text>
                                    <TextInput
                                        placeholder='Ton'
                                        value={mSand?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setMsand(text)}
                                        style={[styles.inputPanel, { width: "48%" }, isFocus === 'Msand' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Msand')}
                                    />
                                </View>

                                <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Enter Jalli:</Text>
                                    <TextInput
                                        placeholder='Ton'
                                        value={jalli?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setJalli(text)}
                                        style={[styles.inputPanel, { width: "48%" }, isFocus === 'Jalli' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Jalli')}
                                    />
                                </View>

                                {vehicle !== "Breakage" && vehicle !== "Production" && <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Enter Msand Jalli Amount:</Text>
                                    <TextInput
                                        placeholder='Amount'
                                        value={msandJalliPrice?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setMsandJalliPrice(text)}
                                        style={[styles.inputPanel, { width: "48%" }, isFocus === 'Msand Jalli Price' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Msand Jalli Price')}
                                    />
                                </View>}

                                <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                                    <Text style={styles.selectText}>Enter Steel:</Text>
                                    <TextInput
                                        placeholder='Ton'
                                        value={steel?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setSteel(text)}
                                        style={[styles.inputPanel, isFocus === 'Steel' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Steel')}
                                    />
                                    {vehicle !== "Breakage" && vehicle !== "Production" && <TextInput
                                        placeholder='Amount'
                                        value={steelPrice?.toString()}
                                        keyboardType="numeric"
                                        onChangeText={(text) => setSteelPrice(text)}
                                        style={[styles.inputPanel, isFocus === 'Steel Price' && { borderColor: Colors.primary }]}
                                        onFocus={() => setIsFocus('Steel Price')}
                                    />}
                                </View>

                                {vehicle !== "Breakage" && vehicle !== "Production" && <View style={[styles.rowContainer, { marginBottom: 20, justifyContent: 'left', borderTopWidth: 2, borderTopColor: 'gray' }]}>
                                    <Text style={styles.selectText}>Total Amount:</Text>
                                    <Text style={styles.selectText}>{totalAmount}</Text>
                                </View>}
                            </View>
                        )}
                    />
                    <View style={styles.bottomContainer} >
                        {error ? <Text style={{ color: 'red', fontSize: 16, marginBottom: 80, textAlign: 'center' }}>{error}</Text> : null}
                        <Button
                            onPress={handleAddData}
                            btnLabel="ADD LOADING DATA"
                            buttonStyle={styles.BtnContainer}
                            textStyle={styles.btnText}
                        />
                    </View>
                </>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    multiSelectView: {
        width: '45%',
        right: 5,
        alignSelf: 'center',
        alignItems: 'center',
        alignContent: 'center',
        top: 0
    },
    container: {
        flex: 1,
        alignItems: 'center',
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    vehicleContainer: {
        width: '90%',
        paddingVertical: 10,
        flexDirection: 'row',
        zIndex: 100
    },
    dropdownPicker: {
        width: '90%',
        borderRadius: 6,
        marginHorizontal: 10,
    },
    plusIcon: {
        width: 50,
        right: '0%',
        backgroundColor: 'transparent',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        width: '80%',
        alignItems: 'center',
    },
    textInput: {
        borderWidth: 2,
        borderColor: '#636363',
        margin: 10,
        padding: 12,
        fontSize: 16,
        borderRadius: 6,
        width: '90%'
    },
    addButton: {
        backgroundColor: Colors.primary,
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        top: 15
    },
    selectText: {
        marginTop: 10,
        fontSize: 18,
        width: '48%',
        textAlign: 'left',
        fontWeight: 'bold',
        paddingHorizontal: 20,
    },
    BtnContainer: {
        paddingVertical: 20,
        paddingHorizontal: 40,
        width: '90%',
        bottom: 10,
        position: 'absolute',
        alignSelf: 'center'
    },
    btnText: {
        color: 'white',
    },
    inputPanel: {
        borderWidth: 2,
        borderColor: '#636363',
        right: 10,
        padding: 6,
        fontSize: 14,
        borderRadius: 6,
        width: '22%',
        top: 5,
        marginRight: 10
    },
    poleDetailsTitle: {
        marginTop: 30,
        fontSize: 18,
        fontWeight: 'bold',
        alignSelf: 'center',
        textDecorationLine: 'underline',
    },
    productContainer: {
        width: '90%',
        marginVertical: 10,
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 2,
        alignSelf: 'center',
    },
    poleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 5,
    },
    poleInputText: {
        fontSize: 14,
        fontWeight: '500',
        width: '30%',
    },
    inputRow: {
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 6,
        padding: 4,
        fontSize: 14,
        width: '22%',
        marginHorizontal: 2
    },
    checkbox: {
        marginTop: 10,
        marginLeft: 2,
    },
    buttonContainer: {
        position: 'absolute',
        right: 20,
        marginTop: 20,
    },
    addButtonIcon: {
        marginLeft: 10,
    },
    removeButtonIcon: {
        marginLeft: 10,
    },
    bottomContainer: {
        width: '100%',
        position: 'absolute',
        bottom: 0,
        backgroundColor: "white"
    }
});

export default LoadingScreen