import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { AntDesign, Feather } from '@expo/vector-icons';
import ShowAlert from '../modules/AlertModule';
import useDataStore from '../stores/dataStore';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { makeApiRequest } from '../utils/apiUtil';
import { formatDate } from '../utils/dateUtil';

const SiteList = ({ siteName, siteKey, clientKey, type, isClosed: initialIsClosed, callback, pendingAmount, handleDelete, empWork }) => {
    const navigation = useNavigation();
    const route = useRoute();
    const currentScreen = route.name;
    const [API_CONFIG, userData, setToastMessage, authToken, setAuthToken, refreshToken, setRefreshToken] = useDataStore((state) => [state.API_CONFIG, state.userData, state.setToastMessage, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken]);
    const [isClosed, setIsClosed] = useState(initialIsClosed);
    let today = new Date();

    useEffect(() => {
        setIsClosed(initialIsClosed);
    }, [initialIsClosed]);

    const goToOtherScreen = () => {
        if (currentScreen !== 'InfoScreen') {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SITE_CLICKED, userData);
            navigation.navigate("SiteDetailsScreen", { siteName, siteKey, clientKey, type });
        }
    }

    const handleCloseSite = () => {
        ShowAlert("Close Site", "Are you sure you want to close site?", "YesNo", () => {
            console.log('OK Pressed');
        }, async () => {
            console.log('Yes Pressed');
            try {
                await makeApiRequest("put",
                    `${API_CONFIG.SITE_DETAILS}/${clientKey}/${siteKey}`,
                    authToken, refreshToken, setAuthToken, setRefreshToken, {
                    isClosed: true,
                    endDate: formatDate(today)
                });
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CLIENT_CLOSED_SITE_CLICK, userData);
                setToastMessage("Site closed successfully");
                setIsClosed(true);
                console.log('Site closed successfully');
                callback();
            } catch (error) {
                console.error('Failed to close site:', error);
                handleAlertForError(error);
            }
        }, () => {
            console.log('No Pressed');
        });
    }

    const handleOpenSite = () => {
        ShowAlert("Reopen Site", "Are you sure you want to re-open this site?", "YesNo", () => {
            console.log('OK Pressed');
        }, async () => {
            console.log('Yes Pressed');
            try {
                await makeApiRequest("put",
                    `${API_CONFIG.SITE_DETAILS}/${clientKey}/${siteKey}`,
                    authToken, refreshToken, setAuthToken, setRefreshToken, {
                    isClosed: false
                });
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SITE_OPEN_CLICKED, userData);
                setToastMessage("Site re-opened successfully");
                setIsClosed(false);
                console.log('Site re-opened successfully');
                callback();
            } catch (error) {
                console.error('Failed to re-open site:', error);
                handleAlertForError(error);
            }
        }, () => {
            console.log('No Pressed');
        });
    }

    return (
        <TouchableOpacity onPress={goToOtherScreen} disabled={currentScreen === 'InfoScreen' || currentScreen === 'Closed Sites'}>
            <View style={styles.container}>
                {
                    currentScreen !== 'InfoScreen' && userData.role === APP_ROLE.OWNER && isClosed ? <AntDesign name="checksquare" size={24} color="green" style={styles.icon} onPress={handleOpenSite} /> :
                        currentScreen !== 'InfoScreen' && <Feather name="square" size={24} color="black" style={styles.icon} onPress={currentScreen === 'Closed Sites' ? handleOpenSite : handleCloseSite} />
                }
                <Text style={styles.siteName}>{siteName}</Text>
                {currentScreen !== 'InfoScreen' && userData.role === APP_ROLE.OWNER && <View style={styles.iconView}>
                    {pendingAmount !== null && pendingAmount !== undefined && (
                        <Text style={styles.amountText}> ₹ {pendingAmount} </Text>
                    )}
                    {currentScreen !== 'Closed Sites' && <Feather name="trash-2" size={24} color="red" style={[styles.deleteIcon, { marginRight: 10 }]} onPress={() => handleDelete(siteKey)} />}
                </View>}
            </View>
            {empWork && <View style={styles.innerContainer}>
                <Text style={[styles.textEmp, { marginBottom: 5 }]}>Total: {empWork.reduce((acc, curr) => {
                    const price = parseFloat(curr.price) || 0;
                    const sqFeet = parseFloat(curr.sqFeet) || 0;
                    return acc + (price * sqFeet);
                }, 0)}</Text>
                {empWork.map((item, index) =>
                (
                    <View style={styles.workContainer}>
                        <Text style={styles.textEmp}>SqFeet: {item.sqFeet}</Text>
                        <Text style={styles.textEmp}>Rate: {item.price}</Text>
                    </View>
                )
                )
                }
            </View>}
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    workContainer: {
        flexDirection: 'row',
        // justifyContent: 'space-between',
    },
    innerContainer: {
        padding: 10,
        backgroundColor: "#fff",
        margin: 10,
        shadowColor: 'rgba(0, 0, 0, 1)',
        shadowOpacity: 0.8,
        elevation: 4,
        shadowRadius: 15,
        shadowOffset: { width: 1, height: 13 },
    },
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: "#fff",
        margin: 10,
        width: '100vw'
        // elevation: 4,
        // shadowRadius: 15,
    },
    textEmp: {
        marginLeft: 10,
    },
    siteName: {
        fontSize: 18,
        fontWeight: 'bold',
        width: "90%",
    },
    iconView: {
        display: 'flex',
        flexDirection: 'row-reverse',
        height: "100%",
        width: "20%",
        position: 'absolute',
        // margin: 10,
        right: 0,
    },
    icon: {
        marginHorizontal: 5,
        right: 10,
    },
    deleteIcon: {
        marginRight: 20,
        // right: 0,
    },
    amountText: {
        fontSize: 18,
        right: 50,
        position: 'absolute',
        width: 120,
        textAlign: 'right',
        fontWeight: 'bold',
        color: 'red'
    }
});

export default SiteList;