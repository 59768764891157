import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, StyleSheet, ImageBackground, TouchableWithoutFeedback, TouchableOpacity } from 'react-native';
import { FACTORY_API, TOKEN_API, USERS_API, getAPIConfigs } from '../env-config/api-config';
import axios from "axios";
import { useNavigation } from '@react-navigation/native';
import useDataStore from '../stores/dataStore';
import background from "../assets/background.png";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Button from '../components/Button';
import { Colors } from '../constants/theme';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import DropDownPicker from 'react-native-dropdown-picker';
import { Keyboard } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getExpoPushTokenAsync } from '../modules/Notification';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import * as LocalAuthentication from 'expo-local-authentication';
import { isEmpty, removeLeadingZero } from '../utils/commonUtils';
import Loader from '../components/Loader';
import { refreshTokenAndRetry } from '../utils/refreshTokenAndRetry';
import { makeApiRequest } from '../utils/apiUtil';

const LoginScreen = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [factoryList, setFactoryList] = useState([]);
    const [isLogin, setIsLogin] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const navigation = useNavigation();
    const [setUserData, setAPI_CONFIG, userData, authToken, setAuthToken, refreshToken, setRefreshToken] = useDataStore((state) => [state.setUserData, state.setAPI_CONFIG, state.userData, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loginText, setLoginText] = useState('Verify');
    const pinInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    useEffect(() => {
        checkLoginStatus();
    }, []);

    useEffect(() => {
        if (authToken && factoryList.length === 0) {
            getFactories();
        }
    }, [authToken]);

    const getFactories = async () => {
        let factoryResponse;
        try {
            factoryResponse = await makeApiRequest('get', FACTORY_API, authToken, refreshToken, setAuthToken, setRefreshToken);
        } catch (error) {
            handleAlertForError(error);
        }
        let factories = factoryResponse;
        if (Object.keys(factories).length !== 0) {
            const factoriesArray = factories.map(city => {
                return {
                    label: city.charAt(0).toUpperCase() + city.slice(1),
                    value: city.toLowerCase()
                };
            });
            setFactoryList(factoriesArray);
        }
    }

    const checkLoginStatus = async () => {
        try {
            const savedUsername = await AsyncStorage.getItem('username');
            const savedFactory = await AsyncStorage.getItem('value');
            if (savedUsername) {
                setUsername(savedUsername);
                setValue(savedFactory);
                setIsLogin(true)
                setLoginText('Login')
            }
        } catch (error) {
            console.error('Error checking login status:', error);
        }
    };

    const validateForm = (isRegister = false) => {
        if (!username) {
            setError('Please add a username.');
            return false;
        }

        if (!password) {
            setError('Please add password.');
            return false;
        }

        if (!/^\d{4}$/.test(password)) {
            setError('Please enter a valid 4-digit PIN.');
            return;
        }

        setError('');
        return true;
    };

    const handleLogin = async (isLoginBiometric = false) => {
        setLoading(true);
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.LOGIN_CLICKED, { username });
        let newUserName = username.toLowerCase();
        let oAuth;
        let userResponse;

        if (isLoginBiometric) {
            let newToken = await refreshTokenAndRetry(setAuthToken, null, setRefreshToken, () => { }, true);
            oAuth = newToken;
        }

        if (isLogin && !isLoginBiometric) {
            try {
                const userLogin = await axios.post(`${USERS_API}/login`, { username: newUserName, password: password });
                const { idToken, refreshToken } = userLogin.data
                oAuth = idToken;
                setAuthToken(idToken);
                setRefreshToken(refreshToken);
                await AsyncStorage.setItem('refreshToken', refreshToken);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.INVALID_CREDENTIALS, { username });
                setError('Invalid user or password. Please try again.');
            }
        }
        if (oAuth || authToken) {
            try {
                userResponse = await axios.get(`${USERS_API}/${value}/${newUserName}`, {
                    headers: {
                        'Authorization': `Bearer ${oAuth ? oAuth : authToken}`,
                        'Content-Type': 'application/json'
                    }
                });
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await refreshTokenAndRetry(setAuthToken, refreshToken, setRefreshToken, async () => {
                        userResponse = await axios.get(`${USERS_API}/${value}/${newUserName}`, {
                            headers: {
                                'Authorization': `Bearer ${oAuth ? oAuth : authToken}`,
                                'Content-Type': 'application/json'
                            }
                        });
                    });
                } else {
                    handleAlertForError(error);
                }
            }

            const user = userResponse.data;
            if (isEmpty(user)) {
                setError('User is not registered on selected factory')
                setLoading(false);
            } else {
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.LOGIN_SUCCESS, user);
                MixpanelTracker.setUserIdentity(`${username}_${value}`);
                setAPI_CONFIG(getAPIConfigs(value))
                setUserData(user);
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'HomeScreen' }],
                });
                setLoading(false);
                navigation.navigate('HomeScreen');
                await AsyncStorage.setItem('username', username);
                await AsyncStorage.setItem('value', value);
            }
        }
    };

    const handleVerify = async (isLoginBiometric = false) => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.VERIFY_CLICKED, { username });
        if (isLoginBiometric || validateForm(false)) {
            try {
                setLoading(true);
                let newUserName = username.toLowerCase();
                const userLogin = await axios.post(`${USERS_API}/login`, { username: newUserName, password: password });
                const { idToken, refreshToken } = userLogin.data
                setAuthToken(idToken);
                setRefreshToken(refreshToken);
                await AsyncStorage.setItem('refreshToken', refreshToken);
                setLoading(false);
                setLoginText('Login')
                return;
            } catch (error) {
                setLoading(false);
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.INVALID_CREDENTIALS, { username });
                setError('Invalid user or password. Please try again.');
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlePinChange = (value) => {
        if (value.length <= 4) {
            setPassword(value);
            if (value.length === 4) {
                setPassword(value);
            }
        }
    };

    const newLoginClick = async () => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.NEW_LOGIN_CLICKED);
        if (await AsyncStorage.getItem('token')) {
            const savedFactory = await AsyncStorage.getItem('value');
            let token = await AsyncStorage.getItem('token');
            try {
                await makeApiRequest('delete', `${TOKEN_API}/${savedFactory}/${token}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                await AsyncStorage.removeItem('token');
            } catch (error) {
                handleAlertForError(error);
            }
        }
        await AsyncStorage.removeItem('username');
        await AsyncStorage.removeItem('value');
        setUsername('');
        setPassword('');
        setValue(null);
        setIsLogin(false);
        setLoginText('Verify');
    };

    const handleKeyPress = (index, { nativeEvent: { key } }) => {
        if (/^\d$/.test(key) && index < 3) {
            pinInputRefs[index + 1].current.focus();
        }
        if (key === 'Backspace' && index > 0) {
            pinInputRefs[index - 1].current.focus();
        }
    };

    return (
        <ImageBackground source={background} style={styles.background}>
            <View style={styles.container}>
                {loading && <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>}
                <Text style={styles.title}>{username ? `Welcome ${username}` : "Welcome"}</Text>
                {isLogin && <Text style={styles.desc}>Please Enter the PIN</Text>}
                {isLogin && <View style={styles.PinContainer}>
                    {[...Array(4)].map((_, index) => (
                        <TextInput
                            key={index}
                            ref={pinInputRefs[index]}
                            style={styles.inputPin}
                            keyboardType="numeric"
                            maxLength={1}
                            value={password[index] || ''}
                            onChangeText={(value) => handlePinChange(password.substring(0, index) + value + password.substring(index + 1))}
                            onKeyPress={(event) => handleKeyPress(index, event)}
                            secureTextEntry={true}
                        />
                    ))}
                </View>}
                {!isLogin && <><TextInput
                    style={styles.input}
                    placeholder="Username"
                    onChangeText={text => setUsername(removeLeadingZero(text))}
                    value={username}
                />
                    <View style={styles.passwordContainer}>
                        <TextInput
                            style={styles.passwordInput}
                            placeholder="4-digit PIN"
                            onChangeText={text => setPassword(text.replace(/[^0-9]/g, ''))}
                            value={password}
                            keyboardType="numeric"
                            secureTextEntry={!showPassword}
                            maxLength={4}
                        />
                        <TouchableWithoutFeedback onPress={togglePasswordVisibility}>
                            <View style={styles.eyeButton}>
                                {/* You can use your eye icon here */}
                                {showPassword ? (
                                    <MaterialCommunityIcons name="eye" size={20} color="#000" />
                                ) : (
                                    <MaterialCommunityIcons name="eye-off" size={20} color="#000" />
                                )}
                            </View>
                        </TouchableWithoutFeedback>
                    </View>
                    {loginText === 'Login' && <View style={styles.containerDrop}>
                        <DropDownPicker
                            placeholder='Select Factory'
                            style={styles.roleDropDown}
                            dropDownContainerStyle={styles.roleDropDown}
                            open={open}
                            value={value}
                            items={factoryList}
                            setOpen={(val) => {
                                Keyboard.dismiss();
                                setOpen(val)
                            }
                            }
                            setValue={setValue}
                            setItems={factoryList} />
                    </View>}</>}
                {isLogin && <TouchableOpacity onPress={newLoginClick} style={styles.button}>
                    <Text style={styles.buttonText}>New Login?</Text>
                </TouchableOpacity>}
                {error && <Text style={styles.errorText}>{error}</Text>}
                <Button
                    onPress={loginText === 'Verify' ? handleVerify : handleLogin}
                    btnLabel={loginText}
                    buttonStyle={styles.loginButton} />
                {/* {noBioMetric && isLogin && <TouchableOpacity onPress={authenticate} style={styles.buttonFinger}>
                    <MaterialCommunityIcons name="fingerprint" size={70} color="#000" />
                </TouchableOpacity>} */}
            </View>
        </ImageBackground>
    );
};

const styles = StyleSheet.create({
    containerDrop: {
        zIndex: 100
    },
    loaderContainer: {
        position: 'absolute',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        height: '100%',
        width: '100%',
    },
    PinContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 10
    },
    inputPin: {
        width: 50,
        height: 50,
        borderWidth: 1,
        borderRadius: 5,
        textAlign: 'center',
    },
    background: {
        flex: 1,
        resizeMode: 'cover',
        justifyContent: 'center',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white
        padding: 20,
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    desc: {
        fontSize: 16,
        marginBottom: 20,
    },
    input: {
        width: '80%',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 10,
        marginBottom: 20,
        backgroundColor: '#fff',
    },
    factoryInput: {
        width: '80%',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        top: 30,
        padding: 10,
        marginBottom: 20,
        backgroundColor: '#fff',
    },
    passwordContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '80%',  // Adjust the width to your preference
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 10,
        marginBottom: 20,
        backgroundColor: '#fff',
    },

    passwordInput: {
        flex: 1,
        paddingRight: 30,  // Space for the eye icon
    },

    eyeButton: {
        position: 'absolute',
        right: 10,  // Adjust the right position to your preference
        padding: 10,
    },
    roleDropDown: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        marginBottom: 20,
    },
    loginButton: {
        backgroundColor: Colors.blue,
        width: '80%',
        paddingVertical: 15,
        paddingHorizontal: 30,
        marginTop: 20,
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
    },
    button: {
        marginTop: 20,
    },
    buttonText: {
        color: Colors.blue,
        fontSize: 16,
        textDecorationLine: 'underline',
    },
});

export default LoginScreen;
