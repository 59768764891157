import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, FlatList } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import TransactionList from '../components/TransactionList';
import Loader from '../components/Loader';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';
import Button from '../components/Button';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import useDataStore from '../stores/dataStore';
import { Colors } from '../constants/theme';
import ShowAlert from '../modules/AlertModule';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { isEmpty } from '../utils/commonUtils';
import { makeApiRequest } from '../utils/apiUtil';

const SiteDetailsScreen = ({ route }) => {

    const [siteDetail, setSiteDetail] = useState(null);
    const [dataKey, setDataKey] = useState(null);
    const { siteName, siteKey, clientKey, type } = route.params;
    const [transactionData, setTransactionData] = useState(null);
    const navigation = useNavigation();
    const [loading, setLoading] = useState(true);
    const isFocused = useIsFocused();
    const [userData, API_CONFIG, setToastMessage, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => [state.userData, state.API_CONFIG, state.setToastMessage, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]);

    let prevDate = null;
    let data;
    let pendingAmountRoute = 0;

    useEffect(() => {
        if (isFocused) {
            navigation.setOptions({
                title: `Site`,
            });
            fetchData();
            fetchTransactions();
        }
    }, [isFocused, clientKey, siteKey]);

    if (siteDetail) {
        pendingAmountRoute = (siteDetail.totalAmount - siteDetail.receivedAmount)
    }

    const fetchData = async () => {
        try {
            const response = await makeApiRequest('get', `${API_CONFIG.SITE_DETAILS}/${clientKey}/${siteKey}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            data = response;
            navigation.setOptions({
                title: `Site | ${data.siteName}`,
                headerRight: () => (
                    [APP_ROLE.OWNER].includes(userData.role) && <TouchableOpacity style={styles.headerRight} onPress={handleIconPress}>
                        <MaterialCommunityIcons
                            name="pencil"
                            size={24}
                            color="#000"
                        />
                    </TouchableOpacity>
                ),
            });
            setSiteDetail(data);
            setLoading(false);
            // console.log("Fetching Site Details", data);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching Site Details:', error);
            handleAlertForError(error);
        }
    };

    const fetchTransactions = async () => {
        try {
            const response = await makeApiRequest('get', `${API_CONFIG.SITE_TRANSACTIONS}/${clientKey}/${siteKey}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            let filteredData = response;
            if (userData.role === APP_ROLE.SUPERVISOR) {
                filteredData = Object.values(response).filter(node => node.actionBy === userData.username);
            }
            setTransactionData(filteredData);
            setDataKey(Object.keys(filteredData));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching transactions:', error);
            handleAlertForError(error);
        }
    };

    const goToOtherScreen = () => {
        navigation.navigate('AddTransactionScreen', { type: "clients", clientID: clientKey, siteID: siteKey, siteName, pendingAmountRoute });
    };

    const handleIconPress = () => {
        navigation.navigate('AddSiteScreen', { siteDetails: data, clientID: clientKey, siteID: siteKey });
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EDIT_SITE_SCREEN, userData);
    };

    useEffect(() => {
        fetchTransactions();
    }, []);

    const handleDelete = (key) => {
        let lowerType = type.toLowerCase();
        ShowAlert("Delete", "Are you sure you want to delete this transaction?", "YesNo", () => {
            console.log('Delete Pressed Client site');
        }, async () => {
            try {
                await makeApiRequest('delete', `${API_CONFIG.SITE_TRANSACTIONS}/${lowerType}/${clientKey}/${siteKey}/${key}/${userData.username.toLowerCase()}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                setDataKey(dataKey.filter(item => item !== key))
                setToastMessage("Transaction deleted successfully");
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CLIENT_DELETE_TRANSACTION, userData);
                const response = await makeApiRequest('get', `${API_CONFIG.SITE_TRANSACTIONS}/${clientKey}/${siteKey}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                setTransactionData(response);
            } catch (err) {
                console.log("Transaction delete err: ", err)
                handleAlertForError(err);
            }
        }, () => {
            console.log('No Pressed site details');
        }
        );
    }

    return (
        <>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) :
                <View style={styles.container}>
                    {siteDetail && userData.role !== APP_ROLE.SUPERVISOR &&
                        <View style={styles.amountSection}>
                            <View style={styles.leftSection}>
                                <Text style={styles.textStyle}>Total</Text>
                                <Text style={styles.textStyle}>{(siteDetail.totalAmount ? siteDetail.totalAmount : 0)}</Text>
                            </View>
                            <View style={styles.middleSection}>
                                <Text style={styles.textStyle}>Received</Text>
                                <Text style={styles.textStyle}>{siteDetail.receivedAmount}</Text>
                            </View>
                            <View style={styles.rightSection}>
                                <Text style={styles.textStyle}>Pending</Text>
                                <Text style={styles.textStyle}>{(((siteDetail.totalAmount ? siteDetail.totalAmount : 0)) - siteDetail.receivedAmount).toFixed(2)}</Text>
                            </View>
                        </View>
                    }
                    {dataKey?.length > 0 && !isEmpty(transactionData) ? <FlatList
                        style={styles.flatListStyle}
                        data={dataKey}
                        renderItem={({ item: key }) => {
                            let jsx = <>
                                {prevDate !== transactionData[key].date &&
                                    <View style={styles.dateView}>
                                        <Text style={styles.textDate}>{transactionData[key].date}</Text>
                                    </View>}
                                <View style={styles.rowContainer}>
                                    <View style={{
                                        width: '100%'
                                    }}>
                                        <TransactionList
                                            key={key}
                                            comment={transactionData[key].comment}
                                            date={transactionData[key].date}
                                            time={transactionData[key].time}
                                            amount={transactionData[key].amount}
                                            user={transactionData[key].actionBy}
                                            transactionId={key}
                                            type={"clients"}
                                            clientID={clientKey}
                                            siteID={siteKey}
                                            siteName={siteName}
                                            allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]}
                                            pendingAmountRoute={pendingAmountRoute}
                                        />
                                    </View>
                                    {userData.username === transactionData[key]?.actionBy && <TouchableOpacity style={styles.trigger} onPress={() => handleDelete(key)}>
                                        <MaterialCommunityIcons name="delete" size={28} color="#000" />
                                    </TouchableOpacity>}
                                </View>
                            </>
                            prevDate = transactionData[key].date;
                            return (jsx);
                        }}
                        keyExtractor={(item) => item}
                    /> : <View style={styles.centeredView}>
                        <Text style={styles.noTransaction}>No Transaction Data</Text>
                    </View>}
                    <Button
                        onPress={goToOtherScreen}
                        btnLabel="RECEIVED PAYMENT"
                        buttonStyle={styles.BtnContainer}
                        allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]} />
                </View>
            }
        </>
    );
}

const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        marginVertical: 5
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        flex: 1,
        textAlign: 'center',
        alignItems: 'center'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noTransaction: {
        textAlign: 'center',
    },
    headerRight: {
        paddingRight: 10
    },
    amountSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 16,
        alignItems: 'center',
        width: '100%',
        marginTop: 10,
        marginBottom: 2,
        paddingVertical: 0
    },
    leftSection: {
        width: "30%",
        borderRadius: 12,
        textAlign: "center",
        alignItems: 'center',
        backgroundColor: "#AFDBF5"
    },
    rightSection: {
        width: "30%",
        borderRadius: 12,
        textAlign: "center",
        alignItems: 'center',
        backgroundColor: "#FCACA5"
    },
    middleSection: {
        width: "30%",
        backgroundColor: "#93E2AE",
        borderRadius: 12,
        textAlign: "center",
        alignItems: 'center'
    },
    textStyle: {
        padding: 1,
        color: '#000',
        fontSize: 16,
        fontWeight: 'bold',
    },
    BtnContainer: {
        paddingVertical: 20,
        paddingHorizontal: 40,
        width: '90%',
        bottom: 10,
        position: "absolute",
        backgroundColor: Colors.secondary
    },
    flatListStyle: {
        width: '100%',
        height: '100%',
        marginBottom: 76
    },
    dateView: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textDate: {
        backgroundColor: 'white',
        textAlign: 'center',
        width: "35%",
        borderRadius: 5,
    },
    trashIcon: {
        backgroundColor: 'red',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        top: 15,
        left: 5,
        height: '70%',
    }
});

export default SiteDetailsScreen