import React, { useLayoutEffect } from 'react';
import { View, StyleSheet, FlatList, Text, TouchableOpacity, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import SiteList from '../components/SiteList';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import CardView from '../components/CardView';
import { isEmpty } from '../utils/commonUtils';

const InfoScreen = ({ route }) => {
    const navigation = useNavigation();
    const { contact, actionBy, name, type, empData, siteName, extraExpenseTotalAmount, siteDetailsData } = route.params;

    useLayoutEffect(() => {
        navigation.setOptions({
            title: `${name}'s Details`,
            headerRight: () => (
                <View style={styles.headerRight}>
                    <TouchableOpacity onPress={handleCallPress}>
                        <MaterialCommunityIcons
                            name="phone"
                            size={24}
                            color="#000" />
                    </TouchableOpacity>
                </View>
            ),
        });
    }, [navigation]);

    const handleCallPress = () => {
        Linking.openURL(`tel:${contact}`);
    };

    return (
        <>
            <View style={styles.container}>
                <Text style={styles.name}>Name: {name}</Text>
                <Text style={styles.contact}>Phone No: {contact}</Text>
                <Text style={styles.actionBy}>Added By: {actionBy}</Text>
                {type === "Employees" &&
                    <>
                        <Text style={styles.extraWorkText}>Extra Work: {extraExpenseTotalAmount}</Text>
                        <Text style={styles.workingSites}>Working on Sites</Text>
                    </>
                }
                {siteName?.length > 0 ? <FlatList
                    data={siteName}
                    renderItem={({ item: key, index }) => {
                        return (
                            <SiteList
                                siteKey={key}
                                siteName={key}
                                empWork={empData[index].work}
                            />
                        )
                    }}
                    keyExtractor={(index) => {
                        index.toString();
                    }}
                /> : type === "Employees" && <Text style={styles.noEmpWorking}>Employee is not working</Text>}
                {
                    type === "Clients" && siteDetailsData && !isEmpty(siteDetailsData) &&
                    <View style={styles.materialUsedContainer}>
                        <Text style={styles.materialUsedText}> Materials Used :</Text>
                        <FlatList
                            style={{ marginBottom: 10, backgroundColor: 'lightgrey', height: '80%' }}
                            data={[{ key: 'dummy' }]} // Provide a dummy item as FlatList requires data
                            renderItem={({ item }) => (
                                <CardView siteDetails={siteDetailsData} />
                            )}
                        />
                    </View>
                }
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f9f9f9',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 10,
        padding: 15,
        margin: 10,
        width: '95%',
        alignSelf: 'center',
    },
    name: {
        fontSize: 18,
        marginBottom: 10,
        color: '#333',
    },
    contact: {
        fontSize: 18,
        marginBottom: 10,
    },
    actionBy: {
        fontSize: 18,
        marginBottom: 10,
    },
    extraWorkText: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    workingSites: {
        fontSize: 20,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    noEmpWorking: {
        fontSize: 16,
        margin: 15,
        alignSelf: 'center',
    },
    headerRight: {
        paddingRight: 14,
        paddingTop: 8
    },
    materialUsedText: {
        textAlign: "center",
        fontSize: 18,
        fontWeight: 'bold',
        marginVertical: 10
    }
});

export default InfoScreen