import { View, TextInput, Text, StyleSheet, TouchableOpacity } from 'react-native';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Colors } from '../constants/theme';
import Button from '../components/Button';
import useDataStore from '../stores/dataStore';
import { FontAwesome5, Feather } from '@expo/vector-icons';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import ShowAlert from "../modules/AlertModule";
import { USERS_API } from "../env-config/api-config";
import { sendNotification } from "../modules/Notification";
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { formatDate } from '../utils/dateUtil';
import { removeLeadingZero, isEmpty } from '../utils/commonUtils';
import { makeApiRequest } from '../utils/apiUtil';
import Loader from '../components/Loader';
import DateTimePicker from 'react-native-ui-datepicker';
import Popup from "../components/popup";

const AddFactoryExpense = ({ route }) => {
    const [amount, setAmount] = useState('');
    const [isFocus, setIsFocus] = useState(null);
    const [API_CONFIG, userData, setToastMessage, authToken, setAuthToken, refreshToken, setRefreshToken] = useDataStore((state) => ([state.API_CONFIG, state.userData, state.setToastMessage, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken]));
    const [error, setError] = useState(null);
    const [comment, setComment] = useState('');
    const [chosenDate, setChosenDate] = useState(new Date());
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
    const { existingAmount, existingComment, accountType, commentFactoryExpense, transactionId, accountName } = route.params || { existingAmount: null, existingComment: null, accountType: null, commentFactoryExpense: null, transactionId: null, accountId: null, accountName: null };
    const navigation = useNavigation();
    const [loading, setLoading] = useState(false);
    const isFocused = useIsFocused();
    let dataResponse = false;

    useEffect(() => {
        const fetchDataOnFocus = async () => {
            if (isFocused) {
                dataResponse = await fetchData();
            }
        };
        fetchDataOnFocus();
    }, [isFocused]);

    useLayoutEffect(() => {
        if (existingAmount) {
            navigation.setOptions({
                title: "Edit Expense",
            });
        }
        else {
            navigation.setOptions({
                title: "Add Expense",
                headerRight: () => (
                    <Button
                        onPress={handleDeleteButton}
                        btnLabel={<Feather name="trash-2" size={24} color={Colors.accent} />}
                        textStyle={styles.trashIcon}
                        buttonStyle={{ backgroundColor: null, }}
                        allowedRole={[APP_ROLE.OWNER]}
                    />
                ),
            });
        }
    }, [navigation]);

    useEffect(() => {
        if (existingAmount && existingComment) setAmount(existingAmount); setComment(commentFactoryExpense);
    }, [existingAmount, existingComment])

    let time = new Date();
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    const hideDatePicker = () => {
        setDatePickerVisibility(false);
    };

    const handleConfirm = (date) => {
        setChosenDate(date);
        hideDatePicker();
    };

    const validateTransaction = () => {
        if (!amount) {
            setLoading(false);
            setError('Please enter amount.');
            return false;
        }
        else if (!comment) {
            setLoading(false);
            setError('Please enter your comment.');
            return false;
        }
        setError('');
        return true;
    };

    const handleDeleteButton = () => {
        if (dataResponse === true) {
            ShowAlert("Failed", "Please Delete all the transactions", "Ok");
        }
        else {
            ShowAlert("Delete", `Are you sure you want to delete this account?`, "YesNo", () => { }, async () => {
                console.log('Yes Pressed');
                try {
                    await makeApiRequest('delete', `${API_CONFIG.ACCOUNTS}/${accountType}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                    setToastMessage("Account deleted successfully");
                    navigation.goBack();
                } catch (err) {
                    console.log("delete err: ", err)
                    handleAlertForError(err);
                }
            }, () => {
                console.log('No Pressed');
            }
            );
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const accountsData = await makeApiRequest('get', `${API_CONFIG.TRANSACTIONS}/accounts`, authToken, refreshToken, setAuthToken, setRefreshToken);
            setLoading(false);
            if (!isEmpty(accountsData)) {
                let isTransactionPresent = Object.values(accountsData).some(entry => entry.accountType === accountType);
                return isTransactionPresent;
            }
            else {
                return false;
            }
        }
        catch (error) {
            handleAlertForError(error);
        }
    }

    const submitTransactionDetails = async () => {
        setLoading(true);
        try {
            const user = await makeApiRequest('get', `${USERS_API}/${userData.factory}/${userData.username}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            if (validateTransaction()) {
                if (existingAmount) {
                    if (amount - existingAmount <= user.amount) {
                        await makeApiRequest("put",
                            `${API_CONFIG.TRANSACTIONS}/accounts/${transactionId}/${userData.username.toLowerCase()}`,
                            authToken, refreshToken, setAuthToken, setRefreshToken, {
                            amount: amount,
                            date: formatDate(chosenDate),
                            time: chosenDate.toDateString() !== today.toDateString() ? "11:59:59 PM" : time.toLocaleTimeString("en-US"),
                            comment: comment,
                            accountType: accountType,
                            actionBy: userData.username,
                        });
                        setLoading(false);
                        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.FACTORY_EXPENSE_EDIT_TRANSACTION, userData);
                        setToastMessage("Payment updated successfully");
                        setAmount("");
                        setComment("");
                        navigation.goBack();
                    }
                    else {
                        setLoading(false);
                        ShowAlert("Failed", "Your balance is insufficient!", "Ok", () => {
                            console.log('OK Pressed');
                        });
                    }
                }
                else {
                    if (parseFloat(user.amount) >= parseFloat(amount)) {
                        await makeApiRequest("post",
                            `${API_CONFIG.TRANSACTIONS}/accounts/${userData.username.toLowerCase()}`,
                            authToken, refreshToken, setAuthToken, setRefreshToken, {
                            amount: amount,
                            date: formatDate(chosenDate),
                            time: chosenDate.toDateString() !== today.toDateString() ? "11:59:59 PM" : time.toLocaleTimeString("en-US"),
                            comment: comment,
                            actionBy: userData.username,
                            accountType: accountType,
                        });
                        setLoading(false);
                        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.FACTORY_EXPENSE_YOU_GAVE_CLICKED, userData);
                        sendNotification(userData.factory, `Transaction for Accounts`, `${userData.username}: ${amount} Rs given for ${accountName}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                        setToastMessage("Payment added successfully");
                        setAmount("");
                        setComment("");
                        navigation.goBack();
                    }
                    else {
                        setLoading(false);
                        ShowAlert("Failed", "Your balance is insufficient!", "Ok", () => {
                            console.log('OK Pressed');
                        });
                    }
                }
            }
        } catch (error) {
            setLoading(false);
            handleAlertForError(error);
        }
    }

    return (
        <View style={styles.container}>
            {loading && (
                <View style={styles.overlay}>
                    <Loader size="large" color="#0000ff" />
                </View>
            )}
            <TextInput style={[styles.textInput, { fontWeight: 'bold', color: 'black' }]} editable={false} value={accountName || existingComment} />
            <TextInput
                placeholder='Enter Amount'
                value={amount}
                keyboardType="numeric"
                onChangeText={(text) => setAmount(removeLeadingZero(text))}
                style={[styles.textInput, isFocus === 'amount' && { borderColor: Colors.primary }]}
                onFocus={() => setIsFocus('amount')}
            />
            <TouchableOpacity onPress={() => setDatePickerVisibility(true)}>
                <View style={styles.view}>
                    <FontAwesome5
                        name="calendar"
                        size={24}
                        color={Colors.primary}
                    />
                    <Text style={styles.text}> {chosenDate.toDateString()}</Text>
                    <FontAwesome5
                        name="caret-down"
                        size={24}
                        color={Colors.primary}
                    />
                    <Popup visible={isDatePickerVisible} onClose={() => setDatePickerVisibility(false)}>
                        <DateTimePicker
                            mode="single"
                            date={chosenDate}
                            maxDate={today}
                            onChange={(param) => {
                                handleConfirm(new Date(param.date));
                                setDatePickerVisibility(false)
                            }}
                            headerButtonStyle={{ color: 'green' }}
                        />
                    </Popup>
                </View>
            </TouchableOpacity>
            <TextInput
                style={[styles.textInput, isFocus === 'comment' && { borderColor: Colors.primary }]}
                placeholder="Write your comment here..."
                value={comment}
                onChangeText={(text) => setComment(text)}
                onFocus={() => setIsFocus('comment')}
            />
            {error && <Text style={styles.errorText}>{error}</Text>}
            <Button
                onPress={submitTransactionDetails}
                btnLabel={`YOU GAVE ₹${amount}`}
                buttonStyle={styles.btnContainer}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    inputContainer: {
        width: '90%',
        paddingVertical: 10,
        flexDirection: 'row',
    },
    dropdownPicker: {
        width: '90%',
        borderRadius: 6,
    },
    view: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 55,
        borderColor: '#636363',
        borderRadius: 6,
        paddingHorizontal: 8,
        margin: 12,
        padding: 12,
        width: '55%',
        backgroundColor: "white"
    },
    text: {
        marginLeft: 10,
        marginRight: 10
    },
    textInput: {
        borderWidth: 2,
        borderColor: '#636363',
        margin: 10,
        padding: 12,
        fontSize: 16,
        borderRadius: 6,
        width: '90%'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        width: '80%',
        alignItems: 'center',
    },
    submitButton: {
        backgroundColor: Colors.primary,
    },
    btnContainer: {
        paddingVertical: 20,
        paddingHorizontal: 40,
        width: '90%',
        position: 'absolute',
        bottom: 10,
        margin: 20,
    },
    trashIcon: {
        right: 20,
        alignSelf: 'flex-end'
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
    },
    overlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10000
    },
});

export default AddFactoryExpense