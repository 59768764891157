import { create } from 'zustand'

const useDataStore = create((set) => ({
    employeeData: '',
    setEmployeeData: (employeeData) => set({ employeeData }),
    supplierData: '',
    setSupplierData: (supplierData) => set({ supplierData }),
    clientsData: '',
    setClientsData: (clientsData) => set({ clientsData }),
    userData: '',
    setUserData: (userData) => set({ userData }),
    API_CONFIG: '',
    setAPI_CONFIG: (API_CONFIG) => set({ API_CONFIG }),
    toastMessage: null,
    setToastMessage: (toastMessage) => set({ toastMessage }),
    selectedContact: null,
    setSelectedContact: (selectedContact) => set({ selectedContact }),
    thekedarData: '',
    setThekedarData: (thekedarData) => set({ thekedarData }),
    authToken: null,
    setAuthToken: (authToken) => set({ authToken }),
    refreshToken: null,
    setRefreshToken: (refreshToken) => set({ refreshToken }),
    siteData: '',
    setSiteData: (siteData) => set({ siteData }),
    stockData: '',
    setStockData: (stockData) => set({ stockData }),
}))

export default useDataStore