import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import Button from '../components/Button';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import { Colors } from '../constants/theme';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import Loader from '../components/Loader';
import List from '../components/List';
import Search from '../components/Search';
import useDataStore from '../stores/dataStore';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { makeApiRequest } from '../utils/apiUtil';

const QuotationScreen = () => {
    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const [loading, setLoading] = useState(true);
    const [quoteKey, setQuoteKey] = useState(null);
    const [quoteData, setQuoteData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [API_CONFIG, userData, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => [state.API_CONFIG, state.userData, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]);

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.QUOTATION_CLICKED, userData);
            fetchData();
        }
    }, [isFocused]);

    const fetchData = async () => {
        try {
            const data = await makeApiRequest('get', API_CONFIG.QUOTATION, authToken, refreshToken, setAuthToken, setRefreshToken);
            setQuoteData(data);
            setQuoteKey(Object.keys(data));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching quotation:', error);
            handleAlertForError(error);
        }
    };

    const goToOtherScreen = () => {
        navigation.navigate('CreateQuotationScreen', { data: null });
    };

    const filteredQuotes = quoteKey?.filter(key => {
        if (quoteData[key]) {
            return quoteData[key]['clientName']?.toLowerCase().includes(searchQuery?.toLowerCase()) && !quoteData[key]['isDeleted']
        }
    });

    return (
        <View style={styles.container}>
            <Search value={searchQuery} onChangeText={setSearchQuery} placeholder="Search Quotation" />
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) : filteredQuotes?.length > 0 ? (
                <FlatList
                    data={filteredQuotes}
                    style={{ height: '100%', marginBottom: 85 }}
                    renderItem={({ item: key }) => (
                        <List
                            key={key}
                            name={quoteData[key]['clientName']}
                            data={quoteData[key]}
                            type={'Quotation'}
                            userID={key}
                        />
                    )}
                    keyExtractor={(item) => item}
                />
            ) : (
                <View style={styles.noQuoteContainer}>
                    {searchQuery ? <Text style={styles.noQuoteText}> No Quotation Found</Text> : <Text style={styles.noQuoteText}>No Quotation Added</Text>}
                </View>
            )}
            <Button
                onPress={goToOtherScreen}
                btnLabel="CREATE QUOTATION"
                buttonStyle={styles.BtnContainer}
                textStyle={styles.btnText}
                allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]} />
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    containerButton: {
        textAlign: 'center',
        alignItems: 'center',
    },
    BtnContainer: {
        paddingVertical: 20,
        paddingHorizontal: 40,
        alignSelf: 'center',
        width: '90%',
        bottom: 10,
        position: 'absolute',
        backgroundColor: Colors.secondary,
    },
    btnText: {
        color: 'white',
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noQuoteContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noQuoteText: {
        textAlign: 'center',
    },
})
export default QuotationScreen;