import { EVENT_ACTIONS } from "../constants/Constant";
import ShowAlert from "../modules/AlertModule";
import useDataStore from "../stores/dataStore";
import MixpanelTracker from '../utils/MixpanelTracker';

export const handleAlertForError = (error) => {
    const [userData] = useDataStore((state) => [state.userData]);

    console.error("Error occurred:", error);
    MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ERROR_OCCURED, userData, error);
    ShowAlert("Error", "Please try again.", "OK", () => {
    });
};