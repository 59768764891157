import React, { useEffect, useRef, useState } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, FlatList } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Button from '../components/Button';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import { Colors } from '../constants/theme';
import { FontAwesome5 } from '@expo/vector-icons';
import Icon from 'react-native-vector-icons/FontAwesome';
import useDataStore from '../stores/dataStore';
import { AntDesign } from '@expo/vector-icons';
import ShowAlert from '../modules/AlertModule';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { makeApiRequest } from '../utils/apiUtil';
import DateTimePicker from 'react-native-ui-datepicker';
import Popup from "../components/popup";
import html2pdf from 'html2pdf.js';

const CreateQuotationScreen = ({ route }) => {
    const { data } = route.params;
    const [companyName, setCompanyName] = useState('');
    const [clientName, setClientName] = useState('');
    const [gstNo, setGstNo] = useState('');
    const [mobNo, setMobNo] = useState('');
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
    const [chosenDate, setChosenDate] = useState(new Date());
    const [products, setProducts] = useState([{ productDesc: '', sqFeet: '', pricePerSQFT: '', runningFT: '', height: '' }]);
    const flatListRef = useRef();
    const [amount, setAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [cgst, setcgst] = useState('9');
    const [sgst, setsgst] = useState('9');
    const [error, setError] = useState('');
    const [termsPoints, setTermsPoints] = useState([]);
    const [API_CONFIG, userData, setToastMessage, authToken, setAuthToken, refreshToken, setRefreshToken] = useDataStore((state) => [state.API_CONFIG, state.userData, state.setToastMessage, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken]);
    const navigation = useNavigation();

    const [termsText, setTermsText] = useState(data?.termsText ||
        `• This Quotation is valid for 20 days only.\n
• Land should be in proper level and clear.\n
• During Fitting, water & electricity supply should be provided by you.\n
• In legal Invoice/bill, 18% GST will be extra.\n
• Once work begins, if it stops for more than a day, you'll need to pay extra for labor.\n
• Payment condition: 30% advance with work order, 20% when material supply and remaining 50% should be in phase as work complete, Total payment when work complete.`
    );

    const showDatePicker = () => {
        setDatePickerVisibility(true);
    };

    const hideDatePicker = () => {
        setDatePickerVisibility(false);
    };

    const handleConfirm = (date) => {
        setChosenDate(date);
        hideDatePicker();
    };

    const updateProductsData = (index, key, value) => {
        setProducts(prevData => {
            const newData = [...prevData];
            newData[index][key] = value;
            if (key === 'runningFT' || key === 'height') {
                if (newData[index].runningFT && newData[index].height) {
                    newData[index]['sqFeet'] = ((parseFloat(newData[index]['runningFT']) * parseFloat(newData[index]['height'])).toString());
                } else {
                    newData[index]['sqFeet'] = '';
                }
            }
            return newData;
        });
    }

    const addProductRow = () => {
        setProducts(prevData => [...prevData, { productDesc: '', sqFeet: '', pricePerSQFT: '', runningFT: '', height: '' }]);
    }

    const removeProductRow = (index) => {
        setProducts(prevData => prevData.filter((_, i) => i !== index));
    }

    const calculateAmount = () => {
        const total = products.reduce((accumulator, currentValue) => {
            if (currentValue.sqFeet !== '' && currentValue.pricePerSQFT !== '') {
                const product = parseFloat(currentValue.sqFeet) * parseFloat(currentValue.pricePerSQFT);
                return accumulator + product;
            }
            return accumulator
        }, 0);
        setAmount(total);
    };

    const calculateTotalAmount = () => {
        let total = products.reduce((accumulator, currentValue) => {
            if (currentValue.sqFeet !== '' && currentValue.pricePerSQFT !== '') {
                const product = parseFloat(currentValue.sqFeet) * parseFloat(currentValue.pricePerSQFT);
                return accumulator + product;
            }
            return accumulator
        }, 0);
        let totalAmount = total;
        if (cgst) totalAmount = total + (total * cgst) / 100;
        if (sgst) totalAmount = totalAmount + (total * sgst) / 100;
        setTotalAmount(totalAmount);
    };

    useEffect(() => {
        calculateAmount();
    }, [products]);

    useEffect(() => {
        if (!termsText) {
            const items = `• This Quotation is valid for 20 days only.\n
• Land should be in proper level and clear.\n
• During Fitting, water & electricity supply should be provided by you.\n
• In legal Invoice/bill, 18% GST will be extra.\n
• Once work begins, if it stops for more than a day, you'll need to pay extra for labor.\n
• Payment condition: 30% advance with work order, 20% when material supply and remaining 50% should be in phase as work complete, Total payment when work complete.`?.split('\n').filter(item => item.trim().length > 0);
            setTermsPoints(items);
        } else {
            const items = termsText?.split('\n').filter(item => item.trim().length > 0);
            setTermsPoints(items);
        }
    }, [termsText]);

    useEffect(() => {
        calculateTotalAmount();
    }, [products, cgst, sgst]);

    const deleteQuote = () => {
        ShowAlert("Delete", "Are you sure you want to delete Quotation?", "YesNo", () => {
            console.log('OK Pressed');
        }, async () => {
            await makeApiRequest('delete', `${API_CONFIG.QUOTATION}/${data.id}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            setToastMessage("Quotation deleted successfully");
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.QUOTATION_DELETE_CLICKED, userData);
            navigation.goBack();
        }, () => {
            console.log('No Pressed');
        });
    }

    useEffect(() => {
        if (data) {
            navigation.setOptions({
                title: 'Update Quotation',
                headerRight: () => (
                    <TouchableOpacity onPress={() => deleteQuote()}>
                        <AntDesign name="delete" size={20} color="red" style={{ marginRight: 20 }} />
                    </TouchableOpacity>
                ),
            });
            setCompanyName(data.companyName);
            setClientName(data.clientName);
            setGstNo(data.gstNo);
            setMobNo(data.mobNo);
            setProducts(data.products);
            setTotalAmount(data.totalAmount);
            setcgst(data.cgst);
            setsgst(data.sgst);
            setTermsText(data.termsText);
        }
    }, [data]);

    const validateForm = () => {
        if (!clientName) {
            setError('Please add client name.');
            return false;
        }

        let isErr = false;
        products?.map(key => {
            if (key['pricePerSQFT'] === '') {
                setError('Please add Rate/SQ feet.');
                isErr = true;
            }
            if (key['height'] === '') {
                setError('Please add height.');
                isErr = true;
            }
            if (key['runningFT'] === '') {
                setError('Please add running feet.');
                isErr = true;
            }
        });
        if (isErr) {
            return false;
        }
        setError('');
        return true;
    };

    const createAndSavePDF = async () => {
        if (validateForm()) {
            const pushData = async () => {
                if (data) {
                    try {
                        await makeApiRequest("put",
                            `${API_CONFIG.QUOTATION}/${data.id}`,
                            authToken, refreshToken, setAuthToken, setRefreshToken, {
                            companyName: companyName,
                            clientName: clientName,
                            gstNo: gstNo,
                            date: chosenDate,
                            products: products,
                            amount: amount,
                            totalAmount: totalAmount,
                            cgst: cgst || '0',
                            sgst: sgst || '0',
                            termsText: termsText,
                            mobNo: mobNo
                        });
                        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.QUOTATION_EDIT_CLICKED, userData);
                    } catch (error) {
                        console.error('Error Pushing Updated Quotation:', error);
                        handleAlertForError(error);
                    }
                } else {
                    try {
                        await makeApiRequest("post", API_CONFIG.QUOTATION, authToken, refreshToken, setAuthToken, setRefreshToken, {
                            companyName: companyName,
                            clientName: clientName,
                            gstNo: gstNo,
                            date: chosenDate,
                            products: products,
                            amount: amount,
                            totalAmount: totalAmount,
                            cgst: cgst || '0',
                            sgst: sgst || '0',
                            termsText: termsText,
                            mobNo: mobNo
                        });
                        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.QUOTATION_CREATE_CLICKED, userData);
                    } catch (error) {
                        console.error('Error Pushing Quotation:', error);
                        handleAlertForError(error);
                    }
                }

            };
            pushData();
            try {
                const htmlContent = generateHTML();
                const fileName = `${clientName}_quotation.pdf`;

                const element = document.createElement("div");
                element.innerHTML = htmlContent;

                html2pdf(element, {
                    filename: fileName,
                });
            } catch (error) {
                console.error('Failed to create PDF', error);
            }
        }

    };

    const convertDate = (datePassed) => {
        var date = datePassed.getDate();

        var month = datePassed.getMonth() + 1;

        var year = datePassed.getFullYear();

        month = (month < 10) ? '0' + month : month;
        date = (date < 10) ? '0' + date : date;

        var dateMonthYear = date + '-' + month + '-' + year;
        return dateMonthYear;
    }

    const generateHTML = () => {
        // Generate HTML content for the PDF here
        let htmlContent = `<html>
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link
        href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Signika:wght@300..700&display=swap"
        rel="stylesheet">

    <title>Quotation</title>
    <style>
        .title {
            float: inline-end;
            font-weight: 500;
            font-size: 40px;
        }
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 10px;
            background-color: #f2f2f2;
            border-width: 0px;
            border-color: black;
            border-style: solid;
        }

        .container {
            padding: 20px;
            background-color: #fff;
            /* White background for container */
            border-radius: 10px;
            /* Rounded corners */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            /* Shadow effect */
        }

        .header {
            text-align: right;
            margin-bottom: 0px;
        }

        .details-container {
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 5px;
        }

        .details-heading {
            margin-bottom: 5px;
            font-size: 30px;
            font-family: "Arial", sans-serif;
            font-weight: 550;
        }

        .table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
        }

        .table th,
        .table td {
            border: 1px solid #000;
            padding: 4px;
            text-align: center;
        }

        .total-row {
            font-weight: bold;
        }

        .terms {
            max-width: 48%;
            border-width: 1px;
            border-color: black;
            border-style: solid;
            border-radius: 6px;
            padding-inline: 12px;
        }

        .company-use {
            width: 48%;
            border-width: 1px;
            border-color: black;
            border-style: solid;
            border-radius: 6px;
            padding-inline: 12px;
            margin-left: 20px;
        }

        .address {
            max-width: 40%;
            margin: 0;
            margin-top: 8px;
        }

        .mobNum {
            position: absolute;
            right: 34px;
        }

        .gst {
            position: absolute;
            right: 34px;
            margin-top: 70px;
        }

        .email {
            position: absolute;
            right: 34px;
            margin-top: 43px;
        }

        .quote-heading {
            font-size: 20px;
            font-family: "Arial", sans-serif;
            font-weight: 500;
        }

        .clientText {
            margin: 0;
            margin-top: 6px;
        }

        .clientTextRight {
            position: absolute;
            right: 50px;
            margin-top: 6px;
            display: flex;
            flex-direction: row-reverse;
        }

        .details-container-client {
            padding: 8px;
            border-radius: 5px;
            margin-bottom: 10px;
            border-width: 1px;
            border-color: black;
            border-style: solid;
        }

        .row-container {
            display: flex;
        }
         .header {
            background-color: #111;
            color: #fff;
            text-align: center;
            padding: 5px 0;
            font-size: 36px;
        }
        .header span {
            font-weight: bold;
        }
        .header .red {
            font-weight: bold;
            color: red;
        }
        .company {
            font-size: 18px;
        }
        .clientName {
            font-weight: bold;
        }
    </style>
</head>

<body>
    <div class="container">
        <div class="header">
        <span class="red">G</span>reat Wall <span class="red">P</span>recas<span class="red">t</span>
        </div>
        <div class="details-container">
            ${userData.factory === "banglore" ? `<p class="mobNum"> <span class="phone-icon">&#x1F4DE;</span>
                +91 8866344990, +91 8866344887</p>
            <p class="gst"><strong>GST No:</strong> 29AAWFG1877F1ZR</p>
            <p class="email"><strong>Email:</strong> greatwallprecast@gmail.com</p>
            <p class="address"><strong>Plant No. 1 </strong> - Sr. No. 208, Vishwanathpura, Nr. Bharat Petrol Pump, Devanahalli,
                Bangalore-562110</p>
            <p class="address"><strong>Plant No. 2 </strong>- Sr. No. 132, Kestur Village, Kasaba Hobli, Doddabalapura Taluk, Bangaluru -
                561203</p>` : `<p class="mobNum"> <span class="phone-icon">&#x1F4DE;</span>
                +91 8866077551, +91 8866077115</p>
            <p class="gst"><strong>GST No:</strong> 33ABAFG1262D1Z3</p>
            <p class="email"><strong>Email:</strong> greatwallprecastcn@gmail.com</p>
            <p class="address"><strong>Plant No. 1 </strong> Sr.No 9/1, Opp. Bharat Petrol Pump, Main Road,Thumbakkam,
Near By Periyapalayam, Thiruvallur, Chennai, 601102</p>`}

        </div>
        <div class="details-container-client">
            <div class="quote-heading">Quote to: <span class="company">${companyName || ''}</span></div>
            <div class="clientTextRight">
                <strong style="margin-right: 160px;">GST No.:</strong>
                <p style="margin: auto; position: absolute;">${gstNo || ''}</p>
            </div>
            <p class="clientText"><span class="clientName"> Client Name:</span> ${clientName}</p>
            <div class="clientTextRight">
                <strong style="margin-right: 90px;">Date:</strong>
                <p style="margin: auto; position: absolute;">${convertDate(chosenDate)}</p>
            </div>
            <p class="clientText"><span class="clientName"> Contact No:</span> ${mobNo || '  -'}</p>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th>Sr. No.</th>
                    <th>Product Description</th>
                    <th>Total Sq. Ft.</th>
                    <th>Rate/Sq. Ft.</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
            `;

        products?.map((product, index) => (
            htmlContent += `<tr>
                <td>${index + 1}</td>
                <td style="text-align: left; line-height: 1.5;">${product.runningFT} Running foot approx ${product.height} Height <br> Work detail: <br>
* Panel size: 1X7 foot X 50 mm thickness (4
numbers of 3 mm HTCS (HIGH TENSLE CARBO
STEEL) wire in the panel) <br>* Pole size- 6 inch X 6 inch * ${product.productDesc} Ft length (7 no. of 3mm HTCS (HIGH TENSLE CARBO STEEL) wire in the pole)</td>
                <td>${product.sqFeet}</td>
                <td>${product.pricePerSQFT}</td>
                <td>${(product.sqFeet * parseFloat(product.pricePerSQFT)).toFixed(2)}</td>
            </tr>`
        ))
        htmlContent += `<tr class="total-row" style="background-color: #4c4f54; font-weight: unset;">
                    <td colspan="4" style="color: #fff;">Amount</td>
                    <td style="color: #fff;">${amount.toFixed(2)}</td>
                </tr>
                <tr class="total-row" style="font-weight: unset;">
                    <td colspan="4">CGST ${cgst || 0} %</td>
                    <td>${(cgst * amount / 100).toFixed(2)}</td>
                </tr>
                <tr class="total-row" style="font-weight: unset;">
                    <td colspan="4">SGST ${sgst || 0} %</td>
                    <td>${(sgst * amount / 100).toFixed(2)}</td>
                </tr>
                <tr class="total-row">
                    <td colspan="4">Total Amount</td>
                    <td>${totalAmount.toFixed(2)}</td>
                </tr>
                </tbody>
        </table>
        <div class="row-container">
            <div class="terms">
                <h3>Terms & Conditions</h3>
                <ul>
                ${termsPoints.map(item => `<li>${item.replace('• ', '')}</li>`).join('')}
                </ul>
                
            </div >
    <div class="company-use">
        <h3>For Great Wall Precast</h3>
    </div>
        </div>
    </div>
</body>
</html> `
        return htmlContent;
    };

    const handleContactChange = (text) => {
        // Remove any non-numeric characters
        let numericText = text.replace(/[^0-9]/g, '');
        // Remove leading zeros
        numericText = numericText.replace(/^0+/, '');
        const formattedText = numericText.slice(0, 10);
        setMobNo(formattedText);
    };

    return (
        <View style={{ backgroundColor: 'white', height: '100%' }}>
            <FlatList
                ref={flatListRef}
                style={{ height: '100%', marginBottom: 85, backgroundColor: 'white' }}
                data={[{ key: 'dummy' }]} // Provide a dummy item as FlatList requires data
                renderItem={({ item }) => ( // Render the inner content within FlatList
                    <View style={styles.container}>
                        <View style={styles.rowContainer}>
                            <Text style={styles.inputText}>Company Name:</Text>
                            <TextInput
                                placeholder='Company Name'
                                value={companyName}
                                onChangeText={(text) => setCompanyName(text)}
                                style={styles.input}
                            />
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={styles.inputText}>Client Name:</Text>
                            <TextInput
                                placeholder='Client Name'
                                value={clientName}
                                onChangeText={(text) => setClientName(text)}
                                style={styles.input}
                            />
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={styles.inputText}>GST No:</Text>
                            <TextInput
                                placeholder='GST Number (Optional)'
                                value={gstNo}
                                onChangeText={(text) => setGstNo(text)}
                                style={styles.input}
                            />
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={styles.inputText}>Mob No:</Text>
                            <TextInput
                                placeholder='Mob number'
                                value={mobNo}
                                keyboardType="numeric"
                                onChangeText={handleContactChange}
                                style={styles.input}
                            />
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={styles.inputText}>Date:</Text>
                            <TouchableOpacity onPress={showDatePicker}>
                                <View style={styles.view}>
                                    <FontAwesome5
                                        name="calendar"
                                        size={20}
                                        color={Colors.primary}
                                    />
                                    <Text style={styles.text}> {chosenDate.toDateString()}</Text>
                                    <FontAwesome5
                                        name="caret-down"
                                        size={20}
                                        color={Colors.primary}
                                    />
                                    <Popup visible={isDatePickerVisible} onClose={() => setDatePickerVisibility(false)}>
                                        <DateTimePicker
                                            mode="single"
                                            date={chosenDate}
                                            onChange={(param) => {
                                                handleConfirm(new Date(param.date));
                                                setDatePickerVisibility(false)
                                            }}
                                            headerButtonStyle={{ color: 'green' }}
                                        />
                                    </Popup>
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={[styles.inputText, { width: '100%' }]}>Products information:</Text>
                        </View>
                        {products?.map((item, index) => (
                            <View key={index} style={[styles.productContainer, { flexDirection: 'row', backgroundColor: '#F8F7F7', paddingBottom: 12 }, index !== products.length - 1 ? { borderBottomWidth: 1, borderBottomColor: 'black' } : { borderBottomWidth: 0 }]}>
                                <View key={index} style={styles.productContainer}>
                                    <View style={styles.rowContainer}>
                                        <TextInput
                                            placeholder='Running ft'
                                            value={item.runningFT}
                                            keyboardType="numeric"
                                            onChangeText={(text) => updateProductsData(index, 'runningFT', text)}
                                            style={[styles.inputRow, { marginLeft: 0 }]}
                                        />
                                        <TextInput
                                            placeholder='Height'
                                            value={item.height}
                                            keyboardType="numeric"
                                            onChangeText={(text) => updateProductsData(index, 'height', text)}
                                            style={styles.inputRow}
                                        />
                                        <TextInput
                                            placeholder='Rate/SQ Feet'
                                            value={item.pricePerSQFT}
                                            keyboardType="numeric"
                                            onChangeText={(text) => updateProductsData(index, 'pricePerSQFT', text)}
                                            style={styles.inputRow}
                                        />
                                    </View>
                                    <View style={styles.rowContainer}>
                                        <TextInput
                                            placeholder='Pole length ft'
                                            value={item.productDesc}
                                            onChangeText={(text) => updateProductsData(index, 'productDesc', text)}
                                            style={[styles.inputRow, { width: '50%', marginLeft: 0 }]}
                                            multiline={true}
                                            numberOfLines={3}
                                        />
                                        <TextInput
                                            placeholder='0 SQ Feet'
                                            value={item.sqFeet + ' SQ Feet'}
                                            keyboardType="numeric"
                                            onChangeText={(text) => updateProductsData(index, 'sqFeet', text)}
                                            style={[styles.inputRow, { width: '50%' }]}
                                            multiline={true}
                                            numberOfLines={3}
                                            editable={false}
                                        />
                                    </View>
                                </View>
                                {index === 0 && ( // Render remove button for all rows except the first one
                                    <TouchableOpacity onPress={addProductRow}>
                                        <Icon name="plus-circle" size={24} color="green" style={styles.removeButtonIcon} />
                                    </TouchableOpacity>
                                )}
                                {index !== 0 && ( // Render remove button for all rows except the first one
                                    <TouchableOpacity onPress={() => removeProductRow(index)}>
                                        <Icon name="minus-circle" size={24} color="red" style={styles.removeButtonIcon} />
                                    </TouchableOpacity>
                                )}

                            </View>

                        ))}
                        <View style={styles.rowContainer}>
                            <Text style={styles.inputText}>Amount:</Text>
                            <TextInput
                                value={amount.toString() + ' Rs'}
                                style={styles.input}
                                editable={false}
                            />
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={styles.inputText}>CGST %:</Text>
                            <TextInput
                                placeholder='CGST %'
                                value={cgst}
                                onChangeText={(text) => setcgst(text)}
                                style={styles.input}
                                keyboardType="numeric"
                            />
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={styles.inputText}>SGST %:</Text>
                            <TextInput
                                placeholder='SGST %'
                                value={sgst}
                                onChangeText={(text) => setsgst(text)}
                                style={styles.input}
                                keyboardType="numeric"
                            />
                        </View>
                        <View style={styles.rowContainer}>
                            <Text style={styles.inputText}>Total amount:</Text>
                            <TextInput
                                value={totalAmount.toString() + ' Rs'}
                                style={styles.input}
                                editable={false}
                            />
                        </View>
                        <View style={styles.columnContainer}>
                            <Text style={[styles.inputText, { width: '95%' }]}>Terms and Conditions:</Text>
                            <TextInput
                                value={termsText}
                                multiline
                                onChangeText={(termsText) => setTermsText(termsText)}
                                style={[styles.input, { height: 120, marginBottom: 10, marginLeft: 10, width: '95%' }]}
                                editable={true}
                            />
                        </View>
                    </View>
                )}
            />
            <View style={styles.container} >
                {error ? <Text style={{ color: 'red', fontSize: 16, bottom: 80 }}>{error}</Text> : null}
                <Button
                    btnLabel="CREATE QUOTATION"
                    buttonStyle={styles.BtnContainer}
                    textStyle={styles.btnText}
                    onPress={createAndSavePDF}
                    allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]} />
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        textAlign: 'center',
        alignItems: 'center',
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '95%',
        top: 5,
        marginBottom: 2
    },
    columnContainer: {
        flexDirection: 'column',
        width: '95%',
        top: 5,
        marginBottom: 2,
    },
    inputText: {
        padding: 12,
        fontSize: 14,
        width: '40%',
        fontWeight: '500'
    },
    input: {
        borderWidth: 2,
        borderColor: '#636363',
        padding: 2,
        paddingLeft: 5,
        fontSize: 14,
        borderRadius: 6,
        width: '58%',
    },
    productContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
    },
    inputRow: {
        borderWidth: 2,
        borderColor: '#636363',
        padding: 2,
        paddingLeft: 5,
        fontSize: 14,
        borderRadius: 6,
        width: '33%',
        marginLeft: 4,
        marginTop: 4
    },
    view: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 45,
        borderColor: '#636363',
        borderRadius: 6,
        margin: 12,
        padding: 12,
        width: '92%',
        right: 5,
        backgroundColor: "#E8E8E8"
    },
    BtnContainer: {
        paddingVertical: 20,
        paddingHorizontal: 40,
        width: '90%',
        bottom: 10,
        position: 'absolute',
        backgroundColor: Colors.secondary,
    },
    btnText: {
        color: 'white',
    },
    text: {
        marginLeft: 10,
        marginRight: 10
    },
    removeButtonIcon: {
        marginLeft: 5, // Adding some space between the input fields and the icon
    },
})
export default CreateQuotationScreen;