import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { FontAwesome5 } from '@expo/vector-icons';

const InternetCheckWrapper = ({ children }) => {
    const [isConnected, setIsConnected] = useState(true);

    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
            setIsConnected(state.isConnected);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <View style={styles.container}>
            {isConnected ? (
                children
            ) : (
                <View style={styles.noInternetContainer}>
                    <FontAwesome5 name="wifi" size={50} color="#434343" />
                    <Text style={styles.noInternetText}>No internet connection</Text>
                    <Text style={styles.extraMessage}>Please connect to the internet</Text>
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    noInternetContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noInternetText: {
        color: '#434343',
        fontWeight: 'bold',
        fontSize: 20,
        marginTop: 20,
    },
    extraMessage: {
        color: '#434343',
        marginTop: 10,
    },
});

export default InternetCheckWrapper;
