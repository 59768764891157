import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, StyleSheet, ImageBackground, TouchableWithoutFeedback } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import background from "../assets/background.png";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Button from '../components/Button';
import { Colors } from '../constants/theme';
import DropDownPicker from 'react-native-dropdown-picker';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import { useIsFocused } from '@react-navigation/native';
import useDataStore from '../stores/dataStore';
import { USERS_API } from '../env-config/api-config';
import { Keyboard } from 'react-native';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { removeLeadingZero } from '../utils/commonUtils';
import axios from 'axios';

const AddUserScreen = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [amount, setAmount] = useState('');
    const navigation = useNavigation();
    const saltRounds = 100;
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const isFocused = useIsFocused();
    const [userData, setToastMessage, authToken, setAuthToken, refreshToken, setRefreshToken] = useDataStore((state) => ([state.userData, state.setToastMessage, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken]));

    const appRole = [
        { label: 'Supervisor', value: APP_ROLE.SUPERVISOR },
        { label: 'Viewer', value: APP_ROLE.VIEWER }
    ];

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_USERS_CLICKED, userData);
            setValue(null);
        }
    }, [isFocused]);

    const validateForm = () => {
        if (!username) {
            setError('Please add a username.');
            return false;
        }
        if (!password) {
            setError('Please add password.');
            return false;
        }
        if (!value) {
            setError('Please select role.');
            return false;
        }

        if (!/^\d{4}$/.test(password)) {
            setError('Please enter a valid 4-digit PIN.');
            return;
        }

        if (value === APP_ROLE.SUPERVISOR && !amount) {
            setError('Please add balance.');
            return;
        }
        setError('');
        return true;
    };

    const handleRegister = async () => {
        if (validateForm()) {
            try {
                var payload;
                if (value === APP_ROLE.SUPERVISOR) {
                    payload = {
                        username: username,
                        password: password,
                        role: value,
                        factory: userData.factory,
                        amount: amount
                    }
                } else {
                    payload = {
                        username: username,
                        password: password,
                        role: value,
                        factory: userData.factory
                    }
                }
                await axios.post(`${USERS_API}`, payload);
            } catch (error) {
                if (error.response.status === 409) {
                    setError('User already exists.');
                } else {
                    console.error('Error creating user:', error);
                    handleAlertForError(error);
                }
            }
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_USER_REGISTER_CLICKED, userData);
            console.log('User registered successfully!');
            setToastMessage("User registered successfully");
            navigation.goBack();

            setUsername('');
            setPassword('');
            setValue(null);
            setError('');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <ImageBackground source={background} style={styles.background}>
            <View style={styles.container}>
                <TextInput
                    style={styles.input}
                    placeholder="Username"
                    onChangeText={text => setUsername(removeLeadingZero(text))}
                    value={username}
                />
                <View style={styles.passwordContainer}>
                    <TextInput
                        style={styles.passwordInput}
                        placeholder="4-digit PIN"
                        onChangeText={text => setPassword(text)}
                        value={password}
                        keyboardType="numeric"
                        secureTextEntry={!showPassword}
                        maxLength={4}
                    />
                    <TouchableWithoutFeedback onPress={togglePasswordVisibility}>
                        <View style={styles.eyeButton}>
                            {/* You can use your eye icon here */}
                            {showPassword ? (
                                <MaterialCommunityIcons name="eye" size={20} color="#000" />
                            ) : (
                                <MaterialCommunityIcons name="eye-off" size={20} color="#000" />
                            )}
                        </View>
                    </TouchableWithoutFeedback>
                </View>
                <DropDownPicker
                    placeholder='Select Role'
                    style={styles.roleDropDown}
                    dropDownContainerStyle={styles.roleDropDown}
                    open={open}
                    value={value}
                    items={appRole}
                    setOpen={(val) => {
                        Keyboard.dismiss();
                        setOpen(val)
                    }
                    }
                    setValue={setValue}
                    setItems={appRole}
                />
                {value === APP_ROLE.SUPERVISOR && <TextInput
                    style={styles.input}
                    placeholder="Existing Bank Amount"
                    onChangeText={text => setAmount(removeLeadingZero(text))}
                    keyboardType="numeric"
                    value={amount}
                />}
                {error && <Text style={styles.errorText}>{error}</Text>}
                <Button
                    onPress={handleRegister}
                    btnLabel="Register"
                    buttonStyle={styles.loginButton}
                    textStyle={styles.buttonText}
                ></Button>
            </View>
        </ImageBackground>
    );
};

const styles = StyleSheet.create({
    background: {
        flex: 1,
        resizeMode: 'cover',
        justifyContent: 'center',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white
        padding: 20,
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    input: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 10,
        marginBottom: 20,
        backgroundColor: '#fff',
    },
    passwordContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 10,
        marginBottom: 20,
        backgroundColor: '#fff',
    },
    passwordInput: {
        flex: 1,
        paddingRight: 30,  // Space for the eye icon
    },
    eyeButton: {
        position: 'absolute',
        right: 10,
        padding: 10,
    },
    roleDropDown: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        marginBottom: 20,
    },
    loginButton: {
        width: '50%',
        backgroundColor: Colors.secondary,
        borderRadius: 5,
        paddingVertical: 15,
        paddingHorizontal: 30,
        marginTop: 10,
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
    },
});

export default AddUserScreen;
