import { View, StyleSheet, FlatList, TouchableOpacity, Text } from 'react-native';
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import TransactionList from '../components/TransactionList';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import useDataStore from '../stores/dataStore';
import DropDownPicker from 'react-native-dropdown-picker';
import { Colors } from '../constants/theme';
import ShowAlert from '../modules/AlertModule';
import Loader from '../components/Loader';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { isEmpty } from '../utils/commonUtils';
import { parseDate } from '../utils/dateUtil';
import { makeApiRequest } from '../utils/apiUtil';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const OtherIncome = () => {
    const navigation = useNavigation();
    const [API_CONFIG, userData, setToastMessage, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => ([state.API_CONFIG, state.userData, state.setToastMessage, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]));
    const [dataKey, setDataKey] = useState(null);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [incomeAccountList, setIncomeAccountList] = useState([]);
    const [incomeAccountsData, setIncomeAccountsData] = useState(null);
    const [isFocus, setIsFocus] = useState('all');
    const [transactions, setTransactions] = useState(null);
    const [transactionData, setTransactionData] = useState(null);
    const [loading, setLoading] = useState(true);

    const isFocused = useIsFocused();
    let prevDate = null;

    useLayoutEffect(() => {
        navigation.setOptions({
            title: "Other Income"
        });
    });

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.OTHER_INCOME_HISTORY_CLICKED, userData);
            fetchData("all");
            setIsFocus("all");
            setOpen(false);
        }
    }, [isFocused]);

    useEffect(() => {
        if (transactionData) {
            let filterData = transactionData;
            if (userData.role === APP_ROLE.SUPERVISOR) {
                filterData = Object.values(transactionData).filter(node => node.actionBy === userData.username);
            }
            setDataKey(Object.keys(sortDateLatest(filterData)))
            setTransactions(sortDateLatest(filterData));
            setLoading(false);
        }
    }, [transactionData]);

    useEffect(() => {
        if (value) {
            fetchData(value);
        }
    }, [value]);

    const handleDelete = (key) => {
        ShowAlert("Delete", "Are you sure you want to delete this transaction?", "YesNo", () => {
            console.log('OK Pressed');
        }, async () => {
            console.log('Yes Pressed');
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.OTHER_INCOME_DELETE_TRANSACTION, userData);
            try {
                await makeApiRequest('delete', `${API_CONFIG.TRANSACTIONS}/${key}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                setDataKey(dataKey.filter(item => item !== key))
                setToastMessage("Transaction deleted successfully");
            } catch (err) {
                console.log("Transaction delete err: ", err)
                handleAlertForError(err);
            }
        }, () => {
            console.log('No Pressed');
        }
        );
    }

    const handleAllTransactions = () => {
        setValue(true);
        fetchData("all");
    };

    const sortDateLatest = (data) => {
        let dataArray = Object.values(data);
        dataArray.sort(function (a, b) {
            // Convert dates to Date objects for comparison
            var dateA = parseDate(a.date);
            var dateB = parseDate(b.date);

            // Compare dates
            return dateB - dateA;
        });

        const sortedDataTData = {};
        dataArray.forEach(item => {
            sortedDataTData[item.id] = item;
        });
        return sortedDataTData;
    };

    function reverseTransactionsByDate(data) {
        const groupedData = {};
        for (const key in data) {
            const date = data[key].date;
            if (!groupedData[date]) {
                groupedData[date] = [];
            }
            groupedData[date].push(key);
        }
        for (const date in groupedData) {
            groupedData[date].reverse();
        }
        const reversedData = {};
        for (const date in groupedData) {
            groupedData[date].forEach(key => {
                reversedData[key] = data[key];
            });
        }
        return reversedData;
    }

    const fetchData = async (selectAccount) => {
        try {
            setLoading(true);
            const accountResponse = await makeApiRequest('get', API_CONFIG.OTHER_INCOME, authToken, refreshToken, setAuthToken, setRefreshToken);
            const transactionResponse = await makeApiRequest('get', `${API_CONFIG.TRANSACTIONS}/income`, authToken, refreshToken, setAuthToken, setRefreshToken);
            if (!isEmpty(accountResponse)) {
                setIncomeAccountsData(accountResponse);
                const dataArray = Object.entries(accountResponse).map(([id, label]) => ({ label, value: id }));
                setIncomeAccountList(dataArray);
                if (selectAccount === "all") {
                    setTransactionData(reverseTransactionsByDate(transactionResponse));
                }
                else if (value !== true) {
                    let filterdata = [];
                    Object.entries(reverseTransactionsByDate(transactionResponse)).forEach(([transactionId, transactionData]) => {
                        let accountTypeId = transactionData.incomeType;
                        if (value === accountTypeId) {
                            filterdata.push(transactionData);
                        }
                    });
                    setTransactionData(filterdata);
                }
                else {
                    setTransactionData(reverseTransactionsByDate(transactionResponse));
                }
            }
            else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching Other income:', error);
            handleAlertForError(error);
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.dropdownContainer}>
                <DropDownPicker
                    placeholder='Select Income Account'
                    style={[styles.dropdownPicker, isFocus === "picker" && { borderColor: Colors.primary, borderWidth: 2 }]}
                    dropDownContainerStyle={[styles.dropdownPicker, isFocus && { borderColor: Colors.primary, borderWidth: 2 }]}
                    open={open}
                    value={value}
                    items={incomeAccountList}
                    setOpen={(val) => {
                        setOpen(val);
                    }}
                    setValue={setValue}
                    setItems={incomeAccountList}
                    onOpen={() => setIsFocus("picker")}
                    onClose={() => setIsFocus("picker")} />
            </View>
            <TouchableOpacity style={styles.allButton} onPress={() => {
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.OTHER_INCOME_TRANSACTION_ALL, userData);
                setIsFocus("all");
                handleAllTransactions();
                setOpen(false);
            }}>
                <View style={styles.buttonView}>
                    <Text style={[styles.allButtonText, isFocus === "all" && styles.focusedButton]}>All</Text>
                </View>
            </TouchableOpacity>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) :
                dataKey?.length > 0 && transactions && incomeAccountsData !== null ?
                    <FlatList
                        data={dataKey}
                        style={styles.flatListStyle}
                        onScrollBeginDrag={() => { if (open) setOpen(false) }}
                        renderItem={({ item: key }) => {
                            let jsx = <>
                                {prevDate !== transactions[key]?.date && <View style={styles.dateView}><Text style={styles.textDate}>{transactions[key]?.date}</Text></View>}
                                <View style={styles.rowContainer}>
                                    <View style={{
                                        width: '100%'
                                    }}>
                                        <TransactionList
                                            key={key}
                                            comment={incomeAccountsData[transactions[key].incomeType]}
                                            commentFactoryExpense={transactions[key].comment}
                                            date={transactions[key].date}
                                            time={transactions[key].time}
                                            amount={transactions[key].amount}
                                            user={transactions[key].actionBy}
                                            id={transactions[key].id}
                                            transactionId={key}
                                            accountType={transactions[key].incomeType}
                                            type={"Other Income"}
                                            allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]}
                                        />
                                    </View>
                                    {userData.username === transactions[key]?.actionBy && <TouchableOpacity style={styles.trigger} onPress={() => handleDelete(key)}>
                                        <MaterialCommunityIcons name="delete" size={28} color="#000" />
                                    </TouchableOpacity>}
                                </View>
                            </>
                            prevDate = transactions[key].date;
                            return (jsx);
                        }}
                        keyExtractor={(item) => item}
                    /> : <View style={styles.centeredView}>
                        <Text style={styles.noTransaction}>No Transactions Found</Text>
                    </View>}
        </View>
    );
}

const styles = StyleSheet.create({
    rowContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '90%',
        marginVertical: 5
    },
    container: {
        flex: 1,
        alignItems: 'center'
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    dropdownContainer: {
        width: '90%',
        paddingVertical: 10,
        flexDirection: 'row',
        zIndex: 100
    },
    dropdownPicker: {
        width: '60%',
        position: 'absolute',
    },
    buttonView: {
        border: 1,
    },
    allButton: {
        width: '35%',
        position: 'absolute',
        left: '65%',
        paddingVertical: 9,
        paddingRight: 10,
    },
    focusedButton: {
        borderColor: Colors.primary,
        borderWidth: 2
    },
    allButtonText: {
        fontSize: 14,
        padding: 14,
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 10,
        backgroundColor: 'white',
    },
    dateView: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textDate: {
        backgroundColor: 'white',
        textAlign: 'center',
        width: "30%",
        borderRadius: 5,
    },
    flatListStyle: {
        marginTop: '45px',
        width: '100%',
        height: '100%',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noTransaction: {
        textAlign: 'center',
    },
});

export default OtherIncome