import { APP_ROLE } from "./Constant";

export default [
    {
        name: 'Home',
        iconType: 'Feather',
        iconName: 'home',
        allowedRole: [APP_ROLE.OWNER, APP_ROLE.SUPERVISOR, APP_ROLE.VIEWER]
    },
    {
        name: 'Day Transactions',
        iconType: 'Material',
        iconName: 'content-paste',
        allowedRole: [APP_ROLE.OWNER, APP_ROLE.VIEWER]
    },
    {
        name: 'Transactions History',
        iconName: 'list-alt',
        allowedRole: [APP_ROLE.OWNER, APP_ROLE.SUPERVISOR, APP_ROLE.VIEWER]
    },
    {
        name: 'Production',
        iconType: 'Material',
        iconName: 'wall',
        allowedRole: [APP_ROLE.OWNER, APP_ROLE.SUPERVISOR, APP_ROLE.VIEWER]
    },
    {
        name: 'Stock Management',
        iconName: 'luggage-cart',
        allowedRole: [APP_ROLE.OWNER, APP_ROLE.VIEWER, APP_ROLE.SUPERVISOR]
    },
    {
        name: 'Other Income',
        iconType: 'Material',
        iconName: 'transfer-down',
        allowedRole: [APP_ROLE.OWNER, APP_ROLE.VIEWER]
    },
    {
        name: 'Closed Sites',
        iconType: 'Material',
        iconName: 'sticker-check-outline',
        allowedRole: [APP_ROLE.OWNER, APP_ROLE.VIEWER]
    },
    {
        name: 'Quotation',
        iconType: 'Material',
        iconName: 'file-document',
        allowedRole: [APP_ROLE.OWNER]
    },
    {
        name: 'Profile',
        iconType: 'Feather',
        iconName: 'user',
        allowedRole: [APP_ROLE.OWNER, APP_ROLE.SUPERVISOR, APP_ROLE.VIEWER]
    },
    {
        name: 'Add Users',
        iconType: 'Feather',
        iconName: 'user-plus',
        allowedRole: [APP_ROLE.OWNER]
    },
    {
        name: 'Change PIN',
        iconType: 'Feather',
        iconName: 'settings',
        allowedRole: [APP_ROLE.OWNER, APP_ROLE.SUPERVISOR, APP_ROLE.VIEWER]
    }
]