import mixpanel from "mixpanel-browser";
import { MIXPANEL_PROJECT_TOKEN } from '@env';

const initMixPanelTracker = () => {
    let initialized = false;

    const initializeAnalytics = (init) => {
        if (init) {
            const trackAutomaticEvents = false;
            mixpanel.init(MIXPANEL_PROJECT_TOKEN, trackAutomaticEvents);
            initialized = true;
        }
    };
    const setUserIdentity = (userIdentity) => {
        mixpanel?.identify(userIdentity);
    };

    const resetUserIdentity = () => {
        mixpanel?.reset();
    };

    const sendAnalyticsEvent = (eventAction, userData = {}, message = "") => {
        mixpanel?.track(eventAction, {
            user: userData.username,
            role: userData.role,
            message: message,
        });
    };

    return {
        sendAnalyticsEvent,
        initializeAnalytics,
        setUserIdentity,
        resetUserIdentity,
    };
};

const MixpanelTracker = initMixPanelTracker();
export default MixpanelTracker;