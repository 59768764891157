// themes.js

export const Colors = {
    primary: '#2200AB', //blue
    secondary: '#406e48', //green
    accent: '#ff0000', //red
    blue: '#007bff',
    gray: '#808080',
    black: '#000',
};

export const FontSizes = {
    small: 12,
    medium: 16,
    large: 20,
};
