import { View, StyleSheet, TouchableOpacity, FlatList, Text, Modal, TextInput } from 'react-native';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';
import useDataStore from '../stores/dataStore';
import { makeApiRequest } from '../utils/apiUtil';
import Search from '../components/Search';
import Loader from '../components/Loader';
import { handleAlertForError } from '../utils/handleError';
import List from '../components/List';
import Button from '../components/Button';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import { Colors } from '../constants/theme';
import MixpanelTracker from '../utils/MixpanelTracker';

const FactoryExpenseScreen = () => {
    const navigation = useNavigation();
    const [accountKey, setAccountKey] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [account, setAccount] = useState('');
    const [errorModal, setErrorModal] = useState(null);
    const [API_CONFIG, userData, authToken, setAuthToken, refreshToken, setRefreshToken] = useDataStore((state) => ([state.API_CONFIG, state.userData, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken]));
    const [searchQuery, setSearchQuery] = useState('');
    const isFocused = useIsFocused();

    useLayoutEffect(() => {
        navigation.setOptions({
            title: "Factory Accounts"
        });
    });

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.FACTORY_ACCOUNTS_CLICKED, userData);
            getAllAccounts();
        }
    }, [isFocused]);

    const getAllAccounts = async () => {
        try {
            const accountResponse = await makeApiRequest('get', API_CONFIG.ACCOUNTS, authToken, refreshToken, setAuthToken, setRefreshToken);
            const dataArray = Object.entries(accountResponse).map(([key, value]) => ({
                id: key,
                name: value
            }));
            setAccountKey(dataArray);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleAlertForError(error);
        }
    }

    const handleIconPress = () => {
        console.log("Jay in click: ", navigation)
        navigation.navigate('FactoryExpenses');
    };

    const handleAddAccount = () => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.FACTORY_EXPENSE_ADD_ACCOUNT_CLICKED, userData);
        setErrorModal('');
        setPopupVisible(true);
    };

    const handleSubmit = () => {
        let isErr = false;
        for (let i = 0; i < accountKey?.length; i++) {
            if (account.toLowerCase().trim() === accountKey[i]?.name.toLowerCase()) {
                isErr = true;
            }
        }
        if (isErr) setErrorModal("Account Already Exist")
        else {
            if (!account) {
                setErrorModal('Please enter account name.');
                return false;
            }
            else {
                addAcountToDB();
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.FACTORY_EXPENSE_ADD_ACCOUNT, userData);
                setPopupVisible(false);
            }
            setErrorModal('');
        }
    };

    const addAcountToDB = async () => {
        try {
            if (account) {
                await makeApiRequest("post", `${API_CONFIG.ACCOUNTS}/${account.trim()}`, authToken, refreshToken, setAuthToken, setRefreshToken)
                getAllAccounts();
                setAccount('');
            } else {
                setErrorModal('Enter account name');
            }
        } catch (error) {
            handleAlertForError(error);
        }
    }

    const filteredAccounts = accountKey?.filter(item => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
    });

    return (
        <View style={styles.container}>
            <View style={styles.searchContainer}>
                <Search searchStyle={styles.searchFactoryExp} value={searchQuery} onChangeText={setSearchQuery} placeholder="Search Account" />
                <TouchableOpacity style={styles.headerRight} onPress={handleIconPress}>
                    <MaterialIcons name="history" size={24} color="black" />
                </TouchableOpacity>
            </View>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) : filteredAccounts?.length > 0 ? (
                <FlatList
                    data={filteredAccounts}
                    renderItem={({ item }) => (
                        <List
                            name={item.name}
                            type={'Accounts'}
                            accountId={item.id}
                        />
                    )}
                    style={{ height: '100%', marginBottom: 85 }}
                    keyExtractor={item => item.id}
                />
            ) : (
                <View style={styles.noAccContainer}>
                    {searchQuery ? <Text>No Accounts Found</Text> : <Text style={styles.noAccText}> No Accounts Added</Text>}
                </View>
            )}
            <Modal
                animationType="slide"
                transparent={true}
                visible={isPopupVisible}
                onRequestClose={() => setPopupVisible(false)}>
                {/* <TouchableWithoutFeedback onPress={() => setPopupVisible(false)}> */}
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <TextInput
                            style={styles.textInput}
                            onChangeText={(text) => setAccount(text)}
                            placeholder="Account"
                            value={account} />
                        {errorModal && <Text style={styles.errorText}>{errorModal}</Text>}
                        <Button
                            onPress={handleSubmit}
                            buttonStyle={styles.submitButton}
                            btnLabel={"Submit"} />
                    </View>
                </View>
                {/* </TouchableWithoutFeedback> */}
            </Modal>
            <Button
                onPress={handleAddAccount}
                btnLabel={"ADD ACCOUNT"}
                buttonStyle={styles.btnContainer}
                allowedRole={[APP_ROLE.OWNER]}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    searchContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    searchFactoryExp: {
        width: '95%',
    },
    headerRight: {
        paddingRight: 20,
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noAccContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noAccText: {
        textAlign: 'center',
        fontSize: 15,
    },
    btnContainer: {
        backgroundColor: Colors.blue,
        paddingVertical: 20,
        paddingHorizontal: 40,
        width: '90%',
        position: 'absolute',
        bottom: 0,
        margin: 20,
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
    },
    textInput: {
        borderWidth: 2,
        borderColor: '#636363',
        margin: 10,
        padding: 12,
        fontSize: 16,
        borderRadius: 6,
        width: '90%'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        width: '80%',
        alignItems: 'center',
    },
    submitButton: {
        backgroundColor: Colors.primary,
        width: '90%',
    },
});

export default FactoryExpenseScreen;