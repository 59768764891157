import { View, Text, StyleSheet, TouchableOpacity, FlatList } from 'react-native'
import React, { useState, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native';
import TransactionList from '../components/TransactionList';
import { useIsFocused } from '@react-navigation/native';
import Loader from '../components/Loader';
import useDataStore from '../stores/dataStore';
import Button from '../components/Button';
import { APP_ROLE, EVENT_ACTIONS, USER_TYPE } from '../constants/Constant';
import OverflowMenu from '../components/OverflowMenu';
import ShowAlert from '../modules/AlertModule';
import DropDownPicker from 'react-native-dropdown-picker';
import { Colors } from '../constants/theme';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { convertToDateTime, parseDate } from '../utils/dateUtil';
import { isEmpty } from '../utils/commonUtils';
import { formatDate } from '../utils/dateUtil';
import { makeApiRequest } from '../utils/apiUtil';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function TransactionScreen({ route }) {
    const navigation = useNavigation();
    let { name, type, userID, contact, actionBy, plantName } = route.params;
    const [transactions, setTransactions] = useState(null);
    const [dataKey, setDataKey] = useState(null);
    const [transactionData, setTransactionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalAmount, setTotalAmount] = useState(0);
    const [absoluteAmount, setAbsoluteAmount] = useState(0);
    const [siteNames, setSiteNames] = useState([]);
    const isFocused = useIsFocused();
    // const dropdownRef = useRef(null);
    const [userData, API_CONFIG, setToastMessage, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => [state.userData, state.API_CONFIG, state.setToastMessage, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]);
    let prevDate = null;
    let responseData;
    let siteName = [];
    let empData = [];
    const newType = type.slice(0, -1);
    const [menuOptions, setMenuOptions] = useState([
        { label: 'Download PDF', value: '1' },
        { label: 'View Details', value: '2' },
        { label: 'Edit Details', value: '3' },
        { label: `Delete ${newType}`, value: '4' },
    ]);
    const pendingAmount = totalAmount - absoluteAmount;
    const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [isFocus, setIsFocus] = useState(null);
    let financialYears;
    let extraExpenseTotalAmount = 0;
    let vehicles = [];
    let dates = [];
    let loadingActionsBy = [];
    let loadingAmounts = [];
    let thekedarProductionData = {};

    const handleSelect = (option) => {
        setOpen(false);
        switch (option.value) {
            case '1':
                handleDownloadReport();
                break;
            case '2':
                handleViewDetail();
                break;
            case '3':
                handleEditButton();
                break;
            case '4':
                handleDeleteButton();
                break;
            case '5':
                type === "Employees" ? handleExtraExpense() : handleProductionWork();
                break;
        }
    };

    // Function to generate financial years dynamically
    const generateFinancialYears = () => {
        const currentDate = new Date();
        let endYear = currentDate.getFullYear();
        let startYear = 2024;

        // Determine start and end years based on current date
        if (currentDate.getMonth() >= 10) {
            startYear = startYear; // If current month is November or later, start year is current year
            endYear = endYear + 1; // End year is next year
        } else {
            startYear = startYear - 1; // If current month is before November, start year is previous year
            endYear = endYear; // End year is current year
        }

        const years = [];

        // Loop to generate financial years
        for (let i = startYear; i < endYear; i++) {
            years.push({
                label: `NOV ${i} - OCT ${i + 1}`,
                value: `${i}-${i + 1}`
            });
        }
        return years;
    };

    const sortDateLatest = (data) => {
        let dataArray = Object.values(data);
        dataArray.sort(function (a, b) {
            // Convert dates to Date objects for comparison
            var dateA = parseDate(a.date);
            var dateB = parseDate(b.date);

            // Compare dates
            return dateB - dateA;
        });

        const sortedDataTData = {};
        dataArray.forEach(item => {
            sortedDataTData[item.id] = item;
        });
        return sortedDataTData;
    };

    const goToOtherScreen = () => {
        navigation.navigate('AddTransactionScreen', { userID: userID, type: type, absoluteAmount, pendingAmount, plantName });
        if (type === "Employees") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_YOU_GAVE_SCREEN, userData);
        else if (type === "Suppliers") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SUPPLIER_YOU_GAVE_SCREEN, userData);
        else if (type === "Thekedars") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_YOU_GAVE_SCREEN, userData);
    };
    const handleDownloadReport = () => {
        navigation.navigate('ReportScreen', { name, type, contact, userID, pendingAmount, empData, siteName, extraExpenseTotalAmount, vehicles, dates, loadingActionsBy, loadingAmounts, thekedarProductionData });
        if (type === "Employees") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_DOWNLOAD_PDF, userData);
        else if (type === "Suppliers") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SUPPLIER_DOWNLOAD_PDF, userData);
        else if (type === 'Thekedars') MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_DOWNLOAD_PDF, userData);
    };
    const handleViewDetail = () => {
        navigation.navigate('InfoScreen', { userID, contact, actionBy, name, type, empData, siteName, extraExpenseTotalAmount });
        if (type === "Employees") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_VIEW_DETAILS, userData);
        else if (type === "Suppliers") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SUPPLIER_VIEW_DETAILS, userData);
        else if (type === 'Thekedars') MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_VIEW_DETAIL, userData);
    };
    const handleEditButton = () => {
        navigation.navigate('AddDetail', { existinguserID: userID, existingName: name, existingContact: contact, existingType: type.toLowerCase() === USER_TYPE.EMPLOYEES ? 0 : type.toLowerCase() === USER_TYPE.THEKEDARS ? 3 : 1, screen: "Production", plantName });
        if (type === "Employees") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_EDIT_DETAILS, userData);
        else if (type === "Suppliers") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SUPPLIER_EDIT_DETAILS, userData);
        else if (type === 'Thekedars') MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_EDIT_DETAIL, userData);
    };
    const handleDeleteButton = () => {
        ShowAlert("Delete", `Are you sure you want to delete this ${newType}?`, "YesNo", () => {
            console.log('OK Pressed');
        }, async () => {
            console.log('Yes Pressed');
            try {
                let url;
                let lowerType = type.toLowerCase();
                switch (lowerType) {
                    case USER_TYPE.EMPLOYEES:
                        url = `${API_CONFIG.EMPLOYEES}/${userID}`;
                        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_DELETE, userData);
                        break;
                    case USER_TYPE.SUPPLIERS:
                        url = `${API_CONFIG.SUPPLIERS}/${userID}`;
                        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SUPPLIER_DELETE, userData);
                        break;
                    case USER_TYPE.CLIENTS:
                        url = `${API_CONFIG.CLIENTS}/${userID}`;
                        break;
                    case USER_TYPE.THEKEDARS:
                        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_DELETE_THEKEDAR, userData);
                        url = `${API_CONFIG.THEKEDARS}/${plantName}/${userID}`;
                        break;
                }
                await makeApiRequest("put", url, authToken, refreshToken, setAuthToken, setRefreshToken, {
                    isDeleted: true
                });
                setToastMessage(`${newType} deleted successfully`)
                navigation.goBack();
            } catch (err) {
                console.log("delete err: ", err)
                handleAlertForError(err);
            }
        }, () => {
            console.log('No Pressed');
        }
        );
    };

    const handleExtraExpense = () => {
        navigation.navigate("ExtraExpenseScreen", { name, userID });
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_ADD_EXTRA_EXPENSE, userData);
    };

    const handleProductionWork = () => {
        navigation.navigate('ProductionWorkScreen', { name, userID, plantName })
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_PRODUCTION_WORK, userData);
    };

    useEffect(() => {
        if (isFocused) {
            navigation.setOptions({
                title: newType,
            });
            if (value) {
                fetchData(value)
            }
        }
    }, [isFocused, value]);

    useEffect(() => {
        if (isFocused) {
            if (type === "Employees" && menuOptions.length === 4) {
                const updatedOptions = [...menuOptions, { label: 'Extra Expense', value: '5' }];
                setMenuOptions(updatedOptions);
            } else if (type === "Thekedars" && menuOptions.length === 4) {
                const updatedOptions = [...menuOptions, { label: 'Production History', value: '5' }];
                setMenuOptions(updatedOptions);
            }
        }
    }, [isFocused]);

    financialYears = generateFinancialYears();

    const getData = async () => {
        let extraExpense = await makeApiRequest('get', `${API_CONFIG.UNSETTLED_EXPENSES}/extraExpenses/${userID}`, authToken, refreshToken, setAuthToken, setRefreshToken);
        return extraExpense;
    }

    const fetchExtraExpense = async () => {
        let extraExpenseData = await getData();
        if (extraExpenseData) {
            Object.values(extraExpenseData).forEach((expense) => {
                vehicles.push(expense.vehicle || expense.comment);
                dates.push(expense.date);
                loadingActionsBy.push(expense.actionBy);
                loadingAmounts.push(expense.amount);
                extraExpenseTotalAmount += parseFloat(expense.amount);
            });
        }
        return extraExpenseTotalAmount;
    };


    const fetchEmpData = async () => {
        try {
            const responseEmpData = await makeApiRequest('get', API_CONFIG.SITE_DETAILS, authToken, refreshToken, setAuthToken, setRefreshToken);
            const respEmpData = await makeApiRequest('get', `${API_CONFIG.EMPLOYEES}/${userID}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            if (respEmpData) {
                setAbsoluteAmount(respEmpData.absolute_amount);
            }
            let total = 0;
            Object.values(responseEmpData).forEach(site => {
                Object.values(site).forEach(entry => {
                    if (entry?.employeeList) {
                        for (var i = 0; i < entry?.employeeList.length; i++) {
                            if (entry?.employeeList[i].id.includes(userID)) {
                                siteName.push(entry.siteName);
                                empData.push(entry.employeeList[i]);
                                if (entry.employeeList[i].work) {
                                    total += entry.employeeList[i].work.reduce((acc, curr) => {
                                        const price = parseFloat(curr.price) || 0;
                                        const sqFeet = parseFloat(curr.sqFeet) || 0;
                                        return acc + (price * sqFeet);
                                    }, 0);
                                }
                            }
                        }
                    }
                });
            });

            if (type === 'Employees') {
                const extraExpense = await fetchExtraExpense();
                total += extraExpense;
            }
            setSiteNames(siteName);
            setTotalAmount(total);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching Data:', error);
            handleAlertForError(error);
        }
    };

    const fetchProductionData = async () => {
        try {
            const productionExpenseData = await makeApiRequest('get', `${API_CONFIG.PLANT}/${plantName}/${userID}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            const thekedarResponse = await makeApiRequest('get', `${API_CONFIG.THEKEDARS}/getThekedar/${plantName}/${userID}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            if (thekedarResponse) {
                setAbsoluteAmount(thekedarResponse.absolute_amount);
            }
            thekedarProductionData = productionExpenseData;
            let total = 0;
            Object.values(productionExpenseData).forEach(expense => {
                total += parseFloat(expense.amount);
            });
            setTotalAmount(total);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching Production Expense:', error);
            handleAlertForError(error);
        }
    };

    const fetchData = async (selectedFinancialYear) => {
        try {
            setLoading(true);
            let lowerType = type.toLowerCase();
            if (type === "Employees") fetchEmpData();
            else if (type === "Thekedars") fetchProductionData();
            responseData = await makeApiRequest('get', `${API_CONFIG.TRANSACTIONS}/${lowerType}`, authToken, refreshToken, setAuthToken, setRefreshToken);

            let url;
            if (type === 'Suppliers') url = API_CONFIG.SUPPLIERS;
            else if (type === 'Thekedars') url = `${API_CONFIG.THEKEDARS}/${plantName}`;
            else url = API_CONFIG.EMPLOYEES;

            const responseType = await makeApiRequest('get', url, authToken, refreshToken, setAuthToken, setRefreshToken);
            name = responseType[userID]?.name;
            contact = responseType[userID]?.contact;
            navigation.setOptions({
                title: `${newType} | ${name}`,
                headerRight: () => (
                    <View style={styles.headerRight}>
                        <OverflowMenu
                            options={menuOptions}
                            onSelect={handleSelect}
                            allowedRole={[APP_ROLE.OWNER]}
                        />
                    </View>
                ),
            });
            if (!isEmpty(responseData)) {
                let filterdata = responseData[userID];
                if (userData.role === APP_ROLE.SUPERVISOR) {
                    filterdata = Object.fromEntries(
                        Object.entries(responseData[userID]).filter(([key, value]) => value.actionBy === userData.username),
                    );
                    setTransactionData(filterdata);
                }
                if (selectedFinancialYear === true) {
                    setTransactionData(reverseTransactionsByDate(filterdata));
                }
                if (filterdata && selectedFinancialYear && selectedFinancialYear !== true) {
                    if (selectedFinancialYear === "all") {
                        setTransactionData(reverseTransactionsByDate(filterdata));
                    }
                    else {
                        const { startDate, endDate } = getFinancialYearDates(selectedFinancialYear);
                        filterdata = sortDataOnDate(filterdata, formatDate(startDate), formatDate(endDate));
                        setTransactionData(filterdata);
                    }
                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching employees:', error);
            handleAlertForError(error);
        }
    };

    useEffect(() => {
        if (transactionData) {
            setDataKey(Object.keys(sortDateLatest(transactionData)))
            setTransactions(sortDateLatest(transactionData));
            setLoading(false);
        }
    }, [userID, transactionData, type]);

    useEffect(() => {
        if (!value) { // Only set an initial value if one hasn't been selected yet
            setValue(financialYears.length > 0 ? financialYears[0].value : undefined);
        }
    }, [financialYears]);

    const handleDelete = (key) => {
        let lowerType = type.toLowerCase();
        ShowAlert("Delete", "Are you sure you want to delete this transaction?", "YesNo", () => {
            console.log('OK Pressed');
        }, async () => {
            console.log('Yes Pressed');
            try {
                let newUrl = null;
                if (type === "Thekedars") newUrl = `${API_CONFIG.TRANSACTIONS}/${lowerType}/${userID}/${key}/${userData.username.toLowerCase()}/${plantName}`;
                else newUrl = `${API_CONFIG.TRANSACTIONS}/${lowerType}/${userID}/${key}/${userData.username.toLowerCase()}`;
                await makeApiRequest('delete', newUrl, authToken, refreshToken, setAuthToken, setRefreshToken);
                setDataKey(dataKey.filter(item => item !== key))

                if (type === "Employees") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_DELETE_TRANSACTION, userData);
                else if (type === "Suppliers") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SUPPLIER_DELETE_TRANSACTION, userData);
                else if (type === "Thekedars") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_DELETE_TRANSACTION, userData);

                setToastMessage("Transaction deleted successfully");
                fetchData(value);
            } catch (err) {
                console.log("Transaction delete err: ", err);
                handleAlertForError(err);
            }
        }, () => {
            console.log('No Pressed');
        }
        );
    }

    function reverseTransactionsByDate(data) {
        const groupedData = {};
        for (const key in data) {
            const date = data[key].date;
            if (!groupedData[date]) {
                groupedData[date] = [];
            }
            groupedData[date].push(key);
        }

        for (const date in groupedData) {
            groupedData[date].reverse();
        }

        const reversedData = {};
        for (const date in groupedData) {
            groupedData[date].forEach(key => {
                reversedData[key] = data[key];
            });
        }
        return reversedData;
    }

    const sortDataOnDate = (data, startDateTime, endDateTime) => {
        const sortedData = {};
        const newStartDateTime = convertToDateTime(startDateTime);
        const newEndDateTime = convertToDateTime(endDateTime);

        const reversedData = reverseTransactionsByDate(data);
        for (const [key, value] of Object.entries(reversedData)) {
            const transactionDateTime = convertToDateTime(reversedData[key]["date"]);
            if (transactionDateTime >= newStartDateTime && transactionDateTime <= newEndDateTime) {
                sortedData[key] = reversedData[key];
            }
        }
        return sortedData;
    }

    const getFinancialYearDates = (financialYear) => {
        if (financialYear !== true) {
            const [startYear, endYear] = financialYear?.split('-').map(Number);

            const startDate = new Date(startYear, 10, 1); // November is month 10 (zero-based index)
            const endDate = new Date(endYear, 9, 31); // October is month 9 (zero-based index)

            return { startDate, endDate };
        }
    };

    const handleAllTransactions = () => {
        setValue(true);
        fetchData("all");
    };

    const handleOutsidePress = () => {
        if (open) {
            setOpen(false);
        }
    };

    const goToProductionScreen = () => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_PRODCUTION_SCREEN, userData)
        navigation.navigate("AddProductionScreen", { name, userID, plantName });
    }

    return (
        <View style={styles.container}>
            <View style={styles.dropdownContainer}>
                <DropDownPicker
                    // ref={dropdownRef}
                    placeholder='Select Financial Year'
                    style={[styles.dropdownPicker, isFocus === "picker" && { borderColor: Colors.primary, borderWidth: 2 }]}
                    dropDownContainerStyle={[styles.pickerContainer, isFocus && { borderColor: Colors.primary, borderWidth: 2 }]}
                    open={open}
                    value={value}
                    items={financialYears}
                    setOpen={(val) => {
                        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.TRANSACTION_HISTORY_DROPDOWN, userData);
                        setOpen(val)
                    }}
                    setValue={setValue}
                    setItems={financialYears}
                    onOpen={() => setIsFocus("picker")}
                    onClose={() => setIsFocus("picker")}
                />

            </View>
            <TouchableOpacity style={styles.allButton} onPress={() => {
                if (type === "Employees") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_TRANSACTION_ALL, userData);
                else if (type === "Suppliers") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SUPPLIER_TRANSACTION_ALL, userData);
                else if (type === "Thekedars") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_TRANSACTION_ALL, userData);
                setIsFocus("all");
                handleAllTransactions();
                setOpen(false)
            }}>
                <View style={styles.buttonView}>
                    <Text style={[styles.allButtonText, isFocus === "all" && styles.focusedButton]}>All</Text>
                </View>
            </TouchableOpacity>
            {(type.toLowerCase() === USER_TYPE.EMPLOYEES || type.toLowerCase() === USER_TYPE.THEKEDARS) && [APP_ROLE.OWNER, APP_ROLE.VIEWER].includes(userData.role) && <View style={styles.amountSection}>
                <View style={styles.leftSection}>
                    <Text style={styles.textStyle}>Total</Text>
                    <Text style={styles.textStyle}>{totalAmount}</Text>
                </View>
                <View style={styles.middleSection}>
                    <Text style={styles.textStyle}>Received</Text>
                    <Text style={styles.textStyle}>{absoluteAmount}</Text>
                </View>
                <View style={styles.rightSection}>
                    <Text style={styles.textStyle}>Pending</Text>
                    <Text style={styles.textStyle}>{pendingAmount}</Text>
                </View>
            </View>}
            <View style={styles.historyHeader}>
                <Text style={styles.entriesText}>ENTRIES</Text>
                <Text style={styles.youGaveText}>YOU GAVE</Text>
            </View>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) :
                dataKey?.length > 0 && transactions ? <FlatList
                    style={styles.flatListStyle}
                    data={dataKey}
                    onScrollBeginDrag={handleOutsidePress}
                    renderItem={({ item: key }) => {
                        let jsx = <>
                            {prevDate !== transactions[key].date && <View style={styles.dateView}><Text style={styles.textDate}>{transactions[key].date}</Text></View>}
                            <View style={styles.rowContainer}>
                                <View style={{
                                    width: '100%'
                                }}>
                                    {(userData.role === APP_ROLE.SUPERVISOR ? userData.username === transactions[key].actionBy : true) && <TransactionList
                                        key={key}
                                        comment={transactions[key].comment}
                                        date={transactions[key].date}
                                        time={transactions[key].time}
                                        amount={transactions[key].amount}
                                        user={transactions[key].actionBy}
                                        type={type}
                                        userId={userID}
                                        transactionId={key}
                                        allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]}
                                        plantName={plantName}
                                    />}
                                </View>
                                {userData.username === transactions[key].actionBy && <TouchableOpacity style={styles.trigger} onPress={() => handleDelete(key)}>
                                    <MaterialCommunityIcons name="delete" size={28} color="#000" />
                                </TouchableOpacity>}
                            </View>
                        </>
                        prevDate = transactions[key].date;
                        return (jsx);
                    }}
                    keyExtractor={(item) => item}
                /> : <View style={styles.centeredView}>
                    <Text style={styles.noTransaction}>No Transactions Found</Text>
                </View>}
            {type === 'Thekedars' ? <View style={styles.buttonRow}>
                <Button
                    onPress={goToOtherScreen}
                    btnLabel="You Gave"
                    buttonStyle={styles.gaveStyle}
                    textStyle={styles.btnText}
                    allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]} />
                <Button
                    onPress={goToProductionScreen}
                    btnLabel={'Add Producion'}
                    buttonStyle={styles.productStyle}
                    textStyle={styles.btnText}
                    allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]} />
            </View> :
                <Button
                    onPress={goToOtherScreen}
                    btnLabel="YOU GAVE"
                    buttonStyle={styles.BtnContainer}
                    textStyle={styles.btnText}
                    allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]} />}
        </View>
    );
}

const styles = StyleSheet.create({
    buttonRow: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        padding: 12,
        position: 'absolute',
        bottom: 0,
        backgroundColor: '#fff'
    },
    gaveStyle: {
        paddingVertical: 20,
        paddingHorizontal: 20,
        backgroundColor: Colors.accent,
        flex: 1,
        marginHorizontal: 5,
        alignItems: 'center'
    },
    productStyle: {
        paddingVertical: 20,
        paddingHorizontal: 20,
        backgroundColor: Colors.secondary,
        flex: 1,
        marginHorizontal: 5,
        alignItems: 'center'
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        marginVertical: 5
    },
    container: {
        flex: 1,
        textAlign: 'center',
        alignItems: 'center',
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    dropdownContainer: {
        height: '70px',
        width: '90%',
        paddingVertical: 10,
        flexDirection: 'row',
        zIndex: 100
    },
    dropdownPicker: {
        width: '60%',
        position: 'absolute',
    },
    pickerContainer: {
        width: '60%',
        position: 'absolute',
    },
    amountSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 16,
        alignItems: 'center',
        width: '100%',
        marginTop: 0,
        marginBottom: 2,
        paddingVertical: 0
    },
    leftSection: {
        width: "30%",
        borderRadius: 12,
        textAlign: "center",
        alignItems: 'center',
        backgroundColor: "#AFDBF5"
    },
    rightSection: {
        width: "30%",
        borderRadius: 12,
        textAlign: "center",
        alignItems: 'center',
        backgroundColor: "#FCACA5"
    },
    middleSection: {
        width: "30%",
        backgroundColor: "#93E2AE",
        borderRadius: 12,
        textAlign: "center",
        alignItems: 'center'
    },
    textStyle: {
        padding: 1,
        color: '#000',
        fontSize: 16,
        fontWeight: 'bold',
    },
    allButton: {
        width: '35%',
        position: 'absolute',
        left: '65%',
        paddingVertical: 10,
        paddingRight: 15,
        zIndex: 101
    },
    focusedButton: {
        borderColor: Colors.primary,
        borderWidth: 2
    },
    buttonView: {
        border: 1,
    },
    allButtonText: {
        fontSize: 16,
        padding: 14,
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 10,
        backgroundColor: 'white',
    },
    headerRight: {
        paddingRight: 14,
        paddingTop: 8
    },
    historyText: {
        color: '#9e9e9e'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noTransaction: {
        textAlign: 'center',
    },
    BtnContainer: {
        paddingVertical: 20,
        paddingHorizontal: 40,
        width: '90%',
        bottom: 10,
        position: 'absolute'
    },
    btnText: {
        color: 'white',
    },
    historyHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 16,
        alignItems: 'center',
        width: '100%',
        marginTop: 10
    },
    entriesText: {
        color: '#808080'
    },
    youGaveText: {
        color: '#808080'
    },
    dateView: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',

    },
    textDate: {
        backgroundColor: 'white',
        textAlign: 'center',
        width: "35%",
        borderRadius: 5,
    },
    flatListStyle: {
        width: '100%',
        height: '60vh',
        marginBottom: 76
    }
}
);