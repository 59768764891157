import axios from "axios";
import { handleAlertForError } from "./handleError";
import { refreshTokenAndRetry } from "./refreshTokenAndRetry";
import AsyncStorage from '@react-native-async-storage/async-storage';

const makeApiRequest = async (method, url, authToken, refreshToken, setAuthToken, setRefreshToken, payload = null) => {
    try {
        const config = {
            method: method,
            url: url,
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json'
            },
            data: payload
        };
        const response = await axios(config);
        return response?.data;
    } catch (error) {
        await AsyncStorage.setItem('makeAPI', 'api');
        return await handleApiError(error, refreshToken, setAuthToken, setRefreshToken, method, url, payload);
    }
};

const handleApiError = async (error, refreshToken, setAuthToken, setRefreshToken, method, url, payload) => {
    if (error.response && error.response.status === 401) {
        try {
            let data = await refreshTokenAndRetry(setAuthToken, refreshToken, setRefreshToken, async (newAuthToken, newRefreshToken) => {
                return await makeApiRequest(method, url, newAuthToken, newRefreshToken, setAuthToken, setRefreshToken, payload, newAuthToken);
            });
            return data;
        } catch (tokenRefreshError) {
            handleAlertForError(tokenRefreshError);
            throw tokenRefreshError;
        }
    } else {
        console.error('API request error:', error);
        handleAlertForError(error);
        throw error;
    }
};

export { makeApiRequest };
