import React, { useState, useLayoutEffect, useEffect } from "react";
import { View, Text, StyleSheet, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Colors } from '../constants/theme';
import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import useDataStore from "../stores/dataStore";
import Button from "../components/Button";
import ShowAlert from "../modules/AlertModule";
import { sendNotification } from "../modules/Notification";
import { USERS_API } from "../env-config/api-config";
import { handleAlertForError } from "../utils/handleError";
import MixpanelTracker from '../utils/MixpanelTracker';
import { EVENT_ACTIONS, USER_TYPE } from "../constants/Constant";
import { formatDate } from "../utils/dateUtil";
import { removeLeadingZero, sendWAMessage } from "../utils/commonUtils";
import { makeApiRequest } from "../utils/apiUtil";
import DateTimePicker from 'react-native-ui-datepicker';
import Popup from "../components/popup";

const AddTransactionScreen = ({ route }) => {

    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
    const [amount, setAmount] = useState('');
    const [isFocus, setIsFocus] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [supplierData, employeeData, clientsData, userData, API_CONFIG, setToastMessage, thekedarData, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => ([state.supplierData, state.employeeData, state.clientsData, state.userData, state.API_CONFIG, state.setToastMessage, state.thekedarData, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]));
    const [comment, setComment] = useState('');

    let { userID, type, clientID, siteID, existingAmount, siteName, transactionId, existingComment, pendingAmountRoute, absoluteAmount, pendingAmount, plantName } = route.params;
    useEffect(() => {
        if (existingAmount && existingComment) setAmount(existingAmount); setComment(existingComment);
    }, [existingAmount, existingComment])
    const navigation = useNavigation();

    useEffect(() => {
        if (absoluteAmount + parseFloat(amount) > absoluteAmount + pendingAmount && (type.toLowerCase() === USER_TYPE.EMPLOYEES || type.toLowerCase() === USER_TYPE.THEKEDARS)) {
            setError(`${type.slice(0, -1)} is taking more money than work`);
        }
        else {
            setError("");
        }
    }, [amount])
    const [chosenDate, setChosenDate] = useState(new Date());
    let time = new Date();
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    // Use useLayoutEffect to set the header title dynamically
    useLayoutEffect(() => {
        if (type === "Suppliers") {
            navigation.setOptions({
                title: `${supplierData[userID]?.name}`,
            });
        }
        else if (type === "Employees") {
            navigation.setOptions({
                title: `${employeeData[userID]?.name}`,
            });
        }
        else if (type === "Thekedars") {
            navigation.setOptions({
                title: `${thekedarData[userID]?.name}`,
            });
        }
        else {
            navigation.setOptions({
                title: `Site | ${siteName}`,
            });
        }
    }, [navigation, amount]);

    const showDatePicker = () => {
        setDatePickerVisibility(true);
    };

    const hideDatePicker = () => {
        setDatePickerVisibility(false);
    };

    const handleConfirm = (date) => {
        setChosenDate(date);
        hideDatePicker();
    };

    const validateTransaction = () => {
        if (!amount) {
            setError('Please enter amount.');
            return false;
        }
        else if (!comment) {
            setError('Please enter your comment.');
            return false;
        }
        setError('');
        return true;
    };

    const submitTransactionDetails = () => {
        if (validateTransaction()) {
            let lowerType = type.toLowerCase();
            // Calculate total pending amount based on existing transaction data
            if (existingAmount) {
                pendingAmountRoute += parseFloat(existingAmount);
            }
            if (amount > pendingAmountRoute) {
                setError('Received amount cannot exceed total amount.');
                return;
            }
            setLoading(true);
            const pushData = async () => {
                try {
                    let url;
                    let payload;
                    let givenTo = null;
                    if (type === "clients") {
                        if (existingAmount) {
                            url = `${API_CONFIG.SITE_TRANSACTIONS}/${siteID}/${lowerType}/${userData.username.toLowerCase()}`;
                            payload = {
                                amount: amount,
                                date: formatDate(chosenDate),
                                time: chosenDate.toDateString() !== today.toDateString() ? "11:59:59 PM" : time.toLocaleTimeString("en-US"),
                                clientId: `${clientID}`, // Adjusted key based on client type
                                actionBy: userData.username,
                                id: transactionId,
                                comment: comment
                            };
                            console.log('Pushing payload:', payload);
                            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CLIENT_YOU_RECIEVED_EDIT_CLICKED, userData);
                            await makeApiRequest("put", url, authToken, refreshToken, setAuthToken, setRefreshToken, payload);
                            setLoading(false);
                            ShowAlert("SUCCESS", "Do you want to share your transaction via WhatsApp?", "YesNo", () => {
                                console.log('OK Pressed');
                            }, () => {
                                console.log('Yes Pressed');
                                sendWhatsAppMessage(`Hi, ${clientsData[clientID]?.name} \nWe have received an updated payment of ${amount}, which was previously ${existingAmount} for site - ${siteName}\nComment - ${comment}.`);
                            }, () => {
                                console.log('No Pressed');
                                setToastMessage("Payment updated successfully");
                                navigation.goBack();
                            });
                        } else {
                            url = `${API_CONFIG.SITE_TRANSACTIONS}/${siteID}/${lowerType}/${userData.username.toLowerCase()}`;
                            payload = {
                                amount: amount,
                                date: formatDate(chosenDate),
                                time: chosenDate.toDateString() !== today.toDateString() ? "11:59:59 PM" : time.toLocaleTimeString("en-US"),
                                clientId: `${clientID}`, // Adjusted key based on client type
                                actionBy: userData.username,
                                comment: comment
                            };
                            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CLIENT_YOU_RECIEVED_CLICKED, userData);
                            const data = await makeApiRequest("post", url, authToken, refreshToken, setAuthToken, setRefreshToken, payload);
                            setLoading(false);
                            console.log('Pushing transactions:', data);
                            sendNotification(userData.factory, `Transaction for Client: ${clientsData[clientID].name}`, `${amount} Rs received from ${siteName} site in ${userData.username}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                            ShowAlert("SUCCESS", "Do you want to share your transaction via WhatsApp?", "YesNo", () => {
                                console.log('OK Pressed');
                            }, () => {
                                console.log('Yes Pressed');
                                sendWhatsAppMessage();
                            }, () => {
                                console.log('No Pressed');
                                setToastMessage("Payment added successfully");
                                navigation.goBack();
                            });
                        }
                        setAmount("");
                        setComment("");
                    } else {
                        let ownerData = await makeApiRequest('get', `${USERS_API}/${userData.factory}/${userData.username}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                        if (existingAmount) {
                            if (amount - existingAmount <= ownerData.amount) {
                                if (type === "Thekedars") url = `${API_CONFIG.TRANSACTIONS}/${lowerType}/${transactionId}/${userData.username.toLowerCase()}/${plantName}`;
                                else url = `${API_CONFIG.TRANSACTIONS}/${lowerType}/${transactionId}/${userData.username.toLowerCase()}`;
                                await makeApiRequest("put",
                                    url,
                                    authToken, refreshToken, setAuthToken, setRefreshToken, {
                                    amount: amount,
                                    date: formatDate(chosenDate),
                                    userId: `${userID}`,
                                    time: chosenDate.toDateString() !== today.toDateString() ? "11:59:59 PM" : time.toLocaleTimeString("en-US"),
                                    pathToAdd: `${lowerType}/${userID}/${transactionId}`,
                                    comment: comment
                                });
                                setLoading(false);
                                if (type === "Suppliers") {
                                    MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SUPPLIER_YOU_GAVE_EDIT_CLICKED, userData);
                                }
                                else if (type === "Employees") {
                                    MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_YOU_GAVE_EDIT_CLICKED, userData);
                                }
                                else if (type === "Thekedars") {
                                    MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_YOU_GAVE_EDIT_CLICKED, userData);
                                }
                                ShowAlert("SUCCESS", "Do you want to share your transaction via WhatsApp?", "YesNo", () => {
                                    console.log('OK Pressed');
                                }, () => {
                                    console.log('Yes Pressed');
                                    let receipientName = "";
                                    if (type === "Suppliers") receipientName = supplierData[userID]?.name;
                                    else if (type === "Employees") receipientName = employeeData[userID]?.name;
                                    else if (type === "Thekedars") receipientName = thekedarData[userID]?.name;
                                    sendWhatsAppMessage(`Hi, ${receipientName} \nYou have received an updated payment of ${amount}, which was previously ${existingAmount} for ${comment}\nFrom Owner - ${userData.username}.`);
                                }, () => {
                                    console.log('No Pressed');
                                    setToastMessage("Payment updated successfully");
                                    navigation.goBack();
                                });
                                setAmount("");
                                setComment("");
                            } else {
                                setLoading(false);
                                ShowAlert("Failed", "Your balance is insufficient!", "Ok", () => {
                                    console.log('OK Pressed');
                                });
                            }
                        } else {
                            if (parseFloat(ownerData.amount) >= parseFloat(amount)) {
                                if (type === "Thekedars") {
                                    url = `${API_CONFIG.TRANSACTIONS}/${lowerType}/${userData.username.toLowerCase()}/${plantName}`;
                                    payload = {
                                        amount: amount,
                                        date: formatDate(chosenDate),
                                        time: chosenDate.toDateString() !== today.toDateString() ? "11:59:59 PM" : time.toLocaleTimeString("en-US"),
                                        userId: `${userID}`,
                                        actionBy: userData.username,
                                        comment: comment,
                                        thekedarName: thekedarData[userID]?.name
                                    };
                                }
                                else {
                                    url = `${API_CONFIG.TRANSACTIONS}/${lowerType}/${userData.username.toLowerCase()}`;
                                    payload = {
                                        amount: amount,
                                        date: formatDate(chosenDate),
                                        time: chosenDate.toDateString() !== today.toDateString() ? "11:59:59 PM" : time.toLocaleTimeString("en-US"),
                                        userId: `${userID}`,
                                        actionBy: userData.username,
                                        comment: comment
                                    };
                                }
                                await makeApiRequest("post", url, authToken, refreshToken, setAuthToken, setRefreshToken, payload);
                                setLoading(false);
                                if (type === "Suppliers") {
                                    givenTo = supplierData[userID]?.name;
                                    MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SUPPLIER_YOU_GAVE_CLICKED, userData);
                                }
                                else if (type === "Employees") {
                                    givenTo = employeeData[userID]?.name;
                                    MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_YOU_GAVE_CLICKED, userData);
                                }
                                else if (type === "Thekedars") {
                                    givenTo = thekedarData[userID]?.name;
                                    MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_YOU_GAVE_CLICKED, userData)
                                }
                                sendNotification(userData.factory, `Transaction for ${type}`, `${userData.username}: ${amount} Rs given to ${givenTo}`, authToken, refreshToken, setAuthToken, setRefreshToken);
                                ShowAlert("SUCCESS", "Do you want to share your transaction via WhatsApp?", "YesNo", () => {
                                    console.log('OK Pressed');
                                }, () => {
                                    console.log('Yes Pressed');
                                    sendWhatsAppMessage();
                                }, () => {
                                    console.log('No Pressed');
                                    setToastMessage("Payment added successfully");
                                    navigation.goBack();
                                });
                                setAmount("");
                                setComment("");
                            } else {
                                setLoading(false);
                                ShowAlert("Failed", "Your balance is insufficient!", "Ok", () => {
                                    console.log('OK Pressed');
                                });
                            }

                        }
                    }

                } catch (error) {
                    setLoading(false);
                    ShowAlert("Failed", "Transaction Failed, Please retry", "Ok", () => {
                        console.log('OK Pressed');
                    }, () => {
                        console.log('Yes Pressed');
                    }, () => {
                        console.log('No Pressed');
                    });
                    console.error('Error on add transaction:', error);
                    handleAlertForError(error);
                }
            };
            pushData();
        }
    }

    const sendWhatsAppMessage = (customMessage) => {
        if (type === "Suppliers") {
            let defaultMesg = `Hi, ${supplierData[userID]?.name} \nYou have received a payment of ${amount} for ${comment}\nFrom Owner - ${userData.username}.`
            sendWAMessage((customMessage ? customMessage : defaultMesg), supplierData[userID]?.contact);
        }
        else if (type === "Employees") {
            let defaultMesg = `Hi, ${employeeData[userID]?.name} \nYou have received a payment of ${amount} for ${comment} \nFrom Owner - ${userData.username}.`
            sendWAMessage((customMessage ? customMessage : defaultMesg), employeeData[userID]?.contact);
        }
        else if (type === "clients") {
            let defaultMesg = `Hi, ${clientsData[clientID]?.name} \nWe have received a payment of ${amount} for site: ${siteName} \nComment - ${comment}.`
            sendWAMessage((customMessage ? customMessage : defaultMesg), clientsData[clientID]?.contact);
        }
        else if (type === "Thekedars") {
            let defaultMesg = `Hi, ${thekedarData[userID]?.name} \nYou have received a payment of ${amount} for ${comment ? comment : `your work`} \nFrom Owner - ${userData.username}.`
            sendWAMessage((customMessage ? customMessage : defaultMesg), thekedarData[userID]?.contact);
        }
    }

    return (
        <View style={styles.container}>
            {loading && (
                <View style={styles.overlay}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )}
            <TextInput
                placeholder='Enter Amount'
                value={amount}
                keyboardType="numeric"
                onChangeText={(text) => setAmount(removeLeadingZero(text))}
                style={[styles.input, isFocus === 'amount' && { borderColor: Colors.primary }]}
                onFocus={() => setIsFocus('amount')}
            />
            <TouchableOpacity onPress={showDatePicker}>
                <View style={styles.view}>
                    <FontAwesome5
                        name="calendar"
                        size={24}
                        color={Colors.primary}
                    />
                    <Text style={styles.text}> {chosenDate.toDateString()}</Text>
                    <FontAwesome5
                        name="caret-down"
                        size={24}
                        color={Colors.primary}
                    />
                </View>
            </TouchableOpacity>
            <Popup visible={isDatePickerVisible} onClose={() => setDatePickerVisibility(false)}>
                <DateTimePicker
                    mode="single"
                    date={chosenDate}
                    onChange={(param) => {
                        setChosenDate(new Date(param.date));
                        setDatePickerVisibility(false)
                    }}
                    headerButtonStyle={{ color: 'green' }}
                />
            </Popup>
            <TextInput
                style={[styles.textInput, isFocus === 'comment' && { borderColor: Colors.primary }]}
                placeholder="Write your comment here..."
                value={comment}
                onChangeText={(text) => setComment(text)}
                onFocus={() => setIsFocus('comment')}
            />
            {error ? <Text style={{ color: 'red', fontSize: 16, top: 10, alignSelf: "center" }}>{error}</Text> : null}
            <Button
                onPress={submitTransactionDetails}
                btnLabel={type === "clients" ? `YOU RECEIVED ₹${amount}` : `YOU GAVE ₹${amount}`}
                buttonStyle={styles.BtnContainer}
                textStyle={styles.btnText} />
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    input: {
        borderWidth: 2,
        borderColor: '#636363',
        margin: 10,
        padding: 12,
        fontSize: 18,
        borderRadius: 6,
        width: '90%'
    },
    view: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 55,
        borderColor: '#636363',
        borderRadius: 6,
        paddingHorizontal: 8,
        margin: 12,
        padding: 12,
        width: '55%',
        backgroundColor: "white"
    },
    text: {
        marginLeft: 10,
        marginRight: 10
    },
    textInput: {
        borderWidth: 2,
        borderColor: '#636363',
        margin: 10,
        padding: 12,
        fontSize: 16,
        borderRadius: 6,
        width: '90%'
    },
    BtnContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        width: '90%',
        position: 'absolute', //Here is the trick
        bottom: 10,
        margin: 20,
    },
    btnText: {
        color: 'white',
    },
    overlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
    },
});

export default AddTransactionScreen