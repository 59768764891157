import { Alert } from 'react-native';

const ShowAlert = (title, message, type, onOkPress, onYesPress, onNoPress) => {
  switch (type) {
    case 'Ok':
      alert(`${title}\n${message}`);
      if (onOkPress) onOkPress();
      break;
    case 'YesNo':
      if (window.confirm(`${title}\n${message}`)) {
        if (onYesPress) onYesPress();
      } else {
        if (onNoPress) onNoPress();
      }
      break;
    default:
      alert(`${title}\n${message}`);
      if (onOkPress) onOkPress();
  }
};

export default ShowAlert;
