import React, { useLayoutEffect } from 'react'
import { TouchableOpacity } from "react-native";
import { useNavigation, getFocusedRouteNameFromRoute, useRoute } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { FontAwesome5 } from '@expo/vector-icons';
import EmployeesScreen from './EmployeesScreen';
import SuppliersScreen from './SuppliersScreen';
import ClientScreen from './ClientScreen.js';
import { APP_ROLE, EVENT_ACTIONS, USER_TYPE } from '../constants/Constant';
import useDataStore from '../stores/dataStore';
import MixpanelTracker from '../utils/MixpanelTracker';
import FactoryExpenseScreen from './FactoryExpenseScreen';

export default function HomeScreen() {

    const navigation = useNavigation();
    const route = useRoute();
    const activeTabName = getFocusedRouteNameFromRoute(route) || 'Employees';
    const [userData] = useDataStore((state) => [state.userData]);
    const goToOtherScreen = () => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_DETAIL_SCREEN, userData);
        let tabCount = 0;
        if (activeTabName === USER_TYPE.SUPPLIERS) {
            tabCount = 1;
        } else if (activeTabName === USER_TYPE.CLIENTS) {
            tabCount = 2;
        }
        navigation.navigate('AddDetail', { existinguserID: null, existingName: null, existingContact: null, activeTabName: tabCount });
    };

    const Tab = createMaterialTopTabNavigator();

    useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                [APP_ROLE.OWNER].includes(userData.role) && <TouchableOpacity style={{
                    paddingRight: 20,
                }} onPress={goToOtherScreen}>
                    <FontAwesome5
                        name='user-plus'
                        size={22}
                        color='black' />
                </TouchableOpacity>
            ),
        });
    }, [navigation, activeTabName])

    return (
        <>
            <Tab.Navigator>
                <Tab.Screen name={USER_TYPE.EMPLOYEES} component={EmployeesScreen} />
                <Tab.Screen name={USER_TYPE.SUPPLIERS} component={SuppliersScreen} />
                <Tab.Screen name={USER_TYPE.CLIENTS} component={ClientScreen} />
                <Tab.Screen name={'FactoryExpense'} component={FactoryExpenseScreen} />
            </Tab.Navigator>
        </>
    );
}