import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import useDataStore from '../stores/dataStore';
import Loader from '../components/Loader';
import { useIsFocused } from '@react-navigation/native';
import { USERS_API } from '../env-config/api-config';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { EVENT_ACTIONS } from '../constants/Constant';
import { makeApiRequest } from '../utils/apiUtil';

const ProfileScreen = () => {
    const [userData, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => [state.userData, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]);
    const [usersData, setUsersData] = useState(null);
    const [loading, setLoading] = useState(true);
    const isFocused = useIsFocused();

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PROFILE_CLICKED, userData)
            fetchData();
        }
    }, [isFocused]);

    const fetchData = async () => {
        try {
            const data = await makeApiRequest('get', `${USERS_API}/${userData.factory}/${userData.username.toLowerCase()}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            setUsersData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching user:', error);
            handleAlertForError(error);
        }
    };

    return (
        <>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) :
                <View style={styles.container}>
                    <View>
                        <Text style={styles.label}>Name: <Text style={styles.info}>{usersData?.username}</Text></Text>
                        <Text style={styles.label}>Factory: <Text style={styles.info}>{usersData?.factory}</Text></Text>
                        <Text style={styles.label}>Role: <Text style={styles.info}>{usersData?.role}</Text></Text>
                        {userData.role !== "viewer" && <Text style={styles.label}>Balance: <Text style={styles.info}>{usersData?.amount}</Text></Text>}
                    </View>
                </View>}
        </>
    );
};

const styles = StyleSheet.create({
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 5,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        margin: 10,
    },
    label: {
        fontSize: 16,
        padding: 5,
        fontWeight: 'bold',
        marginTop: 5,
    },
    info: {
        fontWeight: 'normal',
        textTransform: 'capitalize',
    },
});

export default ProfileScreen;
