import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Text, View, StyleSheet, FlatList, TouchableOpacity } from 'react-native';
import useDataStore from '../stores/dataStore';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import Loader from '../components/Loader';
import SiteList from '../components/SiteList';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { EVENT_ACTIONS } from '../constants/Constant';
import { isEmpty } from '../utils/commonUtils';
import { makeApiRequest } from '../utils/apiUtil';
import { Feather, FontAwesome5 } from '@expo/vector-icons';
import { Colors } from '../constants/theme';
import ShowAlert from '../modules/AlertModule';
import { convertToDateTime, formatDate, formatDateAsDDMMYY } from '../utils/dateUtil';
import DateTimePicker from 'react-native-ui-datepicker';
import Popup from "../components/popup";
import html2pdf from 'html2pdf.js';

const ClosedSitesScreen = () => {

  const [API_CONFIG, userData, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => [state.API_CONFIG, state.userData, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]);
  const [loading, setLoading] = useState(true);
  const [clientsData, setClientsData] = useState(null);
  const [closedSiteDetails, setClosedSiteDetails] = useState(null);
  const [dataKey, setDataKey] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [isStartDatePickerVisible, setStartDatePickerVisibility] = useState(false);
  const [isEndDatePickerVisible, setEndDatePickerVisibility] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  let siteDetailsData;
  let today = new Date();

  useEffect(() => {
    if (isFocused) {
      MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CLOSED_SITE_CLICKED, userData);
      fetchData();
    }
  }, [isFocused]);

  useLayoutEffect(() => {
    if (!loading) {
      navigation.setOptions({
        headerRight: () => (
          <TouchableOpacity style={{ paddingRight: 20 }} onPress={handleDownloadReport}>
            <Feather name="download" size={24} color="black" />
          </TouchableOpacity>
        ),
      });
    }
  }, [loading])

  const handleDownloadReport = async () => {
    try {
      let htmlContent = generateClosedSiteHTMLContent();
      let formatedDate = formatDate(today);
      const fileName = `Closed_Site_Report_${formatedDate}.pdf`;

      const element = document.createElement("div");
      element.innerHTML = htmlContent;

      html2pdf(element, {
        filename: fileName,
        jsPDF: { unit: "pt", format: "a4" },
      });
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      siteDetailsData = await makeApiRequest('get', API_CONFIG.SITE_DETAILS, authToken, refreshToken, setAuthToken, setRefreshToken);
      if (!isEmpty(siteDetailsData)) {
        const closedSites = getClosedSites(siteDetailsData);
        setClosedSiteDetails(closedSites); //Parent
        setFilteredData(closedSites); //Child
        setDataKey(Object.keys(closedSites));
      }

      let clientsResponse = await makeApiRequest('get', API_CONFIG.CLIENTS, authToken, refreshToken, setAuthToken, setRefreshToken);
      setClientsData(clientsResponse);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching Clients:', error);
      handleAlertForError(error);
    }
  };

  const getClosedSites = (siteDetail) => {
    const closedSites = {};
    for (const clientId in siteDetail) {
      const sites = siteDetail[clientId];
      for (const siteId in sites) {
        if (sites[siteId].isClosed) {
          if (!closedSites[clientId]) {
            closedSites[clientId] = {};
          }
          closedSites[clientId][siteId] = sites[siteId];
        }
      }
    }
    return closedSites;
  };

  const showStartDatePicker = () => {
    setStartDatePickerVisibility(true);
  };

  const showEndDatePicker = () => {
    // Only show the end date picker if the start date is already selected
    if (startDate) {
      setEndDatePickerVisibility(true);
    } else {
      // If start date is not selected, show an alert
      ShowAlert("Try again", "Please select the start date first.", "Ok");
    }
  };

  const hideStartDatePicker = () => {
    setStartDatePickerVisibility(false);
  };

  const hideEndDatePicker = () => {
    setEndDatePickerVisibility(false);
  };

  const handleStartDateConfirm = (date) => {
    setStartDate(date);
    hideStartDatePicker();
    updateFilteredTransactions(date, endDate);
  };

  const handleEndDateConfirm = (date) => {
    // Check if the selected end date is before the start date
    if (startDate && date < startDate) {
      setEndDatePickerVisibility(false);
      ShowAlert("Wrong Date", "End date cannot be before the start date.", "Ok");
    } else {
      // If the end date is valid, update the state and hide the date picker
      setEndDate(date);
      hideEndDatePicker();
      updateFilteredTransactions(startDate, date);
    }
  };

  const updateFilteredTransactions = (startDate, endDate) => {
    if (closedSiteDetails && startDate && endDate) {
      const response = sortDataOnDate(closedSiteDetails, startDate, endDate);
      setDataKey(Object.keys(response));
      setFilteredData(response);
    }
  };

  const sortDataOnDate = (data, startDate, endDate) => {
    const sortedData = {};
    for (let groupKey in data) {
      for (let transactionKey in data[groupKey]) {
        const transaction = data[groupKey][transactionKey];
        const transactionDate = convertToDateTime(transaction["endDate"]);
        if (transactionDate >= convertToDateTime(formatDate(startDate)) && transactionDate <= convertToDateTime(formatDate(endDate))) {
          if (!sortedData[groupKey]) {
            sortedData[groupKey] = {};
          }
          sortedData[groupKey][transactionKey] = transaction;
        }
      }
    }
    return sortedData;
  }

  const generateClosedSiteHTMLContent = () => {
    let totalAmountSum = 0;
    let totalReceivedSum = 0;
    let totalPendingSum = 0;
    let totalRunningFt = 0;
    let totalSqft = 0;
    let totalwtGSTAmount = 0;
    let totalGSTAmount = 0;
    let totalAfterGSTAmount = 0;
    let totalExtraExpense = 0;

    let html = `
            <html>
                <head>
                    <title>Closed Site Report</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            font-family: arial, sans-serif;
                        }
                        th, td {
                            border: 1px solid #ddd;
                            padding: 8px;
                            text-align: left;
                        }
                        h1, h3 {
                            text-align: center;
                        }
                    </style>
                </head>
                <body>
                    <h1>Closed Site Report</h1>
                    <h3>(${startDate ? formatDate(startDate) : formatDate(today)} to ${endDate ? formatDate(endDate) : formatDate(today)})</h3>
                    <div style="border: 1px solid #000;"></div> <br>
                    <br>
                    <table>
                        <tr>
                            <th>Client Name</th>
                            <th>Site Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>RFT</th>
                            <th>Total sq.ft</th>
                            <th>Amount</th>
                            <th>GST</th>
                            <th>Total Amount</th>
                            <th>Extra</th>
                            <th>Total Bill</th>
                            <th>Amount Received</th>
                            <th>Pending Amount</th>
                        </tr>
                      `;
    for (const clientId in filteredData) {
      const clientSites = filteredData[clientId];
      const siteIds = Object.keys(clientSites);
      const rowSpan = siteIds.length;
      let firstSite = true;

      for (const siteId of siteIds) {
        const site = clientSites[siteId];
        const pendingAmount = site.totalAmount - site.receivedAmount;

        const runningFeet = site.runningFtData.reduce((sum, item) => {
          return sum + parseFloat(item.runningFt);
        }, 0);
        const wtGSTAmount = site?.runningFtData?.reduce((sum, item) => {
          const sqFeet = item.sqFeet != null && item.sqFeet !== '' ? parseFloat(item.sqFeet) : 0;
          const priceSqFeet = item.priceSqFeet != null && item.priceSqFeet !== '' ? parseFloat(item.priceSqFeet) : 0;
          return sum + (sqFeet * priceSqFeet);
        }, 0)?.toFixed(2)
        const GSTAmount = site?.gst ? (wtGSTAmount * parseFloat(site?.gst)) / 100 : 0;
        const afterGSTAmount = parseFloat(wtGSTAmount) + parseFloat(GSTAmount);
        let extraExpenses = 0;

        if (site.extraExpense === undefined && site.subtractExpense === undefined || site.extraExpense === 0 && site.subtractExpense === 0) {
          extraExpenses = '';
        }
        else if (site.subtractExpense === 0 || site.subtractExpense === undefined) {
          extraExpenses = `${site.extraExpense}`
        }
        else if (site.extraExpense === 0 || site.extraExpense === undefined) {
          extraExpenses = `-${site.subtractExpense}`
        }
        else extraExpenses = `${site.extraExpense}, -${site.subtractExpense}`;

        totalAmountSum += parseFloat(site.totalAmount);
        totalReceivedSum += parseFloat(site.receivedAmount);
        totalPendingSum += pendingAmount;
        totalRunningFt += runningFeet;
        totalSqft += parseFloat(site.totalSQFT);
        totalwtGSTAmount += parseFloat(wtGSTAmount);
        totalGSTAmount += GSTAmount;
        totalAfterGSTAmount += parseFloat(afterGSTAmount);
        totalExtraExpense += (parseFloat(site.extraExpense) || 0) - (parseFloat(site.subtractExpense) || 0);

        html += `<tr>`;
        if (firstSite) {
          html += `<td rowspan="${rowSpan}">${clientsData[clientId].name}</td>`;
          firstSite = false;
        }
        html += `
                <td>${site.siteName}</td>
                <td>${formatDateAsDDMMYY(site.startDate)}</td>
                <td>${formatDateAsDDMMYY(site.endDate)}</td>
                <td>${runningFeet}</td>
                <td>${site.totalSQFT}</td>
                <td>${wtGSTAmount}</td>
                <td>${GSTAmount}</td>
                <td>${afterGSTAmount}</td>
                <td>${extraExpenses || 0}</td>
                <td>${site.totalAmount}</td>
                <td>${site.receivedAmount}</td>
                <td>${pendingAmount}</td>
            </tr>`;
      }
    }

    html += `
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="2" style="text-align:right">Total: </th>
                            <th></th>
                            <th></th>
                            <th>${totalRunningFt.toFixed(2)}</th>
                            <th>${totalSqft.toFixed(2)}</th>
                            <th>${totalwtGSTAmount.toFixed(2)}</th>
                            <th>${totalGSTAmount.toFixed(2)}</th>
                            <th>${totalAfterGSTAmount.toFixed(2)}</th>
                            <th>${totalExtraExpense.toFixed(2) || 0}</th>
                            <th>${totalAmountSum.toFixed(2)}</th>
                            <th>${totalReceivedSum.toFixed(2)}</th>
                            <th>${totalPendingSum.toFixed(2)}</th>
                        </tr>
                    </tfoot>
                </table>
            </body>
        </html>
        `;
    return html;
  };

  return (
    <>
      {loading ? (
        <View style={styles.loaderContainer}>
          <Loader size="large" />
        </View>
      ) :
        <View style={styles.container}>
          <View style={styles.datePickerCOntainer}>
            <View style={styles.startDateContainer}>
              <TouchableOpacity onPress={showStartDatePicker}>
                <View style={styles.startDateContainerView}>
                  <FontAwesome5
                    name="calendar"
                    size={16}
                    color={Colors.primary}
                  />
                  <Text style={styles.startDateText}> {startDate ? startDate.toDateString() : "Select start date"}</Text>
                  <FontAwesome5
                    name="caret-down"
                    size={16}
                    color={Colors.primary}
                  />
                  <Popup visible={isStartDatePickerVisible} onClose={() => setStartDatePickerVisibility(false)}>
                    <DateTimePicker
                      mode="single"
                      date={startDate || new Date()}
                      maxDate={today}
                      onChange={(param) => {
                        handleStartDateConfirm(new Date(param.date));
                        setStartDatePickerVisibility(false)
                      }}
                      headerButtonStyle={{ color: 'green' }}
                    />
                  </Popup>
                </View>
              </TouchableOpacity>
            </View>

            <View style={styles.endDateContainer}>
              <TouchableOpacity onPress={showEndDatePicker}>
                <View style={styles.endDateContainerView}>
                  <FontAwesome5
                    name="calendar"
                    size={16}
                    color={Colors.primary}
                  />
                  <Text style={styles.endDateText}> {endDate ? endDate.toDateString() : "Select end date"}</Text>
                  <FontAwesome5
                    name="caret-down"
                    size={16}
                    color={Colors.primary}
                  />
                  <Popup visible={isEndDatePickerVisible} onClose={() => setEndDatePickerVisibility(false)}>
                    <DateTimePicker
                      mode="single"
                      date={endDate || new Date()}
                      maxDate={today}
                      onChange={(param) => {
                        handleEndDateConfirm(new Date(param.date));
                        setEndDatePickerVisibility(false)
                      }}
                      headerButtonStyle={{ color: 'green' }}
                    />
                  </Popup>
                </View>
              </TouchableOpacity>
            </View>
          </View>

          {filteredData && clientsData && !isEmpty(filteredData) && !isEmpty(clientsData) && dataKey?.length > 0 ? <FlatList
            data={dataKey}
            renderItem={({ item: key }) => (
              <>
                <View style={styles.clientNameView}>
                  <Text style={styles.clientNameText}>{clientsData[key].name}</Text>
                </View>
                {!isEmpty(filteredData[key]) && Object.values(filteredData[key]).map(site => (
                  site.isClosed && <SiteList
                    key={site.id}
                    siteName={site.siteName}
                    siteKey={site.id}
                    clientKey={clientsData[key].id}
                    type={'Clients'}
                    callback={() => {
                      fetchData();
                    }}
                    pendingAmount={site.totalAmount - site.receivedAmount}
                  />
                ))}
              </>
            )}
            keyExtractor={(item) => item}
          /> : <View style={styles.centeredView}><Text style={styles.noSiteFoundText}>No Sites Found</Text></View>}
        </View>
      }
    </>
  )
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    textAlign: 'center'
  },
  datePickerCOntainer: {
    flexDirection: 'row',
  },
  startDateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 55,
    borderColor: '#636363',
    borderRadius: 6,
    paddingHorizontal: 8,
    margin: 12,
    padding: 6,
    width: '45%',
    backgroundColor: "white"
  },
  startDateContainerView: {
    flexDirection: 'row',
  },
  startDateText: {
    marginLeft: 5,
    marginRight: 5
  },
  endDateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 55,
    borderColor: '#636363',
    borderRadius: 6,
    marginTop: 12,
    paddingLeft: 6,
    width: '45%',
    backgroundColor: "white"
  },
  endDateContainerView: {
    flexDirection: 'row',
    padding: 5,
  },
  endDateText: {
    marginLeft: 5,
    marginRight: 5
  },
  headerRight: {
    paddingRight: 14,
    paddingTop: 8
  },
  clientNameView: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  clientNameText: {
    fontSize: 18,
    backgroundColor: 'white',
    textAlign: 'center',
    width: "35%",
    borderRadius: 5,
    backgroundColor: 'lightgrey'
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noSiteFoundText: {
    textAlign: 'center',
  }
});

export default ClosedSitesScreen