import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import { Colors } from '../constants/theme';
import TransactionList from '../components/TransactionList';
import { FlatList } from 'react-native-gesture-handler';
import Button from '../components/Button';
import { useIsFocused } from '@react-navigation/native';
import useDataStore from '../stores/dataStore';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import ShowAlert from '../modules/AlertModule';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { convertToDateTime } from '../utils/dateUtil';
import { isEmpty } from '../utils/commonUtils';
import { makeApiRequest } from '../utils/apiUtil';
import DateTimePicker from 'react-native-ui-datepicker';
import Popup from "../components/popup";
import html2pdf from 'html2pdf.js';

const ReportScreen = ({ route }) => {
    const [userData, API_CONFIG, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => [state.userData, state.API_CONFIG, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]);
    const [isStartDatePickerVisible, setStartDatePickerVisibility] = useState(false);
    const [isEndDatePickerVisible, setEndDatePickerVisibility] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterList, setFilterList] = useState(null);
    const [filterTransaction, setFilterTransaction] = useState(null);
    const isFocused = useIsFocused();
    const [transactionData, setTransactionData] = useState(null);

    const { name, type, contact, userID, siteDetailsData, empData, siteName, vehicles, dates, loadingActionsBy, loadingAmounts, thekedarProductionData } = route.params;
    let today = new Date();
    let prevDate = null;
    let prevSite = null;
    let total = 0;

    useEffect(() => {
        if (isFocused) {
            fetchData();
        }
    }, [isFocused]);

    const fetchData = async () => {
        try {
            let lowerType = type.toLowerCase();
            const responseData = await makeApiRequest('get', `${API_CONFIG.TRANSACTIONS}/${lowerType}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            if (!isEmpty(responseData)) {
                if (responseData) {
                    let filterdata = responseData[userID];
                    if (userData.role === APP_ROLE.SUPERVISOR) {
                        filterdata = Object.fromEntries(
                            Object.entries(responseData[userID]).filter(([key, value]) => value.actionBy === userData.username)
                        );
                    }
                    setTransactionData(filterdata);
                }
            }
        } catch (error) {
            console.error('Error fetching reports:', error);
            handleAlertForError(error);
        }
    };

    const showStartDatePicker = () => {
        setStartDatePickerVisibility(true);
    };

    const showEndDatePicker = () => {
        // Only show the end date picker if the start date is already selected
        if (startDate) {
            setEndDatePickerVisibility(true);
        } else {
            // If start date is not selected, show an alert
            ShowAlert("Try again", "Please select the start date first.", "Ok");
        }
    };

    const hideStartDatePicker = () => {
        setStartDatePickerVisibility(false);
    };

    const hideEndDatePicker = () => {
        setEndDatePickerVisibility(false);
    };

    const handleStartDateConfirm = (date) => {
        setStartDate(date);
        hideStartDatePicker();
        updateFilteredTransactions(date, endDate);
    };

    const formatDateNew = (date) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    };
    const sortDataOnDate = (data, startDate, endDate, type) => {
        const sortedData = {};
        if (type === "Clients") {
            for (let groupKey in data) {
                for (let transactionKey in data[groupKey]) {
                    const transaction = data[groupKey][transactionKey];
                    const transactionDate = convertToDateTime(transaction["date"]);

                    if (transactionDate >= convertToDateTime(formatDateNew(startDate)) && transactionDate <= convertToDateTime(formatDateNew(endDate))) {
                        if (!sortedData[groupKey]) {
                            sortedData[groupKey] = {};
                        }
                        sortedData[groupKey][transactionKey] = transaction;
                    }
                }
            }
        } else {
            for (let [key, value] of Object.entries(data)) {
                const transactionDate = convertToDateTime(data[key]["date"]);

                if (transactionDate >= convertToDateTime(formatDateNew(startDate)) && transactionDate <= convertToDateTime(formatDateNew(endDate))) {
                    sortedData[key] = data[key];
                }
            }
        }
        return sortedData;
    }

    const handleEndDateConfirm = (date) => {
        // Check if the selected end date is before the start date
        if (startDate && date < startDate) {
            setEndDatePickerVisibility(false);
            ShowAlert("Wrong Date", "End date cannot be before the start date.", "Ok");
        } else {
            // If the end date is valid, update the state and hide the date picker
            setEndDate(date);
            hideEndDatePicker();
            updateFilteredTransactions(startDate, date);
        }
    };

    // Function to handle PDF download
    // useEffect(() => {
    //     checkPermissions(); // Check permissions when component mounts
    // }, []);

    // const checkPermissions = async () => {
    //     try {
    //         const granted = await PermissionsAndroid.request(
    //             PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
    //             {
    //                 title: 'Storage Permission',
    //                 message: 'This app needs access to your storage to save files.',
    //                 buttonNeutral: 'Ask Me Later',
    //                 buttonNegative: 'Cancel',
    //                 buttonPositive: 'OK',
    //             },
    //         );
    //         if (granted !== PermissionsAndroid.RESULTS.GRANTED) {
    //             ShowAlert("Permission Denied", "Storage permission is required to save files. Please enable it in app settings.", "Ok", () => {
    //                 console.log('Permission OK Pressed')
    //             });
    //         }
    //     } catch (err) {
    //         console.warn(err);
    //     }
    // };

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const formattedDate = new Date(date).toLocaleString('en-US', options).replace(',', '').replace(/ /g, '-');
        return formattedDate;
    };

    const handleDownloadPDF = async () => {
        try {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.DOWNLOAD_PDF_BUTTON_CLICKED, userData);

            let htmlContent;
            if (type === 'Clients') {
                htmlContent = generateClientHTMLContent();
            } else if (type === 'Employees') {
                htmlContent = generateEmpHTMLContent();
            } else if (type === 'Thekedars') {
                htmlContent = generateThekedarHTMLContent();
            } else {
                htmlContent = generateHTMLContent();
            }

            let formatedDate = formatDate(today);
            const fileName = `${type}-${name}-${formatedDate}.pdf`;

            const element = document.createElement("div");
            element.innerHTML = htmlContent;

            html2pdf(element, {
                filename: fileName,
                jsPDF: { unit: "pt", format: "a4" },
            });
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    //Get Current Date in DD - MM - YYYY format
    const getCurrentDate = () => {
        // Get the date
        var date = today.getDate();

        // Get the month (January is 0)
        var month = today.getMonth() + 1; // Adding 1 because months start from 0

        // Get the year
        var year = today.getFullYear();

        // Formatting to ensure double-digit representation if necessary
        month = (month < 10) ? '0' + month : month;
        date = (date < 10) ? '0' + date : date;

        // Create a string with date + month + year
        var dateMonthYear = date + '-' + month + '-' + year;
        return dateMonthYear;
    }
    // Function to generate HTML content for the report
    const generateHTMLContent = () => {
        let currentDate = getCurrentDate();
        let html = `
        <html>
            <head>
                <title>Transaction Report</title>
                <style>
                    #details {
                        background-color: #fff;
                    }
                    #details_tr {
                        background-color: #fff;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th, td {
                        border: 1px solid #ddd;
                        padding: 8px;
                        text-align: left;
                        max-width: 60px;
                    }
                    th {
                        background-color: #dddddd;
                    }
                    h1 {
                        text-align: center;
                    }
                    h3 {
                        text-align: center;
                    }
                    #border {
                        border: 1px solid #000;
                    }
                    table {
                      font-family: arial, sans-serif;
                      border-collapse: collapse;
                      width: 100%;
                    }
                    
                    td, th {
                      border: 1px solid #dddddd;
                      text-align: left;
                      padding: 8px;
                    }
                    
                    tr:nth-child(even) {
                      background-color: #f2f2f2;
                    }
                    
                    #endctn p{
                        display: inline-block;
                        margin-right: 10px;
                    }
                </style>
            </head>
            <body>
                <h1>Transaction report</h1>
                <h3>${type}: ${name}</h3>
                <div id="border"></div> <br>
                <table id="details">
                    <tr id ="details_tr">
                        <th colspan="2">${type} Details</th>
                        <th colspan="2">Date</th>
                    </tr>
                    <tr id ="details_tr">
                        <td>Name: ${name}</td>
                        <td colspan="3">${currentDate}</td>
                    </tr>
                    <tr id ="details_tr">
                        <td>Mobile No: ${contact}</td>
                    </tr>
                </table>
                <br>
                <div style="border: 1px solid #000;"></div> <br>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Comment</th>
                            <th>Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
    `;

        // Add filtered transactions to the table
        filterList.forEach((transactionKey) => {
            const transaction = filterTransaction[transactionKey];
            total += parseFloat(transaction.amount);
            html += `
            <tr>
                <td>${transaction.date}</td>
                <td>${transaction.comment ? transaction.comment : "-"}</td>
                <td>${transaction.amount}</td>
                <td>${transaction.actionBy}</td>
            </tr>
        `;
        });

        html += `
                    </tbody>
                </table>
                <br>
                <table>
	                <tr>
		                <th> TOTAL AMOUNT: </th>
                        <th> ${total} Rs </th>
	                </tr>
                </table>
            </body>
        </html>
    `;
        return html;
    };

    const generateEmpHTMLContent = () => {
        let currentDate = getCurrentDate();
        let html = `
        <html>
            <head>
                <title>Transaction Report</title>
                <style>
                    #details {
                        background-color: #fff;
                    }
                    #details_tr {
                        background-color: #fff;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th, td {
                        border: 1px solid #ddd;
                        padding: 8px;
                        text-align: left;
                    }
                    th {
                        background-color: #f2f2f2;
                    }

                    h1 {
                        text-align: center;
                    }
                    h3 {
                        text-align: center;
                    }
                    #border {
                        border: 1px solid #000;
                    }
                    table {
                      font-family: arial, sans-serif;
                      border-collapse: collapse;
                      width: 100%;
                    }
                    
                    td, th {
                      border: 1px solid #dddddd;
                      text-align: left;
                      padding: 8px;
                    }
                    
                    tr:nth-child(even) {
                      background-color: #dddddd;
                    }
                    
                    #endctn p{
                        display: inline-block;
                        margin-right: 10px;
                    }
                </style>
            </head>
            <body>
                <h1>Transaction Report</h1>
                <h3>${type}: ${name}</h3>
                <div id="border"></div> <br>
               <table id="details">
                    <tr id="details_tr">
                        <th colspan="2">${type} Details</th>
                        <th colspan="2">Date</th>
                    </tr>
                    <tr id="details_tr">
                        <td>Name: ${name}</td>
                        <td colspan="3">${currentDate}</td> 
                    </tr>
                    <tr id="details_tr">
                        <td>Mobile No: ${contact}</td>
                    </tr>
                </table>
                <br>
                <div style="border: 1px solid #000;"></div>
                <h3>Working on Sites</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Site</th>
                            <th>Sq Feet</th>
                            <th>Rate</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>`;
        let totalSqFeet = 0;
        let totalAmount = 0;
        empData.forEach((value, index) => {
            totalSqFeet += parseFloat(value.sqFeet)
            totalAmount += parseFloat(value.sqFeet * value.price)
            html += `
            <tr>
                <td>${siteName[index]}</td>
                <td>${value.sqFeet}</td>
                <td>${value.price}</td>
                <td>${value.sqFeet * value.price}</td>
            </tr>
        `;
        });
        html +=
            `<tr>
                <th>Total</th>
                <th>${totalSqFeet}</th>
                <th>-</th>
                <th>${totalAmount}</th>
            </tr>
        </tbody>
                </table>
                <h3>Extra work</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Vehicle/Comment</th>
                            <th>Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>`;
        let totalExtra = 0;

        {
            dates.map((date, index) => {
                totalExtra += loadingAmounts[index];
                html += `
            <tr>
                <td>${date}</td>
                <td>${vehicles[index]}</td>
                <td>${loadingAmounts[index]}</td>
                <td>${loadingActionsBy[index]}</td>
            </tr>
            `})
        };
        html += `<tr>
                <th>Total</th>
                <th>-</th>
                <th>${totalExtra}</th>
                <th>-</th>
            </tr>
            </tbody>
                </table>
                <h3>Transactions</h3>`;

        html += `<table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Comment</th>
                            <th>Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
    `;

        // Add filtered transactions to the table
        filterList.forEach((transactionKey) => {
            const transaction = filterTransaction[transactionKey];
            total += parseFloat(transaction.amount);
            html += `
            <tr>
                <td>${transaction.date}</td>
                <td>${transaction.comment ? transaction.comment : "-"}</td>
                <td>${transaction.amount}</td>
                <td>${transaction.actionBy}</td>
            </tr>
        `;
        });

        html += `<tr>
                <th>Total</th>
                <th>-</th>
                <th>${total}</th>
                <th>-</th>
            </tr>
                    </tbody>
                </table>
                <br>
                <table>    
                    <tr>
		                <th> TOTAL WORK: </th>
                        <th> ${totalAmount + totalExtra} Rs </th>
	                </tr>
                    <tr>
		                <th> TOTAL EXPENSE: </th>
                        <th> ${total} Rs </th>
	                </tr>
                    <tr>
		                <th> BALANCE PENDING: </th>
                        <th> ${totalAmount + totalExtra - total} Rs </th>
	                </tr>
                </table>
            </body>
        </html>
    `;
        return html;
    };

    const generateClientHTMLContent = () => {
        let currentDate = getCurrentDate();
        let total = 0; // Variable to keep track of the total amount received
        let totalAmount = 0; // Variable to keep track of the total amount

        // Group transactions by site name
        const groupedBySite = {};
        Object.values(filterTransaction).forEach((transaction) => {
            if (!transaction?.isClosed) {
                const site = transaction.siteName;
                if (!groupedBySite[site]) {
                    groupedBySite[site] = [];
                }
                groupedBySite[site].push(transaction);
            }
        });

        let html = `
            <html>
                <head>
                    <title>Transaction Report</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            font-family: arial, sans-serif;
                        }
                        th, td {
                            border: 1px solid #ddd;
                            padding: 8px;
                            text-align: left;
                        }
                        h1, h3 {
                            text-align: center;
                        }
                    </style>
                </head>
                <body>
                    <h1>Transaction Report</h1>
                    <h3>(${formatDate(startDate)} to ${formatDate(endDate)})</h3>
                    <h3>${type}: ${name}</h3>
                    <div style="border: 1px solid #000;"></div> <br>
                    <table id="details">
                            <tr id ="details_tr">
                                <th colspan="2">Client Details</th>
                                <th colspan="2">Date</th>
                            </tr>
                            <tr id ="details_tr">
                                <td>Name: ${name}</td>
                                <td colspan="3" rowspan="2">${currentDate}</td>
                            </tr>
                            <tr id ="details_tr">
                                <td>Mobile No: ${contact}</td>
                            </tr>
                        </table>
                        <br>
                    <div style="border: 1px solid #000;"></div> <br>
                    <h3>Site Details</h3>
                    <table>
                        <tr>
                            <th>Site Name</th>
                            <th>Running Ft</th>
                            <th>Height</th>
                            <th>Sq Feet</th>
                            <th>Price Per Sq Ft</th>
                            <th>GST Amount</th>
                            <th>Extra Expenses</th>
                            <th>Subtracted Expenses</th>
                            <th>Total Amount</th>
                        </tr>
                    `;
        let totalRunningFt = 0;
        let totalSqFeet = 0;
        let totalExtraExpense = 0;
        let totalSubtractExpense = 0;
        let GSTAmount = 0;
        let totalGSTAmount = 0;
        Object.values(siteDetailsData).forEach((siteDetail) => {
            if (!siteDetail.isClosed) {
                let firstRow = true;
                const wtGSTAmount = siteDetail?.runningFtData?.reduce((sum, item) => {
                    const sqFeet = item.sqFeet != null && item.sqFeet !== '' ? parseFloat(item.sqFeet) : 0;
                    const priceSqFeet = item.priceSqFeet != null && item.priceSqFeet !== '' ? parseFloat(item.priceSqFeet) : 0;
                    return sum + (sqFeet * priceSqFeet);
                }, 0)?.toFixed(2)
                GSTAmount = siteDetail?.gst ? ((wtGSTAmount * parseFloat(siteDetail?.gst)) / 100).toFixed(2) : 0;
                totalGSTAmount += parseFloat(GSTAmount || 0);
                siteDetail.runningFtData.forEach((runningFtDetail, index) => {
                    console.log("kv", GSTAmount)
                    html += `<tr>`;
                    if (firstRow) {
                        html += `
                    <td rowspan="${siteDetail.runningFtData.length}">${siteDetail.siteName}</td>
                    <td>${runningFtDetail.runningFt}</td>
                    <td>${runningFtDetail.height}</td>
                    <td>${runningFtDetail.sqFeet}</td>
                    <td>${runningFtDetail.priceSqFeet}</td>
                    <td rowspan="${siteDetail.runningFtData.length}">${GSTAmount}</td>
                    <td rowspan="${siteDetail.runningFtData.length}">${siteDetail.extraExpense ? siteDetail.extraExpense : 0}</td>
                    <td rowspan="${siteDetail.runningFtData.length}">${siteDetail.subtractExpense ? siteDetail.subtractExpense : 0}</td>
                    <td rowspan="${siteDetail.runningFtData.length}">${siteDetail.totalAmount ? siteDetail.totalAmount : 0}</td>
                `;
                        firstRow = false;
                        totalExtraExpense += parseFloat(siteDetail.extraExpense || 0);
                        totalSubtractExpense += parseFloat(siteDetail.subtractExpense || 0);
                        totalAmount += parseFloat(siteDetail.totalAmount || 0);
                    } else {
                        html += `
                    <td>${runningFtDetail.runningFt}</td>
                    <td>${runningFtDetail.height}</td>
                    <td>${runningFtDetail.sqFeet}</td>
                    <td>${runningFtDetail.priceSqFeet}</td>`;
                    }
                    totalRunningFt += parseFloat(runningFtDetail.runningFt || 0);
                    totalSqFeet += parseFloat(runningFtDetail.sqFeet || 0);
                    html += `</tr>`;
                });
            }
        });
        html += `
        <tfoot>
            <tr>
                <th>Total</th>
                <th>${totalRunningFt}</th>
                <th>-</th>
                <th>${totalSqFeet}</th>
                <th>-</th>
                <th>${totalGSTAmount}</th>
                <th>${totalExtraExpense}</th>
                <th>${totalSubtractExpense}</th>
                <th>${totalAmount} Rs</th>
            </tr>
        </tfoot>

        </table>
        <div style="border: 1px solid #000;"></div> <br>
        <tr class="separator"><td colspan="5"></td></tr>
        <br>
        <h3>Transaction Details</h3>
            <table>
                <thead>
                    <tr>
                        <th>Site Name</th>
                        <th>Date</th>
                        <th>Comment</th>
                        <th>Amount</th>
                        <th>Action By</th>
                    </tr>
                </thead>
                <tbody>
        `;

        // Loop through each site and add its transactions to the table
        for (let site in groupedBySite) {
            const transactions = groupedBySite[site];
            transactions.forEach((transaction, index) => {
                total += parseFloat(transaction.amount);
                html += `
                <tr>
                    ${index === 0 ? `<td rowspan="${transactions.length}">${site}</td>` : ''}
                    <td>${transaction.date}</td>
                    <td>${transaction.comment}</td>
                    <td>${transaction.amount}</td>
                    <td>${transaction.actionBy}</td>
                </tr>
            `;
            });
        }

        html += `
                </tbody>
                <tr>
                    <th>Total Amount Received:</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>${total}</th>
                </tr>
            </table>
            <br>
                <table>
                    <tr>
		                <th> TOTAL WORK AMOUNT: </th>
                        <th> ${totalAmount} Rs </th>
	                </tr>
                    <tr>
		                <th> TOTAL RECEIVED: </th>
                        <th> ${total} Rs </th>
	                </tr>
                    <tr>
		                <th> TOTAL PENDING: </th>
                        <th> ${totalAmount - total} Rs </th>
	                </tr>
                </table>
        </body>
    </html>
    `;
        return html;
    };

    const generateThekedarHTMLContent = () => {
        let dates = [];
        let lines = [];
        let vehiclesOrComments = [];
        let amounts = [];
        let poleLine = [];
        let actionsBy = [];
        let currentDate = getCurrentDate();
        let totalExtraAmount = 0;
        let panelTotal = 0;
        let poleTotal = 0;
        let loadingAmountTotal = 0;
        let totalAmountTotal = 0;

        for (const key in thekedarProductionData) {
            const record = thekedarProductionData[key];
            dates.push(record.date);
            lines.push(record.line || '');
            panelTotal += parseFloat(record.line || 0);
            poleLine.push(record.poleLine || '');
            poleTotal += parseFloat(record.poleLine || 0);
            vehiclesOrComments.push(record.vehicle || null);
            amounts.push(record.amount);
            loadingAmountTotal += parseFloat(record.vehicle ? record.amount : 0);
            totalAmountTotal += parseFloat(record.vehicle ? 0 : record.amount);
            actionsBy.push(record.actionBy);
            totalExtraAmount += parseFloat(record.amount);
        }
        let html = `
        <html>
            <head>
                <title>Transaction Report</title>
                <style>
                    #details {
                        background-color: #fff;
                    }
                    #details_tr {
                        background-color: #fff;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th, td {
                        border: 1px solid #ddd;
                        padding: 8px;
                        text-align: left;
                        max-width: 60px;
                    }
                    th {
                        background-color: #dddddd;
                    }
                    h1 {
                        text-align: center;
                    }
                    h3 {
                        text-align: center;
                    }
                    #border {
                        border: 1px solid #000;
                    }
                    table {
                      font-family: arial, sans-serif;
                      border-collapse: collapse;
                      width: 100%;
                    }
                    
                    td, th {
                      border: 1px solid #dddddd;
                      text-align: left;
                      padding: 8px;
                    }
                    
                    tr:nth-child(even) {
                      background-color: #f2f2f2;
                    }
                    
                    #endctn p{
                        display: inline-block;
                        margin-right: 10px;
                    }
                </style>
            </head>
            <body>
                <h1>Transaction report</h1>
                <h3>${type}: ${name}</h3>
                <div id="border"></div> <br>
                <table id="details">
                    <tr id ="details_tr">
                        <th colspan="2">${type} Details</th>
                        <th colspan="2">Date</th>
                    </tr>
                    <tr id ="details_tr">
                        <td>Name: ${name}</td>
                        <td colspan="3" rowspan="2">${currentDate}</td>
                    </tr>
                    <tr id ="details_tr">
                        <td>Mobile No: ${contact}</td>
                    </tr>
                </table>
                <br>
                <div style="border: 1px solid #000;"></div> <br>
                <h3>Transactions</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Comment</th>
                            <th>Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>`;
        // Add filtered transactions to the table
        filterList.forEach((transactionKey) => {
            const transaction = filterTransaction[transactionKey];
            total += parseFloat(transaction.amount);
            html += `
            <tr>
                <td>${transaction.date}</td>
                <td>${transaction.comment ? transaction.comment : "-"}</td>
                <td>${transaction.amount}</td>
                <td>${transaction.actionBy}</td>
            </tr>
        `;
        });
        html += `</tbody>
                </table>
                <h3>Extra work</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Panel</th>
                            <th>Pole</th>
                            <th>Loading Amount</th>
                            <th>Total Amount</th>
                            <th>Vehicle</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>`;
        html += `
        ${dates.map((date, index) => `
            <tr>
                <td>${date}</td>
                <td>${lines[index]}</td>
                <td>${poleLine[index]}</td>
                <td>${vehiclesOrComments[index] ? amounts[index] : ''}</td>
                <td>${vehiclesOrComments[index] ? '' : amounts[index]}</td>
                <td>${vehiclesOrComments[index] || ''}</td>
                <td>${actionsBy[index]}</td>
            </tr>
            `).join('')}`;
        html += `
        <tr>
                <th>Total</th>
                <th>${panelTotal}</th>
                <th>${poleTotal}</th>
                <th>${loadingAmountTotal}</th>
                <th>${totalAmountTotal}</th>
                <th>-</th>
                <th>-</th>
            </tr>
                    </tbody>
                </table>
                <br>
                <table>
                    <tr>
		                <th> TOTAL WORK AMOUNT: </th>
                        <th> ${totalExtraAmount} Rs </th>
	                </tr>
	                <tr>
		                <th> TOTAL EXPENSE: </th>
                        <th> ${total} Rs </th>
	                </tr>
                    <tr>
		                <th>BALANCE PENDING: </th>
                        <th> ${totalExtraAmount - total} Rs </th>
	                </tr>
                </table>
            </body>
        </html>`;
        return html;
    };

    const updateFilteredTransactions = (startDate, endDate) => {
        if (transactionData && startDate && endDate) {
            const response = sortDataOnDate(transactionData, startDate, endDate, type);

            if (type === 'Clients') {
                const flattenedTransactions = {};
                for (let [key, transactionGroup] of Object.entries(response)) {
                    Object.values(transactionGroup).forEach(transaction => {
                        transaction['siteName'] = siteDetailsData[key].siteName;
                        transaction['totalSQFeet'] = siteDetailsData[key].totalSQFT;
                        transaction['pricePerSQFeet'] = siteDetailsData[key].pricePerSQFT;
                        transaction['GST'] = siteDetailsData[key].gst;
                        transaction['subtractExpense'] = siteDetailsData[key].subtractExpense;
                        transaction['extraExpense'] = siteDetailsData[key].extraExpense;
                        transaction['totalAmount'] = siteDetailsData[key].totalAmount;
                        transaction['isClosed'] = siteDetailsData[key].isClosed;

                        flattenedTransactions[transaction.id] = transaction;
                    });
                }
                setFilterList(Object.keys(flattenedTransactions));
                setFilterTransaction(flattenedTransactions);
            } else {
                setFilterList(Object.keys(response));
                setFilterTransaction(response);
            }
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.datePickerCOntainer}>
                <View style={styles.startDateContainer}>
                    <TouchableOpacity onPress={showStartDatePicker}>
                        <View style={styles.startDateContainerView}>
                            <FontAwesome5
                                name="calendar"
                                size={16}
                                color={Colors.primary}
                            />
                            <Text style={styles.startDateText}> {startDate ? startDate.toDateString() : "Select start date"}</Text>
                            <FontAwesome5
                                name="caret-down"
                                size={16}
                                color={Colors.primary}
                            />
                            <Popup visible={isStartDatePickerVisible} onClose={() => setStartDatePickerVisibility(false)}>
                                <DateTimePicker
                                    mode="single"
                                    date={startDate || new Date()}
                                    maxDate={today}
                                    onChange={(param) => {
                                        handleStartDateConfirm(new Date(param.date));
                                        setStartDatePickerVisibility(false)
                                    }}
                                    headerButtonStyle={{ color: 'green' }}
                                />
                            </Popup>
                        </View>
                    </TouchableOpacity>
                </View>

                <View style={styles.endDateContainer}>
                    <TouchableOpacity onPress={showEndDatePicker}>
                        <View style={styles.endDateContainerView}>
                            <FontAwesome5
                                name="calendar"
                                size={16}
                                color={Colors.primary}
                            />
                            <Text style={styles.endDateText}> {endDate ? endDate.toDateString() : "Select end date"}</Text>
                            <FontAwesome5
                                name="caret-down"
                                size={16}
                                color={Colors.primary}
                            />
                            <Popup visible={isEndDatePickerVisible} onClose={() => setEndDatePickerVisibility(false)}>
                                <DateTimePicker
                                    mode="single"
                                    date={endDate || new Date()}
                                    maxDate={today}
                                    onChange={(param) => {
                                        handleEndDateConfirm(new Date(param.date));
                                        setEndDatePickerVisibility(false)
                                    }}
                                    headerButtonStyle={{ color: 'green' }}
                                />
                            </Popup>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>

            <View style={styles.transactionsContainer}>
                {filterTransaction && !isEmpty(filterTransaction) && startDate && endDate ?
                    <FlatList
                        style={styles.flatListStyle}
                        data={filterList}
                        renderItem={({ item: transaction }) => {
                            if (type === "Clients") {
                                if (!filterTransaction[transaction]?.isClosed) {
                                    let jsx = (
                                        <>
                                            {prevSite !== filterTransaction[transaction]?.siteName && <View style={styles.siteName}><Text style={styles.textSite}>{filterTransaction[transaction]?.siteName}</Text></View>}
                                            {prevDate !== filterTransaction[transaction]?.date && <View style={styles.dateView}><Text style={styles.textDate}>{filterTransaction[transaction]?.date}</Text></View>}
                                            {filterTransaction[transaction] && <TransactionList
                                                key={filterTransaction[transaction].id} // Assuming each transaction has a unique id
                                                comment={filterTransaction[transaction].comment}
                                                date={filterTransaction[transaction].date}
                                                time={filterTransaction[transaction].time}
                                                amount={filterTransaction[transaction].amount}
                                                user={filterTransaction[transaction].actionBy}
                                                isDisable={true}
                                            />}
                                        </>
                                    );
                                    prevDate = filterTransaction[transaction]?.date;
                                    prevSite = filterTransaction[transaction]?.siteName;
                                    return jsx;
                                }
                            }
                            else {
                                let jsx = (
                                    <>
                                        {prevDate !== filterTransaction[transaction]?.date && <View style={styles.dateView}><Text style={styles.textDate}>{filterTransaction[transaction]?.date}</Text></View>}
                                        {filterTransaction[transaction] && <TransactionList
                                            key={filterTransaction[transaction].id} // Assuming each transaction has a unique id
                                            comment={filterTransaction[transaction].comment}
                                            date={filterTransaction[transaction].date}
                                            time={filterTransaction[transaction].time}
                                            amount={filterTransaction[transaction].amount}
                                            user={filterTransaction[transaction].actionBy}
                                            isDisable={true}
                                        />}
                                    </>
                                );
                                prevDate = filterTransaction[transaction]?.date;
                                return jsx;
                            }
                        }}
                        keyExtractor={(item) => item} // Assuming each transaction has a unique id
                    /> : startDate && endDate ? <View style={styles.centeredView}>
                        <Text style={styles.noTransaction}>No transaction found for these dates</Text>
                    </View> : <View style={styles.centeredView}>
                        <Text style={styles.noTransaction}>Select Date Range To Display Transactions</Text>
                    </View>}
            </View>

            <View style={styles.bottomContainer}>
                {filterTransaction && !isEmpty(filterTransaction) && <Button
                    onPress={handleDownloadPDF}
                    btnLabel="Download PDF"
                    buttonStyle={styles.button}
                    textStyle={styles.buttonText}
                />}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        textAlign: 'center'
    },
    datePickerCOntainer: {
        flexDirection: 'row',
    },
    startDateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 55,
        borderColor: '#636363',
        borderRadius: 6,
        paddingHorizontal: 8,
        margin: 12,
        padding: 6,
        width: '45%',
        backgroundColor: "white"
    },
    startDateContainerView: {
        flexDirection: 'row',
    },
    startDateText: {
        marginLeft: 5,
        marginRight: 5
    },
    endDateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 55,
        borderColor: '#636363',
        borderRadius: 6,
        marginTop: 12,
        paddingLeft: 6,
        width: '45%',
        backgroundColor: "white"
    },
    endDateContainerView: {
        flexDirection: 'row',
        padding: 5,
    },
    endDateText: {
        marginLeft: 5,
        marginRight: 5
    },
    transactionsContainer: {
        flexDirection: 'column',
        height: "90%",
        alignItems: 'center'
    },
    flatListStyle: {
        width: '100%',
        height: '100%',
        marginBottom: 76
    },
    siteName: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 10
    },
    dateView: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomContainer: {
        flexDirection: 'row',
        width: '90%',
        position: 'absolute', //Here is the trick
        justifyContent: 'center',
        alignItems: 'center',
        margin: 20,
        bottom: 0
    },
    textSite: {
        backgroundColor: 'lightgrey',
        textAlign: 'center',
        width: "90%",
        borderRadius: 5,
        fontSize: 16,
        fontWeight: "bold",
        padding: 6
    },
    textDate: {
        backgroundColor: 'white',
        textAlign: 'center',
        width: "35%",
        borderRadius: 5,
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noTransaction: {
        textAlign: 'center',
    },
    loginButton: {
        width: '50%',
        backgroundColor: Colors.secondary,
        borderRadius: 5,
        paddingVertical: 15,
        paddingHorizontal: 30,
        marginTop: 10,
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
    },
    button: {
        backgroundColor: Colors.secondary,
        borderRadius: 5,
        fontSize: 16,
        width: "100%",
        marginHorizontal: 10
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
    },
});

export default ReportScreen