import React, { useEffect, useLayoutEffect, useState } from 'react';
import { View, StyleSheet, Text, Modal, TouchableOpacity, FlatList, TouchableWithoutFeedback, TextInput } from 'react-native';
import Button from '../components/Button';
import { Colors } from '../constants/theme';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { makeApiRequest } from '../utils/apiUtil';
import Loader from '../components/Loader';
import useDataStore from '../stores/dataStore';
import { handleAlertForError } from '../utils/handleError';
import { APP_ROLE, EVENT_ACTIONS } from '../constants/Constant';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import MultiSelect from '../components/MultiSelect';
import { USERS_API } from "../env-config/api-config";
import MixpanelTracker from '../utils/MixpanelTracker';

const StockManagementScreen = ({ route }) => {
    const { plant, panelSize, supervisorList } = route.params;
    const [API_CONFIG, userData, authToken, setAuthToken, refreshToken, setRefreshToken, stockData, setStockData] = useDataStore((state) => [state.API_CONFIG, state.userData, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken, state.stockData, state.setStockData]);
    const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [poleData, setPoleData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
    const [supervisorItems, setSupervisorItems] = useState([]);
    const [supervisorValue, setSupervisorValue] = useState([]);
    const navigation = useNavigation();
    const isFocused = useIsFocused();

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.STOCK_MANAGEMENT_SCREEN, userData);
            fetchData();
        }
    }, [isFocused]);

    useEffect(() => {
        if (supervisorList) {
            setSupervisorValue(supervisorList)
        }
    }, [supervisorList]);

    useLayoutEffect(() => {
        navigation.setOptions({
            title: `Stock | ${plant}`,
            headerRight: () => (
                APP_ROLE.OWNER && <TouchableOpacity style={styles.headerRight} onPress={handleIconPress}>
                    <MaterialCommunityIcons
                        name="pencil"
                        size={24}
                        color="#000"
                    />
                </TouchableOpacity>
            ),
        });
    }, [plant]);

    const fetchData = async () => {
        try {
            const data = await makeApiRequest('get', `${API_CONFIG.PLANT}/stocks/${plant}/getStock`, authToken, refreshToken, setAuthToken, setRefreshToken);
            setStockData(data);
            setPoleData(data.poles);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            handleAlertForError(error);
            console.error('Failed to fetch stocks:', error);
        }
    };

    const handleLoading = () => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.LOADING_BUTTON_CLICKED, userData);
        navigation.navigate('LoadingScreen', { plant });
    };

    const handleUnloading = () => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.UNLOADING_BUTTON_CLICKED, userData);
        navigation.navigate('UnloadingScreen', { plant });
    };

    const handlePoleClick = () => {
        setModalVisible(true);
    };

    const renderPoleItem = ({ item }) => (
        <View style={styles.poleItem}>
            <Text style={styles.poleItemKey}>{item.key}</Text>
            <Text style={styles.poleItemValue}>{item.value}</Text>
        </View>
    );

    const fetchtUserData = async () => {
        try {
            const userDataResponse = await makeApiRequest('get', `${USERS_API}/${userData.factory}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            const supervisors = Object.values(userDataResponse).filter(user => user.role === 'supervisor');
            const items = supervisors.map(supervisor => ({
                label: supervisor.username,
                value: supervisor.username,
            }));
            setSupervisorItems(items);
        } catch (error) {
            handleAlertForError(error);
        }
    }

    const handleIconPress = () => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EDIT_PLANT_DETAILS_CLICKED, userData);
        fetchtUserData();
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const savePlant = async () => {
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SAVE_PLANT_CLICKED, userData);
        await makeApiRequest("put", `${API_CONFIG.PLANT}/${plant}/supervisorList`, authToken, refreshToken, setAuthToken, setRefreshToken, {
            supervisorList: supervisorValue,
        });
        closeModal();
    };

    const getColor = (index) => {
        const colors = [
            '#FFCCCB', '#FFE4C4', '#FFD700', '#DAA520', '#ADFF2F', '#98FB98', '#00FFFF', '#AFEEEE', '#ADD8E6', '#87CEEB'
        ];
        return colors[index % colors.length];
    };

    return (
        <View style={styles.container}>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) : (
                <View style={styles.stockContainer}>
                    <View style={styles.stockHeader}>
                        <Text style={styles.stockNameText}>STOCK NAME</Text>
                        <Text style={styles.quantityText}>QUANTITY</Text>
                    </View>

                    <View style={styles.stockItem}>
                        <Text style={styles.stockName}>Panel :</Text>
                        <Text style={styles.stockQuantity}>{stockData.panel}</Text>
                    </View>

                    <TouchableOpacity style={styles.stockItem} onPress={handlePoleClick}>
                        <Text style={styles.stockName}>Pole :</Text>
                        <Text style={styles.seeDetailsText}>See details</Text>
                    </TouchableOpacity>

                    <View style={styles.stockItem}>
                        <Text style={styles.stockName}>Cement :</Text>
                        <Text style={styles.stockQuantity}>{stockData.cement} bag</Text>
                    </View>

                    <View style={styles.stockItem}>
                        <Text style={styles.stockName}>Msand :</Text>
                        <Text style={styles.stockQuantity}>{stockData.msand} ton</Text>
                    </View>

                    <View style={styles.stockItem}>
                        <Text style={styles.stockName}>Jalli :</Text>
                        <Text style={styles.stockQuantity}>{stockData.jalli} ton</Text>
                    </View>

                    <View style={styles.stockItem}>
                        <Text style={styles.stockName}>Steel :</Text>
                        <Text style={styles.stockQuantity}>{stockData.steel} ton</Text>
                    </View>
                </View>
            )}
            <View style={styles.buttonView}>
                <Button
                    onPress={handleLoading}
                    btnLabel={'Loading'}
                    buttonStyle={styles.loadingBtnContainer}
                    textStyle={styles.btnText}
                />
                <Button
                    onPress={handleUnloading}
                    btnLabel={'Unloading'}
                    buttonStyle={styles.unloadingBtnContainer}
                    textStyle={styles.btnText}
                />
            </View>

            <Modal
                visible={modalVisible}
                transparent={true}
                animationType="slide"
                onRequestClose={() => setModalVisible(false)}
            >
                <View style={styles.modalOverlay}>
                    <View style={styles.modalContainer}>
                        <TouchableOpacity style={styles.closeButton} onPress={() => setModalVisible(false)}>
                            <Text style={styles.closeButtonText}>×</Text>
                        </TouchableOpacity>
                        <Text style={styles.modalTitle}>Pole Details</Text>
                        <View style={styles.poleHeader}>
                            <Text style={styles.poleHeaderText}>Pole Type</Text>
                            <Text style={styles.poleHeaderText}>Quantity</Text>
                        </View>
                        {poleData ? <FlatList
                            data={Object.entries(poleData).map(([key, value]) => ({ key, value }))}
                            renderItem={renderPoleItem}
                            keyExtractor={(item) => item.key}
                        /> : <Text style={styles.poleHeaderText}> No Data Found</Text>}
                    </View>
                </View>
            </Modal>

            <Modal
                visible={isOpen}
                transparent={true}
                onRequestClose={closeModal}
                animationType="fade">
                {/* <TouchableWithoutFeedback onPress={closeModal}> */}
                <View style={styles.editModalContainer}>
                    <View style={styles.editModalContent}>
                        <Text style={styles.editModalTitle}>Edit Plant</Text>
                        <TextInput
                            style={styles.input}
                            value={plant}
                        />
                        <TextInput
                            style={styles.input}
                            value={panelSize}
                        />

                        <View style={[styles.rowContainer, { marginBottom: 20 }]}>
                            <Text style={styles.selectSupervisorText}>Select Supervisor : </Text>
                            <MultiSelect
                                isOpen={isMultiSelectOpen}
                                onClose={() => setIsMultiSelectOpen(false)}
                                options={supervisorItems}
                                selectedValues={supervisorValue}
                                onSelect={(selectedItems) => {
                                    setSupervisorValue(selectedItems)
                                }}
                                label="Select Supervisors..."
                            />
                        </View>
                        {supervisorValue?.length > 0 && <View style={styles.badgeContainer}>
                            {supervisorValue.map((tag, index) => (
                                <View key={index} style={[styles.tagContainer, { backgroundColor: getColor(index) }]}>
                                    <Text style={styles.tagText}>{tag}</Text>
                                </View>
                            ))}
                        </View>}
                        <View style={{ marginTop: 18 }}>
                            <Button onPress={savePlant} btnLabel="SAVE" buttonStyle={styles.saveButton} />
                        </View>
                    </View>
                </View>
                {/* </TouchableWithoutFeedback> */}
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f8f8f8',
    },
    headerRight: {
        paddingRight: 10
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    stockContainer: {
        padding: 20,
    },
    stockHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        marginBottom: 10,
    },
    stockNameText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#808080',
    },
    quantityText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#808080',
    },
    stockItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 10,
        borderBottomWidth: 2,
        borderBottomColor: '#ccc',
    },
    stockName: {
        fontSize: 18,
        fontWeight: 'bold',
        width: '45%',
    },
    stockQuantity: {
        fontSize: 18,
        fontWeight: 'bold',
        width: '40%',
    },
    seeDetailsText: {
        fontSize: 18,
        fontWeight: 'bold',
        width: '40%',
        color: Colors.blue
    },
    panelQuantityContainer: {
        borderWidth: 1,
        borderColor: '#000',
        padding: 5,
        borderRadius: 5,
        backgroundColor: '#fff',
    },
    buttonView: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        padding: 12,
        position: 'absolute',
        bottom: 0,
        backgroundColor: '#fff'
    },
    loadingBtnContainer: {
        paddingVertical: 20,
        paddingHorizontal: 40,
        backgroundColor: Colors.accent,
        flex: 1,
        marginHorizontal: 5,
        alignItems: 'center'
    },
    unloadingBtnContainer: {
        paddingVertical: 20,
        paddingHorizontal: 40,
        backgroundColor: Colors.secondary,
        flex: 1,
        marginHorizontal: 5,
        alignItems: 'center'
    },
    btnText: {
        color: 'white',
        textAlign: 'center',
    },
    modalOverlay: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modalContainer: {
        width: '80%',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        alignItems: 'center'
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        borderRadius: 15,
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center'
    },
    closeButtonText: {
        color: '#808080',
        fontSize: 32,
        lineHeight: 32
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10
    },
    poleHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 15,
        marginBottom: 10,
        paddingBottom: 5,
        width: '100%'
    },
    poleHeaderText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#808080'
    },
    poleItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        width: '100%'
    },
    poleItemKey: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    poleItemValue: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    editModalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    editModalContent: {
        width: '80%',
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        elevation: 5,
    },
    editModalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center'
    },
    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 10,
        paddingHorizontal: 10,
        borderRadius: 5,
    },
    rowContainer: {
        flexDirection: 'row',
        width: '90%',
    },
    selectSupervisorText: {
        marginTop: 10,
    },
    badgeContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        borderWidth: 2,
        borderRadius: 5,
        borderColor: '#ccc',
        width: '100%',
    },
    tagContainer: {
        backgroundColor: '#E0FFFF',
        borderRadius: 20,
        paddingHorizontal: 10,
        paddingVertical: 5,
        margin: 5,
    },
    tagText: {
        color: '#000',
    },
    saveButton: {
        backgroundColor: Colors.primary,
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
});

export default StockManagementScreen;
