const parseDate = (dateString) => {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var parts = dateString?.split(' ');
    var day = parseInt(parts[0]);
    var monthIndex = months.indexOf(parts[1]);
    var year = parseInt(parts[2]);
    return new Date(year, monthIndex, day);
}

const convertToDateTime = (dateString) => {
    const months = {
        Jan: '01', Feb: '02', Mar: '03', Apr: '04',
        May: '05', Jun: '06', Jul: '07', Aug: '08',
        Sep: '09', Oct: '10', Nov: '11', Dec: '12'
    };

    const dateParts = dateString.split(' ');
    const month = months[dateParts[1]];
    const day = dateParts[0];
    const year = dateParts[2];

    return `${year}-${month}-${day}`;
}

const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
};

const formatDateAsDDMMYY = (dateString) => {
    const date = parseDate(dateString);
    if (!date || isNaN(date)) {
        return 'Invalid Date';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
};

export { parseDate, convertToDateTime, formatDate, formatDateAsDDMMYY };
