import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Feather from '@expo/vector-icons/Feather';


const CustomCheckbox = ({ label, isChecked, onChange, style }) => {
    return (
        <TouchableOpacity style={[styles.checkboxContainer, style]} onPress={onChange}>
            <View style={[styles.checkbox]}>
                {isChecked && <Feather name="check" size={18} color="black" />}
            </View>
            <Text style={styles.label}>{label}</Text>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    checkbox: {
        width: 20,
        height: 20,
        borderWidth: 1,
        borderColor: '#000',
    },
    checkedCheckbox: {
        backgroundColor: '#000',
    },
    label: {
        fontSize: 12,
        fontWeight: '500',
        marginLeft: 6
    },
});


export default CustomCheckbox;
