import React, { useEffect, useState } from 'react';
import { View, Text, TextInput, StyleSheet, ImageBackground, TouchableWithoutFeedback } from 'react-native';
import { encode as base64Encode } from 'base-64';
import { USERS_API } from '../env-config/api-config';
import axios from "axios";
import useDataStore from '../stores/dataStore';
import background from "../assets/background.png";
import Button from '../components/Button';
import { Colors } from '../constants/theme';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import MixpanelTracker from '../utils/MixpanelTracker';
import { EVENT_ACTIONS } from '../constants/Constant';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { makeApiRequest } from '../utils/apiUtil';

const ChangePassScreen = () => {
    const [oldPin, setOldPin] = useState('');
    const [newPin, setNewPin] = useState('');
    const [renewPin, setRenewPin] = useState('');
    const saltRounds = 100;
    const [userData, setUserData, setToastMessage, setAuthToken, setRefreshToken] = useDataStore((state) => [state.userData, state.setUserData, state.setToastMessage, state.setAuthToken, state.setRefreshToken]);
    const [error, setError] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const navigation = useNavigation();
    const isFocused = useIsFocused();

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CHANGE_PIN_CLICKED, userData);
            setSuccessMsg(null);
            setOldPin("");
            setNewPin("");
            setRenewPin("");
            setError("");
        }
    }, [isFocused]);

    const validateForm = () => {
        setSuccessMsg(null)
        if (!oldPin) {
            setError('Please enter a old pin.');
            return false;
        }

        if (!newPin) {
            setError('Please enter a new pin.');
            return false;
        }

        if (!renewPin) {
            setError('Please enter a new pin.');
            return false;
        }

        if (!/^\d{4}$/.test(newPin)) {
            setError('Please enter a valid 4-digit PIN.');
            return;
        }

        if (newPin !== renewPin) {
            setError('Please enter both new PIN same.');
            return;
        }
        if (newPin === oldPin) {
            setError('Please enter different PIN.');
            return;
        }
        setError('');
        return true;
    };

    const handleLogin = async () => {
        if (validateForm()) {
            try {
                const enteredHashedPassword = hashPassword(oldPin, saltRounds);
                let newUserName = userData.username.toLowerCase();

                const userLogin = await axios.post(`${USERS_API}/login`, { username: newUserName, password: oldPin });
                const { idToken, refreshToken } = userLogin.data

                setAuthToken(idToken);
                setRefreshToken(refreshToken);
                await AsyncStorage.setItem('refreshToken', refreshToken);
                if (idToken) {
                    const data = await makeApiRequest('put', `${USERS_API}/changePIN`, idToken, refreshToken, setAuthToken, setRefreshToken, {
                        username: newUserName,
                        password: renewPin,
                    });
                    setOldPin("");
                    setNewPin("");
                    setRenewPin("");
                    setToastMessage("PIN updated successfully");
                    MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.CHANGE_PIN_BUTTON_CLICKED, userData);
                    navigation.goBack();
                } else {
                    setError('Invalid Old PIN. Please enter the correct PIN.');
                    console.log('Invalid password', 'Please enter the correct password.');
                }
            } catch (error) {
                console.log('Error: ', error);
                setError('Invalid Old PIN. Please enter the correct PIN.');
            }
        }
    };

    const hashPassword = (password, salt) => {
        // Simple hash function using concatenation and Base64 encoding
        const hashed = base64Encode(password + salt);
        return hashed;
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <ImageBackground source={background} style={styles.background}>
            <View style={styles.container}>
                <View style={styles.pinContainer}>
                    <TextInput
                        style={styles.input}
                        placeholder="Enter Old PIN"
                        onChangeText={text => setOldPin(text)}
                        value={oldPin}
                        keyboardType="numeric"
                        secureTextEntry={!showPassword}
                        maxLength={4}
                    />
                    <TouchableWithoutFeedback onPress={togglePasswordVisibility}>
                        <View style={styles.eyeButton}>
                            {/* You can use your eye icon here */}
                            {showPassword ? (
                                <MaterialCommunityIcons name="eye" size={20} color="#000" />
                            ) : (
                                <MaterialCommunityIcons name="eye-off" size={20} color="#000" />
                            )}
                        </View>
                    </TouchableWithoutFeedback>
                </View>
                <View style={styles.pinContainer}>
                    <TextInput
                        style={styles.input}
                        placeholder="Enter New PIN"
                        onChangeText={text => setNewPin(text)}
                        value={newPin}
                        keyboardType="numeric"
                        maxLength={4}
                    />
                </View>
                <View style={styles.pinContainer}>
                    <TextInput
                        style={styles.input}
                        placeholder="Re-enter New PIN"
                        onChangeText={text => setRenewPin(text)}
                        value={renewPin}
                        keyboardType="numeric"
                        maxLength={4}
                    />
                </View>
                {error && <Text style={styles.errorText}>{error}</Text>}
                {successMsg && <Text style={styles.succText}>{successMsg}</Text>}
                <Button
                    onPress={handleLogin}
                    btnLabel="Change PIN"
                    buttonStyle={styles.loginButton}
                    textStyle={styles.buttonText} />
            </View>
        </ImageBackground>
    );
};

const styles = StyleSheet.create({
    background: {
        flex: 1,
        resizeMode: 'cover',
        justifyContent: 'center',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white
        padding: 20,
    },
    input: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        padding: 10,
        marginBottom: 20,
        backgroundColor: '#fff',
    },
    pinContainer: {
        width: '80%',
        position: 'relative',
    },
    eyeButton: {
        position: 'absolute',
        right: 10,
        padding: 10,
    },
    loginButton: {
        backgroundColor: Colors.blue, // Blue color
        width: '60%',
        paddingVertical: 15,
        paddingHorizontal: 30,
        marginTop: 10,
    },
    buttonText: {
        color: '#fff',
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
    },
    succText: {
        color: 'green',
        marginBottom: 10,
    },
});

export default ChangePassScreen;
