import { ActivityIndicator, StyleSheet, View } from "react-native";
import { Colors } from "../constants/theme";

const Loader = ({ size, color = Colors.primary }) => {
    return (
        <View style={[styles.container, styles.horizontal]}>
            <ActivityIndicator size={size} color={color} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    horizontal: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: 10,
    },
});

export default Loader;
