import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { StyleSheet, TextInput, TouchableOpacity, View, Text, ActivityIndicator } from 'react-native';
import { Colors } from '../constants/theme';
import { FontAwesome5 } from '@expo/vector-icons';
import Button from '../components/Button';
import useDataStore from '../stores/dataStore';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { EVENT_ACTIONS } from '../constants/Constant';
import { formatDate } from '../utils/dateUtil';
import { removeLeadingZero } from '../utils/commonUtils';
import { makeApiRequest } from '../utils/apiUtil';
import DateTimePicker from 'react-native-ui-datepicker';
import Popup from "../components/popup";

const AddExtraExpenseScreen = ({ route }) => {

    let { userID, existingAmount, existingComment, transactionId } = route.params;
    const [amount, setAmount] = useState('');
    const [comment, setComment] = useState('');
    const [error, setError] = useState('');
    const [isFocus, setIsFocus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
    const [userData, API_CONFIG, setToastMessage, employeeData, authToken, setAuthToken, refreshToken, setRefreshToken] = useDataStore((state) => ([state.userData, state.API_CONFIG, state.setToastMessage, state.employeeData, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken]));
    const [chosenDate, setChosenDate] = useState(new Date());
    const navigation = useNavigation();

    let today = new Date();
    let time = new Date();
    today.setHours(0, 0, 0, 0);

    useEffect(() => {
        if (existingAmount && existingComment) setAmount(existingAmount); setComment(existingComment);
    }, [existingAmount, existingComment])

    useLayoutEffect(() => {
        navigation.setOptions({
            title: `${employeeData[userID]?.name}`
        })
    });

    const showDatePicker = () => {
        setDatePickerVisibility(true);
    };

    const handleConfirm = (date) => {
        setChosenDate(date);
        hideDatePicker();
    };

    const hideDatePicker = () => {
        setDatePickerVisibility(false);
    };

    const validateExpense = () => {
        if (!amount) {
            setError('Please enter amount.');
            return false;
        }
        else if (!comment) {
            setError('Please enter your comment.');
            return false;
        }
        setError('');
        return true;
    };

    const submitExtraExpense = async () => {
        if (validateExpense()) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EXTRA_EXPENSE_CLICKED, userData)
            setLoading(true);
            if (existingAmount) {
                try {
                    let url = `${API_CONFIG.UNSETTLED_EXPENSES}/extraExpenses/${userID}/${transactionId}`;
                    let payload = {
                        amount: amount,
                        date: formatDate(chosenDate),
                        time: chosenDate.toDateString() !== today.toDateString() ? "11:59:59 PM" : time.toLocaleTimeString("en-US"),
                        userId: `${userID}`,
                        actionBy: userData.username,
                        comment: comment
                    };
                    const data = await makeApiRequest("put", url, authToken, refreshToken, setAuthToken, setRefreshToken, payload);
                    console.log('Updating Extra Expense:', data);
                    setAmount("");
                    setComment("");
                    setLoading(false);
                    setToastMessage("Expense Updated Successfully");
                    navigation.goBack();
                } catch (err) {
                    setLoading(false);
                    console.error('Error on updated expense:', err);
                    handleAlertForError(err);
                }
            } else {
                try {
                    let url = `${API_CONFIG.UNSETTLED_EXPENSES}/extraExpenses`;
                    let payload = {
                        amount: amount,
                        date: formatDate(chosenDate),
                        time: chosenDate.toDateString() !== today.toDateString() ? "11:59:59 PM" : time.toLocaleTimeString("en-US"),
                        userId: `${userID}`,
                        actionBy: userData.username,
                        comment: comment,
                    };
                    const data = await makeApiRequest('post', url, authToken, refreshToken, setAuthToken, setRefreshToken, payload)
                    console.log('Pushing Extra Expense:', data);
                    setAmount("");
                    setComment("");
                    setLoading(false);
                    setToastMessage("Expense Added Successfully");
                    navigation.goBack();
                } catch (error) {
                    setLoading(false);
                    console.error('Error on add expense:', error);
                    handleAlertForError(error);
                }
            }
        }
    };

    return (
        <View style={styles.container}>
            {loading && (
                <View style={styles.overlay}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )}
            <TextInput
                placeholder='Enter Extra Expense Amount'
                value={amount}
                keyboardType="numeric"
                onChangeText={(text) => setAmount(removeLeadingZero(text))}
                style={[styles.input, isFocus === 'amount' && { borderColor: Colors.primary }]}
                onFocus={() => setIsFocus('amount')}
            />
            <TouchableOpacity onPress={showDatePicker}>
                <View style={styles.view}>
                    <FontAwesome5
                        name="calendar"
                        size={24}
                        color={Colors.primary}
                    />
                    <Text style={styles.text}> {chosenDate.toDateString()}</Text>
                    <FontAwesome5
                        name="caret-down"
                        size={24}
                        color={Colors.primary}
                    />
                    <Popup visible={isDatePickerVisible} onClose={() => setDatePickerVisibility(false)}>
                        <DateTimePicker
                            mode="single"
                            date={chosenDate}
                            maxDate={today}
                            onChange={(param) => {
                                handleConfirm(new Date(param.date));
                                setDatePickerVisibility(false)
                            }}
                            headerButtonStyle={{ color: 'green' }}
                        />
                    </Popup>
                </View>
            </TouchableOpacity>
            <TextInput
                style={[styles.textInput, isFocus === 'comment' && { borderColor: Colors.primary }]}
                placeholder="Write your comment here..."
                value={comment}
                onChangeText={(text) => setComment(text)}
                onFocus={() => setIsFocus('comment')}
            />
            {error ? <Text style={{ color: 'red', fontSize: 16, top: 10, alignSelf: "center" }}>{error}</Text> : null}
            <Button
                btnLabel={`Extra Expense ₹${amount}`}
                buttonStyle={styles.BtnContainer}
                textStyle={styles.btnText}
                onPress={submitExtraExpense}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    overlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
    },
    input: {
        borderWidth: 2,
        borderColor: '#636363',
        margin: 10,
        padding: 12,
        fontSize: 18,
        borderRadius: 6,
        width: '90%'
    },
    view: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 55,
        borderColor: '#636363',
        borderRadius: 6,
        paddingHorizontal: 8,
        margin: 12,
        padding: 12,
        width: '55%',
        backgroundColor: "white"
    },
    text: {
        marginLeft: 10,
        marginRight: 10
    },
    textInput: {
        borderWidth: 2,
        borderColor: '#636363',
        margin: 10,
        padding: 12,
        fontSize: 16,
        borderRadius: 6,
        width: '90%'
    },
    BtnContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 40,
        paddingRight: 40,
        width: '90%',
        position: 'absolute',
        bottom: 10,
        margin: 20,
        backgroundColor: Colors.primary
    },
    btnText: {
        color: 'white',
    }
});

export default AddExtraExpenseScreen