import React, { useEffect, useState } from 'react'
import { View, Text, FlatList, StyleSheet } from 'react-native';
import Search from '../components/Search';
import List from '../components/List';
import useDataStore from '../stores/dataStore';
import Loader from '../components/Loader';
import { useIsFocused } from '@react-navigation/native';
import MixpanelTracker from '../utils/MixpanelTracker';
import { EVENT_ACTIONS } from '../constants/Constant';
import { makeApiRequest } from '../utils/apiUtil';

const SuppliersScreen = () => {

    const [supplierKey, setSupplierKey] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [supplierData, setSupplierData, API_CONFIG, userData, authToken, setAuthToken, refreshToken, setRefreshToken] = useDataStore((state) => [state.supplierData, state.setSupplierData, state.API_CONFIG, state.userData, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken]);
    const [loading, setLoading] = useState(true);
    const isFocused = useIsFocused();

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SUPPLIER_TAB, userData);
            fetchData();
        }
    }, [isFocused]);

    const fetchData = async () => {
        try {
            const data = await makeApiRequest('get', API_CONFIG.SUPPLIERS, authToken, refreshToken, setAuthToken, setRefreshToken);
            setSupplierData(data);
            setSupplierKey(Object.keys(data));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching suppliers:', error);
        }
    };

    const filteredSupplier = supplierKey?.filter(key => {
        return supplierData[key]['name'].toLowerCase().includes(searchQuery.toLowerCase()) && !supplierData[key]['isDeleted']
    });

    return (
        <View style={styles.container}>
            <Search value={searchQuery} onChangeText={setSearchQuery} placeholder="Search Supplier" />
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) : filteredSupplier?.length > 0 ? (
                <FlatList
                    data={filteredSupplier}
                    renderItem={({ item: key }) => (
                        <List
                            key={key}
                            name={supplierData[key]['name']}
                            contact={supplierData[key]['contact']}
                            actionBy={supplierData[key]['actionBy']}
                            amount={supplierData[key]['absolute_amount']}
                            type={'Suppliers'}
                            userID={key}
                        />
                    )}
                    keyExtractor={(item) => item}
                />
            ) : (
                <View style={styles.noSupContainer}>
                    {searchQuery ? <Text> No Suppliers Found</Text> : <Text style={styles.noSupText}>No Suppliers Added</Text>}
                </View>
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    noSupContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noSupText: {
        textAlign: 'center',
        fontSize: 15,
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default SuppliersScreen;
