import React, { useEffect, useState, useLayoutEffect } from 'react';
import { View, StyleSheet, FlatList, Text } from 'react-native'
import useDataStore from '../stores/dataStore';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import Loader from '../components/Loader';
import List from '../components/List';
import { handleAlertForError } from '../utils/handleError';
import MixpanelTracker from '../utils/MixpanelTracker';
import { EVENT_ACTIONS } from '../constants/Constant';
import { makeApiRequest } from '../utils/apiUtil';
import { Colors } from '../constants/theme';
import Button from '../components/Button';

const ProductionExpense = ({ route }) => {
    const navigation = useNavigation();
    const { plantName } = route.params || { plantName: null };
    const [thekedarData, setThekedarData, API_CONFIG, userData, authToken, refreshToken, setAuthToken, setRefreshToken] = useDataStore((state) => [state.thekedarData, state.setThekedarData, state.API_CONFIG, state.userData, state.authToken, state.refreshToken, state.setAuthToken, state.setRefreshToken]);
    const [thekedarsKey, setThekedarsKey] = useState(null);
    const [loading, setLoading] = useState(true);
    const isFocused = useIsFocused();

    useLayoutEffect(() => {
        navigation.setOptions({
            title: "Production Expense",
        });

    }, [navigation]);

    useEffect(() => {
        if (isFocused) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_SCREEN, userData);
            fetchData();
        }
    }, [isFocused]);

    const addThekedar = async () => {
        navigation.navigate('AddDetail', { screen: "Production", plantName: plantName });
        MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_THEKEDAR, userData);
    };

    const fetchData = async () => {
        try {
            const data = await makeApiRequest('get', `${API_CONFIG.THEKEDARS}/${plantName}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            setThekedarData(data);
            setThekedarsKey(Object.keys(data));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching thekedars:', error);
            handleAlertForError(error);
        }
    };

    return (
        <View style={styles.container}>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) : thekedarsKey?.length > 0 ? (
                <FlatList
                    data={thekedarsKey}
                    renderItem={({ item: key }) => (
                        !thekedarData[key]['isDeleted'] && <List
                            key={key}
                            name={thekedarData[key]['name']}
                            contact={thekedarData[key]['contact']}
                            actionBy={thekedarData[key]['actionBy']}
                            amount={thekedarData[key]['absolute_amount']}
                            type={'Thekedars'}
                            userID={key}
                            plantName={plantName}
                        />
                    )}
                    keyExtractor={(item) => item}
                />
            ) : (
                <View style={styles.noThekedarContainer}>
                    <Text style={styles.noThekedarText}>No Thekedar Added</Text>
                </View>
            )}
            <View style={styles.buttonContainer}>
                <Button
                    onPress={addThekedar}
                    btnLabel="ADD THEKEDAR"
                    buttonStyle={styles.btnAddThekedar} />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noThekedarContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noThekedarText: {
        textAlign: 'center',
        fontSize: 15,
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 10,
        width: '100%',
        alignItems: 'center',
    },
    btnAddThekedar: {
        paddingVertical: 20,
        width: '90%',
        backgroundColor: Colors.primary,
    },
});

export default ProductionExpense
